// helper functions for dealing with request objects

// state constants
export const LOADED = 'LOADED';
export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const ABSENT = 'ABSENT';
export const states = [LOADED, LOADING, ERROR, ABSENT];

// request shape creators
export const onRequest = () => ({ loading: true, error: null });
export const onError = error => ({ loading: false, error });
export const onSuccess = () => ({ loading: false, error: null });

// get state from request object
export const requestState = request => {
  if (!request) {
    return ABSENT;
  } else if (request.error) {
    return ERROR;
  } else if (request.loading) {
    return LOADING;
  } else {
    return LOADED;
  }
};

export default requestState;
