import { connect } from 'react-redux';
import AddTeamModal from './AddTeamPage';
import {
  mapDispatchToAddTeamPage,
  mapStateToAddTeamPage
} from './addTeamPageMappings';
export default connect(
  mapStateToAddTeamPage,
  mapDispatchToAddTeamPage
)(AddTeamModal);
