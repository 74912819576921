import createReducer from 'redux/createReducer';
import { GET_MEMBERS_SUCCESS } from '../teamActionTypes';

export const members = createReducer(
  {},
  {
    [GET_MEMBERS_SUCCESS]: (state, { payload }) =>
      payload
        ? payload.reduce(
            (members, member) => ({ ...members, [member.id]: member }),
            {}
          )
        : state
  }
);

export default members;
