import React, { Component } from 'react';
import Checkbox from 'components/Checkbox';
import moment from 'moment';
import ImageContainer from 'components/CompanyPages/lib/ImageContainer';
import Money from 'components/Money';
import './ExpensedTrip.css';

const dateFormat = 'ddd MMM DD';
const timeFormat = 'LT';

export default class ExpensedTrip extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onCheck = this.onCheck.bind(this);
  }

  onCheck() {
    const { trip, onCheck } = this.props;

    onCheck && onCheck(trip.id);
  }

  onClick() {
    const { id, onClick } = this.props;

    onClick && onClick(id);
  }

  render() {
    const {
      checked,
      trip,
      unitOfMeasure,
      purpose,
      vehicle,
      getUser,
      distance,
      selected
    } = this.props;

    const {
      startTime,
      endTime,
      departureAddress,
      destinationAddress,
      description,
      expense,
      purposeId,
      tripType
    } = trip || {};

    const { email, image } = getUser() || {};

    return (
      <div
        onClick={this.onClick}
        className={`expensed-trip${selected ? ' selected-expensed-trip' : ''}`}
      >
        <div className="expensed-trip-first-column">
          <Checkbox
            className="expensed-trip-check-box"
            onChange={this.onCheck}
            label={moment(startTime).format(dateFormat)}
            checked={checked}
          />
          <ImageContainer
            className="expensed-trip-user-image"
            image={image}
            email={email}
          />
        </div>
        <div className="expensed-trip-second-column">
          <h1 className="expensed-trip-row-one">
            {moment(startTime).format(timeFormat)}
          </h1>
          <h1 className="expensed-trip-row-two">
            {moment(endTime).format(timeFormat)}
          </h1>
          <h1 className="expensed-trip-row-three">
            {`${distance} ${unitOfMeasure}`}
          </h1>
        </div>
        <div className="expensed-trip-third-column">
          <h1 className="expensed-trip-user-name">{email}</h1>
          <h1 className="expensed-trip-row-one">{departureAddress}</h1>
          <h1 className="expensed-trip-row-two">{destinationAddress}</h1>
          <h1 className="expensed-trip-row-three">
            {vehicle && vehicle.nickname}
          </h1>
          <h1 className="expensed-trip-description">{description}</h1>
        </div>
        <div className="expensed-trip-fourth-column">
          <div className="expensed-trip-fourth-column-data">
            <h1 className="expensed-trip-cost">
              <Money
                purposeId={purposeId}
                date={startTime}
                type={tripType}
                cost={expense}
                distance={distance}
              />
            </h1>
            <h1 className="expensed-trip-purpose">
              {purpose || 'Unclassified'}
            </h1>
          </div>
        </div>
      </div>
    );
  }
}
