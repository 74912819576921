import { connect } from 'react-redux';
import CompanyAccountsCardInfo from './CompanyAccountsCardInfo';
import { injectStripe } from 'react-stripe-elements';
import {
  mapStateToCompanyAccountsCardInfo,
  mapDispatchToCompanyAccountsCardInfo
} from './companyAccountsCardInfoMappings';

export default connect(
  mapStateToCompanyAccountsCardInfo,
  mapDispatchToCompanyAccountsCardInfo
)(injectStripe(CompanyAccountsCardInfo));
