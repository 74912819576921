import _ from 'lodash';
import locales from 'locales.json';
import { numeric } from 'lib/normalizers';
import { getUsersCountry } from 'ducks/countries/selectors';
import {
  getDistanceMeasure,
  getActive,
  getCurrentUser
} from 'ducks/users/selectors';

export const mapStateToRateInput = (state, { activeUser }) => {
  const user = (activeUser ? getActive : getCurrentUser)(state);
  return {
    normalize: value => {
      const country = getUsersCountry(state);
      return country && numeric(country.countryCode, value);
    },
    distanceMeasure: getDistanceMeasure(state),
    symbol: user ? _.get(locales[user.countryCode], 'currencySymbol', '$') : '$'
  };
};
