import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { deleteCompanyPurpose as deletePurpose } from 'api/endpoints/company/purposes';
import {
  DELETE_COMPANY_PURPOSE_REQUEST,
  DELETE_COMPANY_PURPOSE_SUCCESS,
  DELETE_COMPANY_PURPOSE_FAILURE
} from 'ducks/company/companyActionTypes';

export const deleteCompanyPurpose = id => dispatch =>
  dispatch(
    createSimpleAction(deletePurpose, [
      DELETE_COMPANY_PURPOSE_REQUEST,
      DELETE_COMPANY_PURPOSE_SUCCESS,
      DELETE_COMPANY_PURPOSE_FAILURE
    ])(id)
  ).then(() => dispatch({ type: DELETE_COMPANY_PURPOSE_SUCCESS, payload: id }));

export default deleteCompanyPurpose;
