import React, { Component } from 'react';
import debounce from 'debounce';
import Textbox from 'components/TextBox';

export default class AsyncTextbox extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value || '' };
    this.onChange = this.onChange.bind(this);
    this.debouncedOnChange = debounce(props.onChange, 600);
  }

  componentWillReceiveProps({ onChange }) {
    this.debouncedOnChange = debounce(onChange, 600);
  }

  onChange(event) {
    const { value } = this.state;
    const { normalize } = this.props;
    const { value: newValue } = event.target;
    const normalized = normalize ? normalize(newValue) : newValue;

    if (normalized !== value) {
      this.setState({ value: normalized });
      this.debouncedOnChange(normalized);
    }
  }

  render() {
    const {
      value: _value,
      onChange: _onChange,
      normalize: _normalize,
      ...props
    } = this.props;

    const { value } = this.state;

    return <Textbox onChange={this.onChange} value={value} {...props} />;
  }
}
