import React from 'react';
import CompactTrip from '../CompactTrip';
const createCompactTrip = ({ id, ...trip }) => (
  <CompactTrip trip={trip} key={id} id={id} />
);
export default ({ trips, date }) => (
  <div className="compact-trip-group">
    <div className="compact-trip__date">{date}</div>
    {trips.map(createCompactTrip)}
  </div>
);
