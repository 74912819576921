import countryList from './countries';
import {
  COUNTRIES_USING_COMMAS_TO_PERIOD,
  COUNTRIES_USING_COMMAS_TO_SQUARE,
  COUNTRIES_USING_PERIODS_TO_COMMA,
  COUNTRIES_USING_SPACES_TO_COMMA
} from './countryCurrencyFormats';

const countries = countryList.countries.reduce((countryMap, country) => {
  countryMap[country.full_name] = country;
  return countryMap;
}, {});

const ALLOWED_DECIMAL_PLACES = 3;
const DEFAULT_DECIMAL = '.';
const EMPTY_STRING = '';
const PLACE_HOLDER = '0';

const spacesToComma = stringNumber =>
  format(stringNumber, ' ', ',', ALLOWED_DECIMAL_PLACES);

const periodsToComma = stringNumber =>
  format(stringNumber, '.', ',', ALLOWED_DECIMAL_PLACES);

const commasToPeriod = stringNumber =>
  format(stringNumber, ',', '.', ALLOWED_DECIMAL_PLACES);

const commasToSquare = stringNumber =>
  format(stringNumber, ',', '·', ALLOWED_DECIMAL_PLACES);

const getCountryCode = countryName =>
  countries[countryName] && countries[countryName].country_code;

const divisibleByThree = number => !(number % 3);

const setFormat = (formats, countryName, format) => {
  const countryCode = getCountryCode(countryName);
  if (countryCode) formats[countryCode] = format;
  return formats;
};

export const formatListOfCountries = (list, format) => {
  return list.reduce(
    (formats, countryName) => setFormat(formats, countryName, format),
    {}
  );
};

const populateDecimalMarks = (list, decimalMark) => {
  return list.reduce((codes, countryName) => {
    const countryCode = getCountryCode(countryName);
    if (countryCode) codes[countryCode] = decimalMark;
    return codes;
  }, {});
};

const representations = {
  ...formatListOfCountries(COUNTRIES_USING_COMMAS_TO_PERIOD, commasToPeriod),
  ...formatListOfCountries(COUNTRIES_USING_COMMAS_TO_SQUARE, commasToSquare),
  ...formatListOfCountries(COUNTRIES_USING_PERIODS_TO_COMMA, periodsToComma),
  ...formatListOfCountries(COUNTRIES_USING_SPACES_TO_COMMA, spacesToComma),
  DEFAULT: commasToPeriod
};

const DECIMAL_MARKS_BY_COUNTRY = {
  ...populateDecimalMarks(COUNTRIES_USING_COMMAS_TO_PERIOD, '.'),
  ...populateDecimalMarks(COUNTRIES_USING_COMMAS_TO_SQUARE, '·'),
  ...populateDecimalMarks(
    COUNTRIES_USING_PERIODS_TO_COMMA.concat(COUNTRIES_USING_SPACES_TO_COMMA),
    ','
  )
};

export const getDecimalMarkByCountryCode = countryCode =>
  DECIMAL_MARKS_BY_COUNTRY[countryCode] || DEFAULT_DECIMAL;

const getDecimalPosition = (number, decimalMark) => {
  const stringNumber = toString(number);
  let positionOfDecimal = stringNumber.length;
  while (
    positionOfDecimal-- &&
    stringNumber[positionOfDecimal] !== decimalMark
  );
  return positionOfDecimal;
};

export const toString = number => `${number}`;

export const splitAtDecimal = (stringNumber, decimalMark = DEFAULT_DECIMAL) => {
  const decimalLocation = getDecimalPosition(stringNumber, decimalMark);
  return decimalLocation > -1
    ? [
        stringNumber.slice(0, decimalLocation),
        stringNumber.slice(decimalLocation + 1),
        decimalMark
      ]
    : [stringNumber, EMPTY_STRING, EMPTY_STRING];
};

export const splitAtThousands = number => {
  const numbersSplitAtThousands = [];
  const numberArray = number
    .slice()
    .split('')
    .reverse();
  let index = numberArray.length;
  let currentGrouping = EMPTY_STRING;
  while (index--) {
    currentGrouping += numberArray[index];
    if (divisibleByThree(index)) {
      numbersSplitAtThousands.push(currentGrouping);
      currentGrouping = EMPTY_STRING;
    }
  }
  return numbersSplitAtThousands.length ? numbersSplitAtThousands : [number];
};

export const removeLeadingZeros = (
  stringNumber,
  decimalMark = DEFAULT_DECIMAL
) => {
  const decimalPosition = getDecimalPosition(stringNumber, decimalMark);
  const onesPosition =
    (decimalPosition > -1 ? decimalPosition : stringNumber.length) - 2;
  let index = 0;
  while (index < onesPosition && stringNumber[index] === PLACE_HOLDER) {
    index += 1;
  }
  return stringNumber.slice(index) || '';
};

export const removeAllNonNumericChars = stringNumber => {
  const space = ' ';
  return (
    stringNumber &&
    stringNumber
      .split(EMPTY_STRING)
      .reduce(
        (numbers, char) =>
          numbers + (isNaN(char) || char === space ? EMPTY_STRING : char),
        EMPTY_STRING
      )
  );
};

export const toNumber = (number, decimalMark) =>
  Number(toFormattedNumber(number, decimalMark));

export const toFormattedNumber = (number, decimalMark = DEFAULT_DECIMAL) => {
  const stringNumber = toString(number);
  const [wholeNumber, decimals, foundDecimal] = splitAtDecimal(
    stringNumber,
    decimalMark
  );

  return (
    removeAllNonNumericChars(wholeNumber) +
    ((foundDecimal && DEFAULT_DECIMAL) || EMPTY_STRING) +
    removeAllNonNumericChars(decimals)
  );
};

export const format = (
  number,
  delimiter,
  decimalMark,
  allowedDecimalPlaces
) => {
  const stringNumber = removeLeadingZeros(
    toFormattedNumber(number, (isNaN(number) && decimalMark) || undefined)
  );
  const [wholeNumber, decimals, foundDecimal] = splitAtDecimal(stringNumber);
  return (
    splitAtThousands(wholeNumber).join(delimiter) +
    ((foundDecimal && decimalMark) || EMPTY_STRING) +
    decimals.slice(0, allowedDecimalPlaces)
  );
};
export default (countryCode, number) =>
  (representations[countryCode] || commasToPeriod)(number);
