import { createFlatFilters } from './createFlatFilters';

export const getReportStatusFilters = () =>
  createFlatFilters(
    ['pending', 'approved', 'rejected', 'paid'],
    'Status',
    'check-square-o'
  );

export default getReportStatusFilters;
