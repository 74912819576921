import { deleteUserFromDb } from 'api/endpoints/user/users';
import { INVALIDATE } from 'ducks/session/actionTypes';
import { DELETE_USER_REQUEST, DELETE_USER_FAILURE } from '../actionTypes';

export const deleteUserRequest = payload => ({
  type: DELETE_USER_REQUEST,
  payload
});

export const deleteUserSuccess = payload => ({
  type: INVALIDATE,
  payload: { ...payload, redirecting: false },
  meta: {
    alert: {
      type: 'success',
      message: 'Account was successfully deleted'
    }
  }
});

export const deleteUserFailure = (error, payload) => ({
  type: DELETE_USER_FAILURE,
  payload,
  error
});

export const deleteUser = id => dispatch => {
  dispatch(deleteUserRequest(id));
  return deleteUserFromDb(id)
    .then(({ data }) => dispatch(deleteUserSuccess(data)))
    .catch(error => dispatch(deleteUserFailure(error, id)));
};

export default deleteUser;
