export const formatUserLocation = ({ coords: { latitude, longitude } }) => [
  latitude,
  longitude
];
export default () =>
  new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(
      coords => resolve(formatUserLocation(coords)),
      reject
    )
  );
