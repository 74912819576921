import { connect } from 'react-redux';
import { getBestMatchedVehicle } from 'ducks/vehicles/selectors';
import * as purposes from 'ducks/purposes/selectors';
import * as trips from 'ducks/trips/selectors';
import CompactTrip from './CompactTrip';

const mapStateToProps = (state, { id }) => {
  const selectedTrip = trips.getTrip(state, id);
  return {
    trip: selectedTrip,
    purpose: purposes.getById(state, selectedTrip.purposeId),
    vehicle: getBestMatchedVehicle(state, selectedTrip.vehicleId)
  };
};

export default connect(mapStateToProps)(CompactTrip);
