import { deselect } from './deselect';
import { deselectAll } from './deselectAll';
import { select } from './select';
import { selectAll } from './selectAll';
import { toggleSelection } from './toggleSelection';
import { toggleSelectAll } from './toggleSelectAll';
import { paginateActions } from 'ducks/lib/Pagination/paginateActions';
import { addPageToSelection } from './addPageToSelection';

export default paginateActions({
  deselect,
  deselectAll,
  select,
  selectAll,
  toggleSelection,
  toggleSelectAll
});
