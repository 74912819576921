import React, { Component } from 'react';
import TeamSelector from 'components/CompanyPages/lib/TeamSelector';
import SingleTeamSelector from 'components/CompanyPages/lib/SingleTeamSelector';
import RoleSelector from 'components/CompanyPages/lib/RoleSelector';
import EditedUserElement from 'components/CompanyPages/Admin/SettingsPage/UsersPage/EditUsersPage/EditedUserElement';
import EditedUsersElement from 'components/CompanyPages/Admin/SettingsPage/UsersPage/EditUsersPage/EditedUsersElement';
import { roleValues, rolesByName } from 'components/CompanyPages/lib/roles';
import AddButton from 'components/CompanyPages/lib/AddButton';
import RateInput from 'components/RateInput';
import './EditUsersPage.css';

export default class EditUsersPage extends Component {
  constructor(props) {
    const { users, globalCustomBusinessRate } = props;
    const firstUser = users && users[0];
    const { role, teamId } = firstUser ? firstUser : {};
    const managerValue = rolesByName.manager.value;
    const roleValue = roleValues[role];
    const userCustomBusinessRate = firstUser && firstUser.customBusinessRate;
    const customBusinessRate =
      users.length === 1 && userCustomBusinessRate
        ? userCustomBusinessRate
        : globalCustomBusinessRate;

    super(props);

    this.state = {
      role,

      // TODO there is currently no distinction as to what team is being managed by who
      // this code will need to be changed if we implement this level of specificity
      teamIdForMembership: roleValue < managerValue && teamId,
      teamIdForManagement:
        roleValue <= managerValue && teamId ? [teamId] : null,
      customBusinessRate
    };

    this.setCustomBusinessRate = this.setCustomBusinessRate.bind(this);
    this.saveUserSettings = this.saveUserSettings.bind(this);
    this.setTeamMembership = this.setTeamMembership.bind(this);
    this.setTeamManagement = this.setTeamManagement.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.deleteInvite = this.deleteInvite.bind(this);
    this.deleteMember = this.deleteMember.bind(this);
    this.setRole = this.setRole.bind(this);
  }

  setRole(role) {
    this.setState({ role });
  }

  setTeamMembership(teamIdForMembership) {
    this.setState({ teamIdForMembership });
  }

  setTeamManagement([teamIdForManagement]) {
    this.setState({ teamIdForManagement });
  }

  setCustomBusinessRate(customBusinessRate) {
    this.setState({ customBusinessRate });
  }

  saveUserSettings() {
    const {
      users,
      updateUser,
      updateInvite,
      updateTeam,
      onSubmit,
      reload
    } = this.props;

    const {
      role,
      teamIdForMembership,
      teamIdForManagement,
      customBusinessRate
    } = this.state;

    users.forEach(({ id: userId, ...rest }) => {
      if (rest.class === 'user') {
        // TODO: which is the team id of a user? a team they manage or a team they are a part of??? both?
        teamIdForMembership &&
          updateTeam(teamIdForMembership, {
            id: teamIdForMembership,
            members: [userId]
          });
        teamIdForManagement &&
          updateTeam(teamIdForManagement, {
            id: teamIdForManagement,
            managers: [userId]
          });
        role &&
          updateUser(userId, {
            userId,
            role,
            teamId: teamIdForMembership || teamIdForManagement,
            customBusinessRate
          }).then(() => reload && reload());
      } else {
        updateInvite(userId, {
          userId,
          role,
          teamId: teamIdForMembership,
          customBusinessRate
        });
      }
    });

    onSubmit && onSubmit();
  }

  deleteUser(deleteMethod) {
    const { users } = this.props;
    const user = users && users[0];
    const userId = user && user.id;

    userId && deleteMethod && deleteMethod(userId);
  }

  deleteInvite() {
    const { deleteInvite } = this.props;

    deleteInvite && this.deleteUser(deleteInvite);
  }

  deleteMember() {
    const { deleteUser } = this.props;

    deleteUser && this.deleteUser(deleteUser);
  }

  render() {
    const userClass = 'user';
    const { users } = this.props;
    const { role, customBusinessRate } = this.state;
    const multipleUsers = users && users.length > 1;
    const firstUser = users && users[0];
    const firstUserIsActive = firstUser && firstUser.class === userClass;
    const [notAllUsersAreActive] = users.filter(
      user => user.class !== userClass
    );

    return (
      <div className="edit-users-page">
        {multipleUsers ? (
          <EditedUsersElement users={users} />
        ) : (
          <EditedUserElement
            onDelete={firstUserIsActive ? this.deleteMember : this.deleteInvite}
            user={firstUser}
          />
        )}
        <RoleSelector role={role} onChange={this.setRole} />
        <div className="edit-user-custom-business-rate-section">
          <h1>Expense rate</h1>
          <RateInput
            defaultValue={customBusinessRate}
            onChange={this.setCustomBusinessRate}
            className="edit-user-custom-business-rate"
          />
        </div>
        {!notAllUsersAreActive && (
          <SingleTeamSelector
            onChange={this.setTeamMembership}
            title="Assigned to team"
          />
        )}
        {!notAllUsersAreActive &&
          !multipleUsers &&
          firstUserIsActive && (
            <TeamSelector
              selected={firstUser ? firstUser.managedTeams : []}
              onChange={this.setTeamManagement}
              title="Manager of team(s)"
            />
          )}
        <AddButton
          className="edit-users-page-save-button"
          onClick={this.saveUserSettings}
        >
          Save
        </AddButton>
      </div>
    );
  }
}
