import { userIsAdmin } from 'ducks/users/usersSelectors/userIsAdmin';
import { changeReportStatus } from 'ducks/company/companyActions/reports/changeReportStatus';

export const mapStateToReportStatusActionButtons = state => ({
  isAdmin: userIsAdmin(state)
});

export const mapDispatchToReportStatusActionButtons = {
  changeReportStatus
};
