import React, { Component } from 'react';
import DropDown from 'components/CompanyPages/lib/DropDownSelector/index';
import AddButton from 'components/CompanyPages/lib/AddButton/index';
import TextBox from 'components/TextBox/index';
import './AddAutoApprovalRulePage.css';

export const AutoApprovalRulePageDropDown = ({
  elements,
  className,
  onClick,
  title
}) => (
  <DropDown
    title={title}
    className={`add-rule-drop-down-selector${className ? ' ' + className : ''}`}
  >
    {elements && elements.length
      ? elements
          .map(
            ({ name }, index) =>
              name !== title && (
                <div
                  key={`${title}-rule-${index}`}
                  id={index}
                  className="add-rule-drop-down-selection"
                  onClick={onClick}
                >
                  <h1 id={index} className="add-rule-drop-down-selection-name">
                    {name}
                  </h1>
                </div>
              )
          )
          .filter(element => !!element)
      : undefined}
  </DropDown>
);

export default class AddAutoApprovalRulePage extends Component {
  constructor(props) {
    const { timeSpans } = props;
    const periodIndex = 0;
    const defaultTimeSpan = timeSpans && timeSpans[periodIndex];
    const period = defaultTimeSpan && defaultTimeSpan.value;

    super(props);

    this.state = {
      teamIndex: 0,
      purposeIndex: 0,
      teamId: null,
      purposeId: null,
      amount: null,
      distance: null,
      periodIndex,
      period
    };

    this.setTeamId = this.setTeamId.bind(this);
    this.setMileage = this.setMileage.bind(this);
    this.setAmount = this.setAmount.bind(this);
    this.setPurposeId = this.setPurposeId.bind(this);
    this.setRecurrence = this.setRecurrence.bind(this);
    this.addAutoApprovalRule = this.addAutoApprovalRule.bind(this);
  }
  setTeamId({ target }) {
    const teamIndex = target.id;
    const { teams } = this.props;
    const team = teams && teams[teamIndex];
    this.setState({ teamIndex, teamId: team && team.id });
  }
  setPurposeId({ target }) {
    const purposeIndex = target.id;
    const { purposes } = this.props;
    const purpose = purposes && purposes[purposeIndex];
    this.setState({ purposeId: purpose.id, purposeIndex });
  }
  setAmount({ target }) {
    const { normalize } = this.props;
    this.setState({ amount: normalize(target.value) });
  }
  setMileage({ target }) {
    const { normalize } = this.props;
    this.setState({ distance: normalize(target.value) });
  }
  setRecurrence({ target }) {
    const periodIndex = target.id;
    const { timeSpans } = this.props;
    const period = timeSpans[periodIndex];
    this.setState({ period: period.value, periodIndex });
  }
  addAutoApprovalRule() {
    const { addAutoApprovalRule, onSubmit } = this.props;
    const { amount, distance, teamId, purposeId, period } = this.state;
    addAutoApprovalRule({
      teamId,
      purposeId,
      period,
      distance: Number(distance),
      amount: Number(amount)
    });
    onSubmit();
  }
  render() {
    const { teamIndex, purposeIndex, periodIndex } = this.state;
    const { teams, purposes, timeSpans } = this.props;
    const selectedTeam = teams && teams[teamIndex];
    const selectedPurpose = purposes && purposes[purposeIndex];
    const selectedTimeSpan = timeSpans && timeSpans[periodIndex];

    return (
      <div className="add-auto-approval-rule-page">
        <h1 className="add-rule-title">This rule applies to</h1>
        <AutoApprovalRulePageDropDown
          title={(selectedTeam && selectedTeam.name) || 'All Teams'}
          onClick={this.setTeamId}
          selectedIndex={teamIndex}
          className="add-rule-team-selector"
          elements={teams}
        />
        <h1 className="add-rule-title">
          For expenses classified as (optional)
        </h1>
        <AutoApprovalRulePageDropDown
          title={(selectedPurpose && selectedPurpose.name) || 'All Purposes'}
          onClick={this.setPurposeId}
          selectedIndex={purposeIndex}
          className="add-rule-purpose-selector"
          elements={purposes}
        />
        <h1 className="add-rule-title">
          Amount is lower then or equal to (optional)
        </h1>
        <TextBox
          className="add-rule-distance-input add-rule-text-input"
          value={this.state.amount}
          onChange={this.setAmount}
          type="text"
          placeHolder={'0.00'}
          normalizeinput={true}
        />
        <h1 className="add-rule-title">
          Mileage is lower then or equal to (optional)
        </h1>
        <TextBox
          className="add-rule-mileage-input add-rule-text-input"
          type="text"
          value={this.state.distance}
          onChange={this.setMileage}
          normalizeinput={true}
          placeHolder={'500 mi'}
        />
        <h1 className="add-rule-title">For a period of time</h1>
        <AutoApprovalRulePageDropDown
          title={(selectedTimeSpan && selectedTimeSpan.name) || 'Month'}
          onClick={this.setRecurrence}
          selectedIndex={periodIndex || 0}
          className="add-rule-recurrence-selector"
          elements={timeSpans}
        />
        <AddButton
          onClick={this.addAutoApprovalRule}
          className="add-rule-button"
        >
          Add
        </AddButton>
      </div>
    );
  }
}
