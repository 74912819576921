import { updateLocationFailure } from 'ducks/locations/locationActions/updateLocation';
import getTripPoints from 'ducks/trips/lib/getTripPoints';
import { updateTrip } from 'ducks/trips/actions';
import { getTrip } from 'ducks/trips/selectors';
import { route } from 'lib/services/tripRouting';
import polyUtil from 'polyline-encoded';
import { NUMBER_OF_ROUTE_POINTS } from '../constants';
import { updateTripFailure } from '../actions';

export const changeTripStartLocation = (
  tripId,
  { address, coordinates, id }
) => (dispatch, getState) => {
  const trip = getTrip(getState(), tripId);
  const decodedPolyline = [coordinates, ...polyUtil.decode(trip.polyline)];
  return route(getTripPoints(decodedPolyline, NUMBER_OF_ROUTE_POINTS))
    .then(({ distance, polyline }) => ({
      id: tripId,
      departureAddress: address,
      distance,
      polyline
    }))
    .then(trip => dispatch(updateTrip(trip)))
    .catch(error => dispatch(updateLocationFailure(error, id)));
};

export const changeTripEndLocation = (tripId, { address, coordinates }) => (
  dispatch,
  getState
) => {
  const trip = getTrip(getState(), tripId);
  const decodedPolyline = [...polyUtil.decode(trip.polyline), coordinates];
  return route(getTripPoints(decodedPolyline, NUMBER_OF_ROUTE_POINTS))
    .then(({ distance, polyline }) => ({
      id: tripId,
      destinationAddress: address,
      distance,
      polyline
    }))
    .then(trip => dispatch(updateTrip(trip)))
    .catch(error => dispatch(updateTripFailure(error, tripId)));
};
