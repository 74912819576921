import { getTeams as loadTeams } from 'ducks/teams/teamsActions/getTeams';
import { getStatistics as loadStatistics } from 'ducks/company/companyActions/statistics/getStatistics';
import getCompanyStatistics from 'ducks/company/companySelectors/companyStatistics/getCompanyStatistics';
import { getCompanyMembers as loadMembers } from 'ducks/company/companyActions/users/getCompanyMembers';
import { getCompanyPurposes as loadPurposes } from 'ducks/company/companyActions/purposes/getCompanyPurposes';
import combineFilters from 'components/CompanyPages/lib/Filters/lib/combineFilters';
import getTeamFilters from 'components/CompanyPages/lib/Filters/lib/getTeamFilters';
import getUserFilters from 'components/CompanyPages/lib/Filters/lib/getUserFilters';
import getPurposeFilters from 'components/CompanyPages/lib/Filters/lib/getPurposeFilters';
import getManagerFilters from 'components/CompanyPages/lib/Filters/lib/getManagerFilters';
import getYearToDateFilters from 'components/CompanyPages/lib/Filters/lib/getYearToDateFilters';
import getCompanyStatisticsPageRequests from 'ducks/company/companySelectors/companyRequests/getCompanyStatisticsPageRequests';
import getApprovedExpenses from 'ducks/company/companySelectors/companyStatistics/getApprovedExpenses';
import getRejectedExpenses from 'ducks/company/companySelectors/companyStatistics/getRejectedExpenses';
import getApprovedMiles from 'ducks/company/companySelectors/companyStatistics/getApprovedMiles';
import getRejectedMiles from 'ducks/company/companySelectors/companyStatistics/getRejectedMiles';

export const mapStateToStatisticsPage = state => ({
  statistics: getCompanyStatistics(state),
  filters: combineFilters({
    timeScope: getYearToDateFilters(state),
    teamId: getTeamFilters(state),
    userId: getUserFilters(state),
    purposeId: getPurposeFilters(state),
    managerId: getManagerFilters(state)
  }),
  users: [],
  requestsState: getCompanyStatisticsPageRequests(state),
  approvedExpenses: getApprovedExpenses(state),
  returnedExpenses: getRejectedExpenses(state),
  approvedDistance: getApprovedMiles(state),
  returnedDistance: getRejectedMiles(state),
  totalWorkHourMiles: 240.95
});

export const mapDispatchToStatisticsPage = {
  loadTeams,
  loadMembers,
  loadStatistics,
  loadPurposes
};
