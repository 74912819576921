import { connect } from 'react-redux';
import UsersPage from './UsersPage';
import addSelection from 'components/Selector';
import {
  mapStateToUsersPage,
  mapDispatchToUsersPage
} from './usersPageMappings';

export default connect(
  mapStateToUsersPage,
  mapDispatchToUsersPage
)(addSelection(UsersPage));
