import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { getAutoApprovalRules as getRules } from 'api/endpoints/company/autoApprovalRules';
import { RULES_NAME_SPACE } from 'ducks/rules/constants';
import {
  GET_AUTO_APPROVAL_RULES_FAILURE,
  GET_AUTO_APPROVAL_RULES_REQUEST,
  GET_AUTO_APPROVAL_RULES_SUCCESS
} from 'ducks/rules/rulesAcitionTypes';

export const getAutoApprovalRules = createSimpleAction(
  getRules,
  [
    GET_AUTO_APPROVAL_RULES_REQUEST,
    GET_AUTO_APPROVAL_RULES_SUCCESS,
    GET_AUTO_APPROVAL_RULES_FAILURE
  ],
  RULES_NAME_SPACE
);

export default getAutoApprovalRules;
