import React from 'react';
import FontAwesome from 'react-fontawesome';
import 'components/TextBoxField/TextboxField.css';
import './CompanyField.css';

export const createRenderField = Component => ({
  meta: { touched, error, warning },
  input,
  className,
  ...props
}) => [
  <Component
    className={className}
    onBlur={input.onBlur}
    {...props}
    {...input}
  />,
  ...(touched && (error || warning)
    ? [
        (error && (
          <div className="textbox-field__error company-field-error">
            <FontAwesome name="exclamation-circle" /> {error}
          </div>
        )) ||
          (warning && (
            <div className="textbox-field__warning company-field-warning">
              <FontAwesome name="exclamation-circle" /> {warning}
            </div>
          ))
      ]
    : [])
];

export default createRenderField;
