import React, { Component } from 'react';
import LocationsList from './LocationsList/LocationsList';
import MyLocationsHeader from './LocationsHeader';
import MyLocationsMap from './LocationsMap/MyLocationsMap';
import AddLocationModal from './AddLocationModal';
import { Scrollbars } from 'react-custom-scrollbars';
import RenderPage from 'lib/RenderPage';
import './MyLocations.css';

export default class MyLocationsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    };

    this.closeAddLocationModal = this.closeAddLocationModal.bind(this);
    this.openAddLocationModal = this.openAddLocationModal.bind(this);
  }

  openAddLocationModal() {
    this.setState({ modalIsOpen: true });
  }

  closeAddLocationModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const {
      locations,
      coordinates,
      selectLocation,
      selectedCoordinate,
      selectedId,
      addLocation,
      requestsState
    } = this.props;

    const { modalIsOpen } = this.state;
    const { description } = this.props;

    return (
      <div className="my-locations-page">
        <AddLocationModal
          selectedLocation={selectLocation}
          addLocation={addLocation}
          isOpen={modalIsOpen}
          onClose={this.closeAddLocationModal}
        />
        <div className="my-locations-left">
          <MyLocationsHeader
            openAddLocationModal={this.openAddLocationModal}
            description={description}
          />
          <RenderPage requestsState={requestsState}>
            {locations &&
              !!locations.length && (
                <Scrollbars
                  className="locations-list-scrollbar"
                  style={{
                    paddingBottom: '50px'
                  }}
                >
                  <LocationsList
                    select={selectLocation}
                    selectedId={selectedId}
                    locations={locations}
                  />
                </Scrollbars>
              )}
          </RenderPage>
        </div>
        <MyLocationsMap coordinates={coordinates} focus={selectedCoordinate} />
      </div>
    );
  }
}
