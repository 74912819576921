import { getAdministeredUsers } from 'api/endpoints/company/teams';
import {
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS
} from 'ducks/users/usersActionTypes';

export const getUsersRequest = () => ({
  type: GET_USERS_REQUEST
});

export const getUsersSuccess = payload => ({
  type: GET_USERS_SUCCESS,
  payload
});

export const getUsersFailure = error => ({
  type: GET_USERS_FAILURE,
  payload: error,
  error
});

export const getUsers = () => dispatch => {
  dispatch(getUsersRequest());
  return getAdministeredUsers()
    .then(({ data }) => dispatch(getUsersSuccess(data.users)))
    .catch(error => dispatch(getUsersFailure(error)));
};

export default getUsers;
