import React, { Component } from 'react';
import './InvalidUuid.css';

export default class InvalidUuid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeRemaining: 5
    };

    this.countDown = this.countDown.bind(this);
    this.decrementTimeByOne = this.decrementTimeByOne.bind(this);
    this.redirectToPortal = this.redirectToPortal.bind(this);
  }

  countDown() {
    const { timeRemaining } = this.state;

    timeRemaining > 0
      ? setTimeout(() => {
          this.decrementTimeByOne(timeRemaining);
          this.countDown();
        }, 1000)
      : this.redirectToPortal();
  }

  decrementTimeByOne(time) {
    this.setState({ timeRemaining: time - 1 });
  }

  redirectToPortal() {
    const { history } = this.props;

    history.push('/sign_in');
  }

  componentDidMount() {
    this.countDown();
  }

  render() {
    const { timeRemaining } = this.state;

    return (
      <div className="invalid-uuid-page">
        <h1 className="invalid-uuid-text">
          We're sorry, this invitation seems to no longer be valid. Please
          contact your company for further information
        </h1>
        <h1 className="invalid-uuid-redirect-timer">
          You will be redirected to our sign up page in {timeRemaining} seconds.
        </h1>
      </div>
    );
  }
}
