import { getCompanyPurposes } from 'ducks/company/companySelectors/getCompanyPurposes';
import { addAutoApprovalRule } from 'ducks/rules/rulesActions/addAutoApprovalRule';
import { getTeamsArray } from 'ducks/teams/teamsSelectors/getTeamsArray';
import { getUsersCountry } from 'ducks/countries/selectors';
import { GLOBAL_PURPOSE_ID } from 'ducks/purposes/constants';
import { GLOBAL_TEAM_ID } from 'ducks/teams/constants';
import { numeric } from 'lib/normalizers';
import { timeSpans } from 'lib/timeSpans';

export const mapStateToAddAutoApprovalRulePage = state => ({
  teams: [{ name: 'All Teams', id: GLOBAL_TEAM_ID }, ...getTeamsArray(state)],
  purposes: [
    { name: 'All Categories', id: GLOBAL_PURPOSE_ID },
    ...getCompanyPurposes(state)
  ],
  normalize: value => {
    // TODO fix this when there is api support for get company
    const country = getUsersCountry(state);
    return numeric('US', value);
  },
  timeSpans
});

export const mapDispatchToAddAutoApprovalRulePage = {
  addAutoApprovalRule
};
