import { getUsers } from './getUsers';
import { differenceByName } from 'lib/differenceByName';
export const getUsersArray = state => {
  const users = getUsers(state);
  return (
    users &&
    Object.keys(users)
      .map(id => users[id])
      .sort(differenceByName)
  );
};
