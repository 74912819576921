import getRateFromPurpose from 'lib/getRateFromPurpose';
import getCostCenters from 'ducks/costCenters/costCentersSelectors/getCostCenters';
import { numeric } from 'lib/normalizers';
import { getTeamsArray } from 'ducks/teams/teamsSelectors/getTeamsArray';
import { getUsersCountryCode } from 'ducks/countries/selectors';

export const mapStateToAddPurposePage = (state, { purpose }) => {
  const countryCode = getUsersCountryCode(state);
  return {
    normalize: value => numeric(countryCode, value),
    teams: getTeamsArray(state),
    costCenters: getCostCenters(state),
    enableReinitialize: true,
    initialValues: {
      purposeName: purpose && purpose.name,
      rate: getRateFromPurpose(purpose)
    }
  };
};

export const mapDispatchToAddPurposePage = {};
