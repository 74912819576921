import React, { Component } from 'react';
import moment from 'moment';
import DeleteButton from 'components/DeleteButton';
import Money from 'components/Money';
import ReportDownloadLinks from 'components/ReportDownloadLinks';
import 'components/Report/css/Report.css';
import Checkbox from 'components/Checkbox';
import ReportStatusActionButtons from 'components/ReportStatusActionButtons';
import ShowConditionally from 'components/ShowConditionally';

class Report extends Component {
  constructor(props) {
    super(props);

    this.pollState = this.pollState.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { report } = this.props;

    // regularly poll until report is finished generating
    if (report.status === 'generating') {
      this.intervalId = setInterval(this.pollState, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  submit() {
    const { submitReport, report } = this.props;

    submitReport && submitReport(report);
  }

  pollState() {
    const { report, getReport } = this.props;

    if (report.status === 'generating') {
      // poll /api/reports/:id
      getReport();
    } else {
      // cancel polling if done
      clearInterval(this.intervalId);
    }
  }

  render() {
    const {
      report,
      deleteReport,
      starReport,
      isChecked,
      onCheck,
      companyId
    } = this.props;

    const highlighting = report.starred ? 'highlighted__star' : '';

    return (
      <div className="report" key={report.id}>
        <ShowConditionally show={false}>
          <div className="report__check-box">
            <Checkbox checked={isChecked} onChange={onCheck} />
          </div>
        </ShowConditionally>
        <div
          className={`report__star fa fa-2x fa-star ${highlighting}`}
          onClick={starReport}
        />
        <div className="report__created">
          {moment(report.createdAt).format('lll')}
        </div>

        <div className="report__dates">
          {moment(report.from).format('ll')}
          &ndash;
          {moment(report.to).format('ll')}
        </div>

        <div className="report__value">
          <Money value={report.totalValue} />
        </div>

        <ReportDownloadLinks {...report} />

        <div className="report__delete">
          <DeleteButton onClick={deleteReport} big />
        </div>
      </div>
    );
  }
}

export default Report;
