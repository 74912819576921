import { connect } from 'react-redux';
import TripLocationField from './TripLocationField';
import {
  mapDispatchToLocationField,
  mapStateToLocationField
} from './TripLocationFieldMappings';
export default connect(
  mapStateToLocationField,
  mapDispatchToLocationField
)(TripLocationField);
