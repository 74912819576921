import { connect } from 'react-redux';
import {
  mapStateToAddCostCenterPage,
  mapDispatchToAddCostCenterPage
} from './addCostCenterPageMappings';
import AddCostCenterPage from './AddCostCenterPage';

export default connect(
  mapStateToAddCostCenterPage,
  mapDispatchToAddCostCenterPage
)(AddCostCenterPage);
