import React from 'react';
import Logo from './Logo';
import ReportDropDown from './ReportDropdown';
import UserSwitcherContainer from 'components/UserSwitcherContainer';
import ErrorBoundary from 'components/ErrorBoundary';
import CompanyNavigator from './CompanyNavigator';
import Nav from 'components/Nav';
import classNames from 'class-names';
import './Header.css';

export const Header = ({ canViewAdminPages, showAdminView, path, role}) => (
  <div className="header">
    <div className="header__inner">
      <div className="header__left">
        <Logo />
      </div>
      <div className="header__right">
        <ErrorBoundary>
            <div className="header__dropdowns">
              <UserSwitcherContainer />
              <ReportDropDown
                value="Report"
                options={['this week', 'previous week']}
              />
            </div>
          <div className="header__nav">
            <Nav admin={showAdminView} adminRole={role === 'admin' || role === 'owner'} />
          </div>
        </ErrorBoundary>
      </div>
    </div>
  </div>
);

export default Header;
