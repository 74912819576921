import { getCompanyMemberById } from 'ducks/company/companySelectors/companyMembers/getCompanyMemberById';
import moment from 'moment/moment';

const dateFormat = 'MMM DD YYYY';

export const formatReportsForDisplay = (state, reports = []) =>
  reports.map(({ createdAt, from, to, totalValue, userId, ...report }) => {
    const user = getCompanyMemberById(state, userId);
    return {
      name: user && (user.name || user.email),
      submissionDate: moment(createdAt).format(dateFormat),
      dateRange: `${moment(from).format(dateFormat)} - ${moment(to).format(
        dateFormat
      )}`,
      value: Number(totalValue),
      ...report
    };
  });

export default formatReportsForDisplay;
