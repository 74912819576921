import _ from 'lodash';
import * as t from './actionTypes';
import { combineReducers } from 'redux';
import { normalizeArray } from 'lib/utils';
import createReducer from 'redux/createReducer';
import { createRequestsByIdReducer } from 'redux/requestsByIdReducer';
import { onRequest, onSuccess, onError } from 'lib/requests';
import { PUT_COMPANY_REPORTS_SUCCESS } from '../company/companyActionTypes';

const addFilterToObject = (object, filter) => {
  object[filter.option] = filter;
  return object;
};

export default combineReducers({
  itemRequests: createRequestsByIdReducer([
    [t.GET_SINGLE_REQUEST, t.GET_SINGLE_SUCCESS, t.GET_SINGLE_FAILURE]
  ]),
  items: createReducer(
    {},
    {
      [PUT_COMPANY_REPORTS_SUCCESS]: (state, { payload }) =>
        payload.reduce(
          (reports, report) => ({
            ...reports,
            [report.id]: report
          }),
          state
        ),
      [t.GET_PAGE_SUCCESS]: (state, action) => ({
        ...state,
        ...normalizeArray(action.payload.reports)
      }),
      [t.GET_SINGLE_SUCCESS]: (state, action) => ({
        ...state,
        [action.payload.report.id]: action.payload.report
      }),
      [t.DELETE_SUCCESS]: (state, action) => _.omit(state, action.payload.id),
      [t.CREATE_SUCCESS]: (state, action) => ({
        ...state,
        [action.payload.report.id]: action.payload.report
      }),
      [t.INVALIDATE]: () => ({}),
      [t.TOGGLE_STARRED]: (state, { id }) => {
        const report = Object.assign({}, state[id], {
          starred: !state[id].starred
        });
        return Object.assign({}, state, { [id]: report });
      }
    }
  ),
  pagination: createReducer(
    { current: 0, totalCount: 0 },
    {
      [t.GET_PAGE_REQUEST]: (state, action) => ({
        ...state,
        current: action.payload.page,
        request: onRequest()
      }),
      [t.GET_PAGE_SUCCESS]: (state, action) => ({
        ...state,
        totalCount: action.payload.totalCount || state.totalCount,
        request: onSuccess()
      }),
      [t.GET_PAGE_FAILURE]: (state, action) => ({
        ...state,
        request: onError(action.erorr)
      }),
      [t.INVALIDATE]: () => ({ current: 0, totalCount: 0 })
    }
  ),
  pages: createReducer(
    {},
    {
      [t.GET_PAGE_SUCCESS]: (state, action) => ({
        ...state,
        [action.payload.page]: action.payload.reports.map(report => report.id)
      }),
      // append new report to top of list
      [t.CREATE_SUCCESS]: (state, action) => ({
        ...state,
        1: [action.payload.report.id, ...(state[1] ? state[1] : [])]
      }),
      // remove from page list
      [t.DELETE_SUCCESS]: (state, action) =>
        _.mapValues(state, page => page.filter(id => id !== action.payload.id)),
      [t.INVALIDATE]: () => ({})
    }
  ),
  filters: createReducer(
    {},
    {
      [t.UPDATE_FILTERS]: (state, { filters }) =>
        filters.reduce(addFilterToObject, Object.assign({}, state)),
      [t.REMOVE_FILTERS]: (state, { filters }) =>
        Object.keys(state)
          .filter(option => !filters[option])
          .map(option => state[option])
          .reduce(addFilterToObject, {})
    }
  )
});
