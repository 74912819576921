import createReducer from 'redux/createReducer';
import {
  SET_PAGE,
  UPDATE_PAGE,
  DELETE_FROM_PAGE
} from '../paginationActionTypes';

export const pages = createReducer(
  {},
  {
    [SET_PAGE]: (state, { payload, pageName }) => {
      const { pageNumber, elements } = payload || {};
      const pages = state[pageName];
      const page = pages && pages[pageNumber];
      return elements
        ? {
            ...state,
            [pageName]: {
              ...page,
              [pageNumber]: elements
            }
          }
        : state;
    },
    [UPDATE_PAGE]: (state, { payload, pageName }) => {
      const { pageNumber, element } = payload || {};
      const pages = state[pageName];
      const page = pages && pages[pageNumber];
      return element
        ? {
            ...state,
            [pageName]: {
              ...pages,
              [pageNumber]: page.map(existingElement => {
                return element.id === existingElement.id
                  ? { ...existingElement, ...element }
                  : existingElement;
              })
            }
          }
        : state;
    },
    [DELETE_FROM_PAGE]: (state, { payload, pageName }) => {
      const { pageNumber, id } = payload || {};
      const pages = state[pageName];
      const page = pages && pages[pageNumber];

      return id
        ? {
            ...state,
            [pageName]: {
              ...pages,
              [pageNumber]: page.filter(({ id: elementId }) => elementId !== id)
            }
          }
        : state;
    }
  }
);

export default pages;
