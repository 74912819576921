import './ColoredList.css';
import React from 'react';

export default ({ children, uniqueKey, highlighted, className }) => (
  <ul className={`colored-list${className ? ` ${className}` : ''}`}>
    {children &&
      children.map((item, index) => (
        <li
          key={`colored-list-element-${uniqueKey}-${index}`}
          className={`colored-list-element ${
            `${highlighted}` === `${index}`
              ? 'highlight-colored-list-element'
              : 'normal-colored-background'
          }`}
        >
          {item}
        </li>
      ))}
  </ul>
);
