import React, { Component } from 'react';
import { trackPage } from 'analytics';
import Panel from 'components/Panel/index';
import Sidebar from 'components/Sidebar/index';
import ContentWrap from 'components/ContentWrap/index';
import AccountInfo from 'components/AccountInfo/index';
import SubscriptionInfo from 'components/SubscriptionInfo/index';
import PaginatedReportList from 'components/PaginatedReportList/index';
import ReportFilter from './ReportFilter';
import CheckBox from 'components/Checkbox';
import AddButton from 'components/CompanyPages/lib/AddButton';
import confirm from 'components/Confirmation';
import addSelection from 'components/Selector';
import { getCompanyId } from 'lib/auth';
import ShowConditionally from 'components/ShowConditionally';

import '../css/ReportsPage.css';
import '../css/FilterDropdown.css';

const SubmitReportButton = confirm(AddButton);

class ReportsPage extends Component {
  constructor(props) {
    const filters = ['starred', 'all'];

    super(props);

    this.filters = filters.map((option, index) => ({
      selected: false,
      current: `View ${option[0].toUpperCase() + option.slice(1)}`,
      key: index + 1,
      option
    }));

    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.updateTrips = this.updateTrips.bind(this);
    this.submitReports = this.submitReports.bind(this);
    this.unSubmitReports = this.unSubmitReports.bind(this);
    this.allSubmitted = this.allSubmitted.bind(this);
    this.noneSelected = this.noneSelected.bind(this);
    this.allUnSubmitted = this.allUnSubmitted.bind(this);
    this.filterReports = this.filterReports.bind(this);
    this.toggleSubmission = this.toggleSubmission.bind(this);
    this.checkApprovalStatusOfSelectedReports = this.checkApprovalStatusOfSelectedReports.bind(
      this
    );
  }

  componentWillMount() {
    const { addPageToSelection } = this.props;

    addPageToSelection && addPageToSelection();
  }

  updateTrips(params) {
    const { updateCompanyReports, allSelected, selected } = this.props;

    updateCompanyReports &&
      updateCompanyReports(Object.keys(selected), { allSelected, ...params });
  }

  toggleSubmission({ approvalStatus, id }) {
    const { updateCompanyReports, allSelected } = this.props;

    updateCompanyReports &&
      updateCompanyReports([id], {
        allSelected,
        approvalStatus: approvalStatus ? null : 'submitted'
      });
  }

  unSubmitReports() {
    this.updateTrips({ approvalStatus: null });
  }

  submitReports() {
    this.updateTrips({ approvalStatus: 'submitted' });
  }

  filterReports(filter) {
    const { reports, selected } = this.props;
    const selectedIds = selected ? Object.keys(selected) : [];
    const reportsById =
      reports &&
      reports.reduce(
        (reportsById, report) => ({
          ...reportsById,
          [report.id]: report
        }),
        {}
      );

    return selectedIds.filter(id => filter(reportsById[id]));
  }

  checkApprovalStatusOfSelectedReports(check) {
    const { selected } = this.props;
    const keys = Object.keys(selected || {});
    const filteredReports = this.filterReports(report =>
      check(report.approvalStatus)
    );

    return keys.length && filteredReports.length === keys.length;
  }

  allUnSubmitted() {
    return this.checkApprovalStatusOfSelectedReports(status => !status);
  }

  allSubmitted() {
    return this.checkApprovalStatusOfSelectedReports(status => !!status);
  }

  noneSelected() {
    const { allSelected, selected } = this.props;

    return !allSelected && (!selected || Object.keys(selected).length === 0);
  }

  toggleSelectAll() {
    const { reports, toggleSelectAll, allSelected } = this.props;
    const reportIds = reports.map(({ id }) => id);

    toggleSelectAll && toggleSelectAll(!allSelected && reportIds);
  }

  render() {
    const noneSelected = this.noneSelected();
    const reportsFilter = (
      <ReportFilter
        className="report-filter dropdown_filter"
        selections={this.filters}
      >
        Filters
      </ReportFilter>
    );
    const {
      allSelected,
      amountSelected,
      isSelected,
      toggleSelection,
      totalCount,
      companyId
    } = this.props;

    return (
      <ContentWrap>
        <Panel filter={reportsFilter} title="Report History" sidebar>
          <div className="reports-page">
            <ShowConditionally show={false}>
              <div className="report-header">
                <CheckBox
                  className="reports-page-select-all"
                  label="Select All"
                  checked={allSelected}
                  onChange={this.toggleSelectAll}
                />
                <h1 className="reports-page-amount-selected">
                  {amountSelected
                    ? `${amountSelected} out of ${totalCount} selected`
                    : `${totalCount} reports`}
                </h1>
                <SubmitReportButton
                  disabled={noneSelected || this.allUnSubmitted()}
                  confirm={true}
                  className="de-submit-from-company-button"
                  title="Unsubmit reports"
                  onClick={this.unSubmitReports}
                  message="Are you sure you want to unsubmit the selected reports from review?"
                >
                  Unsubmit
                </SubmitReportButton>
                <SubmitReportButton
                  disabled={noneSelected || this.allSubmitted()}
                  confirm={true}
                  className="submit-to-company-button"
                  title="Submit reports to admin/manager"
                  onClick={this.submitReports}
                  message="Are you sure you want to submit the selected reports? The reports will no longer be viewable by managers or administrators"
                >
                  Submit for review
                </SubmitReportButton>
              </div>
            </ShowConditionally>
            <div className="reports-page__head">
              <ShowConditionally show={false}>
                <div className="report__check-box" />
              </ShowConditionally>
              <div className="report__stars">Starred</div>
              <div className="report__created">Created</div>
              <div className="report__dates">Dates</div>
              <div className="report__project">Project</div>
              <div className="report__value">Value</div>
              <div className="report__status" />
              <div className="report__delete" />
            </div>
            <PaginatedReportList
              submitReport={this.toggleSubmission}
              selectReport={toggleSelection}
              isSelected={isSelected}
              allSelected={allSelected}
              companyId={companyId}
            />
          </div>
        </Panel>
        <Sidebar title="My Account">
          <AccountInfo />
          <SubscriptionInfo />
        </Sidebar>
      </ContentWrap>
    );
  }
}

export default trackPage('reports')(addSelection(ReportsPage));
