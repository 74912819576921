import React, { Component } from 'react';
import classNames from 'class-names';
import FontAwesome from 'react-fontawesome';
import Button from 'components/Button';

import './Style.css';

class DeleteButton extends Component {
  constructor(props) {
    super(props);
    this.state = { fetching: false };
    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    const { onClick } = this.props;

    this.setState({ fetching: true });
    if (onClick) {
      Promise.resolve(onClick()).then(() => this.setState({ fetching: false }));
    }

    event.preventDefault();
  }

  render() {
    const { className, big, onClick: _, ...props } = this.props;
    const { fetching } = this.state;

    return (
      <Button
        className={classNames('delete-button', className)}
        onClick={this.onClick}
        disabled={fetching}
        {...props}
        inline
      >
        <FontAwesome
          name={fetching ? 'circle-o-notch' : 'trash'}
          {...big && { size: '2x' }}
          spin={fetching}
          fixedWidth
        />
      </Button>
    );
  }
}

export default DeleteButton;
