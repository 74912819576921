import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import api from 'api';
import NavIcon from 'components/Nav/NavIcon';
import Modal from 'components/Modal';
import Scrollbars from 'components/Scrollbars';
import Typography from 'components/Typography';

class WhatsNew extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, news: [] };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    api.getNews().then(response => {
      const news = _.get(response, 'data.news', []);
      this.setState({ news });
    });
  }

  open() {
    const { updateLastReadNews } = this.props;

    if (this.isUnread()) {
      updateLastReadNews(moment().format());
    }

    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  isUnread() {
    const { news } = this.state;
    const { lastReadNews } = this.props;
    const latestNewsDate = news && news.length ? news[0].date : '';

    return moment(lastReadNews).isBefore(latestNewsDate, 'day');
  }

  render() {
    const { isOpen, news } = this.state;

    return [
      <Modal
        key="modal"
        className="whats-new__modal"
        title="What's new with MileCatcher?"
        onClose={this.close}
        isOpen={isOpen}
      >
        <Scrollbars>
          {news.map(article => (
            <div className="article">
              <div className="article__date">
                {moment(article.date).format('LL')}
              </div>

              <Typography
                className="article__content"
                markdown={article.body}
              />
            </div>
          ))}
        </Scrollbars>
      </Modal>,
      <a key="button" href="#" role="button" onClick={this.open}>
        <NavIcon
          icon="whats-new"
          title="What's new?"
          active={this.isUnread()}
        />
      </a>
    ];
  }
}

export default WhatsNew;
