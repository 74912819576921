import { putWorkHours } from 'api/endpoints/user/workHours';
import { getDayFromUserWorkHours } from '../workHourSelectors/getDayFromUserWorkHours';
import {
  SET_USER_WORK_HOURS_STATUS,
  SET_USER_WORK_HOURS_FOR_DAY
} from '../actionTypes';
import getWorkHours from 'ducks/workHours/workHourSelectors/getUserWorkHours';

const setWorkHoursEnabled = state => ({
  type: SET_USER_WORK_HOURS_STATUS,
  payload: state
});

const setDay = (day, params) => ({
  type: SET_USER_WORK_HOURS_FOR_DAY,
  payload: { day, ...params }
});

export const setEnabled = enabled => (dispatch, getState) => {
  const prevState = getWorkHours(getState());
  dispatch(setWorkHoursEnabled({ enabled }));
  return putWorkHours({ enabled }).catch(error =>
    dispatch(setWorkHoursEnabled({ enabled: prevState.enabled }))
  );
};

export const setDayEnabled = (day, enabled) => (dispatch, getState) => {
  const prevState = getDayFromUserWorkHours(getState(), day);
  dispatch(setDay(day, { enabled }));
  return putWorkHours({ days: [{ day, enabled }] }).catch(error =>
    dispatch(setDay(day, { enabled: prevState.enabled }))
  );
};

export const addRule = day => (dispatch, getState) => {
  const prevState = getDayFromUserWorkHours(getState(), day);
  const rules = [...prevState.rules, { from: 18, to: 34 }];
  dispatch(setDay(day, { rules }));
  return putWorkHours({ days: [{ day, rules }] }).catch(error =>
    dispatch(setDay(day, { rules: prevState.rules }))
  );
};

export const removeRule = (day, index) => (dispatch, getState) => {
  const prevState = getDayFromUserWorkHours(getState(), day);
  const rules = prevState.rules.filter((_, i) => index !== i);

  dispatch(setDay(day, { rules }));
  return putWorkHours({ days: [{ day, rules }] }).catch(error =>
    dispatch(setDay(day, { rules: prevState.rules }))
  );
};

export const previewRule = (day, index, [from, to]) => (dispatch, getState) => {
  // used on drag to update labels without actually sending API request
  const prevState = getDayFromUserWorkHours(getState(), day);
  const rules = prevState.rules.map(
    (rule, i) => (index === i ? { from, to } : rule)
  );
  return dispatch(setDay(day, { rules }));
};

export const setRule = (day, index, [from, to]) => (dispatch, getState) => {
  const prevState = getDayFromUserWorkHours(getState(), day);
  const rules = prevState.rules.map(
    (rule, i) => (index === i ? { from, to } : rule)
  );
  dispatch(setDay(day, { rules }));
  return putWorkHours({ days: [{ day, rules }] }).catch(error =>
    dispatch(setDay(day, { rules: prevState.rules }))
  );
};
