import React from 'react';
import DeleteButton from 'components/CompanyPages/lib/DeleteButton';
import ScrollBars from 'components/Scrollbars';
import './RulesList.css';

export const RulesList = ({
  rules,
  component,
  className,
  onDelete,
  onClick
}) => (
  <div className="rules-section">
    {rules && (
      <ScrollBars style={{ height: '100%' }}>
        <ul className={`rules-list${className ? ` ${className}` : ''}`}>
          {rules.map((args, index) => (
            <li
              id={args.id}
              onClick={onClick}
              key={`rule-${index}`}
              className="rule-element"
            >
              <div className="rule-text rule-content">{component(args)}</div>
              <DeleteButton
                id={args.id}
                onClick={onDelete}
                className="delete-rule-button rule-content"
              />
            </li>
          ))}
        </ul>
      </ScrollBars>
    )}
  </div>
);

export default RulesList;
