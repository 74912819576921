import { getTeam } from './getTeam';
import { getCompanyUsersObject } from 'ducks/company/companySelectors/companyMembers/getCompanyUsersObject';
import { sortByEmail } from 'ducks/company/lib/sortByEmail';

export const getTeamMembers = (state, teamId) => {
  const team = getTeam(state, teamId);
  const users = getCompanyUsersObject(state);
  const members = team && team.members;

  return members
    ? sortByEmail(
        members.reduce((foundMembers, id) => {
          const member = users[id];
          return member ? [member, ...foundMembers] : foundMembers;
        }, [])
      )
    : [];
};

export default getTeamMembers;
