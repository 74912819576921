import { getCompanyMembers as loadMembers } from 'ducks/company/companyActions/users/getCompanyMembers';
import { getTeams as loadTeams } from 'ducks/teams/teamsActions/getTeams';
import { combineFilters } from 'components/CompanyPages/lib/Filters/lib/combineFilters';
import { getCompanyReportsPage as loadReports } from 'ducks/company/companyActions/reports/getCompanyReportsPage';
import getTeamFilters from 'components/CompanyPages/lib/Filters/lib/getTeamFilters';
import getUserFilters from 'components/CompanyPages/lib/Filters/lib/getUserFilters';
import getYearToDateFilters from 'components/CompanyPages/lib/Filters/lib/getYearToDateFilters';
import getReportStatusFilters from 'components/CompanyPages/lib/Filters/lib/getReportStatusFilters';
import { formatReportsForDisplay } from '../lib/formatReportsForDisplay';
import userIsAdmin from 'ducks/users/usersSelectors/userIsAdmin';
import getCompanyReportsPageRequests from 'ducks/company/companySelectors/companyRequests/getCompanyReportsPageRequests';
import { getCompanyReportsPageSize } from 'ducks/company/companySelectors/companyReports/getCompanyReportsPageSize';
import { getCompanyReportsPageNumber } from 'ducks/company/companySelectors/companyReports/getCompanyReportsPageNumber';
import getCompanyReportsTotalCount from 'ducks/company/companySelectors/companyReports/getCompanyReportsTotalCount';
import { setCompanyReportsPageSize } from 'ducks/company/companyActions/reports/setCompanyReportsPageSize';
import { getCurrentCompanyReportsPage } from 'ducks/company/companySelectors/companyReports/getCurrentCompanyReportsPage';
import exportCompanyReport from 'ducks/company/companyActions/reports/exportCompanyReport';

export const mapStateToReportPage = state => ({
  reports: formatReportsForDisplay(state, getCurrentCompanyReportsPage(state)),
  requestsStatus: getCompanyReportsPageRequests(state),
  numberOfElements: getCompanyReportsTotalCount(state),
  pageNumber: getCompanyReportsPageNumber(state),
  pageSize: getCompanyReportsPageSize(state),
  exportReports: exportCompanyReport,
  isAdmin: userIsAdmin(state),
  filters: combineFilters({
    teamId: getTeamFilters(state),
    userId: getUserFilters(state),
    timeScope: getYearToDateFilters(state),
    approvalStatus: getReportStatusFilters(state)
  })
});

export const mapDispatchToReportPage = {
  loadMembers,
  loadReports,
  loadTeams,
  setCompanyReportsPageSize
};
