import {
  GET_WORK_HOURS_FAILURE,
  GET_WORK_HOURS_REQUEST,
  GET_WORK_HOURS_SUCCESS
} from 'ducks/workHours/actionTypes';
import { createSimpleAction } from 'ducks/lib/simpleActionCreator';

export const createWorkHoursAction = apiCall =>
  createSimpleAction(
    apiCall,
    [GET_WORK_HOURS_REQUEST, GET_WORK_HOURS_SUCCESS, GET_WORK_HOURS_FAILURE],
    'workHours'
  );

export default createWorkHoursAction;
