import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CompanyInvitePage from './CompanyInvitePage';
import {
  mapDispatchToCompanyInvitePage,
  mapStateToCompanyInvitePage
} from './CompanyInviteMappings';
export default withRouter(
  connect(mapStateToCompanyInvitePage, mapDispatchToCompanyInvitePage)(
    CompanyInvitePage
  )
);
