import { getSelectedLocation } from 'ducks/users/selectors';
import updateLocation from './updateLocation';
import addLocation from './addLocation';

export default data => (dispatch, getState) => {
  const location = getSelectedLocation(getState);
  return dispatch(
    (location ? updateLocation : addLocation)({ ...location, ...data })
  );
};
