import { getCompany as loadCompany } from 'ducks/company/companyActions/getCompany';
import getCompanyFromStore from 'ducks/company/companySelectors/company/getCompany';
import getCompanyAccountPageRequests from 'ducks/company/companySelectors/companyRequests/getCompanyAccountPageRequests';
import { getMostRecentTaxRateOfUsersCountry } from 'ducks/countries/selectors';

export const mapStateToCompanyAccountPage = state => {
  const company = getCompanyFromStore(state);
  return {
    requestsState: getCompanyAccountPageRequests(state),
    months: [
      'January',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'November',
      'December',
      'October',
      'November'
    ],
    years: [2018, 2019, 2020, 2021, 2022],
    costPerSeat: 2.34,
    company,
    customBusinessRate:
      (company && company.customBusinessRate) ||
      getMostRecentTaxRateOfUsersCountry(state)
  };
};

export const mapDispatchToCompanyAccountPage = {
  loadCompany
};
