import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { trackUser } from 'analytics';
import {
  isSignedIn,
  isRedirecting,
  isMobile,
  determineSignInPage
} from 'ducks/session/selectors';
import getCompanySignUpStatus from 'ducks/company/companySelectors/company/getCompanySignUpStatus';
import { getCountries } from 'ducks/countries/countryActions';
import getUserRequests from 'ducks/users/usersSelectors/getUserRequests';
import { getUserRole } from 'ducks/users/usersSelectors/getUserRole';
import Application from './Application';

const mapStateToProps = state => ({
  requestsState: getUserRequests(state),
  signedIn: isSignedIn(state),
  redirecting: isRedirecting(state),
  isMobile: isMobile(state),
  defaultSignInPage: determineSignInPage(state),
  signingUp: getCompanySignUpStatus(state),
  role: getUserRole(state)
});

const mapDispatchToProps = {
  getCountries
};

// too many HOCs? :P
export default withRouter(
  trackUser(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Application)
  )
);
