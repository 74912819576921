import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import 'components/CompanyPages/lib/DropDownSelector/DropDownSelector.css';
import './MultiSelectDropDown.css';

export default class MultiSelectDropDown extends Component {
  constructor(props) {
    const { defaultValue } = props;

    super(props);

    this.state = {
      isOpen: false,
      selected: defaultValue || {}
    };

    this.component = null;
    this.onClick = this.onClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setreference = this.setreference.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.displayDropDown = this.displayDropDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  setreference(reference) {
    this.component = reference;
  }

  onClick() {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  }

  handleClick({ target }) {
    if (this.component && !this.component.contains(target)) {
      this.setState({ isOpen: false });
    }
  }

  // create an array containing only the index's of selected elements
  convertSelectedToArray(selected) {
    return Object.keys(selected).reduce(
      (allSelected, checked) =>
        checked && selected[checked] ? [checked, ...allSelected] : allSelected,
      []
    );
  }

  onSelect({ target }) {
    const { onChange, getSelected } = this.props;
    const { selected: previous } = this.state;
    const child = target.firstChild;
    const idOfChild = child && child.id;
    const index = idOfChild || target.id;
    const selected = {
      ...previous,
      [index]: !previous[index]
    };

    const array = this.convertSelectedToArray(selected);

    this.setState({ selected });
    onChange && onChange(array);
    getSelected && getSelected(selected);
  }

  displayDropDown() {
    const { isOpen } = this.state;
    const { children } = this.props;

    return isOpen && children && children.length;
  }

  render() {
    const { selected } = this.state;
    const { className, title, children, icon } = this.props;
    const amountSelected = this.convertSelectedToArray(selected).length;

    return (
      <div
        ref={this.setreference}
        className={`multi-select-drop-down drop-down-component${
          className ? ' ' + className : ''
        }`}
      >
        <div
          className="drop-down-title drop-down-element"
          onClick={this.onClick}
        >
          {icon && <FontAwesome className="drop-down-icon" name={icon} />}
          <h1>
            {title}
            {amountSelected > 1 ? `  (${amountSelected})` : ''}
          </h1>
        </div>
        <ul
          className={this.displayDropDown() ? 'drop-down-view' : 'hide-element'}
        >
          {children.map((element, index) => (
            <li
              key={`drop-down-element-${index}`}
              className="drop-down-element"
              id={index}
              onClick={this.onSelect}
            >
              {element}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
