import React from 'react';

export default ({
  first,
  second,
  third,
  amountOfUsers,
  id,
  onDrop,
  onDragOver,
  onClick
}) => (
  <div
    onClick={onClick}
    id={id}
    key={`teams-row-${id}`}
    className="teams-row"
    onDrop={onDrop}
  >
    {[
      [
        'teams-first-column',
        `${first}${!isNaN(amountOfUsers) ? `  (${amountOfUsers})` : ''}`
      ],
      ['teams-second-column', second],
      ['teams-third-column', third]
    ].map(([style, value]) => (
      <div
        id={id}
        className={`${style} teams-column`}
        onDrop={onDrop}
        onDragOver={onDragOver}
      >
        <h1 onDrop={onDrop} onDragOver={onDragOver} id={id}>
          {value}
        </h1>
      </div>
    ))}
  </div>
);
