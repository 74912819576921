import _ from 'lodash';
import moment from 'moment';
import users from 'ducks/users';
import getRateFromPurpose from 'lib/getRateFromPurpose';
import * as countries from 'ducks/countries/selectors';
import { createSelector } from 'reselect';

export const getAll = state => _.values(state.purposes.items);

export const getByUser = createSelector(
  [getAll, (_, userId) => userId],
  (purposes, userId) =>
    purposes
      .filter(purpose => purpose.ownerId === userId)
      .sort((a, b) => a.index - b.index)
);

export const getById = (state, id) => state.purposes.items[id];

const filterPermanentPurposes = purposes =>
  purposes.filter(
    purpose =>
      !(
        (purpose.name === 'Business' && purpose.type === 'business') ||
        (purpose.name === 'Personal' && purpose.type === 'personal')
      )
  );

export const getAllForSettings = state =>
  filterPermanentPurposes(
    getByUser(state, users.selectors.getCurrentId(state))
  );

export const getActive = state =>
  getByUser(state, users.selectors.getActiveId(state));

export const getActiveWithoutDefault = state =>
  filterPermanentPurposes(getByUser(state, users.selectors.getActiveId(state)));

export const getActiveBusiness = createSelector(getActive, purposes =>
  purposes.find(p => p.name === 'Business' && p.type === 'business')
);

export const getPurposesTaxRate = (state, time, purposeId) => {
  const momentInTime = moment(time);
  const purpose = purposeId && getById(state, purposeId);
  const purposeName = purpose ? purpose.name : 'Business';
  const taxPeriods = countries.getTaxPeriodsOfUsersCountry(state);
  const taxPeriod =
    taxPeriods &&
    taxPeriods.filter(({ startDate, endDate }) => {
      return momentInTime.isBetween(moment(startDate).day(-1), endDate);
    })[0];
  const rate =
    taxPeriod && taxPeriod.rates.filter(({ name }) => name === purposeName)[0];
  return rate && rate.mileageRanges[0].rate;
};

export const getPurposesRate = (state, purposeId) => {
  const purpose = purposeId
    ? getById(state, purposeId)
    : getActiveBusiness(state);
  return purpose && getRateFromPurpose(purpose);
};

export const getActivePersonal = createSelector(getActive, purposes =>
  purposes.find(p => p.name === 'Personal' && p.type === 'personal')
);
