import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import './SideNavSection.css';

export default class SideNavSection extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: false };
    this.selectOption = this.selectOption.bind(this);
  }
  selectOption({ target }) {
    this.setState({ selected: target.title });
  }
  render() {
    const { options, className } = this.props;
    const { selected } = this.state;
    return (
      <div className={'side-nav-menu' || className}>
        <ul className="side-nav-options">
          {options
            ? options.map(({ title, icon, path }, index) => (
                <li
                  key={`side-nav-option-${index}`}
                  onClick={this.selectOption}
                >
                  <NavLink className="side-nav-link" to={path} title={title}>
                    <div
                      className={`side-nav-option${
                        selected === title ? ' selected-side-nav-option' : ''
                      }`}
                      title={title}
                    >
                      <div title={title} className="side-nav-option-icon">
                        <FontAwesome name={icon} title={title} />
                      </div>
                      <h2 className="side-nav-option-title" title={title}>
                        {title}
                      </h2>
                    </div>
                  </NavLink>
                </li>
              ))
            : []}
        </ul>
      </div>
    );
  }
}
