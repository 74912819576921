import {
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAILURE
} from '../teamActionTypes';

import { putUpdateTeam } from 'api/endpoints/company/teams';

export const updateTeamRequest = payload => ({
  payload,
  type: UPDATE_TEAM_REQUEST
});

export const updateTeamSuccess = payload => ({
  payload,
  type: UPDATE_TEAM_SUCCESS,
  meta: {
    alert: {
      type: 'success',
      message: `Team${
        payload && payload.name ? ` "${payload.name}"` : ''
      } has been updated`
    }
  }
});

export const updateTeamFailure = error => ({
  type: UPDATE_TEAM_FAILURE,
  payload: error,
  error,
  meta: {
    alert: {
      type: 'failure',
      message: `Unable to update team`
    }
  }
});

export const updateTeam = (id, team) => dispatch => {
  dispatch(updateTeamRequest(id));
  return putUpdateTeam(id, team)
    .then(({ data }) => dispatch(updateTeamSuccess(data.team)))
    .catch(error => dispatch(updateTeamFailure(error)));
};

export const updateTeamMembers = (id, members) => dispatch =>
  dispatch(updateTeam(id, { members }));

export const updateTeamManagers = (id, managers) => dispatch =>
  dispatch(updateTeam(id, { members: managers }));

export const updateTeamName = (id, name) => dispatch =>
  dispatch(updateTeam(id, { members: name }));

export default updateTeam;
