import { connect } from 'react-redux';
import Statistics from './Statistics';
import trips from 'ducks/trips';

const mapStateToProps = state => ({
  statistics: trips.selectors.getStatistics(state)
});

const mapDispatchToProps = dispatch => ({
  getStatistics: () => dispatch(trips.actions.getStatistics())
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
