import React, { Component } from 'react';
import SideNavSection from 'components/SideNavSection';
import { Switch, Route, Redirect } from 'react-router-dom';
import { trackPage } from 'analytics';
import Teams from './TeamsPage';
import CompanyAccount from './CompanyAccountPage';
import CompanyLocations from './CompanyLocationsPage';
import UsersPage from './UsersPage';
import WorkHours from './WorkHoursPage';
import Rules from './RulesPage';
import Purposes from './PurposesPage';
import Reports from './ReportsPage';
import CostCenters from './CostCentersPage';
import Integrations from './IntegrationsPage';

export default trackPage('admin/settings')(
  class AdminPage extends Component {
    render() {
      const { options, isAdmin } = this.props;
      return [
        <SideNavSection className="admin-side-navigator" options={options} />,
        <div className="admin-page-content">
          <Switch>
            <Route
              path="/admin/settings/integrations"
              component={Integrations}
            />
            <Route
              path="/admin/settings/cost_centers"
              component={CostCenters}
            />
            <Route path="/admin/settings/reports" component={Reports} />
            <Route path="/admin/settings/purposes" component={Purposes} />
            {isAdmin && (
              <Route
                path="/admin/settings/company_account"
                component={CompanyAccount}
              />
            )}
            <Route path="/admin/settings/users" component={UsersPage} />
            <Route
              path="/admin/settings/locations"
              component={CompanyLocations}
            />
            <Route path="/admin/settings/teams" component={Teams} />
            <Route path="/admin/settings/work_hours" component={WorkHours} />
            <Route path="/admin/settings/rules" component={Rules} />
            <Redirect to="/admin/settings/users" />
          </Switch>
        </div>
      ];
    }
  }
);
