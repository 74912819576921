import React, { Component } from 'react';
import FilterDropDown from 'components/FilterDropdown';
export default class WeekDayFilter extends Component {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
  }
  getOptions() {
    const { days } = this.props;
    return days.map(day => [day.id, day.name]);
  }
  onSelect(newValue) {
    const { value, onChange } = this.props;
    const index = value.indexOf(newValue);
    if (index > -1) {
      onChange(
        value.reduce(
          (values, currentValue) =>
            currentValue === newValue ? values : [...values, currentValue],
          []
        )
      );
    } else {
      onChange([...value, newValue]);
    }
  }
  render() {
    const { value } = this.props;
    const options = this.getOptions();
    return (
      <FilterDropDown
        className="trip-filters__item dropdown_filter"
        icon="calendar"
        value={'Week Days'}
        options={options}
        onSelect={this.onSelect}
        selected={value}
        closeOnSelect={false}
      />
    );
  }
}
