import formatRoutePoints from '../lib/formatPointsForTripRoute';
import client from '../client';

export const getAutoCompleteSuggestions = (input, location) =>
  client.get('/maps/autocomplete', { params: { input, location } });

export const getResultsOfTextSearch = (query, location) =>
  client.get('/maps/search', { params: { query, location } });

export const getRoute = points =>
  client.get('/maps/route', { params: formatRoutePoints(points) });

export const getAddressFromCoordinates = point =>
  client.get('/maps/geocode/reverse', { params: { point } });
