export const unsupported = {
  heading: 'The browser you are using is not supported.',
  message: 'Please use Chrome, Firefox, or Safari for the best experience.'
};

export const mobile = {
  heading: 'This web page is not designed for mobile devices.',
  message: 'Please use a PC, Mac or iPad for the best experience.'
};

export const iPad = {
  heading: 'MileCatcher Portal is designed for landscape mode only.',
  message: 'Please use landscape mode for the best experience.'
};
