import _ from 'lodash';
import React, { Component } from 'react';
import LoadingPage from 'components/LoadingPage';
import Value from 'components/Value';
import Distance from 'components/Distance';
import Money from 'components/Money';
import PieChart from 'components/PieChart';
import LineChart from 'components/LineChart';
import './Statistics.css';

class Statistics extends Component {
  renderValue(label, value, yellow, title) {
    return (
      <li className="statistics-values__item">
        <div className="statistics-values__label" title={title}>
          {label}
        </div>
        <div className="statistics-values__value">
          <Value type={yellow ? 'Business' : 'Unclassified'}>{value}</Value>
        </div>
      </li>
    );
  }

  componentDidMount() {
    const { statistics, getStatistics } = this.props;

    if (!statistics) {
      getStatistics();
    }
  }

  componentDidUpdate() {
    const { statistics, getStatistics } = this.props;

    if (!statistics) {
      getStatistics();
    }
  }

  render() {
    const { statistics } = this.props;

    return !statistics ? (
      <LoadingPage />
    ) : (
      <div className="statistics">
        <div className="statistics__row">
          <div className="statistics-card">
            <h4 className="statistics-card__title">
              Deduction by {_.capitalize(statistics.expenseChart.step)}
            </h4>
            <LineChart
              data={statistics.expenseChart.data}
              step={statistics.expenseChart.step}
              formatYTick={value => <Money value={value} round />}
            />
          </div>

          <div className="statistics-card">
            <h4 className="statistics-card__title">Value</h4>

            <ul className="statistics-values">
              {this.renderValue(
                'Mileage',
                <Money value={statistics.mileageDeduction} round />,
                statistics.mileageDeduction > 0,
                'Deduction from mileage'
              )}

              {this.renderValue(
                'Parking',
                <Money value={statistics.parkingDeduction} round />,
                statistics.parkingDeduction > 0,
                'Deduction from parking fees'
              )}

              {this.renderValue(
                'Toll',
                <Money value={statistics.tollDeduction} round />,
                statistics.tollDeduction > 0,
                'Deduction from tolls'
              )}

              {this.renderValue(
                <strong>Total</strong>,
                <Money value={statistics.deduction} round />,
                statistics.deduction > 0,
                'Total deduction'
              )}
            </ul>

            {this.renderValue(
              <span>
                Potential<span className="asterisk">*</span>
              </span>,
              <Money value={statistics.potentialDeduction} round />,
              false,
              'Potential deduction'
            )}

            <div className="statistics-card__footnote">
              *unclassified mileage &times; business rate
            </div>
          </div>
        </div>

        <div className="statistics__row">
          <div className="statistics-card">
            <h4 className="statistics-card__title">
              Mileage by {_.capitalize(statistics.expenseChart.step)}
            </h4>
            <LineChart
              data={statistics.distanceChart.data}
              step={statistics.distanceChart.step}
              formatYTick={value => <Distance value={value} round />}
              distance
            />
          </div>

          <div className="statistics-card">
            <h4 className="statistics-card__title">Distance</h4>

            <ul className="statistics-values">
              {this.renderValue(
                'Deductible',
                <Distance value={statistics.deductibleDistance} round />,
                true,
                'Deductible mileage'
              )}

              {this.renderValue(
                'Total',
                <Distance value={statistics.totalDistance} round />,
                false,
                'Total mileage'
              )}
            </ul>

            <div className="statistics-card__chart">
              <PieChart
                percent={
                  statistics.totalDistance > 0
                    ? Math.round(
                        statistics.deductibleDistance /
                          statistics.totalDistance *
                          100
                      )
                    : 0
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Statistics;
