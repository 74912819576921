import React from 'react';
import AddButton from 'components/CompanyPages/lib/AddButton';
import InformationPopUp from 'components/InformationPopUp';
import './MyLocationsHeader.css';

export default ({ openAddLocationModal, description }) => (
  <div className="my-locations-header">
    <InformationPopUp className="my-locations-info" message={description} />
    <AddButton className="add-location-button" onClick={openAddLocationModal}>
      Add Location
    </AddButton>
  </div>
);
