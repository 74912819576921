import { connect } from 'react-redux';
import {
  mapDispatchToGeneralSettingsForm,
  mapStateToGeneralSettingsForm
} from './generalSettingsFormMappings';
import GeneralSettingsForm from './GeneralSettingsForm';

export default connect(
  mapStateToGeneralSettingsForm,
  mapDispatchToGeneralSettingsForm
)(GeneralSettingsForm);
