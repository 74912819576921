import React, { Component } from 'react';
import PageTitleHeader from 'components/CompanyPages/lib/PageTitleHeader';
import AddButton from 'components/CompanyPages/lib/AddButton';
import AddPurposePage from './AddPurposePage';
import Modal from 'components/Modal';
import Purpose from './Purpose';
import Checkbox from 'components/Checkbox';
import RenderPage from 'lib/RenderPage';
import './PurposesPage.css';

export default class PurposesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addModalIsOpen: false,
      updateModalIsOpen: false,
      selectedPurpose: null,
      showHidden: false
    };

    this.toggleShowHidden = this.toggleShowHidden.bind(this);
    this.openAddPurposeModal = this.openAddPurposeModal.bind(this);
    this.closeAddPurposeModal = this.closeAddPurposeModal.bind(this);
    this.openUpdatePurposeModal = this.openUpdatePurposeModal.bind(this);
    this.closeUpdatePurposeModal = this.closeUpdatePurposeModal.bind(this);
  }

  componentWillMount() {
    const { getTeams, loadPurposes, getCompanyCostCenters } = this.props;

    getTeams();
    loadPurposes();
    getCompanyCostCenters();
  }

  openAddPurposeModal() {
    this.setState({ addModalIsOpen: true });
  }

  closeAddPurposeModal() {
    this.setState({ addModalIsOpen: false });
  }

  toggleShowHidden({ target }) {
    this.setState({ showHidden: target.checked });
  }

  openUpdatePurposeModal(purpose) {
    this.setState({
      updateModalIsOpen: true,
      selectedPurpose: purpose
    });
  }

  closeUpdatePurposeModal() {
    this.setState({
      updateModalIsOpen: false,
      selectedPurpose: null
    });
  }

  render() {
    const {
      purposes,
      updateCompanyPurpose,
      addCompanyPurpose,
      requestsState
    } = this.props;

    const {
      addModalIsOpen,
      updateModalIsOpen,
      selectedPurpose,
      showHidden
    } = this.state;

    return (
      <div className="purpose-page">
        <Modal
          title="Add Purpose"
          className="add-purpose-modal"
          isOpen={addModalIsOpen}
          onClose={this.closeAddPurposeModal}
        >
          <AddPurposePage
            addPurpose={addCompanyPurpose}
            onSubmit={this.closeAddPurposeModal}
          />
        </Modal>
        <Modal
          title="Edit Purpose"
          className="add-purpose-modal"
          isOpen={updateModalIsOpen}
          onClose={this.closeUpdatePurposeModal}
        >
          <AddPurposePage
            onSubmit={this.closeUpdatePurposeModal}
            addPurpose={updateCompanyPurpose}
            purpose={selectedPurpose}
          />
        </Modal>
        <PageTitleHeader>
          Purposes
          <Checkbox
            label="Show hidden purposes"
            className="show-hidden-purposes-check-box"
            onChange={this.toggleShowHidden}
          />
        </PageTitleHeader>
        <div className="purposes-header">
          <div className="purposes-page-description">
            <h1 className="purposes-description">
              Define the purposes that users can use to classify trips. You can
              delete purposes or temporarily hide and restore them later. You
              can apply purposes to specific teams and/or cost centers
              (optional)
            </h1>
          </div>
          <AddButton
            className="purposes-page-add-button"
            onClick={this.openAddPurposeModal}
          >
            Add Purpose
          </AddButton>
        </div>
        <div className="purposes-list-section">
          <RenderPage requestsState={requestsState}>
            <ul className="purposes-list">
              {purposes &&
                purposes
                  .filter(({ hidden }) => !hidden || showHidden)
                  .map((purpose, index) => (
                    <li key={`purposes-list-item-${index}`}>
                      <Purpose
                        purpose={purpose}
                        modifyPurpose={this.openUpdatePurposeModal}
                      />
                    </li>
                  ))}
            </ul>
          </RenderPage>
        </div>
      </div>
    );
  }
}
