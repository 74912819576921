export const formatFilters = initialValues => filters => {
  // go through each filter by name and format it to the correct output
  return Object.keys(filters).reduce((formatted, key) => {
    // get the appropriate index or indices in the case of multiple selections
    const index = filters && filters[key];

    // get the filter from initial values
    const filter = initialValues && initialValues[key];

    // determine whether we are receiving multiple values
    const multiSelect = filter && filter.multiSelect;

    // get the values from the filter
    const values = filter && filter.values;

    // get the formatted values using the input index or indices
    const value =
      values &&
      (multiSelect
        ? index && index.map && index.map(i => values[i])
        : values[index]);

    // return the formatted value(s)
    return {
      ...formatted,
      ...(value && {
        [key]: multiSelect ? value.map(({ id }) => id) : value.id
      })
    };
  }, {});
};

export const combineFilters = initialValues =>
  Object.keys(initialValues).reduce(
    (
      { filters, multiSelect: selectors, defaultValues, icons, ...all },
      key
    ) => {
      const { titles, values, multiSelect, icon, defaultValue } = initialValues[
        key
      ];

      return {
        ...all,
        filters: {
          ...filters,
          [key]: titles
        },
        icons: [...icons, icon],
        multiSelect: [...selectors, multiSelect],
        formatFilters: formatFilters(initialValues),
        [key]: values,
        defaultValues: [...defaultValues, defaultValue]
      };
    },
    { filters: {}, multiSelect: [], icons: [], defaultValues: [] }
  );

export default combineFilters;
