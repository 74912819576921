import moment from 'moment/moment';
import { DATE_FORMAT } from './constants';

export default trips => {
  return trips.reduce((groups, trip) => {
    const date = moment(trip.startTime).format(DATE_FORMAT);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(trip);
    return groups;
  }, {});
};
