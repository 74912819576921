import _ from 'lodash';
import { SubmissionError, reset } from 'redux-form';
import * as t from './actionTypes';
import api from 'api';

const createRequest = () => dispatch => {
  dispatch(reset('addPurpose'));
  return { type: t.CREATE_REQUEST };
};

const createFailure = error => ({
  type: t.CREATE_FAILURE,
  error
});

const createSuccess = purpose => ({
  type: t.CREATE_SUCCESS,
  payload: purpose,
  meta: {
    alert: { type: 'success', message: `Purpose "${purpose.name}" created` }
  }
});

export const create = purpose => dispatch => {
  dispatch(createRequest());
  return api.postPurposes({ ...purpose, index: -1 }).then(
    response => dispatch(createSuccess(response.data.purpose)),
    error => {
      dispatch(createFailure(error));
      if (error instanceof SubmissionError) {
        return Promise.reject(error);
      }
    }
  );
};

const reorderRequest = indexes => ({
  type: t.REORDER_REQUEST,
  payload: indexes
});

const reorderFailure = error => ({
  type: t.REORDER_FAILURE,
  error,
  meta: { alert: { type: 'success', message: 'Unable to reorder purposes' } }
});

const reorderSuccess = purposes => ({
  type: t.REORDER_SUCCESS,
  payload: purposes
});

export const reorder = indexes => dispatch => {
  const purposes = _.entries(indexes).map(entry => ({
    id: entry[0],
    index: entry[1]
  }));
  dispatch(reorderRequest(purposes));
  return api
    .putPurposes(purposes)
    .then(
      response => dispatch(reorderSuccess(response.data.purposes)),
      error => dispatch(reorderFailure(error))
    );
};

const destroyRequest = id => ({ type: t.DESTROY_REQUEST, payload: id });

const destroyFailure = error => ({
  type: t.DESTROY_FAILURE,
  error
});

const destroySuccess = id => ({ type: t.DESTROY_SUCCESS, payload: id });

export const destroy = id => dispatch => {
  dispatch(destroyRequest(id));
  return api
    .deletePurpose(id)
    .then(
      () => dispatch(destroySuccess(id)),
      error => dispatch(destroyFailure(error))
    );
};
