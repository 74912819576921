import React from 'react';
import classNames from 'class-names';
import './PieChart.css';

export const PieChart = ({ percent, className }) => (
  <div className={`${className ? `${className} ` : ''}pie-chart`}>
    <span className="pie-chart__label">
      {percent}
      <span className="pie-chart__percent">%</span>
    </span>

    <div
      className={classNames('pie-chart__bars', {
        'pie-chart__bars_over50': percent > 50
      })}
    >
      <div
        className="pie-chart__left"
        style={{ transform: `rotate(${percent * 3.6}deg)` }}
      />
      <div className="pie-chart__right" />
    </div>
  </div>
);

export default PieChart;
