import { getCompanyTeams } from 'api/endpoints/company/teams';
import {
  GET_TEAMS_FAILURE,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS
} from 'ducks/teams/teamActionTypes';

export const getTeamsRequest = () => ({
  type: GET_TEAMS_REQUEST
});

export const getTeamsSuccess = payload => ({
  type: GET_TEAMS_SUCCESS,
  payload
});

export const getTeamsFailure = error => ({
  type: GET_TEAMS_FAILURE,
  payload: error,
  error
});

export const getTeams = () => dispatch => {
  dispatch(getTeamsRequest());
  return getCompanyTeams()
    .then(({ data }) => dispatch(getTeamsSuccess(data.teams)))
    .catch(error => dispatch(getTeamsFailure(error)));
};

export default getTeams;
