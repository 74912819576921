import { connect } from 'react-redux';
import ReportStatusActionButtons from './ReportStatusActionButtons';
import {
  mapStateToReportStatusActionButtons,
  mapDispatchToReportStatusActionButtons
} from './reportStatusActionButtonsMappings';

export default connect(
  mapStateToReportStatusActionButtons,
  mapDispatchToReportStatusActionButtons
)(ReportStatusActionButtons);
