import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import RecalculateTripRouteForm from './RecalculateTripRouteForm';
import {
  mapDispatchToRecalculateRouteForm,
  mapStateToRecalculateRouteForm
} from './RecalculateTripRouteContainer';
const Form = reduxForm({ form: 'recalculate-trip-form' })(
  RecalculateTripRouteForm
);
export default connect(
  mapStateToRecalculateRouteForm,
  mapDispatchToRecalculateRouteForm
)(Form);
