import { getUserByLogin } from 'ducks/session/sessionActions';
import { setAuthToken, setUserId, clearLocalStorage } from 'lib/auth';
import { deleteUser } from './deleteUser';

export const confirmAccountDeletionFailure = error => ({
  type: 'CONFIRM_ACCOUNT_DELETION_FAILURE',
  payload: error,
  error,
  meta: {
    alert: {
      type: 'failure',
      message:
        'We could not match the provided email and password with an existing account'
    }
  }
});

export const confirmAccountDeletion = (email, password) => dispatch =>
  getUserByLogin(email, password)
    .then(({ id, authToken }) => {
      setAuthToken(authToken);
      setUserId(id);
      return dispatch(deleteUser(id)).then(action => {
        clearLocalStorage();
        return action;
      });
    })
    .catch(error => dispatch(confirmAccountDeletionFailure(error)));
