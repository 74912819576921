import { companyApi } from '../../client';

export const getKeyPerformanceIndexReports = () =>
  companyApi.get('/key_performance_index_reports');

export const postKeyPerformanceIndexReport = keyPerformanceIndexReport =>
  companyApi.post('/key_performance_index_reports', {
    keyPerformanceIndexReport
  });

export const putKeyPerformanceIndexReport = (id, keyPerformanceIndexReport) =>
  companyApi.put(`/key_performance_index_reports/${id}`, {
    keyPerformanceIndexReport
  });

export const deleteKeyPerformanceIndexReport = id =>
  companyApi.delete(`/key_performance_index_reports/${id}`);
