import { connect } from 'react-redux';
import ReportsPage from './components/ReportsPage';
import {
  mapDispatchToReportsPage,
  mapStateToReportsPage
} from './reportsPageMappings';

export default connect(
  mapStateToReportsPage,
  mapDispatchToReportsPage
)(ReportsPage);
