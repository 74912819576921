import { connect } from 'react-redux';
import TeamSelector from './SingleTeamSelector';
import {
  mapStateToSingleTeamSelector,
  mapDispatchToSingleTeamSelector
} from './singleTeamSelectorMappings';

export default connect(
  mapStateToSingleTeamSelector,
  mapDispatchToSingleTeamSelector
)(TeamSelector);
