import React, { Component } from 'react';
import {
  UserCreatedLocation,
  AdminCreatedLocation
} from 'components/MyLocations/Location';
import './LocationsList.css';
export default class LocationsList extends Component {
  render() {
    const { locations, select, selectedId } = this.props;
    return (
      <ul key={'locations-list'} className="locations-list">
        {locations.map(location => {
          const id = location.id;
          const props = {
            selected: selectedId === id,
            id,
            select,
            location
          };
          return location.createdByAdmin ? (
            <AdminCreatedLocation key={id} {...props} />
          ) : (
            <UserCreatedLocation key={id} {...props} />
          );
        })}
      </ul>
    );
  }
}
