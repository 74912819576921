// see https://stackoverflow.com/a/9851769/7351962
export const browserIsSupported = () => isFirefox() || isChrome() || isSafari();
export const isFirefox = () => typeof InstallTrigger !== 'undefined';
export const isChrome = () =>
  navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
export const isSafari = () => {
  return (
    /constructor/i.test(window.HTMLElement) ||
    (p => p.toString() === '[object SafariRemoteNotification]')(
      !window['safari'] ||
        (typeof window.safari !== 'undefined' && window.safari.pushNotification)
    )
  );
};
