import React from 'react';
import FontAwesome from 'react-fontawesome';
import formatDate from 'components/MyLocations/lib/formatDate';
import './Location.css';
export default ({ location, select, selected, id }) => (
  <li
    key={id}
    onClick={() => select(location)}
    className={`admin-location ${selected ? 'selected-location' : ''}`}
  >
    <p className="admin-location-text admin-location-name">{location.name}</p>
    <p className="admin-location-text admin-created-message">
      Created By Admin
    </p>
    <p className="admin-location-text admin-location-address">
      {location.address}
    </p>
    <p className="admin-location-text admin-location-created-at">
      Added on {formatDate(location.createdAt)}
    </p>
    <button
      className="admin-hide-location-button location-button"
      onClick={() => alert('hide!')}
    >
      <FontAwesome
        className="hide-location-icon"
        name="eye-slash"
        size="2x"
        fixedWidth
      />
    </button>
  </li>
);
