export const GET_REQUEST = 'vehicles/GET_REQUEST';
export const GET_SUCCESS = 'vehicles/GET_SUCCESS';
export const GET_FAILURE = 'vehicles/GET_FAILURE';

export const UPDATE_REQUEST = 'vehicles/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'vehicles/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'vehicles/UPDATE_FAILURE';

export const DESTROY_REQUEST = 'vehicles/DESTROY_REQUEST';
export const DESTROY_SUCCESS = 'vehicles/DESTROY_SUCCESS';
export const DESTROY_FAILURE = 'vehicles/DESTROY_FAILURE';

export const CREATE_REQUEST = 'vehicles/CREATE_REQUEST';
export const CREATE_SUCCESS = 'vehicles/CREATE_SUCCESS';
export const CREATE_FAILURE = 'vehicles/CREATE_FAILURE';

export const UNSET_PRIMARY = 'vehicles/UNSET_PRIMARY';
