import client from 'api/client';

export const putResetPassword = (token, password, confirmation, legacy) =>
  client.put(legacy ? '/reset_legacy_password' : '/reset_password', {
    user: {
      resetPasswordToken: token,
      password,
      passwordConfirmation: confirmation
    }
  });
