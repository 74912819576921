import { getCompanyTrips } from 'api/endpoints/company/trips';
import { getPage } from 'ducks/pagination/paginationActions/getPage';
import { NAME as TripsPageName } from 'ducks/companyTrips/constants';
import { GET_COMPANY_TRIPS_SUCCESS } from '../companyTripActionTypes';

const getTrips = getPage(TripsPageName, 'trips', getCompanyTrips);

export const getCompanyTripsPage = params => dispatch =>
  dispatch(getTrips(params)).then(() =>
    dispatch({ type: GET_COMPANY_TRIPS_SUCCESS })
  );

export default getCompanyTripsPage;
