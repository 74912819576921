import getAutoApprovalRulesArray from 'ducks/rules/rulesSelectors/getAutoApprovalRulesArray';
import { getAutoApprovalRules } from 'ducks/rules/rulesActions/getAutoApprovalRules';
import { removeAutoApprovalRule } from 'ducks/rules/rulesActions/removeAutoApprovalRule';
import getSelectedAutoApprovalRuleId from 'ducks/rules/rulesSelectors/getSelectedAutoApprovalRuleId';
import { getCompanyPurposes as loadPurposes } from 'ducks/company/companyActions/purposes/getCompanyPurposes';
import { getCompanyPurposes } from 'ducks/company/companySelectors/getCompanyPurposes';
import { getTeams } from 'ducks/teams/teamsActions/getTeams';

export const mapStateToRulesPage = state => ({
  autoSubmissionRules: [],
  selectedAutoSubmissionRule: 'Every Saturday Midnight',
  autoApprovalRules: getAutoApprovalRulesArray(state),
  selectedAutoApprovalRuleId: getSelectedAutoApprovalRuleId(state),
  purposes: getCompanyPurposes(state)
});

export const mapDispatchToRulesPage = {
  getAutoApprovalRules,
  removeAutoApprovalRule,
  loadPurposes,
  getTeams
};
