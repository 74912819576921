import { updateCompanyInvite } from 'ducks/company/companyActions/invitations/updateCompanyInvite';
import { updateCompanyUser } from 'ducks/company/companyActions/users/updateCompanyUser';
import { deleteCompanyInvite } from 'ducks/company/companyActions/invitations/deleteCompanyInvite';
import { removeUserFromCompany } from 'ducks/company/companyActions/users/removeUserFromCompany';
import { updateTeam } from 'ducks/teams/teamsActions/updateTeam';
import { getMostRecentTaxRateOfUsersCountry } from 'ducks/countries/selectors';

export const mapStateToEditUsersPage = state => ({
  globalCustomBusinessRate: getMostRecentTaxRateOfUsersCountry(state)
});

export const mapDispatchToEditUsersPage = {
  updateUser: updateCompanyUser,
  updateInvite: updateCompanyInvite,
  deleteUser: removeUserFromCompany,
  deleteInvite: deleteCompanyInvite,
  updateTeam
};
