import signUp from 'ducks/company/companyActions/signUpCompany.js';
import { userSignIn as signIn } from 'ducks/session/sessionActions';
import { countriesLoaded } from 'ducks/countries/selectors';

export const mapStateToCompanySignUp = state => ({
  countriesLoaded: countriesLoaded(state)
});

export const mapDispatchToCompanySignUp = {
  signUp,
  signIn
};
