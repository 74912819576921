import createReducer from 'redux/createReducer';
import {
  GET_KPI_REPORT_SUCCESS,
  PUT_KPI_REPORT_SUCCESS,
  POST_KPI_REPORT_SUCCESS,
  DELETE_KPI_REPORT_SUCCESS
} from 'ducks/kpiReports/kpiReportActionTypes';

export const removeKpiReport = (state, action) => {
  const removed = action && action.payload;
  return state.filter(({ id }) => Number(id) !== Number(removed));
};

export const setKpiReports = (state, action) => {
  const reports = action && action.payload;
  return reports || state;
};

export const addKpiReport = (state, action) => {
  const report = action && action.payload;
  return report ? [report, ...state] : state;
};

export const updateKpiReport = (state, action) => {
  const report = action && action.payload;
  const id = report && report.id;
  const withoutReplacement = removeKpiReport(state, { payload: id });
  return report ? [report, ...withoutReplacement] : state;
};

export const kpiReports = createReducer([], {
  [DELETE_KPI_REPORT_SUCCESS]: removeKpiReport,
  [GET_KPI_REPORT_SUCCESS]: setKpiReports,
  [POST_KPI_REPORT_SUCCESS]: addKpiReport,
  [PUT_KPI_REPORT_SUCCESS]: updateKpiReport
});

export default kpiReports;
