import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { postCompanyReports } from 'api/endpoints/company/reports';
import {
  POST_COMPANY_REPORTS_REQUEST,
  POST_COMPANY_REPORTS_SUCCESS,
  POST_COMPANY_REPORTS_FAILURE
} from 'ducks/company/companyActionTypes';
import addSuccessAndFailureMessages from '../../../lib/addSuccessAndFailureMessages';

export const createCompanyReports = addSuccessAndFailureMessages(
  createSimpleAction(postCompanyReports, [
    POST_COMPANY_REPORTS_REQUEST,
    POST_COMPANY_REPORTS_SUCCESS,
    POST_COMPANY_REPORTS_FAILURE
  ]),
  'Reports were successfully sent to be generated',
  'There was an error generating the submitted reports'
);

export default createCompanyReports;
