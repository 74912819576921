import { connect } from 'react-redux';
import AddLocationModal from './AddLocationModal';
import {
  mapDispatchToAddLocationModal,
  mapStateToAddLocationModal
} from './AddLocationModalMappings';

export default connect(
  mapStateToAddLocationModal,
  mapDispatchToAddLocationModal
)(AddLocationModal);
