import { getRecalculatedRoute, updateTripRoute } from 'ducks/trips/actions';
import { getRequestState } from 'ducks/trips/selectors';
import { getRecalculatedTrip } from 'ducks/trips/selectors';
import { getMetricMeasure } from 'ducks/users/selectors';
import { formatDistance } from 'lib/formatDistance';
import { toNumber } from 'lib/localization/numericLocalization';
import { getUsersCountryDecimal } from 'ducks/countries/selectors';

export const mapStateToRecalculateRouteForm = (state, { tripId }) => {
  const metric = getMetricMeasure(state);
  const trip = getRecalculatedTrip(state, tripId);
  const decimal = getUsersCountryDecimal(state);

  return {
    status: getRequestState(state, tripId),
    distance: trip && formatDistance(state, toNumber(trip.distance, decimal)),
    unitOfMeasure: metric ? 'ki' : 'mi',
    tripId,
    ...trip
  };
};
export const mapDispatchToRecalculateRouteForm = {
  getRecalculatedRoute,
  onSubmit: updateTripRoute
};
