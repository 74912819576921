import { getCompanyUsersObject } from 'ducks/company/companySelectors/companyMembers/getCompanyUsersObject';
import { getTeam } from 'ducks/teams/teamsSelectors/getTeam';
import { updateTeam } from 'ducks/teams/teamsActions/updateTeam';
import { removeUserFromTeam } from 'ducks/teams/teamsActions/removeUserFromTeam';
import getCostCenters from 'ducks/costCenters/costCentersSelectors/getCostCenters';
import getTeamManagers from 'ducks/teams/teamsSelectors/getTeamManagers';
import getTeamMembers from 'ducks/teams/teamsSelectors/getTeamMembers';
import userIsAdmin from 'ducks/users/usersSelectors/userIsAdmin';
import { getMostRecentTaxRateOfUsersCountry } from 'ducks/countries/selectors';

export const mapStateToEditTeamPage = (state, { teamId }) => {
  const team = getTeam(state, teamId);
  return {
    team,
    isAdmin: userIsAdmin(state),
    members: getTeamMembers(state, teamId),
    managers: getTeamManagers(state, teamId),
    usersObject: getCompanyUsersObject(state),
    costCenters: [{ name: 'None' }, ...getCostCenters(state)],
    enableReinitialize: true,
    initialValues: {
      name: team && team.name,
      customBusinessRate:
        (team && team.customBusinessRate) ||
        getMostRecentTaxRateOfUsersCountry(state)
    }
  };
};

export const mapDispatchToEditTeamPage = {
  updateTeam,
  removeUserFromTeam
};
