import client from '../../client';

export const getVehicles = () => client.get('/vehicles');

export const postVehicles = params =>
  client.post('/vehicles', { vehicle: params });

export const putVehicle = (id, params) =>
  client.put(`/vehicles/${id}`, { vehicle: params });

export const deleteVehicle = id => client.delete(`/vehicles/${id}`);
