import createReducer from 'redux/createReducer';
import { paginateHandlers } from 'ducks/lib/Pagination/paginateHandlers';
import {
  SELECT_ALL,
  DESELECT_ALL,
  TOGGLE_SELECT_ALL,
  ADD_PAGE_TO_SELECTION
} from '../selectionActionTypes';

export default createReducer(
  {},
  paginateHandlers(
    {
      [ADD_PAGE_TO_SELECTION]: state => state,
      [TOGGLE_SELECT_ALL]: state => !state,
      [DESELECT_ALL]: () => false,
      [SELECT_ALL]: () => true
    },
    false
  )
);
