import { combineReducers } from 'redux';
import pages from './pages';
import pageNumber from './pageNumber';
import totalCount from './totalCount';
import pageSize from './pageSize';
import totalExpense from './totalExpense';

export default combineReducers({
  pages,
  pageNumber,
  totalCount,
  totalExpense,
  pageSize
});
