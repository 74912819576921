import { connect } from 'react-redux';
import TopExpensersDisplay from './StatisticsUsersDisplay';
import {
  mapStateToStatisticsUsersDisplay,
  mapDispatchToStatisticsUsersDisplay
} from './statisticsUsersDisplayMappings';

export default connect(
  mapStateToStatisticsUsersDisplay,
  mapDispatchToStatisticsUsersDisplay
)(TopExpensersDisplay);
