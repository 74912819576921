import React from 'react';
import './CompanySignUpTerms.css';
export default ({ onChange }) => (
  <div className="company-sign-up-terms">
    <input
      className="company-sign-up-confirmation-checkbox"
      type="checkbox"
      onChange={onChange}
    />
    <p className="company-sign-up-terms-text">
      I agree to the <a href="#">Terms of Service</a> and{' '}
      <a href="#">Privacy Policy</a>.
    </p>
  </div>
);
