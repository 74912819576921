// docs for stripe: https://github.com/stripe/react-stripe-elements
import React, { Component } from 'react';
import {
  CardNumberElement,
  CardCVCElement,
  injectStripe,
  CardExpiryElement
} from 'react-stripe-elements';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  async submit() {
    const {
      buyAdditionalSeats,
      stripe: { createSource }
    } = this.props;

    let token = await createSource({
      name: 'Name',
      amount: 2000,
      currency: 'usd',
      description: 'purchase additional seats'
    });

    buyAdditionalSeats && buyAdditionalSeats(token);
  }

  render() {
    return (
      <div className="checkout">
        <p>Would you like to complete the purchase?</p>
        <CardCVCElement />
        <CardNumberElement />
        <CardExpiryElement />
        <button onClick={this.submit}>Send</button>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
