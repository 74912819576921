import { connect } from 'react-redux';
import IntegrationsPage from './IntegrationsPage';
import {
  mapStateToIntegrationsPage,
  mapDispatchToIntegrationsPage
} from './integrationsPageMappings';

export default connect(
  mapStateToIntegrationsPage,
  mapDispatchToIntegrationsPage
)(IntegrationsPage);
