import React from 'react';

import './Footer.css';

const Footer = () => (
  <div className="footer">
    <div className="footer__inner">
      MileCatcher&trade; is an app and trademark owned by MileCatcher, Inc.
      <ul className="footer__links">
        <li className="footer__links__item">
          <a
            href="https://support.milecatcher.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Support
          </a>
        </li>
        <li className="footer__links__item">
          <a
            href="https://www.milecatcher.com/legalpro/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Legal
          </a>
        </li>
      </ul>
    </div>
  </div>
);

export default Footer;
