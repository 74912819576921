const fakeData = index => (index + 1) * (3.45 + Number(`${index}.${index}`));

export const mapStateToTopFiveExpensesBarChart = () => ({
  data: [1, 2, 3, 4, 5]
    .map((number, index) => ({
      title: `data point ${number}`,
      percentage: number * 10,
      value: fakeData(index)
    }))
    .reverse()
});

export const mapDispatchToTopFiveExpensesBarChart = {};
