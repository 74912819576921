import { getCompanyVehicles } from './getCompanyVehicles';

export const getVehiclesUniqueNames = state => {
  const uniqueVehicles = getCompanyVehicles(state).reduce(
    (vehicles, vehicle) => {
      const { nickname, model } = vehicle;
      const name = nickname || model;
      const existingVehicles = vehicles[name] || [];
      return {
        ...vehicles,
        [name]: [...existingVehicles, vehicle]
      };
    },
    {}
  );

  return Object.keys(uniqueVehicles).map(name => ({
    id: uniqueVehicles[name].map(({ id }) => id),
    name
  }));
};
