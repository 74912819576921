import { connect } from 'react-redux';
import session from 'ducks/session';
import SignUpForm from './SignUpForm';
const mapDispatchToProps = dispatch => ({
  signUp: data => dispatch(session.actions.signUp(data))
});
const mapStateToProps = (state, props) => ({
  r: props.r.split("=")[1],
  initialValues: {r: props.r.split("=")[1]}
});
export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
