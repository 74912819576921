import { updateCompanyUser as updateUser } from 'api/endpoints/company/users';
import { updateCompanyUsersPage } from './updateCompanyUsersPage';
import simpleActionCreator from 'ducks/lib/simpleActionCreator';
import {
  UPDATE_COMPANY_USER_REQUEST,
  UPDATE_COMPANY_USER_SUCCESS,
  UPDATE_COMPANY_USER_FAILURE
} from 'ducks/company/companyActionTypes';

export const updateCompanyUser = (id, user) => dispatch =>
  dispatch(
    simpleActionCreator(
      updateUser,
      [
        UPDATE_COMPANY_USER_REQUEST,
        'USER_UPDATE_SUCCESSFUL',
        UPDATE_COMPANY_USER_FAILURE
      ],
      'user'
    )(id, user)
  ).then(() => {
    // TODO this is a hack to make up for server returning a company rather than the modified user object, make normal after api is fixed
    dispatch({
      type: UPDATE_COMPANY_USER_SUCCESS,
      payload: user,
      meta: { alert: { type: 'success', message: 'User was updated' } }
    });
    dispatch(updateCompanyUsersPage(user));
  });

export default updateCompanyUser;
