import { getCompanyPurposes } from 'ducks/company/companySelectors/getCompanyPurposes';
import { addCostCenter } from 'ducks/costCenters/costCentersActions/addCostCenter';

export const mapStateToAddCostCenterPage = state => ({
  purposes: getCompanyPurposes(state)
});

export const mapDispatchToAddCostCenterPage = {
  addCostCenter
};
