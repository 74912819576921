import { connect } from 'react-redux';
import trips from 'ducks/trips';
import AddTripPage from './AddTripPage';

const mapDispatchToProps = (dispatch, ownProps) => ({
  addTrip: trip => dispatch(trips.actions.addTrip(trip, ownProps.history)),
  failedToFindCoordinatesError: () =>
    dispatch({
      type: 'FAILED_TO_FIND_COORDINATES_ERROR',
      meta: {
        alert: {
          type: 'failure',
          message:
            'We could not find an address for the point you have selected on the map.'
        }
      }
    })
});

export default connect(
  null,
  mapDispatchToProps
)(AddTripPage);
