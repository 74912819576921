import { getCurrentCompanyTripsPage } from 'ducks/companyTrips/tripsSelectors/getCurrentCompanyTripsPage';
import { getCompanyTripsPage } from 'ducks/companyTrips/tripsActions/getCompanyTripsPage';
import { getCompanyMembers as loadMembers } from 'ducks/company/companyActions/users/getCompanyMembers';
import { getCompanyLocations as loadLocations } from 'ducks/company/companyActions/locations/getCompanyLocations';
import { getTeams as loadTeams } from 'ducks/teams/teamsActions/getTeams';
import { getCompanyPurposes as loadPurposes } from 'ducks/company/companyActions/purposes/getCompanyPurposes';
import { getCompanyVehicles as loadVehicles } from 'ducks/company/companyActions/vehicles/getCompanyVehicles';
import { combineFilters } from 'components/CompanyPages/lib/Filters/lib/combineFilters';
import getTeamFilters from 'components/CompanyPages/lib/Filters/lib/getTeamFilters';
import getPurposeFilters from 'components/CompanyPages/lib/Filters/lib/getPurposeFilters';
import getUserFilters from 'components/CompanyPages/lib/Filters/lib/getUserFilters';
import getReportStatusFilters from 'components/CompanyPages/lib/Filters/lib/getReportStatusFilters';
import getYearToDateFilters from 'components/CompanyPages/lib/Filters/lib/getYearToDateFilters';
import getLocationFilters from 'components/CompanyPages/lib/Filters/lib/getLocationFilters';
import { getCompanyVehiclesFilters } from 'components/CompanyPages/lib/Filters/lib/getCompanyVehiclesFilters';
import { weekDayFilter } from 'components/CompanyPages/lib/Filters/lib/weekDayFilter';
import getCompanyTripsPageRequests from 'ducks/company/companySelectors/companyRequests/getCompanyTripsPageRequests';
import getCompanyTripsTotalCount from 'ducks/companyTrips/tripsSelectors/getCompanyTripsTotalCount';
import { getDistanceMeasure } from 'ducks/users/selectors';
import getCompanyTripsPageNumber from 'ducks/companyTrips/tripsSelectors/getCompanyTripsPageNumber';
import { setTripsPageSize } from 'ducks/companyTrips/tripsActions/setTripsPageSize';
import { getCompanyTripsPageSize } from 'ducks/companyTrips/tripsSelectors/getCompanyTripsPageSize';

export const mapStateToManagerPage = state => ({
  pageName: 'companyTrips',
  requestsState: getCompanyTripsPageRequests(state),
  unitOfMeasure: getDistanceMeasure(state),
  pageNumber: getCompanyTripsPageNumber(state),
  pageSize: getCompanyTripsPageSize(state),
  trips: getCurrentCompanyTripsPage(state),
  total: getCompanyTripsTotalCount(state),
  filters: combineFilters({
    teamId: getTeamFilters(state),
    userId: getUserFilters(state),
    purposeId: getPurposeFilters(state),
    vehicle: getCompanyVehiclesFilters(state),
    location: getLocationFilters(state),
    approvalStatus: getReportStatusFilters(state),
    weekdays: weekDayFilter,
    timeScope: getYearToDateFilters(state)
  })
});

export const mapDispatchToManagerPage = {
  getCompanyTripsPage,
  loadMembers,
  loadTeams,
  loadPurposes,
  loadLocations,
  loadVehicles,
  setTripsPageSize
};
