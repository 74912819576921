import { createRequestsReducer } from 'redux/requestReducer';
import {
  GET_COMPANY_FAILURE,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS
} from 'ducks/company/companyActionTypes';

export default createRequestsReducer([
  [GET_COMPANY_REQUEST, GET_COMPANY_SUCCESS, GET_COMPANY_FAILURE]
]);
