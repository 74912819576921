import { convertUnitOfMeasurement } from '../../../lib/utils';
import { getUsersCountryDecimal } from '../../../ducks/countries/selectors';
import users from '../../../ducks/users';
import { toNumber } from '../../../lib/localization/numericLocalization';
export default (purpose, state) => {
  const decimalMark = getUsersCountryDecimal(state);
  const rate = toNumber(purpose.rate, decimalMark);

  return { ...purpose, rate };
};
