import {
  getResultsOfTextSearch,
  getAutoCompleteSuggestions
} from 'api/endpoints/maps';

export const autoComplete = (input, location) =>
  getAutoCompleteSuggestions(input, location).then(
    ({ data }) =>
      data && data.length && data.map(({ description }) => description)
  );

export const locationSearch = (text, location) => {
  return getResultsOfTextSearch(text, location).then(({ data }) => {
    const result = data && data.length && data[0];
    if (result) {
      let { lat, lng } = result.location;
      return [lat, lng];
    }
  });
};
