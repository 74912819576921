import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Button from "components/Button";
import ValidationError from "../ValidationError/ValidationError";
import AddAccessToParent from "components/GrantAccessToParent";
import "./EditTripForm.css";

export default class AddReturnTripForm extends Component {
  render() {
    const {
      onSubmit,
      handleSubmit,
      submitting,
      onCancel,
      error,
      children,
      saveIcon,
      buttonText,
      description,
      initialValues,
      ...props
    } = this.props;
    return (
      <form
        className="edit-trip-form"
        initialValues={initialValues}
        onSubmit={handleSubmit(args => onSubmit(Object.assign(props, args)))}
      >
        {error && (
          <div className="add-trip-page__error space-below-error-message">
            <ValidationError message={error} />
          </div>
        )}
        <AddAccessToParent parent={this}>{children}</AddAccessToParent>
        <div className="edit-trip-form__buttons">
          <Button disabled={submitting} color="save">
            <FontAwesome
              name={submitting ? "gear" : saveIcon}
              spin={submitting}
              fixedWidth
            />{" "}
            {submitting ? <span>Add Trip&hellip;</span> : buttonText}
          </Button>
          <Button color="cancel" onClick={onCancel}>
            <FontAwesome name="times" fixedWidth /> Cancel
          </Button>
        </div>
        <p className="edit-trip-form__help-text">{description}</p>
      </form>
    );
  }
}
