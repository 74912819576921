import React, { Component } from 'react';
import classNames from 'class-names';
import { Field } from 'redux-form';
import FontAwesome from 'react-fontawesome';
import Textbox from 'components/TextBox';
import PreloginTextbox from 'components/PreLogin/PreLoginTextbox';
import './TextboxField.css';

const renderInput = ({
  input,
  meta: { touched, error, warning },
  placeholder,
  type,
  prelogin,
  normalizeinput,
  autoComplete
}) => (
  <div
    className={classNames('textbox-field', {
      'textbox-field_prelogin': prelogin
    })}
  >
    {prelogin ? (
      <PreloginTextbox
        className="textbox-field__input"
        placeholder={placeholder}
        type={type || 'text'}
        autoComplete={autoComplete}
        {...input}
      />
    ) : (
      <Textbox
        autoComplete={autoComplete}
        {...input}
        type={type || 'text'}
        normalizeinput={normalizeinput}
        placeholder={placeholder}
      />
    )}
    {touched &&
      ((error && (
        <div className="textbox-field__error">
          <FontAwesome name="exclamation-circle" /> {error}
        </div>
      )) ||
        (warning && (
          <div className="textbox-field__warning">
            <FontAwesome name="exclamation-circle" /> {warning}
          </div>
        )))}
  </div>
);

export default class TextBoxField extends Component {
  render() {
    const props = this.props;
    return <Field component={renderInput} {...props} />;
  }
}
