import createWorkHoursReducer from 'ducks/workHours/lib/createWorkHoursReducer';

import {
  GET_WORK_HOURS_SUCCESS,
  SET_WORK_HOURS_FOR_DAY,
  SET_WORK_HOURS_STATUS
} from 'ducks/workHours/actionTypes';

export const companyWorkHours = createWorkHoursReducer(
  GET_WORK_HOURS_SUCCESS,
  SET_WORK_HOURS_FOR_DAY,
  SET_WORK_HOURS_STATUS
);

export default companyWorkHours;
