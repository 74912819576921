import { createRequestsReducer } from 'redux/requestReducer';
import {
  GET_TEAMS_FAILURE,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS
} from 'ducks/teams/teamActionTypes';
import {
  GET_COST_CENTERS_FAILURE,
  GET_COST_CENTERS_REQUEST,
  GET_COST_CENTERS_SUCCESS
} from 'ducks/costCenters/costCentersActionTypes';
import {
  GET_COMPANY_PURPOSES_FAILURE,
  GET_COMPANY_PURPOSES_REQUEST,
  GET_COMPANY_PURPOSES_SUCCESS
} from 'ducks/company/companyActionTypes';

export default createRequestsReducer([
  [
    GET_COMPANY_PURPOSES_REQUEST,
    GET_COMPANY_PURPOSES_SUCCESS,
    GET_COMPANY_PURPOSES_FAILURE
  ],
  [
    GET_COST_CENTERS_REQUEST,
    GET_COST_CENTERS_SUCCESS,
    GET_COST_CENTERS_FAILURE
  ],
  [GET_TEAMS_REQUEST, GET_TEAMS_SUCCESS, GET_TEAMS_FAILURE]
]);
