import { connect } from 'react-redux';
import TeamSelector from './TeamSelector';
import {
  mapStateToTeamSelector,
  mapDispatchToTeamSelector
} from './teamSelectorMappings';

export default connect(
  mapStateToTeamSelector,
  mapDispatchToTeamSelector
)(TeamSelector);
