import React, { Component } from 'react';
import capitalizeFirstLetter from 'lib/capitalizeFirstLetter';
import IconButton from 'components/CompanyPages/lib/IconButton';
import TextBubble from 'components/TextBubble';
import moment from 'moment';
import './ReportStatusActionButtons.css';

const dateFormat = 'M/DD/YYYY';

export default class ReportStatusActionButtons extends Component {
  constructor(props) {
    super(props);

    this.state = { visibleTextBubble: null };

    this.changeReportStatus = this.changeReportStatus.bind(this);
    this.showTextBubble = this.showTextBubble.bind(this);
    this.hideTextBubble = this.hideTextBubble.bind(this);
  }

  changeReportStatus({ target }) {
    const { id, changeReportStatus } = this.props;
    const reportStatus = target.id;

    this.setState({ reportStatus });

    changeReportStatus &&
      changeReportStatus(id, {
        approvalStatus: reportStatus
      });
  }

  showTextBubble(status) {
    this.setState({ visibleTextBubble: status });
  }

  hideTextBubble() {
    this.setState({ visibleTextBubble: null });
  }

  render() {
    const {
      isAdmin,
      approvalStatus,
      approvedAt,
      submitter,
      selectable,
      additional
    } = this.props;
    const canSelect = selectable !== false;

    const { visibleTextBubble } = this.state;

    return (
      <div className="report-status-action-buttons">
        {[
          ...(additional || []),
          [
            'rejected',
            'report-status-action-button rejected',
            'thumbs-down',
            selectable ? 'Click to reject' : 'rejected'
          ],
          [
            'approved',
            'report-status-action-button approved',
            'thumbs-up',
            selectable ? 'Click to approve' : 'approved'
          ],
          ...(isAdmin
            ? [
                [
                  'paid',
                  'report-status-action-button paid',
                  'check',
                  selectable ? 'Click to mark as paid' : 'paid'
                ]
              ]
            : [])
        ].map(
          ([
            status,
            className,
            icon,
            message,
            clickAble,
            handler,
            customIsSelected
          ]) => {
            const isSelected = approvalStatus === status;

            return (
              <div className="reports-status-action-button-container">
                <IconButton
                  selected={isSelected || customIsSelected}
                  value={status}
                  className={className}
                  onClick={
                    (canSelect || clickAble) &&
                    (handler || this.changeReportStatus)
                  }
                  onMouseEnter={() => this.showTextBubble(status)}
                  onMouseLeave={this.hideTextBubble}
                  icon={icon}
                />
                {visibleTextBubble === status && (
                  <TextBubble
                    className="report-status-info-bubble"
                    message={
                      isSelected && selectable
                        ? `${capitalizeFirstLetter(
                            status
                          )} by ${submitter} on ${moment(approvedAt).format(
                            dateFormat
                          )}`
                        : message
                    }
                  />
                )}
              </div>
            );
          }
        )}
      </div>
    );
  }
}
