import { createRequestsReducer } from 'redux/requestReducer';
import {
  GET_COMPANY_MEMBERS_FAILURE,
  GET_COMPANY_MEMBERS_REQUEST,
  GET_COMPANY_MEMBERS_SUCCESS,
  GET_COMPANY_LOCATIONS_FAILURE,
  GET_COMPANY_LOCATIONS_REQUEST,
  GET_COMPANY_LOCATIONS_SUCCESS
} from 'ducks/company/companyActionTypes';

export default createRequestsReducer([
  [
    GET_COMPANY_LOCATIONS_REQUEST,
    GET_COMPANY_LOCATIONS_SUCCESS,
    GET_COMPANY_LOCATIONS_FAILURE
  ],
  [
    GET_COMPANY_MEMBERS_REQUEST,
    GET_COMPANY_MEMBERS_SUCCESS,
    GET_COMPANY_MEMBERS_FAILURE
  ]
]);
