import React, { Component } from "react";
import { connect } from "react-redux";
import users from "ducks/users";
import _ from "lodash";
import posthog from "posthog-js";

const token = "phc_7gWWvvHrygzsBFV0s7CNUswRHoswE4mHZEBcecXsTnA";

posthog.init(token, {api_host: "https://stats.milecatcher.com"});

const setUser = user => {
  posthog.identify(user.id, {email: user.email, name: user.name});
};

const clearUser = () => {
  posthog.reset();
};

export const trackUser = WrappedComponent => {
  const hoc = class extends Component {
    componentDidMount() {
      if (this.props.trackingUser) {
        setUser(this.props.trackingUser);
      }
    }

    componentWillReceiveProps(nextProps) {
      if (
        token &&
        _.get(this.props, "trackingUser.id") !==
          _.get(nextProps, "trackingUser.id")
      ) {
        if (nextProps.trackingUser) {
          setUser(nextProps.trackingUser);
        } else {
          clearUser();
        }
      }
    }

    render() {
      const { trackingUser: _, ...props } = this.props;
      return <WrappedComponent {...props} />;
    }
  };

  return connect(state => ({
    trackingUser: users.selectors.getCurrentUser(state)
  }))(hoc);
};

export const trackPage = _page => WrappedComponent =>
  class extends Component {
    componentDidMount() {
      posthog.capture('$pageview');
  }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
