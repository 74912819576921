import React from 'react';
import { Field } from 'redux-form';
import NumberField from 'components/NumberField';
import Button from 'components/Button';
import VehicleSelector from 'components/VehicleSelector';

import './AddTripDetails.css';
import NotesIcon from './icon-edit.gif';
import ParkingIcon from './icon-p.gif';
import TollsIcon from './icon-s.gif';
import VehicleIcon from './icon-car.gif';

const renderVehicleField = ({ input: { value, onChange } }) =>
  <VehicleSelector selectedId={value} onSelect={onChange} />;

const AddTripDetails = ({ valid, submitting }) =>
  <div className="add-trip-details">
    <div className="add-trip-details__control">
      <div className="add-trip-details__control__icon">
        <img src={NotesIcon} alt="notes" />
      </div>

      <div className="add-trip-details__control__input">
        <Field
          component="textarea"
          className="textbox"
          name="notes"
          placeholder="Notes"
        />
      </div>
    </div>

    <div className="add-trip-details__control">
      <div className="add-trip-details__control__icon">
        <img src={TollsIcon} alt="tolls" />
      </div>

      <div className="add-trip-details__control__input">
        <NumberField placeholder="Tolls" name="tolls" />
      </div>
    </div>

    <div className="add-trip-details__control">
      <div className="add-trip-details__control__icon">
        <img src={ParkingIcon} alt="parking" />
      </div>

      <div className="add-trip-details__control__input">
        <NumberField placeholder="Parking" name="parking" />
      </div>
    </div>

    <div className="add-trip-details__control">
      <div className="add-trip-details__control__icon">
        <img src={VehicleIcon} alt="vehicle" />
      </div>

      <div className="add-trip-details__control__input">
        <Field name="vehicleId" component={renderVehicleField} />
      </div>
    </div>

    <div className="add-trip-details__buttons">
      <Button color="save" disabled={!valid || submitting} fullWidth>
        {submitting ? 'Saving...' : 'Save'}
      </Button>

      <Button color="cancel" to="/trips" link fullWidth>Cancel</Button>
    </div>
  </div>;

export default AddTripDetails;
