import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import Modal from 'components/Modal';
import AddLocationMap from './AddLocationMap';
import renderCompanyTextField from 'components/CompanyPages/lib/CompanyFields/renderCompanyField';
import { reverseGeocode } from 'lib/services/opencage';
import getUserLocation from 'lib/getUserLocation';
import { locationSearch } from 'lib/services/googlePlaces';
import AddressInput from 'components/MapConnectedAddressInput';
import FontAwesome from 'react-fontawesome';
import AddButton from 'components/CompanyPages/lib/AddButton';
import { required } from 'lib/validators';
import 'components/TextBoxField/TextboxField.css';
import './AddLocationModal.css';

const INITIAL_MAP_FOCUS = [47.6062, -122.3321];

const renderAddressField = ({
  input: { value, onChange, onBlur, ...input },
  meta: { touched, error, warning },
  onCoords,
  onBlur: blur,
  ...props
}) => {
  return [
    <AddressInput
      value={value}
      onChange={onChange}
      onSelect={input => {
        locationSearch(input, props.location).then(coordinates =>
          onCoords(coordinates)
        );
        onChange(input);
      }}
      onBlur={data => {
        blur && blur(data);
        onBlur(data);
      }}
      {...props}
      {...input}
    />,
    ...(touched && (error || warning)
      ? [
          (error && (
            <div className="textbox-field__error">
              <FontAwesome name="exclamation-circle" /> {error}
            </div>
          )) ||
            (warning && (
              <div className="textbox-field__warning">
                <FontAwesome name="exclamation-circle" /> {warning}
              </div>
            ))
        ]
      : [])
  ];
};

export default reduxForm({ form: 'add-location-modal' })(
  class AddLocationModal extends Component {
    constructor(props) {
      super(props);

      this.state = {
        mapFocus: INITIAL_MAP_FOCUS,
        coordinates: null,
        name: null
      };
      this.setPoint = this.setPoint.bind(this);
      this.submit = this.submit.bind(this);
      this.onBlur = this.onBlur.bind(this);
    }

    componentDidMount() {
      getUserLocation().then(location => this.setState({ mapFocus: location }));
    }

    onBlur() {
      const { address, mapFocus } = this.state;

      locationSearch(address, mapFocus).then(coordinates =>
        this.setPoint(coordinates)
      );
    }

    setPoint(coordinates, shouldGeocode) {
      if (shouldGeocode) {
        const { change } = this.props;

        reverseGeocode(coordinates).then(address => change('address', address));
      }

      this.setState({ coordinates });
    }

    submit(data) {
      const { addLocation, destroy, onClose } = this.props;
      const { coordinates } = this.state;

      this.setState({
        address: null,
        coordinates: null,
        name: null,
        mapFocus: INITIAL_MAP_FOCUS
      });

      destroy && destroy();
      onClose && onClose();

      return addLocation({
        ...data,
        coordinates
      });
    }

    render() {
      const { mapFocus, coordinates } = this.state;
      const { handleSubmit, isOpen, onClose, valid, submitting } = this.props;

      return (
        <Modal
          className="add-location-modal"
          key={0}
          isOpen={isOpen}
          title={'Add Location'}
          onClose={onClose}
        >
          <form
            onSubmit={handleSubmit(this.submit)}
            className={'add-location-form'}
          >
            <label>
              <p className="add-location-field-title">Address</p>
              <Field
                name="address"
                id="add-location-address"
                className="add-location-field"
                component={renderAddressField}
                onCoords={this.setPoint}
                validate={required}
                location={mapFocus}
              />
            </label>
            <label>
              <p className="add-location-field-title">Custom Location Name</p>
              <Field
                name="name"
                id="add-location-name"
                placeholder="e.g. Home"
                className="add-location-field"
                validate={required}
                component={renderCompanyTextField}
              />
            </label>
            <Field
              name="coordinates"
              component={AddLocationMap}
              focus={mapFocus}
              coordinates={coordinates}
              setPoint={this.setPoint}
            />
            <div className="submit-location-section">
              <AddButton
                disabled={!valid || submitting}
                className="my-locations-button"
                type="submit"
              >
                {submitting ? 'Adding...' : 'Add'}
              </AddButton>
            </div>
          </form>
        </Modal>
      );
    }
  }
);
