import { connect } from 'react-redux';
import EditedUserElement from './EditedUserElement';
import {
  mapStateToEditedUserElement,
  mapDispatchToEditUserElement
} from './editedUserElementMappings';

export default connect(
  mapStateToEditedUserElement,
  mapDispatchToEditUserElement
)(EditedUserElement);
