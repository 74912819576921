import React, { Component } from 'react';
import AddButton from 'components/CompanyPages/lib/AddButton';
import confirm from 'components/Confirmation';
import Money from 'components/Money';
import CompanySignUpTerms from 'components/CompanySignUpTerms';
import CompanyDropDown from 'components/CompanyPages/lib/CompanyDropDown';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from 'react-stripe-elements';

const ConfirmSave = confirm(AddButton);

export default class CompanyAccountsRenewal extends Component {
  constructor(props) {
    const { company, firstName, lastName, customBusinessRate } = props;

    super(props);

    this.state = {
      companyName: company ? company.name : '',
      agreedToTerms: false,
      logo: null,
      cardNumber: false,
      city: null,
      address: null,
      country: null,
      state: null,
      expiration: false,
      costOfAdditionalSeats: 0,
      customBusinessRate: customBusinessRate || 0,
      cvv: false,
      lastName,
      firstName
    };

    this.setFirstName = this.setFirstName.bind(this);
    this.setLastName = this.setLastName.bind(this);
    this.cardNumberFilledOut = this.cardNumberFilledOut.bind(this);
    this.expirationFilledOut = this.expirationFilledOut.bind(this);
    this.toggleTermsAgreement = this.toggleTermsAgreement.bind(this);
    this.cvvFilledOut = this.cvvFilledOut.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.setCity = this.setCity.bind(this);
    this.setRegion = this.setRegion.bind(this);
    this.setCountry = this.setCountry.bind(this);
    this.saveCardData = this.saveCardData.bind(this);
  }

  toggleTermsAgreement() {
    const { agreedToTerms } = this.state;

    this.setState({ agreedToTerms: !agreedToTerms });
  }

  setFirstName({ target }) {
    this.setState({ firstName: target.value });
  }

  setLastName({ target }) {
    this.setState({ lastName: target.value });
  }

  cardNumberFilledOut({ complete }) {
    this.setState({ cardNumber: complete });
  }

  expirationFilledOut({ complete }) {
    this.setState({ expiration: complete });
  }

  cvvFilledOut({ complete }) {
    this.setState({ cvv: complete });
  }

  setCity({ target }) {
    this.setState({ city: target.value });
  }

  setRegion({ target }) {
    this.setState({ state: target.value });
  }

  setCountry({ target }) {
    this.setState({ country: target.value });
  }

  setAddress({ target }) {
    this.setState({ address: target.value });
  }

  canSaveCardData() {
    const {
      agreedToTerms,
      expiration,
      cardNumber,
      firstName,
      lastName,
      country,
      address,
      state,
      city,
      cvv
    } = this.state;

    return (
      expiration &&
      agreedToTerms &&
      cardNumber &&
      firstName &&
      lastName &&
      country &&
      address &&
      state &&
      city &&
      cvv
    );
  }

  saveCardData() {
    const {
      stripe: { createSource },
      saveCardDetails,
      currencyCode
    } = this.props;
    const { firstName, lastName, country, address, state, city } = this.state;

    createSource({
      //type: "company_payment_source", // need to figure out actual type
      currency: currencyCode,
      owner: {
        name: `${firstName} ${lastName}`
      }
    }).then(token => {
      console.log('token', token);

      token &&
        saveCardDetails &&
        saveCardDetails({
          token,
          firstName,
          lastName,
          address,
          country,
          state,
          city
        });
    });
  }

  render() {
    const { expirationDate, costPerSeat, inTrialPeriod } = this.props;
    const {
      cardNumber,
      firstName,
      lastName,
      country,
      address,
      state,
      city,
      cvv
    } = this.state;

    return (
      <div className="company-account-billing-section">
        <div className="company-account-billing-description">
          <h1 className="company-account-billing-description-text">
            {inTrialPeriod &&
              `You're currently using a one month free trial that will end ${expirationDate},
                  if you'd like to keep using Milecatcher after that, please fill in your billing information.`}
            Your account will be charged <Money>{costPerSeat}</Money> per seat
            for your subscription. Subscriptions renew automatically each year.
          </h1>
        </div>
        <div className="company-account-billing-info">
          <div className="company-account-billing-info-row">
            {[
              ['First name*', this.setFirstName, firstName],
              ['Last name*', this.setLastName, lastName],
              [
                'Card Number*',
                this.cardNumberFilledOut,
                cardNumber,
                CardNumberElement
              ]
            ].map(([title, onChange, value, Element]) => {
              const className = 'company-account-billing-info-input';
              return (
                <div className="company-account-billing-info-element">
                  <h1 className="company-account-billing-info-title">
                    {title}
                  </h1>
                  {Element ? (
                    <div className={`${className} stripe-container-div`}>
                      <Element onChange={onChange} />
                    </div>
                  ) : (
                    <input
                      type="text"
                      value={value}
                      onChange={onChange}
                      className={className}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="company-account-billing-info-row">
            {[
              [
                'Expiration Date*',
                this.expirationFilledOut,
                undefined,
                false,
                CardExpiryElement
              ],
              [
                'CVV*',
                this.cvvFilledOut,
                cvv,
                'company-account-cvv',
                CardCVCElement
              ],
              ['Country*', this.setCountry, country]
            ].map(([title, onChange, value, customClass, Element]) => {
              const className =
                customClass || 'company-account-billing-info-input';
              return (
                <div className={`company-account-billing-info-element`}>
                  {title && (
                    <h1 className="company-account-billing-info-title">
                      {title}
                    </h1>
                  )}
                  {Element ? (
                    <div className={`${className} stripe-container-div`}>
                      <Element onChange={onChange} />
                    </div>
                  ) : (
                    <input
                      type="text"
                      value={value}
                      onChange={onChange}
                      className={className}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="company-account-billing-info-row">
            {[
              ['Street Address*', this.setAddress, address],
              ['City*', this.setCity, city],
              ['State*', this.setRegion, state]
            ].map(([title, setter, value]) => (
              <div className="company-account-billing-info-element">
                <h1 className="company-account-billing-info-title">{title}</h1>
                <input
                  type="text"
                  value={value}
                  onChange={setter}
                  className="company-account-billing-info-input"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="company-account-billing-terms">
          <CompanySignUpTerms onChange={this.toggleTermsAgreement} />
        </div>
        <ConfirmSave
          className="company-account-info-save-button"
          disabled={!this.canSaveCardData()}
          onClick={this.saveCardData}
          confirm={false}
        >
          Save
        </ConfirmSave>
      </div>
    );
  }
}
