export const GET_REQUEST = 'reports/GET_REQUEST';
export const GET_SUCCESS = 'reports/GET_SUCCESS';
export const GET_FAILURE = 'reports/GET_FAILURE';

export const GET_PAGE_REQUEST = 'reports/GET_PAGE_REQUEST';
export const GET_PAGE_SUCCESS = 'reports/GET_PAGE_SUCCESS';
export const GET_PAGE_FAILURE = 'reports/GET_PAGE_FAILURE';

export const INVALIDATE = 'reports/INVALIDATE';

export const GET_SINGLE_REQUEST = 'reports/GET_SINGLE_REQUEST';
export const GET_SINGLE_SUCCESS = 'reports/GET_SINGLE_SUCCESS';
export const GET_SINGLE_FAILURE = 'reports/GET_SINGLE_FAILURE';

export const CREATE_REQUEST = 'reports/CREATE_REQUEST';
export const CREATE_SUCCESS = 'reports/CREATE_SUCCESS';
export const CREATE_FAILURE = 'reports/CREATE_FAILURE';

export const DELETE_REQUEST = 'reports/DELETE_REQUEST';
export const DELETE_SUCCESS = 'reports/DELETE_SUCCESS';
export const DELETE_FAILURE = 'reports/DELETE_FAILURE';

export const STAR_FAILURE = 'reports/STAR_FAILURE';
export const TOGGLE_STARRED = 'reports/TOGGLE_STARRED';

export const UPDATE_FILTERS = 'reports/ADD_FILTERS';
export const REMOVE_FILTERS = 'reports/REMOVE_FILTERS';
