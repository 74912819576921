import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import Modal from 'components/Modal';
import Button from 'components/Button';
import './Confirmation.css';

export default WrappedComponent =>
  class extends Component {
    static propTypes = {
      onClick: PropTypes.func.isRequired,
      msg: PropTypes.node
    };

    constructor(props) {
      super(props);

      this.state = { isOpen: false };
      this.onClick = this.onClick.bind(this);
      this.onConfirm = this.onConfirm.bind(this);
      this.onCancel = this.onCancel.bind(this);
    }

    onClick(target) {
      const { confirm, onClick } = this.props;

      return confirm !== false
        ? this.setState({ isOpen: true })
        : onClick(target);
    }

    onConfirm() {
      this.props.onClick();

      this.setState({ isOpen: false });
    }

    onCancel() {
      this.setState({ isOpen: false });
    }

    render() {
      const {
        onClick: _,
        message,
        title,
        confirmButtonColor,
        ...props
      } = this.props;

      const { isOpen } = this.state;

      return [
        <Modal key={0} isOpen={isOpen} title={title} onClose={this.onCancel}>
          <div className="confirm">
            {message && <div className="confirmation-message">{message}</div>}
            <div className="confirmation-buttons">
              <Button
                color={confirmButtonColor}
                className="confirmation-ok-button"
                onClick={this.onConfirm}
              >
                <FontAwesome name="check" /> Confirm
              </Button>
              <Button
                className="confirmation-cancel-buttion"
                onClick={this.onCancel}
                color="cancel"
              >
                <FontAwesome name="times" /> Cancel
              </Button>
            </div>
          </div>
        </Modal>,
        <WrappedComponent key={1} onClick={this.onClick} {...props} />
      ];
    }
  };
