export const mapOverObject = (object, modifier) =>
  Object.keys(object).reduce(
    (modifiedObject, key) => ({
      ...modifiedObject,
      [key]: modifier(object[key])
    }),
    {}
  );

export default mapOverObject;
