import { connect } from 'react-redux';
import ExpensedTrip from './ExpensedTrip';
import {
  mapStateToExpensedTrip,
  mapDispatchToExpensedTrip
} from './expensedTripMappings';

export default connect(
  mapStateToExpensedTrip,
  mapDispatchToExpensedTrip
)(ExpensedTrip);
