import { addKpiReport } from 'ducks/kpiReports/kpiReportActions/addKpiReport';
import getUsers from 'ducks/users/usersSelectors/getUsers';
import { timeSpans } from 'lib/timeSpans';
import { getUsersCountry } from 'ducks/countries/selectors';
import { numeric } from 'lib/normalizers';

export const mapStateToAddReportPage = state => ({
  users: getUsers(state),
  periods: timeSpans,
  normalize: value => {
    // TODO fix this when there is api support for get company
    const country = getUsersCountry(state);
    return numeric('US', value);
  }
});

export const mapDispatchAddToReportPage = {
  addKpiReport
};
