export const stringDifference = (stringOne, stringTwo) => {
  return (stringOne &&
    stringTwo &&
    stringOne.toLowerCase() < stringTwo.toLowerCase()) ||
    (stringOne && !stringTwo)
    ? 1
    : -1;
};

export default stringDifference;
