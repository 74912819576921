import React, { Component } from 'react';
import Scrollbars from 'components/Scrollbars';
import AddButton from 'components/CompanyPages/lib/AddButton';
import './CSVUserInvitationsDisplay.css';

const headers = ['Name', 'Email', 'Role', 'Expense rate'];

export default class CSVUserInvitationsDisplay extends Component {
  constructor(props) {
    super(props);

    this.sendInvitations = this.sendInvitations.bind(this);
  }

  sendInvitations() {
    const { onSubmit, inviteUsersInBulk, invitations } = this.props;

    inviteUsersInBulk &&
      inviteUsersInBulk({
        invitation_tokens:
          invitations &&
          invitations.map(({ rate: customBusinessRate, ...invitation }) => ({
            customBusinessRate,
            ...invitation
          }))
      });

    onSubmit && onSubmit();
  }

  render() {
    const { invitations } = this.props;

    return invitations && invitations.length ? (
      <div className="csv-invitations-display">
        <div className="csv-invitations-table-container">
          <Scrollbars>
            <table className="csv-invitations-table">
              <thead className="csv-invitations-table-header">
                <tr>
                  {headers.map(title => (
                    <th className="csv-invitations-column-title">{title}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="csv-invitations-table-body">
                {invitations &&
                  invitations.map &&
                  invitations.map(({ name, email, role, rate }) => (
                    <tr className="csv-invitation-details">
                      <td className="name">{name}</td>
                      <td className="email">{email}</td>
                      <td className="role">{role}</td>
                      <td className="cbr">{rate}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Scrollbars>
        </div>
        <div className="csv-confirmation-buttons">
          <AddButton
            onClick={this.sendInvitations}
            className="csv-invites-button"
          >
            Send invitations
          </AddButton>
        </div>
      </div>
    ) : (
      <h1>No invitations could be detected in the selected file.</h1>
    );
  }
}
