import { connect } from 'react-redux';
import ViewNavigator from './ViewNavigator';
import {
  mapDispatchToViewNavigator,
  mapStateToViewNavigator
} from './viewNavigatorMappings';
export default connect(
  mapStateToViewNavigator,
  mapDispatchToViewNavigator
)(ViewNavigator);
