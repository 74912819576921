import addSuccessAndFailureMessages from 'ducks/lib/addSuccessAndFailureMessages';
import createSimpleAction from 'ducks/lib/simpleActionCreator';
import { putWorkHours } from 'api/endpoints/user/workHours';
import {
  UPDATE_WORK_HOURS_FAILURE,
  UPDATE_WORK_HOURS_REQUEST,
  GET_WORK_HOURS_SUCCESS
} from 'ducks/workHours/actionTypes';

export const updateGlobalWorkHours = addSuccessAndFailureMessages(
  createSimpleAction(
    putWorkHours,
    [
      UPDATE_WORK_HOURS_REQUEST,
      GET_WORK_HOURS_SUCCESS,
      UPDATE_WORK_HOURS_FAILURE
    ],
    'workHours'
  ),
  'Updated global work hours',
  'Unable to update global work hours'
);

export default updateGlobalWorkHours;
