import { connect } from 'react-redux';
import ConfirmOrConfirmed from './ConfirmOrConfirmed';
import {
  mapStateToConfirmOrConfirmed,
  mapDispatchToConfirmOrConfirmed
} from './confirmOrConfirmedMappings';

export default connect(
  mapStateToConfirmOrConfirmed,
  mapDispatchToConfirmOrConfirmed
)(ConfirmOrConfirmed);
