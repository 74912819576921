import { Scrollbars as ScrollBars } from 'react-custom-scrollbars';
import React, { Component } from 'react';

export default class Scrollbars extends Component {
  constructor(props) {
    super(props);

    this.scrollbars = null;
    this.setRef = this.setRef.bind(this);
  }

  setRef(scrollbars) {
    const { setreference } = this.props;

    this.scrollbars = scrollbars;

    setreference && setreference(scrollbars);
  }

  componentDidUpdate({ indexOfCurrentElement: indexOfPreviousElement }) {
    const indexOfFirstElement = 0;
    const { indexOfCurrentElement, elementHeight } = this.props;

    if (
      !isNaN(indexOfCurrentElement) &&
      !isNaN(elementHeight) &&
      indexOfPreviousElement !== indexOfCurrentElement &&
      indexOfCurrentElement >= indexOfFirstElement
    ) {
      const elementTop = elementHeight * indexOfCurrentElement;
      const elementBottom = elementHeight * (indexOfCurrentElement + 1);
      const scrollTop = this.scrollbars.getScrollTop();
      const clientHeight = this.scrollbars.getClientHeight();

      if (elementTop < scrollTop) {
        this.scrollbars.scrollTop(elementTop);
      }

      if (elementBottom > scrollTop + clientHeight) {
        this.scrollbars.scrollTop(elementBottom - clientHeight);
      }
    }
  }

  render() {
    const { ...props } = this.props;

    return <ScrollBars ref={this.setRef} {...props} />;
  }
}
