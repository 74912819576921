import React, { Component } from 'react';
import { renderCompanyTextField } from 'components/CompanyPages/lib/CompanyFields/renderCompanyField';
import CompanyFormError from 'components/CompanyPages/lib/CompanyFormError';
import CompanyDropDown from 'components/CompanyPages/lib/CompanyDropDown';
import AddButton from 'components/CompanyPages/lib/AddButton';
import { toNumber } from 'lib/localization/numericLocalization';
import { reduxForm, Field } from 'redux-form';
import { required } from 'lib/validators';
import Money from 'components/Money';
import RateField from 'components/RateField';
import './AddPurposePage.css';

const form = 'add-purpose-page';

export default reduxForm({ form })(
  class AddPurposePage extends Component {
    constructor(props) {
      super(props);

      this.state = {
        team: 'All teams',
        costCenter: 'select',
        teamIndex: null,
        costCenterIndex: null
      };

      this.setCostCenter = this.setCostCenter.bind(this);
      this.setTeam = this.setTeam.bind(this);
      this.addPurpose = this.addPurpose.bind(this);
      this.normalizeRateField = this.normalizeRateField.bind(this);
    }

    componentWillMount() {
      const { teams, costCenters, purpose } = this.props;
      const { teamId, costCenterId } = purpose || {};
      const teamIndex =
        !isNaN(teamId) && teams.findIndex(({ id }) => id === teamId);
      const costCenterIndex =
        !isNaN(costCenterId) &&
        costCenters.findIndex(({ id }) => id === costCenterId);

      const team = teams[teamIndex];
      const costCenter = costCenters[costCenterIndex];

      team && this.setState({ team: team.name, teamIndex });

      costCenter &&
        this.setState({
          costCenter: costCenter.name,
          costCenterIndex
        });
    }

    addPurpose({ rate, purposeName }) {
      const { team, costCenter } = this.state;
      const { addPurpose, onSubmit, purpose } = this.props;
      const id = purpose && purpose.id;

      addPurpose({
        name: purposeName,
        rate: toNumber(rate),
        id
      });

      onSubmit && onSubmit();
    }

    setTeam({ target }) {
      const { teams } = this.props;
      const teamIndex = target.id;
      const team = teams && teams[teamIndex];

      this.setState({ team: team && team.name, teamIndex });
    }

    setCostCenter({ target }) {
      const { costCenters } = this.props;
      const costCenterIndex = target.id;
      const costCenter = costCenters && costCenters[costCenterIndex];

      this.setState({
        costCenter: costCenter && costCenter.name,
        costCenterIndex
      });
    }

    normalizeRateField(value) {
      const { normalize } = this.props;

      return value ? normalize(value) : '';
    }

    render() {
      const {
        teams,
        costCenters,
        valid,
        error,
        handleSubmit,
        submitting
      } = this.props;
      const { team, costCenter } = this.state;

      return (
        <form
          onSubmit={handleSubmit(this.addPurpose)}
          className="add-purpose-page"
        >
          <CompanyFormError>{error}</CompanyFormError>
          <h1 className="add-purpose-label">Applicable For</h1>
          <CompanyDropDown
            value={team}
            className="add-purpose-teams-drop-down"
            onChange={this.setTeam}
          >
            {teams}
          </CompanyDropDown>
          <h1 className="add-purpose-label">Purpose name</h1>
          <Field
            name="purposeName"
            className="add-purpose-name-input add-purpose-text-input"
            component={renderCompanyTextField}
            validate={required}
          />
          {/*<h1 className="add-purpose-label">Cost center (optional)</h1>*/}
          {/*<CompanyDropDown*/}
          {/*value={costCenter}*/}
          {/*className="add-purpose-teams-drop-down"*/}
          {/*onChange={this.setCostCenter}*/}
          {/*>*/}
          {/*{costCenters}*/}
          {/*</CompanyDropDown>*/}
          <h1 className="add-purpose-label">Rate</h1>
          <RateField
            name="rate"
            className="add-purpose-rate-input"
            placeHolder="0.00"
            normalizer={this.normalizeRateField}
            normalizeinput={true}
            component={renderCompanyTextField}
            validate={required}
          />
          <AddButton
            disabled={!valid || submitting}
            className="add-purpose-button"
          >
            Submit
          </AddButton>
        </form>
      );
    }
  }
);
