import React, { Component } from 'react';
import ImageContainer from 'components/CompanyPages/lib/ImageContainer';
import capitalizeFirstLetter from 'lib/capitalizeFirstLetter';
import DropDown from 'components/CompanyPages/lib/CompanyDropDown';
import DeleteButton from 'components/CompanyPages/lib/DeleteButton';
import './TeamMember.css';

export default class TeamMember extends Component {
  constructor(props) {
    const { user } = props;
    super(props);

    this.state = { role: user.role, deleted: false };
    this.selectRole = this.selectRole.bind(this);
    this.removeUserFromTeam = this.removeUserFromTeam.bind(this);
  }

  selectRole({ target }) {
    const { deleted } = this.props;
    const { roles } = this.props;
    const roleIndex = target && target.id;
    const selectedRole = roles && roles[roleIndex];
    const role = selectedRole && selectedRole.name;

    !deleted && this.makeChange({ role });
  }

  removeUserFromTeam() {
    const { deleted } = this.state;

    this.makeChange({ deleted: !deleted });
  }

  makeChange(changes) {
    const { user, onChange, isAdmin } = this.props;

    if (isAdmin) {
      this.setState(changes);

      onChange &&
        onChange({
          id: user.id,
          ...changes
        });
    }
  }

  render() {
    const { role, deleted } = this.state;
    const { roles, user, image, isAdmin } = this.props;
    const email = user && user.email;

    return (
      <div className={`edit-team-member-element${deleted ? ' deleted' : ''}`}>
        <ImageContainer
          className="team-member-image-container"
          image={image}
          email={email}
        />
        <h1 className="team-member-name">{email}</h1>
        {isAdmin && [
          <DeleteButton
            className="team-member-remove-button"
            onClick={this.removeUserFromTeam}
          />,
          <DropDown
            disabled={!isAdmin || deleted}
            className="edit-team-roles-drop-down"
            value={capitalizeFirstLetter(role)}
            onChange={this.selectRole}
          >
            {roles}
          </DropDown>
        ]}
      </div>
    );
  }
}
