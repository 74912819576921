import React, { Component } from 'react';
import './RoleSelector.css';

export default class InviteUsersPage extends Component {
  constructor(props) {
    const { roles, role } = props;
    const firstRole = roles && roles[0];

    super(props);

    this.state = { role: role || (firstRole && firstRole.name) };
    this.onBlur = this.onBlur.bind(this);
    this.setRole = this.setRole.bind(this);
  }

  setRole({ target }) {
    const { onChange } = this.props;
    const role = target && target.value;

    this.setState({ role });
    onChange && onChange(role);
  }

  onBlur() {}

  render() {
    const { roles, className, onBlur } = this.props;
    const { role } = this.state;

    return (
      <div className={className}>
        <h1 className="role-selector-title">
          Chose a role for the users you invite
        </h1>
        <div className="role-selections" onChange={this.setRole}>
          {roles.map(({ name, description }) => {
            const value = name && name.toLowerCase();
            const checked =
              role && name && role.toLowerCase() === name.toLowerCase();

            return (
              <div className="role-selection-element" key={`${value}-selector`}>
                <label className="role-label" htmlFor={value}>
                  <input
                    className="role-selector"
                    name="role-selector"
                    type="radio"
                    checked={checked}
                    onBlur={() => onBlur && onBlur(value)}
                    value={value || ''}
                  />
                  {name}
                </label>
                <h1 className="role-description">{description}</h1>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
