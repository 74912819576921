import React, { Component } from 'react';
import Filters from 'components/CompanyPages/lib/Filters';
import PieChart from 'components/PieChart';
import Money from 'components/Money';
import ScrollBars from 'components/Scrollbars';
import StatisticsUserDisplay from './StatisticsUserDisplay';
import StatisticsUsersDisplay from './StatisticsUsersDisplay';
import ExpenseComparisonBarChart from 'components/CompanyPages/Admin/StatisticsPage/ExpenseComparisonBarChart';
import TopFiveExpensesBarChart from 'components/CompanyPages/Admin/StatisticsPage/TopFiveExpenseBarChart';
import RenderPage from 'lib/RenderPage';
import './StatisticsPage.css';

export default class StatisticsPage extends Component {
  constructor(props) {
    super(props);

    this.getPercentage = this.getPercentage.bind(this);
  }

  componentWillMount() {
    const { loadTeams, loadMembers, loadStatistics, loadPurposes } = this.props;

    [loadTeams, loadMembers, loadStatistics, loadPurposes].forEach(
      loadResource => loadResource && loadResource()
    );
  }

  getPercentage(approved, rejected) {
    const total = approved + rejected;

    return approved === 0 ? 0 : Math.round((approved / total) * 100);
  }

  render() {
    const {
      filters,
      requestsState,
      loadStatistics,
      approvedExpenses,
      returnedExpenses,
      approvedDistance,
      returnedDistance,
      totalWorkHourMiles,
      users,
      statistics
    } = this.props;

    const singleUser = users && users.length === 1;
    const user = users && users[0];
    const userName = user && user.name;

    return (
      <div className="admin-statistics-page">
        <Filters {...filters} onChange={loadStatistics} />
        {/*<div className="admin-statistics-header">*/}
        {/*<button className="admin-statistics-export-button">*/}
        {/*Export statistics*/}
        {/*</button>*/}
        {/*</div>*/}
        <div className="admin-statistics-body">
          <RenderPage requestsState={requestsState}>
            <ScrollBars>
              <div className="admin-statistics-top">
                <div className="admin-statistics-expenses">
                  <h1 className="admin-statistics-title">
                    {userName}
                    {singleUser ? "'s, " : ''}
                    Expenses
                  </h1>
                  <div className="admin-statistics-row-approved">
                    <h1>Approved</h1>
                    <h1>
                      <Money value={approvedExpenses} />
                    </h1>
                  </div>
                  <div className="admin-statistics-row-returned">
                    <h1>Rejected</h1>
                    <h1>
                      <Money value={returnedExpenses} />
                    </h1>
                  </div>
                  <PieChart
                    className="admin-statistics-pie-chart"
                    percent={this.getPercentage(
                      approvedExpenses,
                      returnedExpenses
                    )}
                  />
                </div>
                <div className="admin-statistics-distance">
                  <h1 className="admin-statistics-title">
                    {userName}
                    {singleUser ? "'s, " : ''}
                    Distance
                  </h1>
                  <div className="admin-statistics-row-approved">
                    <h1>Approved Miles</h1>
                    <h1>{approvedDistance}</h1>
                  </div>
                  <div className="admin-statistics-row-returned">
                    <h1>Rejected Miles</h1>
                    <h1>{returnedDistance}</h1>
                  </div>
                  {/*<div className="admin-statistics-row-returned">*/}
                  {/*<h1>Total work hour miles</h1>*/}
                  {/*<h1>{totalWorkHourMiles}</h1>*/}
                  {/*</div>*/}
                </div>
                <div className="admin-statistics-expense-comparison">
                  <h1 className="admin-statistics-title">
                    {userName}
                    {singleUser ? "'s, " : ''}
                    expenses comparison
                  </h1>
                  <div className="admin-statistics-expense-comparison-approved">
                    <span className="admin-statistics-approved-square" />
                    <h1>Approved Expenses</h1>
                  </div>
                  <div className="admin-statistics-expense-comparison-returned">
                    <span className="admin-statistics-returned-square" />
                    <h1>Rejected Expenses</h1>
                  </div>
                  <ExpenseComparisonBarChart width={540} height={160} />
                </div>
              </div>
              <div className="admin-statistics-bottom">
                <div className="admin-statistics-user">
                  {singleUser ? (
                    <StatisticsUserDisplay {...user} />
                  ) : (
                    <StatisticsUsersDisplay />
                  )}
                </div>
                <div className="admin-statistics-categories">
                  <h1 className="admin-statistics-title">
                    {userName}
                    {singleUser ? "'s, " : ''}
                    Top Expense Categories
                  </h1>
                  <TopFiveExpensesBarChart
                    spacing={1.4}
                    width={340}
                    height={230}
                  />
                </div>
              </div>
            </ScrollBars>
          </RenderPage>
        </div>
      </div>
    );
  }
}
