import React from 'react';
import './CancelButton.css';
export default ({ children, className, onClick, disabled, ...props }) => (
  <button
    disabled={disabled}
    onClick={onClick}
    {...props}
    className={`${disabled ? 'disabled-cancel-button' : 'cancel-button'}${
      className ? ` ${className}` : ''
    }`}
  >
    {children}
  </button>
);
