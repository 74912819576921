import { NAME } from 'ducks/workHours/constants';

export const SET_USER_WORK_HOURS = `${NAME}/SET_USER_WORK_HOURS`;
export const SET_USER_WORK_HOURS_FOR_DAY = `${NAME}/SET_USER_WORK_HOURS_FOR_DAY`;
export const SET_USER_WORK_HOURS_STATUS = `${NAME}/SET_USER_WORK_HOURS_STATUS`;

export const UPDATE_TEAM_WORK_HOURS_REQUEST = `${NAME}/UPDATE_TEAMS_WORK_HOURS_REQUEST`;
export const UPDATE_TEAM_WORK_HOURS_SUCCESS = `${NAME}/UPDATE_TEAMS_WORK_HOURS_SUCCESS`;
export const UPDATE_TEAM_WORK_HOURS_FAILURE = `${NAME}/UPDATE_TEAMS_WORK_HOURS_FAILURE`;

export const GET_TEAM_WORK_HOURS_REQUEST = `${NAME}/GET_TEAM_WORK_HOURS_REQUEST`;
export const GET_TEAM_WORK_HOURS_SUCCESS = `${NAME}/GET_TEAM_WORK_HOURS_SUCCESS`;
export const GET_TEAM_WORK_HOURS_FAILURE = `${NAME}/GET_TEAM_WORK_HOURS_FAILURE`;

export const SET_TEAM_WORK_HOURS_STATUS = `${NAME}/SET_TEAM_WORK_HOURS_STATUS`;
export const SET_TEAM_WORK_HOURS_FOR_DAY = `${NAME}/SET_TEAM_WORK_HOURS_FOR_DAY`;

export const GET_WORK_HOURS_REQUEST = `${NAME}/GET_WORK_HOURS_REQUEST`;
export const GET_WORK_HOURS_SUCCESS = `${NAME}/GET_WORK_HOURS_SUCCESS`;
export const GET_WORK_HOURS_FAILURE = `${NAME}/GET_WORK_HOURS_FAILURE`;

export const SET_WORK_HOURS_FOR_DAY = `${NAME}/SET_WORK_HOURS_FOR_DAY`;
export const SET_WORK_HOURS_STATUS = `${NAME}/SET_WORK_HOURS_STATUS`;

export const UPDATE_WORK_HOURS_REQUEST = `${NAME}/UPDATE_WORK_HOURS_REQUEST`;
export const UPDATE_WORK_HOURS_SUCCESS = `${NAME}/UPDATE_WORK_HOURS_SUCCESS`;
export const UPDATE_WORK_HOURS_FAILURE = `${NAME}/UPDATE_WORK_HOURS_FAILURE`;
