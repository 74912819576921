import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PreLoginForm from 'components/PreLogin/PreLoginForm';
import PreLoginButton from 'components/PreLogin/PreLoginButton';
import PreLoginBox from 'components/PreLogin/PreLoginBox';
import CountryDropDown from 'components/CountryDropdown';
import LoadingPage from 'components/LoadingPage';
import { renderCompanyTextField } from 'components/CompanyPages/lib/CompanyFields/renderCompanyField';
import CompanySignUpTerms from 'components/CompanySignUpTerms';
import { Link } from 'react-router-dom';
import * as validators from 'lib/validators';
import 'components/TextBoxField/TextboxField.css';
import 'components/LoadingPage/LoadingPage.css';
import './CompanySignUp.css';

const validations = {
  email: validators.check(validators.required, validators.email),
  password: validators.check(
    validators.required,
    validators.passwordLength,
    validators.passwordComplexity
  ),
  passwordConfirmation: validators.check(
    validators.required,
    validators.passwordConfirmation
  )
};

const renderCountrySelect = ({ input: { value, onChange }, ...props }) => (
  <CountryDropDown value={value} onChange={onChange} {...props} />
);

const PreLoginField = ({
  title,
  className,
  name,
  component,
  validate,
  ...props
}) => {
  return (
    <div className={className}>
      <label className="title">{title}</label>
      <Field
        className="input"
        component={component || renderCompanyTextField}
        name={name}
        validate={validate}
        {...props}
      />
    </div>
  );
};

export default reduxForm({
  form: 'company-sign-up',
  onSubmitSuccess(_, __, { history }) {
    history.push('/invites');
  }
})(
  class CompanySignUp extends Component {
    constructor(props) {
      super(props);

      this.onSubmit = this.onSubmit.bind(this);
      this.toggleTermsAgreement = this.toggleTermsAgreement.bind(this);
      this.loaded = this.loaded.bind(this);
      this.state = {
        agreedToTermsAndConditions: false
      };
    }

    onSubmit(signUpInfo) {
      const { signUp, signIn } = this.props;
      const { email, password } = signUpInfo;

      return signUp(signUpInfo).then(() => signIn(email, password));
    }

    toggleTermsAgreement() {
      const { agreedToTermsAndConditions } = this.state;

      this.setState({
        agreedToTermsAndConditions: !agreedToTermsAndConditions
      });
    }

    loaded() {
      const { countries } = this.props;

      return countries && countries.length > 0;
    }

    render() {
      const {
        handleSubmit,
        submitting,
        error,
        valid,
        countriesLoaded
      } = this.props;

      const { agreedToTermsAndConditions } = this.state;

      return countriesLoaded ? (
        <PreLoginBox
          className="company-sign-up-spacing"
          content="company-sign-up-content"
        >
          <div className="company-sign-up">
            <PreLoginForm onSubmit={handleSubmit(this.onSubmit)} error={error}>
              <h1 className="company-login-title">Create Company Account</h1>
              <p className="company-sign-up-description">
                Current and next month use are free. No payment required.
              </p>
              <div className="company-sign-up-name">
                <PreLoginField
                  className="company-sign-up-first-name"
                  title="First Name"
                  name="firstName"
                />
                <PreLoginField
                  className="company-sign-up-last-name"
                  title="Last Name"
                  name="lastName"
                />
              </div>
              <PreLoginField
                className="company-sign-up-input"
                title="Company Name"
                name="companyName"
                validate={validators.required}
              />
              <div className="company-sign-up-input">
                <label className="title">
                  Country of operation (cannot be changed later)
                </label>
                <Field
                  className="company-country-selector"
                  component={renderCountrySelect}
                  name="countryId"
                  validate={validators.required}
                />
              </div>
              <PreLoginField
                className="company-sign-up-input"
                title="Work Email"
                validate={validations.email}
                name="email"
              />
              <PreLoginField
                className="company-sign-up-input"
                type="password"
                title="Password"
                name="password"
                validate={validations.password}
                prelogin
              />
              <PreLoginField
                className="company-sign-up-input"
                type="password"
                title="Password Confirmation"
                name="passwordConfirmation"
                validate={validations.passwordConfirmation}
                prelogin
              />
              <CompanySignUpTerms onChange={this.toggleTermsAgreement} />
              <div className="company-login-button-container">
                <PreLoginButton
                  disabled={submitting || !agreedToTermsAndConditions || !valid}
                  className="company-sign-up-button"
                >
                  {submitting ? (
                    <span>Creating account&hellip;</span>
                  ) : (
                    'Create Account'
                  )}
                </PreLoginButton>
              </div>
              <Link className="company-login-link" to="/sign_in">
                Already have an account? Login
              </Link>
            </PreLoginForm>
          </div>
        </PreLoginBox>
      ) : (
        <LoadingPage />
      );
    }
  }
);
