import React, { Component } from 'react';
import { scalePoint, scaleLinear, axisBottom, select } from 'd3';

export default class ComparisonBarChart extends Component {
  constructor(props) {
    super(props);

    const { orderedKeys, data, spacing, width } = props;
    const first = data[0];
    const keys = Object.keys(first);
    const amountOfData = data.length;
    const spaceBetween = spacing || 1;
    const valueKeys =
      orderedKeys || (keys && keys.filter(key => key !== 'title'));
    const columnsPerBar = valueKeys.length;
    const barWidth =
      width / ((columnsPerBar + spaceBetween) * amountOfData - spaceBetween);

    this.state = {
      barWidth,
      spacing: spaceBetween * barWidth,
      columnsPerBar
    };

    this.svg = null;

    this.setSvgRef = this.setSvgRef.bind(this);
    this.spaceBars = this.spaceBars.bind(this);
  }

  componentDidMount() {
    this.renderSvg();
  }

  spaceBars(barNumber) {
    const { barWidth } = this.state;

    return barNumber * barWidth;
  }

  setSvgRef(svg) {
    this.svg = svg;
  }

  renderSvg() {
    const { data, orderedKeys, width, height, className } = this.props;

    const { barWidth, spacing } = this.state;
    const margin = 40;
    const first = data && data[0];
    const keys = first && Object.keys(first);
    const getMax = Math.max;
    const valueKeys = orderedKeys || keys.filter(key => key !== 'title');
    const heightWithoutMargin = height - margin;

    // get max value of all bars
    const maximum = data.reduce((maxNumber, current) => {
      return valueKeys.reduce((previous, key) => {
        const value = current[key];
        return value ? getMax(previous, value) : value;
      }, maxNumber);
    }, 0);

    const ys = valueKeys.map(() =>
      scaleLinear()
        .range([heightWithoutMargin, 0])
        .domain([0, maximum])
    );

    // create scalar for x axis
    const x = scalePoint()
      .domain(data.map(({ title }) => title))
      .range([0, width])
      .padding(0.35);

    const xAxis = axisBottom(x);

    // create svg and append graph
    const svg = select(this.svg)
      .attr('width', width)
      .attr('height', height)
      .append('g');

    svg
      .append('g')
      .attr('class', 'x axis')
      .attr(
        'transform',
        'translate(0,' + (heightWithoutMargin + margin / 5) + ')'
      )
      .call(xAxis);

    const bars = svg
      .selectAll('.bar')
      .data(data)
      .enter();

    ys.forEach((y, yIndex) => {
      const key = valueKeys[yIndex];
      const number = yIndex + 1;

      bars
        .append('rect')
        .attr('class', `bar bar${number}`)
        .attr(
          'x',
          (_, index) =>
            this.spaceBars(index) +
            barWidth * index +
            barWidth * yIndex +
            spacing * index
        )
        .attr('width', barWidth)
        .attr('y', ({ [key]: value }) => y(value))
        .attr('height', ({ [key]: value }) => heightWithoutMargin - y(value));
    });
  }

  render() {
    const { width, height, className } = this.props;
    return (
      <svg
        ref={this.setSvgRef}
        width={width}
        height={height}
        className={className}
      />
    );
  }
}
