import { getCompanyLocations } from './getCompanyLocations';
import getSelectedCompanyLocationId from './getSelectedCompanyLocationId';

export const getSelectedCompanyLocation = state => {
  const selectedId = getSelectedCompanyLocationId(state);
  const locations = getCompanyLocations(state);
  return locations && locations.find(({ id }) => id === selectedId);
};

export default getSelectedCompanyLocation;
