import React from 'react';
import FontAwesome from 'react-fontawesome';
import Button from 'components/Button';
import ScrollBars from 'components/Scrollbars';
import Vehicle from './Vehicle';
import './Garage.css';

const Garage = ({ vehicles, create }) => (
  <ScrollBars>
    <div className="garage">
      {vehicles.map(vehicle => (
        <div key={vehicle.id} className="garage__vehicle">
          <Vehicle id={vehicle.id} />
        </div>
      ))}

      <div className="garage__add-vehicle">
        <Button onClick={create}>
          <FontAwesome name="plus" />
          Add Vehicle
        </Button>
      </div>
    </div>
  </ScrollBars>
);

export default Garage;
