import React, { Component } from 'react';
import ImageContainer from 'components/CompanyPages/lib/ImageContainer';
import LoadingPage from 'components/LoadingPage';
import { Link } from 'react-router-dom';
import './InvitationConfirmed.css';

export default class InvitationConfirmed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeRemaining: 5,
      companyName: null,
      companyImage: null
    };

    this.countDown = this.countDown.bind(this);
    this.decrementTimeByOne = this.decrementTimeByOne.bind(this);
    this.redirectToPortal = this.redirectToPortal.bind(this);
    this.getUuid = this.getUuid.bind(this);
  }

  countDown() {
    const { timeRemaining } = this.state;

    timeRemaining > 0
      ? setTimeout(() => {
          this.decrementTimeByOne(timeRemaining);
          this.countDown();
        }, 1000)
      : this.redirectToPortal();
  }

  decrementTimeByOne(time) {
    this.setState({ timeRemaining: time - 1 });
  }

  redirectToPortal() {
    const { history } = this.props;

    history.push('/trips');
  }

  componentWillMount() {
    const { confirmInvitation, history, getCompany, loadCompany } = this.props;

    const uuid = this.getUuid();

    if (uuid !== 'confirmed') {
      confirmInvitation(uuid)
        .then(data => {
          const user = data && data.user;
          const userId = user && user.id;

          userId &&
            getCompany(userId).then(({ name, image, ...company }) => {
              this.setState({
                companyName: name,
                companyImage: image
              });
            });
        })
        .catch(() => history.push('/invitation/invalid'));
    } else {
      loadCompany();
    }

    this.countDown();
  }

  getUuid() {
    const splitUrl = window.location.href.split('/');

    return splitUrl[splitUrl.length - 1];
  }

  render() {
    const { company } = this.props;
    const { timeRemaining, companyImage } = this.state;
    const { name, image } = company || {};

    return company ? (
      <div className="invitation-confirmed">
        <ImageContainer
          className="invitation-confirmed-page-company-logo"
          image={image || companyImage}
        />
        <h1 className="invitation-confirmed-redirect-title">
          You have successfully joined {name || 'a company'}!
        </h1>
        <h1 className="invitation-confirmed-redirect-timer">
          you will be automatically redirected in {timeRemaining} seconds.
        </h1>
        <h1 className="invitation-confirmed-redirect-text">
          or click {<Link to={'/trips'}>here</Link>} to be redirected to your
          account.
        </h1>
      </div>
    ) : (
      <LoadingPage className="invitation-confirmed-load-wheel" />
    );
  }
}
