import { combineReducers } from 'redux';
import teams from './teams';
import members from './teamMembers';
import { requests, requestsById } from './teamRequests';
export default combineReducers({
  teams,
  members,
  requests,
  requestsById
});
