import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Textbox from 'components/TextBox';

import './Autocomplete.css';

const propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  getItemValue: PropTypes.func,
  renderItem: PropTypes.func,
  value: PropTypes.string,
  onSelect: PropTypes.func,
  onChange: PropTypes.func
};

export default class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }
  render() {
    let {
      items,
      getItemValue,
      renderItem,
      onSelect,
      onFocus,
      onBlur,
      ...props
    } = this.props;
    const { open } = this.state;

    if (renderItem === undefined) {
      renderItem = item => item;
    }

    if (getItemValue === undefined) {
      getItemValue = item => item;
    }

    if (onSelect === undefined) {
      onSelect = () => null;
    }

    if (onFocus === undefined) {
      onFocus = () => null;
    }

    if (onBlur === undefined) {
      onBlur = () => null;
    }

    if (items === undefined) {
      items = [];
    }

    return (
      <div className="autocomplete">
        <Textbox
          {...props}
          onFocus={() => {
            this.setState({ open: true });
            onFocus();
          }}
          onBlur={() =>
            setTimeout(() => {
              this.setState({ open: false });
              onBlur();
            }, 300)
          }
        />

        {items.length > 0 &&
          open && (
            <div className="autocomplete__menu">
              {items.map((item, index) => (
                <div
                  key={index}
                  className="autocomplete__item"
                  onClick={() => onSelect(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          )}
      </div>
    );
  }
}

Autocomplete.propTypes = propTypes;
