import moment from 'moment';
import numeral from 'numeral';
import { convertUnitOfMeasurement } from 'lib/utils';
import { locales } from './locales.json';

function localizeMoment(locale) {
  const is24 = locale.hours24;
  const monthFirst = locale.date === 'MDY';

  moment.locale(`c-${locale.countryCode}`, {
    parentLocale: 'en',
    longDateFormat: {
      LT: is24 ? 'HH:mm' : 'h:mm A',
      LTS: is24 ? 'HH:mm:ss' : 'h:mm:ss A',
      L: monthFirst ? 'MM/DD/YYYY' : 'DD/MM/YYYY',
      LL: monthFirst ? 'MMMM D, YYYY' : 'D MMMM, YYYY',
      LLL: `${monthFirst ? 'MMMM D' : 'D MMMM'}, YYYY ${
        is24 ? 'HH:mm' : 'h:mm A'
      }`,
      LLLL: `dddd, ${monthFirst ? 'MMMM D' : 'D MMMM'}, YYYY ${
        is24 ? 'HH:mm' : 'h:mm A'
      }`
    }
  });
}

function localizeNumeral(locale) {
  try {
    numeral.register('locale', `c-${locale.countryCode}`, {
      delimiters: {
        thousands: locale.thousand,
        decimal: locale.decimal
      },
      currency: {
        symbol: locale.currencySymbol
      }
    });
  } catch (e) {
    // suppress '<locale> is already registered!' errors
    if (!(e instanceof TypeError)) throw e;
  }

  numeral.locale(`c-${locale.countryCode}`);
}

export const setLocaleByCountryCode = countryCode => {
  const locale = locales[countryCode] || locales.OTHER;
  localizeMoment(locale);
  localizeNumeral(locale);
};

// converts localized strings like '€ 1 234,45' to a normalized format numeraljs
// will understand: '$ 1,234.45'
export const normalizeSymbolPlacement = locale =>
  locale.symbolPlacement
    .replace(/1.2/, '1,2')
    .replace(/4.5/, '4.5')
    .replace(locale.currencySymbol, '$');

export const getCurrencySymbol = countryCode => {
  const local = locales[countryCode] || locales.OTHER;

  return local && local.currencySymbol;
};

export const addCurrencySymbol = countryCode => {
  const { symbolPlacement, currencySymbol } =
    locales[countryCode] || locales.OTHER;
  return value => {
    const index = symbolPlacement.indexOf(currencySymbol);
    return index === 0
      ? `${currencySymbol} ${value}`
      : `${value} ${currencySymbol}`;
  };
};

export const formatCurrency = (value, { countryCode, metric, rate, round }) => {
  const locale = locales[countryCode] || locales.OTHER;
  const normalizedFormat = normalizeSymbolPlacement(locale);
  const numeralPrecision = round ? '0,0' : rate ? '0,0.000' : '0,0.00';
  const numeralFormat = normalizedFormat.replace('1,234.56', numeralPrecision);
  return (
    numeral(value).format(numeralFormat) +
    (rate ? (metric ? '/km' : '/mi') : '')
  );
};
