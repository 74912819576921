import { companyApi } from '../../client';

export const getCompanyLocations = params =>
  companyApi.get(`/locations`, { params });

export const putCompanyLocations = (id, location) =>
  companyApi.put(`/locations/${id}`, { location });

export const deleteCompanyLocations = id =>
  companyApi.delete(`/locations/${id}`);

export const postCompanyLocations = location =>
  companyApi.post('/locations', { location });
