import {
  mapStateToAddAutoApprovalRulePage,
  mapDispatchToAddAutoApprovalRulePage
} from './addAutoApprovalRulePageMappings';
import { connect } from 'react-redux';
import AddAutoApprovalRulePage from './AddAutoApprovalRulePage';

export default connect(
  mapStateToAddAutoApprovalRulePage,
  mapDispatchToAddAutoApprovalRulePage
)(AddAutoApprovalRulePage);
