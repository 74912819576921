import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import {
  RESEND_INVITATION_REQUEST,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_FAILURE
} from 'ducks/company/companyActionTypes';
import { resendInvitation as resendInvite } from 'api/endpoints/company/invitations';
import addSuccessAndFailureMessages from '../../../lib/addSuccessAndFailureMessages';

export const resendInvitation = addSuccessAndFailureMessages(
  createSimpleAction(resendInvite, [
    RESEND_INVITATION_REQUEST,
    RESEND_INVITATION_SUCCESS,
    RESEND_INVITATION_FAILURE
  ]),
  'Invitation sent',
  'Invitation failed to send'
);

export default resendInvitation;
