import saveAs from 'file-saver';

export const saveTextToCsvFile = (text, fileName) => {
  const blob = new Blob([text], {
    type: 'text/plain;charset=utf-8;'
  });
  saveAs(blob, `${fileName}.csv`);
};

export default saveTextToCsvFile;
