import { connect } from 'react-redux';
import RenderPage from './RenderPage';
import {
  mapStateToRenderPage,
  mapDispatchToRenderPage
} from './renderPageMappings';

export default connect(
  mapStateToRenderPage,
  mapDispatchToRenderPage
)(RenderPage);
