import React, { Component } from 'react';
import TitleHeader from 'components/CompanyPages/lib/PageTitleHeader';
import Teams from './TeamsList';
import Members from './MembersList';
import AddButton from 'components/CompanyPages/lib/AddButton';
import AddTeamPage from './AddTeamPage';
import EditTeamPage from './EditTeamPage';
import Modal from 'components/Modal';
import _ from 'lodash';
import InformationPopUp from 'components/InformationPopUp';
import RenderPage from 'lib/RenderPage';
import './TeamsPage.css';

export default class TeamsPage extends Component {
  constructor(props) {
    const { updateTeam } = props;

    super(props);

    this.state = {
      highlighted: null,
      addingTeam: false,
      editingTeam: false,
      buySeatsModalIsOpen: false
    };

    this.onDrop = this.onDrop.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.highlight = this.highlight.bind(this);
    this.deHighlight = this.deHighlight.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.openAddTeamModal = this.openAddTeamModal.bind(this);
    this.closeAddTeamModal = this.closeAddTeamModal.bind(this);
    this.openEditTeamModal = this.openEditTeamModal.bind(this);
    this.closeEditTeamModal = this.closeEditTeamModal.bind(this);
    this.openBuySeatsModal = this.openBuySeatsModal.bind(this);
    this.closeBuySeatsModal = this.closeBuySeatsModal.bind(this);
    this.getTeam = this.getTeam.bind(this);
    this.updateTeam =
      updateTeam &&
      _.debounce(updateTeam, 1000, {
        leading: true,
        trailing: false
      });
  }

  componentWillMount() {
    const { loadTeams, loadUsers, loadCostCenters } = this.props;

    [loadTeams, loadUsers, loadCostCenters].forEach(loadResource =>
      loadResource()
    );
  }

  highlight(index) {
    this.setState({
      highlighted: index
    });
  }

  deHighlight(index) {
    const { highlighted } = this.state;

    if (highlighted === index) {
      this.setState({
        highlighted: null
      });
    }
  }

  openAddTeamModal() {
    this.setState({ addingTeam: true });
  }

  closeAddTeamModal() {
    this.setState({ addingTeam: false });
  }

  openEditTeamModal({ target }) {
    this.setState({
      editingTeam: true,
      selectedTeam: this.getTeam(target.id)
    });
  }

  openBuySeatsModal() {
    this.setState({ buySeatsModalIsOpen: true });
  }

  closeBuySeatsModal() {
    this.setState({ buySeatsModalIsOpen: false });
  }

  getTeam(teamId) {
    const { teams } = this.props;

    return teams && teams[teamId];
  }

  closeEditTeamModal() {
    this.setState({
      editingTeam: false,
      selectedTeam: null
    });
  }

  onDragLeave(teamIndex) {
    return () => {
      this.deHighlight(teamIndex);

      return false;
    };
  }

  onDragEnter(teamIndex) {
    return () => {
      this.highlight(teamIndex);

      return false;
    };
  }

  // Silly html 5
  onDragOver(event) {
    event && event.preventDefault && event.preventDefault();
  }

  onDrag({ dataTransfer, target }) {
    dataTransfer.setData('member', target.id);

    return false;
  }

  onDrop(teamIndex, teamId) {
    return event => {
      event.preventDefault();

      const memberId = event.dataTransfer.getData('member');

      this.deHighlight(teamIndex);

      this.updateTeam(teamId, {
        members: [memberId]
      });

      return false;
    };
  }

  render() {
    const { members, isAdmin, requestsState } = this.props;
    const { highlighted, addingTeam, editingTeam, selectedTeam } = this.state;

    return (
      <div className="teams-page">
        <TitleHeader>Teams</TitleHeader>
        <div className="teams-description-and-add-row">
          <h1 className="teams-page-description">
            Define teams(departments) and assign members to easily manage,
            filter, report and set rules for groups of people.
          </h1>
          {isAdmin && (
            <AddButton
              onClick={this.openAddTeamModal}
              className="add-team-button"
            >
              Add Team
            </AddButton>
          )}
        </div>
        <div className="teams-page-data">
          <RenderPage requestsState={requestsState}>
            <Teams
              onDrop={this.onDrop}
              onDragLeave={this.onDragLeave}
              onDragEnter={this.onDragEnter}
              onDragOver={this.onDragOver}
              onClick={this.openEditTeamModal}
              highlighted={highlighted}
            />
            {/*<Members onDrag={this.onDrag} members={members} />*/}
          </RenderPage>
        </div>
        <Modal
          className="add-team-modal"
          key="add-team-modal"
          isOpen={addingTeam}
          onClose={this.closeAddTeamModal}
          title={'Add Team'}
        >
          <AddTeamPage onSubmit={this.closeAddTeamModal} />
        </Modal>
        <Modal
          className="edit-team-modal"
          key="edit-team-modal"
          isOpen={editingTeam}
          onClose={data => {
            return this.closeEditTeamModal(data);
          }}
          title={selectedTeam ? selectedTeam.name : 'Edit Team'}
        >
          <EditTeamPage
            teamId={selectedTeam && selectedTeam.id}
            onSubmit={this.closeEditTeamModal}
          />
        </Modal>
      </div>
    );
  }
}
