import React, { Component } from 'react';
import ReactModal from 'react-modal';
import FontAwesome from 'react-fontawesome';
import Button from 'components/Button';
import InformationPopUp from 'components/InformationPopUp';
import './Style.css';
export default class Modal extends Component {
  render() {
    const {
      title,
      description,
      children,
      onClose,
      className,
      portalClassName,
      ...props
    } = this.props;
    return (
      <ReactModal
        portalClassName={`modal-portal ${portalClassName}`}
        overlayClassName="modal-overlay"
        className={`modal ${className}`}
        bodyOpenClassName="body_modal-open"
        contentLabel={title}
        onRequestClose={onClose}
        {...props}
      >
        {title && (
          <div className="modal__title">
            {description && (
              <InformationPopUp
                className="modal-info-button"
                message={description}
              />
            )}
            {title}{' '}
            <div className="modal__close">
              <Button onClick={onClose} inline>
                <FontAwesome name="times" fixedWidth />
              </Button>
            </div>
          </div>
        )}
        <div className="modal__content">{children}</div>
      </ReactModal>
    );
  }
}
