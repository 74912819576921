import _ from 'lodash';
import { Component } from 'react';
import PropTypes from 'prop-types';
const subscribed = user => _.get(user, 'subscription.status') !== 'cancelled';
export default class Paywall extends Component {
  static propTypes = {
    user: PropTypes.object,
    children: PropTypes.node,
    invalidate: PropTypes.func.isRequired
  };
  componentDidMount() {
    this.checkSubscription(this.props.user);
  }
  componentWillReceiveProps(nextProps) {
    this.checkSubscription(nextProps.user);
  }
  checkSubscription(user) {
    const { invalidate, clear, showInvalidSubscriptionMessage } = this.props;
    if (!subscribed(user)) {
      clear(); // remove 'signed in' notification
      invalidate(); // destroy session so user isn't locked into redirectTo
      showInvalidSubscriptionMessage();
    }
  }
  render() {
    const { user, children } = this.props;
    return subscribed(user) ? children : null;
  }
}
