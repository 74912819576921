import { SubmissionError } from 'redux-form';
import * as t from './usersActionTypes';
import { getUser, putUser } from 'api/endpoints/user/users';
import vehicles from 'ducks/vehicles';
import trips from 'ducks/trips';
import reports from 'ducks/reports';
import {
  getCurrentId,
  getActive,
  getRequestStateById
} from 'ducks/users/selectors';

const getRequest = id => ({ type: t.GET_REQUEST, payload: { id } });
const getSuccess = user => ({ type: t.GET_SUCCESS, payload: user });
const getFailure = (id, error) => ({
  type: t.GET_FAILURE,
  payload: { id },
  error
});

export const fetchUser = id => (dispatch, getState) => {
  const state = getState();
  if (
    id &&
    getRequestStateById(state, id) !== 'READY' &&
    getRequestStateById(state, id) !== 'LOADING'
  ) {
    dispatch(getRequest(id));
    return getUser(id)
      .then(response => {
        return dispatch(vehicles.actions.getVehicles()).then(() =>
          dispatch(getSuccess(response.data.user))
        );
      })
      .catch(error => dispatch(getFailure(id, error)));
  }
};

export const fetchCurrentUser = () => (dispatch, getState) => {
  const state = getState();
  const id = getCurrentId(state);
  dispatch(fetchUser(id));
};

const updateRequest = id => ({
  type: t.UPDATE_REQUEST,
  payload: { id }
});

const updateSuccess = (user, silent) => ({
  type: t.UPDATE_SUCCESS,
  payload: user,
  meta: silent
    ? undefined
    : { alert: { type: 'success', message: 'Settings updated' } }
});

const updateFailure = (id, error) => ({
  type: t.UPDATE_FAILURE,
  payload: { id },
  error
});

export const updateUser = (id, params, silent) => (dispatch, getState) => {
  const state = getState();

  if (!id && id !== 0) {
    id = getCurrentId(state);
  }

  dispatch(updateRequest(id));

  return putUser(id, params).then(
    response => dispatch(updateSuccess(response.data.user, silent)),
    error => {
      dispatch(updateFailure(id, error));
      if (error instanceof SubmissionError) {
        return Promise.reject(error);
      }
    }
  );
};

export const updateCurrentUser = (params, silent) =>
  updateUser(null, params, silent);

export const setActive = id => ({
  type: t.SET_ACTIVE,
  payload: { id }
});

const invalidateTripsAndReports = () => dispatch => {
  dispatch(trips.actions.invalidate());
  dispatch(reports.actions.invalidate());
};

export const switchUser = id => (dispatch, getState) => {
  dispatch(setActive(id));
  return !getActive(getState())
    ? dispatch(fetchUser(id)).then(() => dispatch(invalidateTripsAndReports()))
    : dispatch(invalidateTripsAndReports());
};
