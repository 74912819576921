import moment from 'moment';
import _ from 'lodash';
import * as constants from './constants';
import users from 'ducks/users';
import { createItemsSelectors } from 'redux/itemsReducer';

const getState = state => state[constants.NAME];

export const getRequest = state => getState(state).request;

export const getFetching = (state, id) =>
  getState(state).itemRequests[id] && getState(state).itemRequests[id].fetching;

export const getBestMatchedVehicle = (state, id) => {
  const vehicles = getActive(state);
  return (
    (vehicles &&
      vehicles.length &&
      (vehicles.find(vehicle => id === vehicle.id) ||
        vehicles.find(vehicle => vehicle.isPrimary))) ||
    null
  );
};

const getItems = state => getState(state).items;
export const { getById } = createItemsSelectors(getItems);
// export const getById = (state, id) => getItems(state)[id];

export const getByUserAndVehicleId = (state, userId, vehicleId) => {
  const vehicles = getByUserId(state, userId);
  return vehicles && vehicles.find(({ id }) => id === vehicleId);
};

const getByUserId = (state, userId) =>
  _.values(getItems(state))
    .filter(vehicle => vehicle.userId === userId)
    .sort((a, b) => moment(a.createdAt) - moment(b.createdAt));

export const getCurrentVehicles = state =>
  getByUserId(state, users.selectors.getCurrentId(state));

export const getActive = state =>
  getByUserId(state, users.selectors.getActiveId(state));
