import { connect } from 'react-redux';
import ReportDownloadLinks from './ReportDownloadLinks';

import {
  mapStateToReportDownloadLinks,
  mapDispatchToReportDownloadLinks
} from './ReportDownloadLinksMappings';

export default connect(
  mapStateToReportDownloadLinks,
  mapDispatchToReportDownloadLinks
)(ReportDownloadLinks);
