import { connect } from 'react-redux';
import AccountManagement from './AccountManagement';
import {
  mapStateToAccountManagement,
  mapDispatchToAccountManagement
} from './accountManagementMappings';

export default connect(
  mapStateToAccountManagement,
  mapDispatchToAccountManagement
)(AccountManagement);
