import React from 'react';
import classNames from 'class-names';
import { Route, Link } from 'react-router-dom';

import './TabLink.css';

const TabLink = ({ to, ...props }) =>
  <Route
    path={to}
    exact={true}
    children={({ match }) =>
      <Link
        className={classNames('tab', { tab_active: match })}
        to={to}
        {...props}
      />}
  />;

export default TabLink;
