import moment from 'moment/moment';
import React from 'react';
import DatePicker from 'react-datepicker';
export default ({ initialValue, input: { value, onChange } }) => (
  <DatePicker
    maxDate={moment()}
    className="textbox date-input"
    selected={moment(value || initialValue)}
    onChange={value => onChange(value.format())}
  />
);
