import { formatWorkHourRules } from './formatWorkHourRules';

export const formatWorkDays = days =>
  days &&
  days.map(({ rules, ...day }) => ({
    rules: formatWorkHourRules(rules),
    ...day
  }));

export default formatWorkDays;
