const nameSpace = 'COMPANIES';

export const COMPANY_SIGN_UP_REQUEST = `${nameSpace}/COMPANY_SIGN_UP_REQUEST`;
export const COMPANY_SIGN_UP_SUCCESS = `${nameSpace}/COMPANY_SIGN_UP_SUCCESS`;
export const COMPANY_SIGN_UP_FAILURE = `${nameSpace}/COMPANY_SIGN_UP_FAILURE`;

export const COMPANY_INVITE_USERS_REQUEST = `${nameSpace}/COMPANY_INVITE_USERS_REQUEST`;
export const COMPANY_INVITE_USERS_SUCCESS = `${nameSpace}/COMPANY_INVITE_USERS_SUCCESS`;
export const COMPANY_INVITE_USERS_FAILURE = `${nameSpace}/COMPANY_INVITE_USERS_FAILURE`;

export const COMPANY_INVITE_USERS_IN_BULK_REQUEST = `${nameSpace}/COMPANY_INVITE_USERS_IN_BULK_REQUEST`;
export const COMPANY_INVITE_USERS_IN_BULK_SUCCESS = `${nameSpace}/COMPANY_INVITE_USERS_IN_BULK_SUCCESS`;
export const COMPANY_INVITE_USERS_IN_BULK_FAILURE = `${nameSpace}/COMPANY_INVITE_USERS_IN_BULK_FAILURE`;

export const GET_COMPANY_MEMBERS_REQUEST = `${nameSpace}/GET_COMPANY_MEMBERS_REQUEST`;
export const GET_COMPANY_MEMBERS_SUCCESS = `${nameSpace}/GET_COMPANY_MEMBERS_SUCCESS`;
export const GET_COMPANY_MEMBERS_FAILURE = `${nameSpace}/GET_COMPANY_MEMBERS_FAILURE`;

export const COMPANY_SIGN_UP_STATUS = `${nameSpace}/COMPANY_FINISHED_SIGN_UP`;

export const COMPANY_INVITE_USER_REQUEST = `${nameSpace}/COMPANY_INVITE_USER_REQUEST`;
export const COMPANY_INVITE_USER_SUCCESS = `${nameSpace}/COMPANY_INVITE_USER_SUCCESS`;
export const COMPANY_INVITE_USER_FAILURE = `${nameSpace}/COMPANY_INVITE_USER_FAILURE`;

export const UPDATE_COMPANY_USER_REQUEST = `${nameSpace}/UPDATE_COMPANY_USER_ROLE_REQUEST`;
export const UPDATE_COMPANY_USER_SUCCESS = `${nameSpace}/UPDATE_COMPANY_USER_ROLE_SUCCESS`;
export const UPDATE_COMPANY_USER_FAILURE = `${nameSpace}/UPDATE_COMPANY_USER_ROLE_FAILURE`;

export const REMOVE_USER_FROM_COMPANY_REQUEST = `${nameSpace}/REMOVE_USER_FROM_COMPANY_REQUEST`;
export const REMOVE_USER_FROM_COMPANY_SUCCESS = `${nameSpace}/REMOVE_USER_FROM_COMPANY_SUCCESS`;
export const REMOVE_USER_FROM_COMPANY_FAILURE = `${nameSpace}/REMOVE_USER_FROM_COMPANY_FAILURE`;

export const GET_INVITES_REQUEST = `${nameSpace}/GET_INVITES_REQUEST`;
export const GET_INVITES_SUCCESS = `${nameSpace}/GET_INVITES_SUCCESS`;
export const GET_INVITES_FAILURE = `${nameSpace}/GET_INVITES_FAILURE`;

export const GET_COMPANY_PURPOSES_REQUEST = `${nameSpace}/GET_COMPANY_PURPOSES_REQUEST`;
export const GET_COMPANY_PURPOSES_SUCCESS = `${nameSpace}/GET_COMPANY_PURPOSES_SUCCESS`;
export const GET_COMPANY_PURPOSES_FAILURE = `${nameSpace}/GET_COMPANY_PURPOSES_FAILURE`;

export const PUT_COMPANY_PURPOSE_REQUEST = `${nameSpace}/PUT_COMPANY_PURPOSE_REQUEST`;
export const PUT_COMPANY_PURPOSE_SUCCESS = `${nameSpace}/PUT_COMPANY_PURPOSE_SUCCESS`;
export const PUT_COMPANY_PURPOSE_FAILURE = `${nameSpace}/PUT_COMPANY_PURPOSE_FAILURE`;

export const POST_COMPANY_PURPOSE_REQUEST = `${nameSpace}/POST_COMPANY_PURPOSE_REQUEST`;
export const POST_COMPANY_PURPOSE_SUCCESS = `${nameSpace}/POST_COMPANY_PURPOSE_SUCCESS`;
export const POST_COMPANY_PURPOSE_FAILURE = `${nameSpace}/POST_COMPANY_PURPOSE_FAILURE`;

export const DELETE_COMPANY_PURPOSE_REQUEST = `${nameSpace}/DELETE_COMPANY_PURPOSE_REQUEST`;
export const DELETE_COMPANY_PURPOSE_SUCCESS = `${nameSpace}/DELETE_COMPANY_PURPOSE_SUCCESS`;
export const DELETE_COMPANY_PURPOSE_FAILURE = `${nameSpace}/DELETE_COMPANY_PURPOSE_FAILURE`;

export const DELETE_COMPANY_INVITE_REQUEST = `${nameSpace}/DELETE_COMPANY_INVITE_REQUEST`;
export const DELETE_COMPANY_INVITE_SUCCESS = `${nameSpace}/DELETE_COMPANY_INVITE_SUCCESS`;
export const DELETE_COMPANY_INVITE_FAILURE = `${nameSpace}/DELETE_COMPANY_INVITE_FAILURE`;

export const UPDATE_COMPANY_INVITE_REQUEST = `${nameSpace}/UPDATE_COMPANY_INVITE_REQUEST`;
export const UPDATE_COMPANY_INVITE_SUCCESS = `${nameSpace}/UPDATE_COMPANY_INVITE_SUCCESS`;
export const UPDATE_COMPANY_INVITE_FAILURE = `${nameSpace}/UPDATE_COMPANY_INVITE_FAILURE`;

export const CONFIRM_INVITATION_REQUEST = `${nameSpace}/CONFIRM_INVITATION_REQUEST`;
export const CONFIRM_INVITATION_SUCCESS = `${nameSpace}/CONFIRM_INVITATION_SUCCESS`;
export const CONFIRM_INVITATION_FAILURE = `${nameSpace}/CONFIRM_INVITATION_FAILURE`;

export const GET_COMPANY_LOCATIONS_REQUEST = `${nameSpace}/GET_COMPANY_LOCATIONS_REQUEST`;
export const GET_COMPANY_LOCATIONS_SUCCESS = `${nameSpace}/GET_COMPANY_LOCATIONS_SUCCESS`;
export const GET_COMPANY_LOCATIONS_FAILURE = `${nameSpace}/GET_COMPANY_LOCATIONS_FAILURE`;

export const PUT_COMPANY_LOCATIONS_REQUEST = `${nameSpace}/PUT_COMPANY_LOCATIONS_REQUEST`;
export const PUT_COMPANY_LOCATIONS_SUCCESS = `${nameSpace}/PUT_COMPANY_LOCATIONS_SUCCESS`;
export const PUT_COMPANY_LOCATIONS_FAILURE = `${nameSpace}/PUT_COMPANY_LOCATIONS_FAILURE`;

export const POST_COMPANY_LOCATIONS_REQUEST = `${nameSpace}/POST_COMPANY_LOCATIONS_REQUEST`;
export const POST_COMPANY_LOCATIONS_SUCCESS = `${nameSpace}/POST_COMPANY_LOCATIONS_SUCCESS`;
export const POST_COMPANY_LOCATIONS_FAILURE = `${nameSpace}/POST_COMPANY_LOCATIONS_FAILURE`;

export const DELETE_COMPANY_LOCATIONS_REQUEST = `${nameSpace}/DELETE_COMPANY_LOCATIONS_REQUEST`;
export const DELETE_COMPANY_LOCATIONS_SUCCESS = `${nameSpace}/DELETE_COMPANY_LOCATIONS_SUCCESS`;
export const DELETE_COMPANY_LOCATIONS_FAILURE = `${nameSpace}/DELETE_COMPANY_LOCATIONS_FAILURE`;

export const RESEND_INVITATION_REQUEST = `${nameSpace}/RESEND_INVITATION_REQUEST`;
export const RESEND_INVITATION_SUCCESS = `${nameSpace}/RESEND_INVITATION_SUCCESS`;
export const RESEND_INVITATION_FAILURE = `${nameSpace}/RESEND_INVITATION_FAILURE`;

export const GET_COMPANY_VEHICLES_REQUEST = `${nameSpace}/GET_COMPANY_VEHICLES_REQUEST`;
export const GET_COMPANY_VEHICLES_SUCCESS = `${nameSpace}/GET_COMPANY_VEHICLES_SUCCESS`;
export const GET_COMPANY_VEHICLES_FAILURE = `${nameSpace}/GET_COMPANY_VEHICLES_FAILURE`;

export const GET_COMPANY_REPORTS_REQUEST = `${nameSpace}/GET_COMPANY_REPORTS_REQUEST`;
export const GET_COMPANY_REPORTS_SUCCESS = `${nameSpace}/GET_COMPANY_REPORTS_SUCCESS`;
export const GET_COMPANY_REPORTS_FAILURE = `${nameSpace}/GET_COMPANY_REPORTS_FAILURE`;

export const PUT_COMPANY_REPORT_REQUEST = `${nameSpace}/PUT_COMPANY_REPORT_REQUEST`;
export const PUT_COMPANY_REPORT_SUCCESS = `${nameSpace}/PUT_COMPANY_REPORT_SUCCESS`;
export const PUT_COMPANY_REPORT_FAILURE = `${nameSpace}/PUT_COMPANY_REPORT_FAILURE`;

export const PUT_COMPANY_REPORTS_REQUEST = `${nameSpace}/PUT_COMPANY_REPORTS_REQUEST`;
export const PUT_COMPANY_REPORTS_SUCCESS = `${nameSpace}/PUT_COMPANY_REPORTS_SUCCESS`;
export const PUT_COMPANY_REPORTS_FAILURE = `${nameSpace}/PUT_COMPANY_REPORTS_FAILURE`;

export const POST_COMPANY_REPORTS_REQUEST = `${nameSpace}/POST_COMPANY_REPORTS_REQUEST`;
export const POST_COMPANY_REPORTS_SUCCESS = `${nameSpace}/POST_COMPANY_REPORTS_SUCCESS`;
export const POST_COMPANY_REPORTS_FAILURE = `${nameSpace}/POST_COMPANY_REPORTS_FAILURE`;

export const GET_COMPANY_REQUEST = `${nameSpace}/GET_COMPANY_REQUEST`;
export const GET_COMPANY_SUCCESS = `${nameSpace}/GET_COMPANY_SUCCESS`;
export const GET_COMPANY_FAILURE = `${nameSpace}/GET_COMPANY_FAILURE`;

export const GET_COMPANY_STATISTICS_REQUEST = `${nameSpace}/GET_COMPANY_STATISTICS_REQUEST`;
export const GET_COMPANY_STATISTICS_SUCCESS = `${nameSpace}/GET_COMPANY_STATISTICS_SUCCESS`;
export const GET_COMPANY_STATISTICS_FAILURE = `${nameSpace}/GET_COMPANY_STATISTICS_FAILURE`;

export const SELECT_COMPANY_LOCATION = `${nameSpace}/SELECT_COMPANY_LOCATION`;

export const SELECT_WORK_HOURS = `${nameSpace}/SELECT_WORK_HOURS`;
export const SET_USER_STATUS_FILTER = `${nameSpace}/SET_USER_STATUS_FILTER`;
