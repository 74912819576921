import { getPage } from './getPage';
import { getPageNumber } from './getPageNumber';

export const getCurrentPage = pageName => state => {
  const pageNumber = getPageNumber(pageName)(state);

  return getPage(pageName)(state, pageNumber);
};

export default getCurrentPage;
