import createReducer from 'redux/createReducer';
import {
  POST_AUTO_APPROVAL_RULES_SUCCESS,
  PUT_AUTO_APPROVAL_RULE_SUCCESS
} from 'ducks/rules/rulesAcitionTypes';
import {
  DELETE_AUTO_APPROVAL_RULE_SUCCESS,
  GET_AUTO_APPROVAL_RULE_SUCCESS,
  GET_AUTO_APPROVAL_RULES_SUCCESS
} from '../rulesAcitionTypes';
import { applyReducerToActions } from 'ducks/lib/applyReducerToActions';

export const setAutoApprovalRules = (state, action) =>
  action && action.payload
    ? action.payload.reduce(
        (rules, rule) => ({
          ...rules,
          [rule.id]: rule
        }),
        {}
      )
    : state;

export const updateAutoApprovalRule = (state, action) => {
  const rule = action && action.payload;
  return {
    ...state,
    ...(rule && { [rule.id]: rule })
  };
};

export const removeAutoApprovalRule = (state, action) => {
  const ruleId = action && action.payload;
  return Object.keys(state).reduce((rules, id) => {
    const rule = state[id];
    return {
      ...rules,
      ...(Number(ruleId) !== Number(id) && { [id]: rule })
    };
  }, {});
};

export const autoApprovalRules = createReducer(
  {},
  {
    ...applyReducerToActions(
      setAutoApprovalRules,
      GET_AUTO_APPROVAL_RULES_SUCCESS
    ),
    ...applyReducerToActions(
      removeAutoApprovalRule,
      DELETE_AUTO_APPROVAL_RULE_SUCCESS
    ),
    ...applyReducerToActions(
      updateAutoApprovalRule,
      PUT_AUTO_APPROVAL_RULE_SUCCESS,
      GET_AUTO_APPROVAL_RULE_SUCCESS,
      POST_AUTO_APPROVAL_RULES_SUCCESS
    )
  }
);

export default autoApprovalRules;
