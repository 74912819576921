import React, { Component } from 'react';
import PageTitleHeader from 'components/CompanyPages/lib/PageTitleHeader';
import CheckBox from 'components/CompanyPages/lib/CheckableField';
import DropDown from 'components/CompanyPages/lib/DropDownSelector';
import TimePicker from 'components/TimePicker';
import Scrollbars from 'components/Scrollbars';
import RenderPage from 'lib/RenderPage';
import SaveButton from 'components/CompanyPages/lib/AddButton';
import './WorkHoursPage.css';

const TimeBox = ({ children, name, id, onChange }) => (
  <div className="work-hours-time-box">
    <TimePicker
      minuteInterval={30}
      value={children}
      name={name}
      id={id}
      onChange={onChange}
    />
  </div>
);

export default class WorkHoursPage extends Component {
  constructor(props) {
    super(props);

    this.state = { highlighted: null };

    this.highlight = this.highlight.bind(this);
    this.selectDay = this.selectDay.bind(this);
    this.setLiveViewUse = this.setLiveViewUse.bind(this);
    this.selectTime = this.selectTime.bind(this);
    this.saveWorkHours = this.saveWorkHours.bind(this);
    this.setWorkHoursUse = this.setWorkHoursUse.bind(this);
    this.selectScopeOfWorkHours = this.selectScopeOfWorkHours.bind(this);
    this.deHighlight = this.deHighlight.bind(this);
  }

  componentWillMount() {
    const { getTeams, getGlobalWorkHours } = this.props;

    getTeams();
    getGlobalWorkHours();
  }

  selectScopeOfWorkHours({ target }) {
    const { selectWorkHours } = this.props;

    selectWorkHours(target.id);
  }

  setWorkHoursUse({ target }) {
    const { setWorkHoursEnabled, updateWorkHours } = this.props;

    setWorkHoursEnabled && setWorkHoursEnabled(target.checked);
    updateWorkHours();
  }

  setLiveViewUse({ target }) {}

  selectDay({ target }) {
    const { setDayEnabled } = this.props;

    setDayEnabled && setDayEnabled(target.id, target.checked);
  }

  selectTime(id, rule, origin) {
    const { setTime } = this.props;

    return time => {
      setTime(id, rule, origin, time);
    };
  }

  saveWorkHours() {
    const { updateWorkHours } = this.props;

    updateWorkHours && updateWorkHours();
  }

  highlight({ target }) {
    this.setState({ highlighted: target.id });
  }

  deHighlight() {
    this.setState({ highlighted: null });
  }

  render() {
    const { highlighted } = this.state;

    const {
      workHours,
      usingWorkHours,
      usingLiveView,
      workHoursSelections,
      requestsState,
      selected
    } = this.props;

    return (
      <div className="work-hours-page">
        <PageTitleHeader>Work Hours</PageTitleHeader>
        <Scrollbars>
          <div className="work-hours-pages">
            <div className="work-hours-left">
              <div className="work-hours-check-box-spacing">
                <CheckBox
                  onClick={this.setWorkHoursUse}
                  className="work-hours-check-box work-hours-check-box-large-title"
                  isChecked={usingWorkHours}
                >
                  Use Work Hours
                </CheckBox>
              </div>
              <h1 className="work-hours-description">
                Enable work hours. Only trips driven within work hours will be
                logged and shared with company admin and managers. Work hours
                can be adjusted per team and auto-adjusted to each user's time
                zone.
              </h1>
              <DropDown
                title={selected.name}
                className="work-hours-category-drop-down"
              >
                {workHoursSelections &&
                  workHoursSelections.map(({ name, id }, index) => (
                    <div
                      key={`work-hours-selection-${index}`}
                      onMouseOver={this.highlight}
                      onMouseOut={this.deHighlight}
                      onClick={this.selectScopeOfWorkHours}
                      id={id}
                      className={`work-hours-category ${
                        highlighted === `${id}`
                          ? 'work-hours-highlighted-category'
                          : 'work-hours-normal-category'
                      }`}
                    >
                      {name}
                    </div>
                  ))}
              </DropDown>
              <RenderPage requestsState={requestsState}>
                <ul
                  className={
                    usingWorkHours
                      ? 'work-hours-list'
                      : 'disabled-work-hours-list'
                  }
                >
                  {workHours &&
                    workHours.map(
                      ({ day, enabled, rules }, workHourIndex) =>
                        rules &&
                        rules.map(({ to, from }, ruleIndex) => (
                          <li
                            key={`work-day-${workHourIndex}`}
                            id={workHourIndex}
                            className="work-hours-element"
                          >
                            <CheckBox
                              className={`work-hours-time-check-box work-hours-check-box${
                                ruleIndex > 0 ? ' hidden' : ''
                              }`}
                              onClick={this.selectDay}
                              isChecked={enabled}
                              id={workHourIndex}
                            >
                              {day}
                            </CheckBox>
                            {enabled && (
                              <div className="work-hours-time-boxes">
                                <TimeBox
                                  onChange={this.selectTime(
                                    workHourIndex,
                                    ruleIndex,
                                    'from'
                                  )}
                                >
                                  {from}
                                </TimeBox>
                                <div className="work-hours-time-separator">
                                  -
                                </div>
                                <TimeBox
                                  onChange={this.selectTime(
                                    workHourIndex,
                                    ruleIndex,
                                    'to'
                                  )}
                                >
                                  {to}
                                </TimeBox>
                              </div>
                            )}
                          </li>
                        ))
                    )}
                </ul>
                <SaveButton
                  className="work-hours-save-button"
                  onClick={this.saveWorkHours}
                >
                  Save work hours
                </SaveButton>
              </RenderPage>
            </div>
            <div className="work-hours-right">
              <div className="work-hours-check-box-spacing">
                <CheckBox
                  onClick={this.setLiveViewUse}
                  isChecked={usingLiveView}
                  className="work-hours-check-box"
                >
                  Use Live View Location Sharing
                </CheckBox>
              </div>
              <h1 className="work-hours-description">
                Enable Location Sharing and user's current location will be
                shared in live view during Work Hours.
              </h1>
            </div>
          </div>
        </Scrollbars>
      </div>
    );
  }
}
