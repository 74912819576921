import { updateCompanyReport } from './updateCompanyReport';
import addSuccessMessage from 'ducks/lib/addSuccessMessage';

export const hideCompanyReport = (id, data) => dispatch =>
  dispatch(
    addSuccessMessage(
      updateCompanyReport,
      `Report successfully ${data && data.hidden ? 'hidden' : 'shown'}`
    )(id, data)
  );

export default hideCompanyReport;
