import { connect } from 'react-redux';
import ReportsPage from './ReportsPage';
import {
  mapStateToReportsPage,
  mapDispatchToReportsPage
} from './reportsPageMappings';
export default connect(
  mapStateToReportsPage,
  mapDispatchToReportsPage
)(ReportsPage);
