import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { postCompanyLocations } from 'api/endpoints/company/locations';
import {
  POST_COMPANY_LOCATIONS_REQUEST,
  POST_COMPANY_LOCATIONS_SUCCESS,
  POST_COMPANY_LOCATIONS_FAILURE
} from 'ducks/company/companyActionTypes';

export const addCompanyLocation = ({ coordinates, ...location }) => dispatch =>
  dispatch(
    createSimpleAction(
      postCompanyLocations,
      [
        POST_COMPANY_LOCATIONS_REQUEST,
        POST_COMPANY_LOCATIONS_SUCCESS,
        POST_COMPANY_LOCATIONS_FAILURE
      ],
      'location'
    )({
      lat: coordinates[0],
      lon: coordinates[1],
      ...location
    })
  );

export default addCompanyLocation;
