import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { deleteCompanyInvitation } from 'api/endpoints/company/invitations';
import {
  DELETE_COMPANY_INVITE_REQUEST,
  DELETE_COMPANY_INVITE_SUCCESS,
  DELETE_COMPANY_INVITE_FAILURE
} from 'ducks/company/companyActionTypes';
import removeUserFromCompanyUsersPage from '../users/removeUserFromCompanyUsersPage';

export const deleteCompanyInvite = id => dispatch =>
  dispatch(
    createSimpleAction(
      deleteCompanyInvitation,
      [
        DELETE_COMPANY_INVITE_REQUEST,
        DELETE_COMPANY_INVITE_SUCCESS,
        DELETE_COMPANY_INVITE_FAILURE
      ],
      'invitationToken'
    )(id)
  ).then(() => {
    dispatch({
      type: DELETE_COMPANY_INVITE_SUCCESS,
      payload: id
    });
    dispatch(removeUserFromCompanyUsersPage(id));
  });

export default deleteCompanyInvite;
