import {
  locationFailure,
  locationRequest,
  locationSuccess
} from 'ducks/locations/locationActions/locationActionCreators';
import { getCurrentUser } from 'ducks/users/selectors';
import { putUser } from 'api/endpoints/user/users';
import formatLocationRequest from 'ducks/locations/lib/formatLocationRequest';
import { invalidate } from 'ducks/trips/actions';
export const createRequest = (id, action) => ({ id, ['_' + action]: true });
export const removeLocationById = (id, locations) =>
  locations.filter(location => location.id !== id);

export default (action, [success, request, failure], method = action) => {
  const onSuccess = locationSuccess(success, action);
  const onRequest = locationRequest(request, action);
  const onFailure = locationFailure(failure, action);

  return locationId => (dispatch, getState) => {
    const { id, locations } = getCurrentUser(getState());
    const locationsWithoutLocation = removeLocationById(locationId, locations);
    const locationsWithRequest = [
      createRequest(locationId, method),
      ...locationsWithoutLocation
    ];
    const request = formatLocationRequest(id, locationsWithRequest);

    dispatch(onRequest(locationId));

    return putUser(id, request.user)
      .then(({ data }) => dispatch(onSuccess(data.user)))
      .catch(error => dispatch(onFailure(error, locationId)));
  };
};
