import trips from '../../ducks/trips';
export const mapDispatchToProps = dispatch => ({
  onSubmit: ({ tripId, fromDate, toDate, ...data }) =>
    dispatch(
      trips.actions.copyTrip({
        id: tripId,
        fromDate,
        toDate: toDate || fromDate,
        ...data
      })
    )
});
