import { getUserStatusFilter } from 'ducks/company/companySelectors/getUserStatusFilter';
import getCompanyMembers from './getCompanyMembers';
import { getCompanyInvites } from '../getCompanyInvites';

export const getCompanyMembersAndInvites = state => {
  let users;

  const status = getUserStatusFilter(state);
  const members = (getCompanyMembers(state) || []).map(member => ({
    status: 'active',
    ...member
  }));
  const invites = (getCompanyInvites(state) || []).map(invite => ({
    status: invite.cancelled ? 'cancelled' : 'invited',
    ...invite
  }));

  if (status) {
    users = status === 'active' ? members : invites;
  } else {
    users = [...members, ...invites];
  }

  return users;
};

export default getCompanyMembersAndInvites;
