import { formatCurrency } from 'localization';
export default ({
  countryCode,
  metric,
  value,
  children,
  round,
  rate
}) => {
  return formatCurrency(Number(value || children), {
    countryCode,
    metric,
    rate,
    round
  });
};
