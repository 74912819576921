import { confirmAccountDeletion } from 'ducks/users/userActions/confirmAccountDeletion';
import { formValueSelector } from 'redux-form';

const select = formValueSelector('delete-account-page');
const getEmail = state => select(state, 'email');
const getPassword = state => select(state, 'password');

export const mapStateToDeleteAccountPage = state => ({
  email: getEmail(state),
  password: getPassword(state)
});

export const mapDispatchToDeleteAccountPage = { confirmAccountDeletion };
