import { connect } from 'react-redux';
import CompanyReport from './CompanyReport';
import {
  mapStateToCompanyReport,
  mapDispatchToCompanyReport
} from './companyReportMappings';

export default connect(
  mapStateToCompanyReport,
  mapDispatchToCompanyReport
)(CompanyReport);
