export const createFilter = (
  values,
  title,
  icon,
  multiSelect = false,
  key = 'name',
  defaultValue
) => {
  const valuesWithTitle = title ? [{ [key]: title }, ...values] : values;

  return {
    titles: valuesWithTitle.map(({ [key]: title }) => title),
    values: valuesWithTitle,
    multiSelect,
    defaultValue,
    icon
  };
};

export default createFilter;
