import mapOverObject from 'lib/mapOverObject';

export const paginateSelector = selector => selectorName => (
  state,
  ...args
) => {
  const result = selector(state, ...args);

  return result && result[selectorName];
};

export const paginateSelectors = selectors =>
  mapOverObject(selectors, selector => paginateSelector(selector));

export default paginateSelectors;
