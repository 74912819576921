import { createRequestsReducer } from 'redux/requestReducer';
import {
  GET_COMPANY_MEMBERS_FAILURE,
  GET_COMPANY_MEMBERS_REQUEST,
  GET_COMPANY_MEMBERS_SUCCESS,
  GET_COMPANY_STATISTICS_FAILURE,
  GET_COMPANY_STATISTICS_REQUEST,
  GET_COMPANY_STATISTICS_SUCCESS,
  GET_COMPANY_PURPOSES_FAILURE,
  GET_COMPANY_PURPOSES_REQUEST,
  GET_COMPANY_PURPOSES_SUCCESS
} from 'ducks/company/companyActionTypes';
import {
  GET_TEAMS_FAILURE,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS
} from 'ducks/teams/teamActionTypes';

export default createRequestsReducer([
  [
    GET_COMPANY_STATISTICS_REQUEST,
    GET_COMPANY_STATISTICS_SUCCESS,
    GET_COMPANY_STATISTICS_FAILURE
  ],
  [
    GET_COMPANY_MEMBERS_REQUEST,
    GET_COMPANY_MEMBERS_SUCCESS,
    GET_COMPANY_MEMBERS_FAILURE
  ],
  [
    GET_COMPANY_PURPOSES_REQUEST,
    GET_COMPANY_PURPOSES_SUCCESS,
    GET_COMPANY_PURPOSES_FAILURE
  ],
  [GET_TEAMS_REQUEST, GET_TEAMS_SUCCESS, GET_TEAMS_FAILURE]
]);
