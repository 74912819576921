import React, { Component } from 'react';
import classNames from 'class-names';

export default class RateField extends Component {
  constructor(props) {
    super(props);

    this.state = { value: props.defaultValue };
    this.onChange = this.onChange.bind(this);
  }

  onChange({ target }) {
    const { normalize, onChange } = this.props;
    const initialValue = target.value;
    const value = normalize ? normalize(`${initialValue}`) : initialValue;

    this.setState({ value });

    onChange && onChange(value);
  }

  render() {
    const { onChange: _, ...props } = this.props;
    const { className, distanceMeasure, symbol } = props;

    return (
      <div className={classNames(className, 'input-group')}>
        <div className="input-group__prefix">{symbol}</div>
        <input
          className="input-group__input"
          value={this.state.value}
          onChange={this.onChange}
          {...props}
        />
        <div className="input-group__suffix">/{distanceMeasure}</div>
      </div>
    );
  }
}
