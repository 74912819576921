import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'class-names';
import FontAwesome from 'react-fontawesome';

const SideMenuForm = ({ className, ...props }) => (
  <form className={classNames('side-menu__form', className)} {...props} />
);

const SideMenuFormCtrl = ({ className, children, label, ...props }) => (
  <div className={classNames('side-menu__form-ctrl', className)} {...props}>
    {label && <label className="side-menu__form-ctrl__label">{label}</label>}
    {children}
  </div>
);

const SideMenu = ({ className, selected, onClick, left, items, children }) => (
  <div
    className={classNames('side-menu', className, { 'side-menu_left': left })}
  >
    {items.map(item => (
      <div
        key={item.id}
        onClick={() => (item.onClick ? item.onClick() : onClick(item.id))}
        className={classNames('side-menu__item', {
          'side-menu__item_selected': item.id !== null && item.id === selected
        })}
      >
        {item.icon && (
          <FontAwesome
            className="side-menu__item__icon"
            name={item.icon}
            fixedWidth
          />
        )}
        {item.label}
      </div>
    ))}

    {children}
  </div>
);

SideMenu.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.any,
  onClick: PropTypes.func,
  left: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  children: PropTypes.node
};

SideMenu.defaultProps = {
  onClick: () => null,
  items: []
};

SideMenu.Form = SideMenuForm;
SideMenu.FormCtrl = SideMenuFormCtrl;

export default SideMenu;
