import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserSwitcher from './UserSwitcher';
import users from 'ducks/users';

const mapStateToProps = state => ({
  activeUser: users.selectors.getActive(state),
  users: [
    users.selectors.getCurrentUser(state),
    ...users.selectors.getAdministratedUsers(state)
  ]
});

const mapDispatchToProps = dispatch => ({
  switchUser: bindActionCreators(users.actions.switchUser, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSwitcher);
