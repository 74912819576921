import { postCompanySignUp } from 'api/endpoints/company/company';
import { getCountryCodeById } from 'ducks/countries/selectors';
import {
  COMPANY_SIGN_UP_FAILURE,
  COMPANY_SIGN_UP_REQUEST,
  COMPANY_SIGN_UP_SUCCESS,
  COMPANY_SIGN_UP_STATUS
} from '../companyActionTypes';

export const companySignUpRequest = payload => ({
  type: COMPANY_SIGN_UP_REQUEST,
  payload
});

export const companySignUpSuccess = payload => ({
  type: COMPANY_SIGN_UP_SUCCESS,
  payload
});

export const companySignUpFailure = error => ({
  type: COMPANY_SIGN_UP_FAILURE,
  error
});

export const setCompanySignUpStatus = payload => ({
  type: COMPANY_SIGN_UP_STATUS,
  payload
});

export const formatSignUpInfo = (
  state,
  {
    firstName,
    lastName,
    companyName,
    countryId,
    email,
    password,
    passwordConfirmation
  }
) => ({
  password,
  passwordConfirmation,
  email,
  adminName: `${firstName} ${lastName}`,
  name: companyName,
  countryCode: getCountryCodeById(state, countryId)
});

export default signUpInfo => (dispatch, getState) => {
  const state = getState();
  dispatch(companySignUpRequest());
  return postCompanySignUp(formatSignUpInfo(state, signUpInfo))
    .then(({ data }) => {
      const company = data && data.company;
      dispatch(setCompanySignUpStatus(true));
      return dispatch(companySignUpSuccess(company));
    })
    .catch(error => dispatch(companySignUpFailure(error)));
};
