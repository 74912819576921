import { getCompanyMembers } from './getCompanyMembers';

export const getCompanyUsersObject = state => {
  const users = getCompanyMembers(state);
  return (
    users &&
    users.reduce((mapped, user) => {
      mapped[user.id] = user;
      return mapped;
    }, {})
  );
};
