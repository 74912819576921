import React, { Component } from 'react';
import LoadingPage from 'components/LoadingPage';
import './ConfirmOrConfirmed.css';
import { getUserId } from 'lib/auth';

export default class ConditionalRedirect extends Component {
  constructor(props) {
    super(props);

    this.getUuid = this.getUuid.bind(this);
  }

  componentDidMount() {
    const uuid = this.getUuid();
    const { confirmInvitation, history, getCurrentUser } = this.props;

    confirmInvitation &&
      uuid !== 'invitation' &&
      confirmInvitation(uuid).then(() => {
        const userId = getUserId(); // hack
        history.push(`/invitation/${userId ? 'confirmed' : 'confirm'}/${uuid}`);
      });
  }

  getUuid() {
    const splitUrl = window.location.href.split('/');

    return splitUrl[splitUrl.length - 1];
  }

  render() {
    return (
      <div className="conditional-redirect-load-wheel">
        <LoadingPage />
      </div>
    );
  }
}
