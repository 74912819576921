import { connect } from 'react-redux';
import _ from 'lodash';
import Raven from 'raven-js';
import users from 'ducks/users';

const mapStateToProps = state => ({
  user: users.selectors.getCurrentUser(state)
});

const RavenUserContext = ({ user, children }) => {
  if (user) {
    Raven.setUserContext({
      id: _.get(user, 'id'),
      email: _.get(user, 'email')
    });
  } else {
    Raven.setUserContext();
  }

  return children;
};

export default connect(mapStateToProps)(RavenUserContext);
