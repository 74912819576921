import { getCurrentUser } from 'ducks/users/selectors';
import { teamsExist } from 'ducks/teams/teamsSelectors/teamsExist';
import { userHasEditedWorkHours } from 'ducks/workHours/workHourSelectors/userHasEditedWorkHours';
import { getGlobalWorkHours } from 'ducks/workHours/workHourActions/getGlobalWorkHours';
import { getTeams } from 'ducks/teams/teamsActions/getTeams';
import getCurrentUsersDevice from 'ducks/users/usersSelectors/getCurrentUsersDevice';

export const mapStateToGettingStartedPage = state => {
  const user = getCurrentUser(state);
  const name = user && user.name;

  return {
    title: name ? `Well Done, ${name}!` : 'Well Done!',
    description: `Let's set up your account while waiting for your company members to join.`,
    steps: [
      {
        title: 'Create Company Account',
        description:
          'Congrats! the initial invites were sent out! follow the next steps to completed your company account setup.',
        action: 'Invite Users',
        completed: true,
        url: '/admin/settings/users'
      },
      {
        title: 'Download MileCatcher app on your phone',
        description:
          'Log in with your email and password to start your personal automatic trip log on your phone. MileCatcher is available for Iphone and Android.',
        action: 'Download App',
        completed: !!getCurrentUsersDevice(state),
        url: '/download_app'
      },
      {
        title: 'Create Teams and managers',
        description:
          'Create your company teams (departments) and assign their corresponding managers/approvers. Go to the Settings icon at the at the top right corner and then select Teams.',
        action: 'Create Teams',
        completed: teamsExist(state),
        url: '/admin/settings/teams'
      },
      {
        title: 'Review your company work hours',
        description: `Work hours define when users' trips can be tracked. The default work hours are set from 8AM to 5PM`,
        action: 'Set Work Hours',
        completed: userHasEditedWorkHours(state),
        url: '/admin/settings/work_hours'
      }
      // {
      //   title: 'Review Submission and auto-approval rules',
      //   description:
      //     'By default all business expenses are submitted for approval every Saturday midnight. if such expenses are lower then $50, they will be auto approved.',
      //   action: 'Set Rules',
      //   completed: false,
      //   url: '/admin/settings/rules'
      // }
    ]
  };
};
export const mapDispatchToGettingStartedPage = {
  getTeams,
  getGlobalWorkHours
};
