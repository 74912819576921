import {
  COMPANY_SIGN_UP_SUCCESS,
  GET_COMPANY_SUCCESS
} from '../companyActionTypes';
import createReducer from 'redux/createReducer';
import { COMPANY_SIGN_IN_SUCCESS } from '../../session/actionTypes';
export const setCompany = (state, company) => {
  const payload = company && company.payload;
  return payload || state;
};
export default createReducer(null, {
  ...[COMPANY_SIGN_UP_SUCCESS, COMPANY_SIGN_IN_SUCCESS].reduce(
    (actions, action) => {
      actions[action] = setCompany;
      return actions;
    },
    {}
  )
});
