import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { trackPage } from 'analytics';
import Panel from 'components/Panel';
import ContentWrap from 'components/ContentWrap';
import TripFiltersContainer from 'components/TripFiltersContainer';
import Statistics from './Statistics';
import './StatisticsPage.css';
const StatisticsPage = () => (
  <ContentWrap>
    <Panel sidebar>
      <TripFiltersContainer />
      <div className={'statistics-list'}>
        <Scrollbars>
          <Statistics />
        </Scrollbars>
      </div>
    </Panel>
  </ContentWrap>
);
export default trackPage('statistics')(StatisticsPage);
