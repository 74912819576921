import { postCreateTeam } from 'api/endpoints/company/teams';
import { getCompanyId } from 'lib/auth';
import {
  CREATE_TEAM_FAILURE,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS
} from '../teamActionTypes';

export const createTeamSuccess = payload => ({
  type: CREATE_TEAM_SUCCESS,
  payload,
  meta: {
    alert: {
      type: 'success',
      message: `Team${
        payload && payload.name ? ` ${payload.name}` : ''
      } created`
    }
  }
});

export const createTeamFailure = payload => ({
  type: CREATE_TEAM_FAILURE,
  payload,
  meta: {
    alert: {
      type: 'failure',
      message: `Unable to create team${
        payload && payload.name ? ` ${payload.name}` : ''
      }`
    }
  }
});

export const createTeamRequest = payload => ({
  type: CREATE_TEAM_REQUEST,
  payload
});

export const createTeam = (name, members = [], managers = []) => dispatch => {
  const companyId = getCompanyId();
  const team = {
    name,
    companyId,
    members,
    managers
  };
  dispatch(createTeamRequest(team));
  return postCreateTeam(team)
    .then(({ data }) => dispatch(createTeamSuccess(data.team)))
    .catch(error => dispatch(createTeamFailure(error)));
};

export default createTeam;
