import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import trips from 'ducks/trips';
import Money from 'components/Money';
import Value from 'components/Value';
import './ReportInfo.css';

const mapStateToProps = state => ({
  total: trips.selectors.getTotalItems(state),
  value: trips.selectors.getTotalExpense(state)
});

const ReportInfo = ({ total, value }) =>
  <div className="report-info">
    <span className="report-info__trip-count">
      {numeral(total).format('0,0')} trips worth
    </span>

    <Value type={value > 0 ? 'Business' : 'Unclassified'} big>
      <Money value={value} />
    </Value>
  </div>;

export default connect(mapStateToProps)(ReportInfo);
