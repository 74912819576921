import {
  SELECT_LOCATION,
  SELECT_COMPANY_LOCATION,
  DELETE_COMPANY_LOCATION_SUCCESS,
  DELETE_LOCATION_SUCCESS
} from 'ducks/locations/locationActionTypes';
import createReducer from 'redux/createReducer';

export const selectLocation = (state, { payload }) =>
  payload ? payload : state;

export default createReducer(null, {
  ...[SELECT_LOCATION, SELECT_COMPANY_LOCATION].reduce((all, action) => {
    all[action] = selectLocation;
    return all;
  }, {}),
  ...[DELETE_LOCATION_SUCCESS, DELETE_COMPANY_LOCATION_SUCCESS].reduce(
    (all, action) => {
      all[action] = () => null;
      return all;
    },
    {}
  )
});
