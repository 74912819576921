import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import './AccountManagement.css';
import LoadingPage from 'components/LoadingPage';

const propTypes = {
  subscription: PropTypes.shape({
    gateway: PropTypes.oneOf(['play_store', 'app_store', 'chargebee'])
    // 'playstore' and 'appstore' do not exist. gateway is either 'chargebee' or nil
      .isRequired,
  })
};

const AccountManagementInfo = ({ subscription }) => {
  if (subscription) {
    const { gateway } = subscription;

    return (
      <div className="account-management">
        {/* <div className="account-management-title panel__title">
          Account Management
        </div> */}
        {gateway && gateway == 'chargebee' ? (
          <div>
            <Link className="delete-account-link" to="/delete">
              Delete Account
            </Link>
          </div>
        ): gateway && gateway == 'app_store' ? (
          <div>
            <Link className="delete-account-link" to="/delete">
            Delete Account
            </Link>
          </div>
        ): gateway && gateway == 'play_store' ? (
          <div>
            <Link className="delete-account-link" to="/delete">
            Delete Account
            </Link>
          </div>
        ): !gateway ? (
          <div>
            <Link className="delete-account-link" to="/delete">
            Delete Account
            </Link>
          </div>
        ):
        null
        }
    </div>
    );
  } else {
    return <LoadingPage />;
  }
}
          

AccountManagementInfo.propsTypes = propTypes;

export default withRouter(AccountManagementInfo);
