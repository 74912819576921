import React, { Component } from 'react';
import AddButton from 'components/CompanyPages/lib/AddButton';
import '../AddButton/Button.css';
import './GetInputButton.css';

export default class GetImageButton extends Component {
  constructor(props) {
    super(props);

    this.getImage = this.getImage.bind(this);
  }

  getImage({ target }) {
    const { onChange } = this.props;
    const files = target.files;
    const file = files[0];
    const image = URL.createObjectURL(file);

    onChange && onChange({ image, file, files });
  }

  render() {
    // this is a hack to make up for the lack of styling support for
    // input='file' in modern browsers
    const { buttonStyling, className, children } = this.props;
    return (
      <div className={`file-input-button ${className}`}>
        <AddButton className={'styled-input-button ' + buttonStyling}>
          {children}
        </AddButton>
        <input
          className={'image-input add-button ' + buttonStyling}
          type="file"
          name="background-image"
          onChange={this.getImage}
        />
      </div>
    );
  }
}
