import React from 'react';
import './TextBubble.css';

export default ({ className, message }) => (
  <div className="info-text-container">
    <div className="info-text-outline">
      <p className="info-text">{message}</p>
    </div>
    <div className={`${className ? `${className} ` : ''} info-text-bubble`}>
      <p className="info-text">{message}</p>
    </div>
  </div>
);
