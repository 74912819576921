import React from 'react';
import { Link } from 'react-router-dom';
import PreLoginBox, { PreloginBoxFoot } from 'components/PreLogin/PreLoginBox';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPasswordPage = () => (
  <div className="forgot-password-page">
    <PreLoginBox>
      <ForgotPasswordForm />
    </PreLoginBox>

    <PreloginBoxFoot>
      Alternatively, <Link to="/sign_in">sign in</Link> or{' '}
      <Link to="/sign_up">sign up</Link>.
    </PreloginBoxFoot>
  </div>
);

export default ForgotPasswordPage;
