import { confirmInvitation } from 'ducks/company/companyActions/invitations/confirmInvitation';
import { getCompany as loadCompany } from 'ducks/company/companyActions/getCompany';
import getCompany from 'ducks/company/companySelectors/company/getCompany';

export const mapStateToInvitationConfirmed = state => ({
  company: getCompany(state)
});

export const mapDispatchToInvitationConfirmed = {
  confirmInvitation,
  loadCompany
};
