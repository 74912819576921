import React, { Component } from 'react';
import MultiSelectDropDown from 'components/CompanyPages/lib/MultiSelectDropDown';
import SingleSelectDropDown from 'components/CompanyPages/lib/DropDownSelector';
import CheckBox from 'components/Checkbox';
import './Filter.css';

export default class Filter extends Component {
  constructor(props) {
    const { title, initial, defaultValue, children } = props;

    super(props);

    this.state = {
      selected:
        defaultValue && defaultValue === true
          ? Object.keys(children).reduce(
              (values, value) =>
                Number(value) > 0
                  ? {
                      ...values,
                      [value]: true
                    }
                  : values,
              {}
            )
          : {},
      title: initial || title
    };

    this.onMultiSelection = this.onMultiSelection.bind(this);
    this.onSingleSelection = this.onSingleSelection.bind(this);
    this.setSelected = this.setSelected.bind(this);
  }

  setSelected(selected) {
    this.setState({ selected });
  }

  onMultiSelection(selected) {
    const { onChange, title, children, initial } = this.props;
    const values = selected && selected.map(index => children[index]);
    const titleToDisplay =
      values && values.length > 1 ? title || initial : values[0];

    this.setState({ title: titleToDisplay });

    onChange && onChange(selected);
  }

  onSingleSelection({ target }) {
    const { onChange, children } = this.props;
    const index = target.id;
    const selected = children && children[index];

    this.setState({ title: selected });

    onChange && onChange(selected, index);
  }

  render() {
    const { multiSelect, children, initial, icon } = this.props;
    const { title, selected } = this.state;
    const DropDown = multiSelect ? MultiSelectDropDown : SingleSelectDropDown;
    const initialValue = initial || children[0];

    return (
      <DropDown
        icon={icon}
        defaultValue={selected}
        title={title || initialValue}
        onChange={this.onMultiSelection}
        getSelected={this.setSelected}
        className="filter-drop-down"
      >
        {children
          .map(
            (value, index) =>
              (multiSelect
                ? (value && value.toLowerCase()) !==
                  (initialValue && initialValue.toLowerCase())
                : (value && value.toLowerCase()) !==
                  (title && title.toLowerCase())) && (
                <div
                  key={`${title}-${index}`}
                  id={index}
                  onClick={this.onSingleSelection}
                  className="filter-element"
                >
                  {multiSelect && (
                    <CheckBox
                      id={index}
                      checked={selected[index]}
                      className="filter-selection-check-box"
                      dark
                    />
                  )}
                  <h1
                    id={index}
                    className={
                      multiSelect
                        ? 'filter-multi-selection-text'
                        : 'filter-selection-text'
                    }
                  >
                    {value}
                  </h1>
                </div>
              )
          )
          .filter(value => value)}
      </DropDown>
    );
  }
}
