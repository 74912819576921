import _ from 'lodash';
import { postChargebeeSessions } from 'api/endpoints/chargebee';

const getPortalUrl = redirectUrl =>
  postChargebeeSessions(redirectUrl).then(response =>
    _.get(response, 'data.portalSession.accessUrl')
  );

export const redirectTo = url => {
  if (url) {
    window.location = url;
  }
  return url;
};

export const redirectToChargebeePortal = () =>
  getPortalUrl(window.location.href).then(redirectTo);
