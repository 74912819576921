import selectLocation from 'ducks/locations/locationActions/selectLocation';
import submitLocation from 'ducks/locations/locationActions/submitLocation';
import addLocation from 'ducks/locations/locationActions/addLocation';
import description from 'components/MyLocations/lib/myLocationsDescription';

import {
  getUsersLocations,
  getSelectedLocationId,
  getUsersLocationCoordinates,
  getSelectedLocationCoordinates,
  getSelectedLocation
} from 'ducks/users/selectors';

export const mapStateToMyLocations = state => ({
  locations: getUsersLocations(state),
  selectedId: getSelectedLocationId(state),
  selectedLocation: getSelectedLocation(state),
  selectedCoordinate: getSelectedLocationCoordinates(state),
  coordinates: getUsersLocationCoordinates(state),
  description
});

export const mapDispatchToMyLocations = {
  selectLocation,
  submitLocation,
  addLocation
};
