import React from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import PreloginBox, {
  PreloginBoxTitle,
  PreloginBoxFoot
} from 'components/PreLogin/PreLoginBox';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPasswordPage = ({ match: { params }, location: { search } }) => (
  <div className="reset-password-page">
    <PreloginBox>
      <PreloginBoxTitle>Reset password</PreloginBoxTitle>
      <ResetPasswordForm
        token={params.token}
        legacy={queryString.parse(search).legacy === 'true'}
      />
    </PreloginBox>

    <PreloginBoxFoot>
      Alternatively, <Link to="/sign_in">sign in</Link> or{' '}
      <Link to="/sign_up">sign up</Link>.
    </PreloginBoxFoot>
  </div>
);

export default ResetPasswordPage;
