import { connect } from 'react-redux';
import users from 'ducks/users';
import Money from './Money';

const mapStateToProps = (state, { countryCode }) => {
  const { getCurrentUser, getActive } = users.selectors;
  const user = getCurrentUser(state);
  const active = getActive(state);
  return {
    metric: user && user.settings.metric,
    countryCode: countryCode || (active && active.countryCode)
  };
};

export default connect(mapStateToProps)(Money);
