import React, { Component } from 'react';
import moment from 'moment';
import { formatVehicle } from 'lib/formatters';
import Distance from 'components/Distance';
import Money from 'components/Money';
import Value from 'components/Value';

import './CompactTrip.css';

export default class CompactTrip extends Component {
  render() {
    const { trip, purpose, vehicle } = this.props;
    return (
      <div className="compact-trip">
        <div className="compact-trip__inner">
          <div className="compact-trip__times">
            <div className="compact-trip__time">
              {moment(trip.startTime).format('LT')}
            </div>

            <span className="compact-trip__time">
              {moment(trip.endTime).format('LT')}
            </span>
          </div>

          <div className="compact-trip__addresses">
            <div
              className="compact-trip__address"
              title={trip.departureAddress}
            >
              {trip.departureAddress}
            </div>

            <div
              className="compact-trip__address"
              title={trip.destinationAddress}
            >
              {trip.destinationAddress}
            </div>
          </div>

          <div className="compact-trip__vehicle-notes">
            <div className="compact-trip__vehicle">
              {formatVehicle(vehicle)}
            </div>
            <div className="compact-trip__notes">{trip.notes}</div>
          </div>

          <div className="compact-trip__tolls-parking">
            <div className="compact-trip__tolls">
              tolls: <Money value={trip.tollExpense} />
            </div>

            <div className="compact-trip__parking">
              parking: <Money value={trip.parkingExpense} />
            </div>
          </div>

          <div className="compact-trip__distance">
            <Distance value={trip.distance} />
          </div>

          <div className="compact-trip__price">
            <Value className="compact-trip__price" purposeId={trip.purposeId}>
              <Money value={trip.expense} />
            </Value>
          </div>
          <div className="compact-trip__purpose">
            {purpose ? purpose.name : 'Unclassified'}
          </div>
        </div>
      </div>
    );
  }
}
