import { companyApi } from '../../client';

export const postCreateTeam = team => companyApi.post(`/teams`, { team });

export const putUpdateTeam = (id, team) =>
  companyApi.put(`/teams/${id}`, { team });

export const getCompanyTeams = () => companyApi.get(`/teams`);

export const deleteCompanyTeam = teamId =>
  companyApi.delete(`/teams/${teamId}`);

export const getWorkHoursForTeam = id =>
  companyApi.get(`/teams/${id}/work_hours`);

export const putUpdateWorkHoursForTeam = (id, workHours) =>
  companyApi.put(`/teams/${id}/work_hours`, { workHours });

export const deleteUserFromTeam = (teamId, userId) =>
  companyApi.delete(`/teams/${teamId}/users/${userId}`);

export const getAdministeredUsers = (params = {}) =>
  companyApi.get(`/users`, { params });
