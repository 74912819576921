const nameSpace = 'teamsStore';

export const CREATE_TEAM_REQUEST = `${nameSpace}/CREATE_TEAM_REQUEST`;
export const CREATE_TEAM_SUCCESS = `${nameSpace}/CREATE_TEAM_SUCCESS`;
export const CREATE_TEAM_FAILURE = `${nameSpace}/CREATE_TEAM_FAILURE`;

export const UPDATE_TEAM_REQUEST = `${nameSpace}/UPDATE_TEAM_REQUEST`;
export const UPDATE_TEAM_SUCCESS = `${nameSpace}/UPDATE_TEAM_SUCCESS`;
export const UPDATE_TEAM_FAILURE = `${nameSpace}/UPDATE_TEAM_FAILURE`;

export const GET_TEAMS_REQUEST = `${nameSpace}/GET_TEAMS_REQUEST`;
export const GET_TEAMS_SUCCESS = `${nameSpace}/GET_TEAMS_SUCCESS`;
export const GET_TEAMS_FAILURE = `${nameSpace}/GET_TEAMS_FAILURE`;

export const GET_MEMBERS_REQUEST = `${nameSpace}/GET_MEMBERS_REQUEST`;
export const GET_MEMBERS_SUCCESS = `${nameSpace}/GET_MEMBERS_SUCCESS`;
export const GET_MEMBERS_FAILURE = `${nameSpace}/GET_MEMBERS_FAILURE`;

export const DELETE_USER_FROM_TEAM_REQUEST = `${nameSpace}/DELETE_USER_FROM_TEAM_REQUEST`;
export const DELETE_USER_FROM_TEAM_SUCCESS = `${nameSpace}/DELETE_USER_FROM_TEAM_SUCCESS`;
export const DELETE_USER_FROM_TEAM_FAILURE = `${nameSpace}/DELETE_USER_FROM_TEAM_FAILURE`;

export const DELETE_TEAM_REQUEST = `${nameSpace}/DELETE_TEAM_REQUEST`;
export const DELETE_TEAM_SUCCESS = `${nameSpace}/DELETE_TEAM_SUCCESS`;
export const DELETE_TEAM_FAILURE = `${nameSpace}/DELETE_TEAM_FAILURE`;

export const ADD_MEMBER_TO_TEAM = `${nameSpace}/ADD_MEMBER_TO_TEAM`;
