import { createRequestReducer } from 'redux/requestReducer';

import {
  GET_TEAM_WORK_HOURS_FAILURE,
  GET_TEAM_WORK_HOURS_REQUEST,
  GET_TEAM_WORK_HOURS_SUCCESS
} from 'ducks/workHours/actionTypes';

export default createRequestReducer([
  [
    GET_TEAM_WORK_HOURS_REQUEST,
    GET_TEAM_WORK_HOURS_SUCCESS,
    GET_TEAM_WORK_HOURS_FAILURE
  ]
]);
