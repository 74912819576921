import { zipWith, mapValues } from 'lodash';
import createReducer from 'redux/createReducer';
import { createRequestObject } from './requestReducer';
import { requestState } from '../lib/requests';

export const getPayload = action => (action && action.payload) || {};
export const reduceId = (state, action, id, reduce) =>
  id && { [id]: reduce(state[id], action) };
export const reduceIds = (state, action, ids, reduce) =>
  ids &&
  ids.reduce &&
  ids.reduce(
    (results, id) => ({ ...results, [id]: reduce(state[id], action) }),
    {}
  );

export const reduceById = func => (state, action) => {
  const { id, ids } = getPayload(action);
  const applyReducer = (value, reduce) => reduce(state, action, value, func);
  const [reducedId, reducedIds] = zipWith(
    [id, ids],
    [reduceId, reduceIds],
    applyReducer
  );
  return { ...state, ...reducedId, ...reducedIds };
};

export const createRequestsByIdReducer = actions =>
  createReducer(
    {},
    actions.reduce(
      (requests, action) => ({
        ...requests,
        ...mapValues(createRequestObject(action), reduceById)
      }),
      {}
    )
  );

export const createRequestSelectors = getRequests => ({
  getRequestStateById: (state, id) => requestState(getRequests(state)[id]),
  getRequestById: (state, id) => getRequests(state)[id]
});
