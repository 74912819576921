import React from 'react';
import selectionActions from 'ducks/listSelection/selectionActions';
import { setNameForPaginatedStores } from 'ducks/lib/Pagination/setNameForPaginatedStores';
import { mapOverObject } from 'lib/mapOverObject';

export default WrappedComponent => ({ pageName, dispatch, ...args }) => (
  <WrappedComponent
    {...args}
    {...mapOverObject(
      setNameForPaginatedStores(pageName, selectionActions),
      action => (...args) => dispatch(action(...args))
    )}
  />
);
