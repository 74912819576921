import React, { Component } from 'react';
import TextBox from 'components/TextBox';
import './Notes.css';
export default class Notes extends Component {
  constructor(props) {
    const { getNotes, value } = props;
    super(props);
    this.state = { value, notes: getNotes(), showingSuggestions: false };
    this.dropDown = null;
    this.hideSuggestions = this.hideSuggestions.bind(this);
    this.showSuggestions = this.showSuggestions.bind(this);
    this.selectSuggestion = this.selectSuggestion.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.filterNotes = this.filterNotes.bind(this);
    this.setDropDownReference = this.setDropDownReference.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }
  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, true);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, true);
  }
  handleOutsideClick(event) {
    (!this.dropDown || !this.dropDown.contains(event.target)) &&
      this.hideSuggestions();
  }
  setDropDownReference(element) {
    this.dropDown = element;
  }
  showSuggestions() {
    const { notes } = this.state;
    notes && notes.length && this.setState({ showingSuggestions: true });
  }
  hideSuggestions() {
    this.setState({ showingSuggestions: false });
  }
  selectSuggestion(event) {
    const { onChange } = this.props;
    this.hideSuggestions();
    onChange && onChange(event);
  }
  filterNotes(notes, input) {
    return input && input.length
      ? notes.filter(
          note =>
            note.includes(input) && input.toLowerCase() !== note.toLowerCase()
        )
      : notes;
  }
  handleInput(event) {
    const input = event.target.value;
    const { getNotes, onChange } = this.props;
    onChange && onChange(event);
    this.setState({ notes: this.filterNotes(getNotes(), input) });
  }
  render() {
    const { value, tabIndex, getNotes } = this.props;
    const { showingSuggestions } = this.state;
    const suggestions = this.filterNotes(getNotes(), value);
    return (
      <div>
        <TextBox
          key={'notes-input'}
          name="notes"
          placeholder="Add a note"
          onChange={this.handleInput}
          onClick={this.showSuggestions}
          value={value}
          tabIndex={tabIndex}
        />
        {showingSuggestions &&
          suggestions &&
          suggestions.length > 0 && (
            <div
              key={'note-suggestions'}
              className="note-suggestions"
              ref={this.setDropDownReference}
            >
              {suggestions.map((note, index) => (
                <button
                  name="notes"
                  key={`note-suggestion-${index}`}
                  className="note-suggestion"
                  onClick={this.selectSuggestion}
                  value={note}
                >
                  {note}
                </button>
              ))}
            </div>
          )}
      </div>
    );
  }
}
