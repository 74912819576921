import { companyUsersSortedByEmail } from './companyUsersSortedByEmail';
import { roleValues } from 'components/CompanyPages/lib/roles';

export const getCompanyManagers = state => {
  const users = companyUsersSortedByEmail(state);

  return (
    users &&
    users.filter(({ role }) => {
      return roleValues[role] >= roleValues.manager;
    })
  );
};

export default getCompanyManagers;
