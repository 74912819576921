import _ from 'lodash';
import * as constants from './constants';
import users from 'ducks/users';
import { createSelector } from 'reselect';
import { createItemsSelectors } from 'redux/itemsReducer';
import { locales } from 'locales';
import { getDecimalMarkByCountryCode } from 'lib/localization/numericLocalization';
import moment from 'moment/moment';
import { LOADED } from '../../lib/requests';
import requestState from 'lib/requests';
const getState = state => state[constants.NAME];

export const getRequest = state => getState(state).request;
export const countriesLoaded = state =>
  requestState(getRequest(state)) === LOADED;
export const getFetching = (state, id) =>
  getState(state).itemRequests[id] && getState(state).itemRequests[id].fetching;

export const getAllCountries = state => getState(state).items || {};
export const { getById } = createItemsSelectors(getAllCountries);
export const getByIdFromState = (state, id) => getState(state).items[id];
export const getAllAlphabetical = createSelector(getAllCountries, countries =>
  _.sortBy(_.values(countries), c => c.fullName)
);
export const getCountryCodeById = (state, id) => {
  const country = getByIdFromState(state, id);
  return country && country.countryCode;
};

export const getUsersCountry = state => {
  const user = users.selectors.getCurrentUser(state);
  return user
    ? _.values(getAllCountries(state)).find(
        c => c.countryCode === user.countryCode
      )
    : undefined;
};
export const getUsersCountryCode = state => {
  const user = users.selectors.getCurrentUser(state);
  return user && user.countryCode;
};
export const getTaxPeriodsOfUsersCountry = state => {
  const country = getUsersCountry(state);
  const taxPeriods = country && country.taxPeriods;

  return (
    taxPeriods &&
    taxPeriods.slice().sort((a, b) => moment(a.startDate).diff(b.startDate))
  );
};

export const getRateForTrip = (state, tripId) => {
  const taxPeriods = getTaxRatesOfUsersCountry(state);

  return taxPeriods;
};

export const getMostRecentTaxRateOfUsersCountry = state => {
  const rates = getTaxRatesOfUsersCountry(state);
  const [mostRecentYear] = (rates && rates[0]) || [];
  const [rate] = (mostRecentYear && mostRecentYear.previous) || [];

  return rate;
};

export const getTaxRatesOfUsersCountry = state => {
  const taxPeriods = getTaxPeriodsOfUsersCountry(state);
  return (
    taxPeriods &&
    taxPeriods[0].rates.map(({ name, mileageRanges }) => {
      const { length } = mileageRanges;
      return mileageRanges.map(({ start, end }, mileageRangeIndex) => ({
        current: length > 1 ? { name, start, end } : name,
        previous: taxPeriods.map(({ rates }) => {
          const rate = rates.find(rate => rate.name === name);
          const mileageRanges = rate && rate.mileageRanges;
          return mileageRanges && mileageRanges[mileageRangeIndex]
            ? mileageRanges[mileageRangeIndex].rate
            : '';
        })
      }));
    })
  );
};

export const getUsersCountryDecimal = state => {
  const usersCountry = getUsersCountry(state);
  const countryCode = usersCountry && usersCountry.countryCode;
  return countryCode && getDecimalMarkByCountryCode(countryCode);
};

export const getUsersCountryName = state => {
  const country = getUsersCountry(state);
  return country && country.fullName;
};

export const countryUsesMetricUnits = ({ countryCode }) =>
  locales[countryCode].metric;
export const getActive = state => {
  const user = users.selectors.getActive(state);
  return _.values(getAllCountries(state)).find(
    c => c.countryCode === user.countryCode
  );
};
