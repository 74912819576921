import { connect } from 'react-redux';
import {
  mapStateToUserFilters,
  mapDispatchToUserFilters
} from './filterBarMappings';
import FilterBar from './FilterBar';
export default connect(
  mapStateToUserFilters,
  mapDispatchToUserFilters
)(FilterBar);
