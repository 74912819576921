import createReducer from 'redux/createReducer';
import { GET_USERS_SUCCESS } from 'ducks/users/usersActionTypes';

export const users = createReducer(
  {},
  {
    [GET_USERS_SUCCESS]: (state, { payload }) =>
      payload
        ? payload.reduce((users, user) => ({ ...users, [user.id]: user }), {})
        : state
  }
);

export default users;
