import { connect } from 'react-redux';
import DropDown from 'components/DropDownFilter/index';
import reports from 'ducks/reports/index';

const { actions } = reports;

export default connect(null, dispatch => ({
  onSelect: () => dispatch(actions.filterReports()),
  update: filters => dispatch(actions.updateFilters(filters)),
  remove: filters => dispatch(actions.removeFilters(filters))
}))(DropDown);
