import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { getCompanyPurposes as getPurposes } from 'api/endpoints/company/purposes';
import {
  GET_COMPANY_PURPOSES_REQUEST,
  GET_COMPANY_PURPOSES_SUCCESS,
  GET_COMPANY_PURPOSES_FAILURE
} from 'ducks/company/companyActionTypes';

export const getCompanyPurposes = createSimpleAction(
  getPurposes,
  [
    GET_COMPANY_PURPOSES_REQUEST,
    GET_COMPANY_PURPOSES_SUCCESS,
    GET_COMPANY_PURPOSES_FAILURE
  ],
  'purposes'
);

export default getCompanyPurposes;
