import { createFilter } from './createFilter';
import capitalizeFirstLetter from 'lib/capitalizeFirstLetter';

export const createFlatFilters = (filters, ...rest) =>
  createFilter(
    filters.map(value => ({
      id: value.toLowerCase(),
      name: capitalizeFirstLetter(value)
    })),
    ...rest
  );

export default createFilter;
