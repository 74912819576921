import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import { scaleLinear } from 'd3-scale';
import PropTypes from 'prop-types';
import classNames from 'class-names';
import { convertKilometersToMiles, convertMilesToKilometers } from 'lib/utils';
import { getMetricMeasure } from 'ducks/users/selectors';
import './LineChart.css';

class LineChart extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    yLabelWidth: PropTypes.number,
    xLabelHeight: PropTypes.number,
    margin: PropTypes.number,
    formatYTick: PropTypes.func,
    formatXTick: PropTypes.func,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.number)
      })
    ),
    step: PropTypes.oneOf(['day', 'week', 'month']),
    metric: PropTypes.bool,
    distance: PropTypes.bool
  };

  static defaultProps = {
    width: 800,
    height: 200,
    yLabelWidth: 80,
    xLabelHeight: 30,
    margin: 20,
    data: [],
    formatYTick: value => numeral(value).format('0,0'),
    metric: false,
    distance: false
  };

  getChartWidth() {
    const { width, yLabelWidth, margin } = this.props;
    return width - yLabelWidth - margin;
  }

  getChartHeight() {
    const { height, xLabelHeight, margin } = this.props;
    return height - xLabelHeight - margin;
  }

  getMaxValue() {
    const { data } = this.props;
    const max = _.max(data.map(d => _.max(d.values)));
    return max === 0 ? 100 : max;
  }

  getYScale() {
    const { height, xLabelHeight } = this.props;
    return scaleLinear()
      .domain([0, this.getMaxValue()])
      .range([height - xLabelHeight, 5]);
  }

  getYTicks() {
    const { distance, metric } = this.props;
    const chartHeight = this.getChartHeight();
    const count = Math.floor(chartHeight / 40);
    const scale = this.getYScale();

    // keep nicely rounded ticks even if converting distance
    if (distance && !metric) {
      return scale
        .domain([0, convertKilometersToMiles(this.getMaxValue())])
        .ticks(count)
        .map(convertMilesToKilometers);
    } else {
      return scale.ticks(count);
    }
  }

  getChartX(x) {
    const { data, yLabelWidth, margin } = this.props;
    const chartWidth = this.getChartWidth();
    return (
      yLabelWidth + margin + (x / (data.length - 1)) * (chartWidth - margin)
    );
  }

  getChartY(y) {
    return this.getYScale()(y);
  }

  drawPoints() {
    const { data } = this.props;
    return (
      <g className="line-chart__points">
        {data.map((d, i) => {
          const x = this.getChartX(i);
          return d.values
            .map((dd, ii) => (
              <circle
                key={`${i}/${ii}`}
                className={classNames(
                  'line-chart__point',
                  `line-chart__point_${ii}`
                )}
                r={4}
                cx={x}
                cy={this.getChartY(dd)}
              />
            ))
            .reverse();
        })}
      </g>
    );
  }

  drawXTicks() {
    const { data, xLabelHeight, height, step, formatXTick } = this.props;

    const format = formatXTick
      ? formatXTick
      : value => {
          const shortLocaleDate = moment
            .localeData()
            .longDateFormat('l')
            .replace(/\/YYYY/, '');

          return step === 'day'
            ? moment(value).format(shortLocaleDate)
            : step === 'week'
              ? moment(value).format('\\W W')
              : moment(value).format('MMM');
        };

    return (
      <g className="line-chart__x-labels">
        {data.map((d, i) => (
          <text
            key={i}
            x={this.getChartX(i)}
            y={height - xLabelHeight}
            dy={18}
            textAnchor="middle"
          >
            {format(d.date)}
          </text>
        ))}
      </g>
    );
  }

  drawYTicks() {
    const { yLabelWidth, formatYTick } = this.props;
    const ticks = this.getYTicks();
    return (
      <g className="line-chart__y-labels">
        {ticks.map((y, i) => {
          return (
            <text
              key={i}
              x={yLabelWidth}
              y={this.getChartY(y)}
              dy={4}
              dx={-10}
              textAnchor="end"
            >
              {formatYTick(y)}
            </text>
          );
        })}
      </g>
    );
  }

  drawLabels() {
    return (
      <g className="line-chart__labels">
        {this.drawXTicks()}
        {this.drawYTicks()}
      </g>
    );
  }

  drawAxes() {
    const { width, height, xLabelHeight, yLabelWidth } = this.props;
    return (
      <g className="line-chart__axes">
        <line
          x1={yLabelWidth}
          y1={5}
          x2={yLabelWidth}
          y2={height - xLabelHeight}
        />
        <line
          x1={yLabelWidth}
          y1={height - xLabelHeight}
          x2={width}
          y2={height - xLabelHeight}
        />
      </g>
    );
  }

  drawGrid() {
    const { data, width, height, yLabelWidth, xLabelHeight } = this.props;

    const ticks = this.getYTicks();
    return (
      <g className="line-chart__grid-lines">
        {data.map((_, i) => (
          <line
            key={i}
            x1={this.getChartX(i)}
            y1={5}
            x2={this.getChartX(i)}
            y2={height - xLabelHeight}
          />
        ))}
        {ticks.slice(1).map((d, i) => {
          const y = this.getChartY(d);
          return <line key={i} x1={yLabelWidth} y1={y} x2={width} y2={y} />;
        })}
      </g>
    );
  }

  drawPaths() {
    const { data } = this.props;
    const paths = data[0].values.map((_, i) => {
      const path = data
        .map((d, ii) => {
          const x = this.getChartX(ii);
          const y = this.getChartY(d.values[i]);
          return `${ii === 0 ? 'M' : 'L'} ${x} ${y}`;
        })
        .join(' ');
      return (
        <path
          key={i}
          className={classNames('line-chart__path', `line-chart__path_${i}`)}
          d={path}
        />
      );
    });

    return <g className="line-chart__paths">{paths.reverse()}</g>;
  }

  render() {
    const { className, width, height } = this.props;
    return (
      <svg
        className={classNames('line-chart', className)}
        width={width}
        height={height}
      >
        {this.drawGrid()}
        {this.drawAxes()}
        {this.drawPaths()}
        {this.drawPoints()}
        {this.drawLabels()}
      </svg>
    );
  }
}

const mapStateToProps = state => ({
  metric: getMetricMeasure(state)
});

export default connect(mapStateToProps)(LineChart);
