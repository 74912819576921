import { connect } from 'react-redux';
import { getTrip, getRequest, getActive } from 'ducks/trips/selectors';
import { isSelected } from 'ducks/selection/selectors';
import { selectTrip, deselectTrip } from 'ducks/selection/actions';
import { setActive, updateTrip } from 'ducks/trips/actions';
import getStartingPoint from 'lib/polyline/getStartingPoint';
import getEndPoint from 'lib/polyline/getEndPoint';
import { getLocationsForTrip } from 'ducks/trips/tripSelectors/getLocationsForTrip';
import {
  changeTripStartLocation,
  changeTripEndLocation
} from 'ducks/trips/actions/changeTripLocations';
import { getUsersLocations } from 'ducks/users/selectors';
import Trip from './Trip';

const mapStateToProps = (state, { id }) => {
  const trip = getTrip(state, id);
  const polyline = trip.polyline;
  const { startLocation, endLocation } = getLocationsForTrip(
    getUsersLocations(state),
    trip
  );
  return {
    trip,
    startLocation,
    endLocation,
    startingPoint: getStartingPoint(polyline),
    endPoint: getEndPoint(polyline),
    request: getRequest(state, id),
    isActive: getActive(state) === id,
    selected: isSelected(state, id)
  };
};

const mapDispatchToProps = {
  updateTrip,
  setActive,
  selectTrip,
  deselectTrip,
  changeTripStartLocation,
  changeTripEndLocation
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Trip);
