import React, { Component } from 'react';
import AddButton from 'components/CompanyPages/lib/AddButton';
import RoleSelector from 'components/CompanyPages/lib/RoleSelector';
import TeamSelector from 'components/CompanyPages/lib/TeamSelector';
import { createRenderField } from 'components/CompanyPages/lib/CompanyFields/createRenderField';
import CompanyFormError from 'components/CompanyPages/lib/CompanyFormError';
import { renderCompanyTextField } from 'components/CompanyPages/lib/CompanyFields/renderCompanyField';
import GetInputButton from 'components/CompanyPages/lib/GetInputButton';
import Papa from 'papaparse';
import { reduxForm, Field } from 'redux-form';
import * as validators from 'lib/validators';
import QuestionBox from 'components/InformationPopUp';
import './InviteUsersPage.css';

const form = 'invite-users-page';
const roleSelector = createRenderField(RoleSelector);
const teamSelector = createRenderField(TeamSelector);

const validations = {
  emails: validators.check(validators.required, validators.emails)
};

export default reduxForm({ form })(
  class InviteUsersPage extends Component {
    constructor(props) {
      super(props);

      this.sendInvites = this.sendInvites.bind(this);
      this.validateRoleSelection = this.validateRoleSelection.bind(this);
      this.uploadCsv = this.uploadCsv.bind(this);
    }

    validateRoleSelection(role, { teams }) {
      return role !== 'manager' || (teams && teams.length)
        ? undefined
        : 'A manager must be assigned to at least one team';
    }

    uploadCsv({ file }) {
      const { displayCsv } = this.props;

      Papa.parse(file, {
        dynamicTyping: true,
        header: true,
        complete: results => displayCsv && displayCsv(results && results.data)
      });
    }

    sendInvites({ invites, role, teams }) {
      const { inviteUsers, onSubmit } = this.props;
      const teamId = teams ? teams[0] : undefined;

      if (invites && invites.length) {
        inviteUsers(invites, role, teamId);
        onSubmit();
      }
    }

    render() {
      const {
        downloadExampleCsv,
        handleSubmit,
        submitting,
        error,
        valid
      } = this.props;

      return (
        <form
          className="invite-users-page"
          onSubmit={handleSubmit(this.sendInvites)}
        >
          <CompanyFormError>{error}</CompanyFormError>
          <h1 className="invite-users-page-invites-title">
            Email addresses (comma separated)
          </h1>
          <QuestionBox className="invite-users-info-pop-up">
            You can use the import csv button to import pre-defined invitations
            using a csv file. The fields that can be set in the csv are name,
            email, role, and rate. These fields must be specified in the header
            of the csv file.{' '}
            {
              <button className="example-csv-link" onClick={downloadExampleCsv}>
                Click here
              </button>
            }{' '}
            To download an example/template csv file.
          </QuestionBox>
          <Field
            type="text"
            name="invites"
            className="invite-users-page-invites"
            component={renderCompanyTextField}
            validate={validations.emails}
          />
          <Field
            name="role"
            component={roleSelector}
            validate={this.validateRoleSelection}
          />
          <Field
            title="Assigned to team(s)"
            name="teams"
            component={teamSelector}
          />
          <GetInputButton
            className="invite-users-by-csv-button"
            onChange={this.uploadCsv}
          >
            Import from CSV
          </GetInputButton>
          <AddButton
            disabled={submitting || !valid}
            className="invite-users-page-button"
            onClick={this.sendInvites}
          >
            Invite
          </AddButton>
        </form>
      );
    }
  }
);
