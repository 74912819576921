import { connect } from 'react-redux';
import CompanyLocationsPage from './CompanyLocationsPage';

import {
  mapStateToCompanyLocationsPage,
  mapDispatchToCompanyLocationsPage
} from './companyLocationsPageMappings';

export default connect(
  mapStateToCompanyLocationsPage,
  mapDispatchToCompanyLocationsPage
)(CompanyLocationsPage);
