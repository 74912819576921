import React from 'react';
import classNames from 'class-names';
import moment from 'moment';

const setPM = (value, twelveHour, isPM) => {
  if (!twelveHour) {
    return value;
  }

  const hour = value.hour();
  return isPM ? value.hour(hour % 12 + 12) : value.hour(hour % 12);
};

const Panel = ({ value, onSelect, minuteInterval }) => {
  const minutesInAnHour = 60;
  const twelveHour = moment().format('LT').length > 5;
  const interval = minuteInterval || 5;
  const amount = Math.floor(minutesInAnHour / interval);
  const hours = Array.apply(null, Array(twelveHour ? 12 : 24)).map((_, i) => i);
  const minutes = Array.apply(null, Array(amount)).map((_, i) => i * interval);

  const selectedHour = value
    ? twelveHour ? value.hour() % 12 : value.hour()
    : null;

  const selectedMinute = value ? value.minute() : null;

  const isPM = value.format('LT').slice(-2) === 'PM';

  return (
    <div className="timepicker-panel">
      <div className="timepicker-panel__column">
        {hours.map(
          hour =>
            twelveHour && hour === 0 ? null : (
              <div
                key={hour}
                className={classNames('timepicker-panel__option', {
                  'timepicker-panel__option_selected': hour === selectedHour
                })}
                onClick={e => {
                  e.preventDefault();
                  onSelect(setPM(value.hour(hour), twelveHour, isPM));
                }}
              >
                {hour}
              </div>
            )
        )}
        {twelveHour && (
          <div
            className={classNames('timepicker-panel__option', {
              'timepicker-panel__option_selected': selectedHour === 0
            })}
            onClick={e => {
              e.preventDefault();
              onSelect(setPM(value.hour(0), twelveHour, isPM));
            }}
          >
            12
          </div>
        )}
      </div>

      <div className="timepicker-panel__column">
        {minutes.map(minute => (
          <div
            key={minute}
            className={classNames('timepicker-panel__option', {
              'timepicker-panel__option_selected': minute === selectedMinute
            })}
            onClick={e => {
              e.preventDefault();
              onSelect(value.minute(minute));
            }}
          >
            {(minute < 10 ? '0' : '') + minute}
          </div>
        ))}
      </div>

      {twelveHour && (
        <div className="timepicker-panel__column">
          <div
            className={classNames('timepicker-panel__option', {
              'timepicker-panel__option_selected': !isPM
            })}
            onClick={() => onSelect(setPM(value, twelveHour, false))}
          >
            AM
          </div>

          <div
            className={classNames('timepicker-panel__option', {
              'timepicker-panel__option_selected': isPM
            })}
            onClick={() => onSelect(setPM(value, twelveHour, true))}
          >
            PM
          </div>
        </div>
      )}
    </div>
  );
};

export default Panel;
