import {
  GET_COMPANY_PURPOSES_SUCCESS,
  DELETE_COMPANY_PURPOSE_SUCCESS,
  PUT_COMPANY_PURPOSE_SUCCESS,
  POST_COMPANY_PURPOSE_SUCCESS
} from 'ducks/company/companyActionTypes';
import createReducer from 'redux/createReducer';

export const setPurposes = (state, action) => {
  const payload = action.payload;
  return payload !== undefined ? payload : state;
};

export const addPurpose = (state, action) => {
  const update = action && action.payload;
  return update ? [update, ...state] : state;
};

export const deletePurpose = (state, action) => {
  const removed = action && action.payload;
  return removed !== undefined
    ? state.filter(({ id }) => Number(id) !== Number(removed))
    : state;
};

export const updatePurpose = (state, action) => {
  const update = action && action.payload;
  return state.map(purpose => (purpose.id === update.id ? update : purpose));
};

export const companyPurposes = createReducer([], {
  [POST_COMPANY_PURPOSE_SUCCESS]: addPurpose,
  [PUT_COMPANY_PURPOSE_SUCCESS]: updatePurpose,
  [DELETE_COMPANY_PURPOSE_SUCCESS]: deletePurpose,
  ...[GET_COMPANY_PURPOSES_SUCCESS].reduce((state, action) => {
    state[action] = setPurposes;
    return state;
  }, {})
});

export default companyPurposes;
