import { getUserId } from '../../../lib/auth';
import client from '../../client';

export const deleteUserFromDb = id => client.delete(`/users/${id}`);

export const postUserSignIn = (email, password) =>
  client.post('/users/sign_in', { user: { email, password }, client: 'portal'});

export const postUsers = user => client.post('/users', { user });

export const getUser = userId => {
  const loggedInUserId = getUserId();
  return client.get(`/users/${userId}`, {
    params: {
      userId: Number(loggedInUserId) === Number(userId) ? undefined : userId
    }
  });
};

export const putUser = (id, user) => client.put(`/users/${id}`, { user });
