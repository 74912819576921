import { getCompanyLocations as getLocations } from 'api/endpoints/company/locations';
import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import {
  GET_COMPANY_LOCATIONS_REQUEST,
  GET_COMPANY_LOCATIONS_SUCCESS,
  GET_COMPANY_LOCATIONS_FAILURE
} from 'ducks/company/companyActionTypes';

export const getCompanyLocations = createSimpleAction(
  getLocations,
  [
    GET_COMPANY_LOCATIONS_REQUEST,
    GET_COMPANY_LOCATIONS_SUCCESS,
    GET_COMPANY_LOCATIONS_FAILURE
  ],
  'locations'
);

export default getCompanyLocations;
