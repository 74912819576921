import React, { Component } from 'react';
import { fetchCurrentUser } from '../ducks/users/actions';
import { connect } from 'react-redux';

export default connect(
  () => ({}),
  { fetchCurrentUser }
)(
  class FetchUser extends Component {
    componentDidMount() {
      this.props.fetchCurrentUser();
    }

    render() {
      return <div>{this.props.children}</div>;
    }
  }
);
