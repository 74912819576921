import { ADD_LOCATION, ADDRESS_NOT_FOUND } from '../locationActionTypes';
import errors from 'ducks/locations/lib/errors';
export const locationRequest = type => id => ({
  type,
  payload: { id }
});

export const locationSuccess = (type, action) => (payload, notify = true) => ({
  type,
  payload,
  ...(notify && {
    meta: {
      alert: {
        type: 'success',
        message: `Location ${action}d`
      }
    }
  })
});

export const locationFailure = (type, action) => (error, id) => ({
  type,
  error,
  payload: { id },
  meta: {
    alert: {
      type: 'error',
      message: errors(error, action)
    }
  }
});

export const locationModalStatus = active => ({
  type: ADD_LOCATION,
  payload: active
});

export const addressNotFound = () => ({
  type: ADDRESS_NOT_FOUND,
  meta: {
    alert: {
      type: 'error',
      message: 'Address could not be found, please enter a valid address.'
    }
  }
});
