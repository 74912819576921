import { combineReducers } from 'redux';
import company from './company';
import requests from './companyRequests';
import signUpStatus from './companySignUpStatus';
import companyInviteRequests from './companyInviteRequests';
import selectedWorkHoursId from './selectedWorkHoursId';
import invites from './companyInvites';
import members from './companyMembers';
import purposes from './purposes';
import locations from './companyLocations';
import selectedCompanyLocation from './selectedCompanyLocation';
import vehicles from './companyVehicles';
import reports from './companyReports';
import statistics from './companyStatistics';
import userStatusFilter from './userStatusFilter';
import tripsPageRequests from './companyPageRequests/companyTripsPageRequests';
import reportsPageRequests from './companyPageRequests/companyReportsPageRequests';
import statisticsPageRequests from './companyPageRequests/companyStatisticsPageRequests';
import usersPageRequests from './companyPageRequests/companyUsersPageRequests';
import teamsPageRequests from './companyPageRequests/companyTeamsPageRequests';
import workHoursPageRequests from './companyPageRequests/companyWorkHoursPageRequests';
import purposesPageRequests from './companyPageRequests/companyPurposesPageRequests';
import locationsPageRequests from './companyPageRequests/companyLocationsPageRequests';
import accountPageRequests from './companyPageRequests/companyAccountPageRequests';

export default combineReducers({
  selectedWorkHoursId,
  company,
  requests,
  signUpStatus,
  companyInviteRequests,
  invites,
  members,
  purposes,
  locations,
  selectedCompanyLocation,
  vehicles,
  reports,
  statistics,
  userStatusFilter,
  tripsPageRequests,
  reportsPageRequests,
  statisticsPageRequests,
  usersPageRequests,
  teamsPageRequests,
  workHoursPageRequests,
  purposesPageRequests,
  locationsPageRequests,
  accountPageRequests
});
