import React from 'react';
import './Button.css';

export default ({ children, className, onClick, disabled, ...props }) => (
  <button
    disabled={disabled}
    onClick={onClick}
    {...props}
    className={`${disabled ? 'disabled-add-button' : 'add-button'}${
      className ? ` ${className}` : ''
    }`}
  >
    {children}
  </button>
);
