import { NAME } from './constants';

export const SELECT_AUTO_APPROVAL_RULE = `${NAME}/SELECT_AUTO_APPROVAL_RULE`;

export const GET_AUTO_APPROVAL_RULES_REQUEST = `${NAME}/GET_AUTO_APPROVAL_RULES_REQUEST`;
export const GET_AUTO_APPROVAL_RULES_SUCCESS = `${NAME}/GET_AUTO_APPROVAL_RULES_SUCCESS`;
export const GET_AUTO_APPROVAL_RULES_FAILURE = `${NAME}/GET_AUTO_APPROVAL_RULES_FAILURE`;

export const POST_AUTO_APPROVAL_RULES_REQUEST = `${NAME}/POST_AUTO_APPROVAL_RULES_REQUEST`;
export const POST_AUTO_APPROVAL_RULES_SUCCESS = `${NAME}/POST_AUTO_APPROVAL_RULES_SUCCESS`;
export const POST_AUTO_APPROVAL_RULES_FAILURE = `${NAME}/POST_AUTO_APPROVAL_RULES_FAILURE`;

export const GET_AUTO_APPROVAL_RULE_REQUEST = `${NAME}/GET_AUTO_APPROVAL_RULE_REQUEST`;
export const GET_AUTO_APPROVAL_RULE_SUCCESS = `${NAME}/GET_AUTO_APPROVAL_RULE_SUCCESS`;
export const GET_AUTO_APPROVAL_RULE_FAILURE = `${NAME}/GET_AUTO_APPROVAL_RULE_FAILURE`;

export const PUT_AUTO_APPROVAL_RULE_REQUEST = `${NAME}/PUT_AUTO_APPROVAL_RULE_REQUEST`;
export const PUT_AUTO_APPROVAL_RULE_SUCCESS = `${NAME}/PUT_AUTO_APPROVAL_RULE_SUCCESS`;
export const PUT_AUTO_APPROVAL_RULE_FAILURE = `${NAME}/PUT_AUTO_APPROVAL_RULE_FAILURE`;

export const DELETE_AUTO_APPROVAL_RULE_REQUEST = `${NAME}/DELETE_AUTO_APPROVAL_RULE_REQUEST`;
export const DELETE_AUTO_APPROVAL_RULE_SUCCESS = `${NAME}/DELETE_AUTO_APPROVAL_RULE_SUCCESS`;
export const DELETE_AUTO_APPROVAL_RULE_FAILURE = `${NAME}/DELETE_AUTO_APPROVAL_RULE_FAILURE`;
