import * as trips from 'ducks/trips/selectors';
import numeral from 'numeral';
import { updateTripTimeAndDistance } from '../../ducks/trips/actions';
import { convertUnitOfMeasurement, setTimeOnDate } from '../../lib/utils';
import { toNumber } from '../../lib/localization/numericLocalization';
import { getUsersCountryDecimal } from '../../ducks/countries/selectors';
import { getMetricMeasure } from '../../ducks/users/selectors';
import round from './lib/round';
const convertDistance = (distance, decimal, metric) =>
  round(convertUnitOfMeasurement(toNumber(distance, decimal), metric, true), 1);
export const mapStateToTripEditDistanceAndTimeForm = (state, { tripId }) => {
  const trip = trips.getTrip(state, tripId);
  const metric = getMetricMeasure(state);
  const decimal = getUsersCountryDecimal(state);
  return {
    trip,
    initialValues: {
      distance: numeral(convertDistance(trip.distance, decimal, metric)).format(
        '0,0.0'
      )
    },
    decimal,
    metric
  };
};
export const mapDispatchToEditTripDistanceAndTimeForm = dispatch => ({
  onSubmit: ({
    fromDate,
    toDate,
    fromTime,
    toTime,
    distance,
    decimal,
    metric,
    trip
  }) => {
    const { startTime, endTime, id } = trip;
    const tripDistance = trip.distance;
    const convertedDistance = convertUnitOfMeasurement(
      toNumber(distance, decimal),
      metric
    );
    return dispatch(
      updateTripTimeAndDistance({
        distance:
          distance === convertDistance(tripDistance, decimal, metric)
            ? tripDistance
            : convertedDistance,
        endTime: setTimeOnDate(toDate || endTime, toTime),
        id,
        startTime: setTimeOnDate(fromDate || startTime, fromTime)
      })
    );
  }
});
