import React from 'react';
import { connect } from 'react-redux';
import Dropdown from 'components/DropDown';
import { formatVehicle } from 'lib/formatters';
import { getBestMatchedVehicle, getActive } from '../ducks/vehicles/selectors';

const mapStateToProps = (state, { selectedId }) => ({
  vehicles: getActive(state),
  vehicle: getBestMatchedVehicle(state, selectedId)
});

const VehicleSelector = ({ vehicles, vehicle, ...props }) => (
  <Dropdown
    value={vehicle ? formatVehicle(vehicle) : 'Select Vehicle'}
    options={
      vehicles
        ? vehicles.map(vehicle => [vehicle.id, formatVehicle(vehicle)])
        : []
    }
    {...props}
  />
);

export default connect(mapStateToProps)(VehicleSelector);
