import createReducer from 'redux/createReducer';
import _ from 'lodash';
import {
  REMOVE_LOCATION_IF_STORED,
  REMOVE_COMPANY_LOCATION_IF_STORED,
  REMOVE_STORED_LOCATION,
  REMOVE_COMPANY_STORED_LOCATION,
  STORE_LOCATION,
  STORE_COMPANY_LOCATION
} from '../locationActionTypes';

export const findLocationIdByAddressOrCoordinates = (locations, location) =>
  Object.keys(locations).find(id => {
    const { address, lat, lon } = locations[id];
    return (
      address === location.address ||
      (lat === location.lat && lon === location.lon)
    );
  });

export const removeLocation = (locations, id) =>
  Object.keys(locations)
    .filter(locationId => Number(locationId) !== Number(id))
    .reduce(
      (newState, locationId) => ({
        ...newState,
        [locationId]: locations[locationId]
      }),
      {}
    );

export const storeLocation = (state, { payload }) => ({
  ...state,
  [payload.id]: Object.assign({}, payload)
});

export const findAndRemoveLocation = (state, { payload }) => {
  const id = findLocationIdByAddressOrCoordinates(state, payload);
  return _.isString(id) || _.isNumber(id) ? removeLocation(state, id) : state;
};

export default createReducer(
  {},
  {
    ...[REMOVE_LOCATION_IF_STORED, REMOVE_COMPANY_LOCATION_IF_STORED].reduce(
      (state, action) => {
        state[action] = findAndRemoveLocation;
        return state;
      },
      {}
    ),

    ...[STORE_LOCATION, STORE_COMPANY_LOCATION].reduce((state, action) => {
      state[action] = storeLocation;
      return state;
    }, {}),

    ...[REMOVE_STORED_LOCATION, REMOVE_COMPANY_STORED_LOCATION].reduce(
      (state, action) => {
        state[action] = removeLocation;
        return state;
      },
      {}
    )
  }
);
