import { getTeam } from 'ducks/teams/teamsSelectors/getTeam';
import { resendInvitation } from 'ducks/company/companyActions/invitations/resendInvitation';
import { cancelInvitation } from 'ducks/company/companyActions/invitations/cancelInvitation';
import { deactivateUser } from 'ducks/company/companyActions/users/deactivateUser';
import userIsAdmin from 'ducks/users/usersSelectors/userIsAdmin';
import { getDistanceMeasure } from 'ducks/users/selectors';

const extractCustomBusinessRate = cbrObject => {
  const { mileageRanges } = cbrObject || {};
  const cbr = mileageRanges && mileageRanges[0];
  return cbr && cbr.rate;
};

export const mapStateToInvitedUserElement = (
  state,
  { teamId, customBusinessRate }
) => ({
  isAdmin: userIsAdmin(state),
  team: getTeam(state, teamId),
  unitOfMeasure: getDistanceMeasure(state),
  customBusinessRate: extractCustomBusinessRate(customBusinessRate)
});

export const mapDispatchToInvitedUserElement = {
  resendInvitation,
  cancelInvitation,
  deactivateUser
};
