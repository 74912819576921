import { getTeamsArray } from 'ducks/teams/teamsSelectors/getTeamsArray';
import { getRateFromPurpose } from 'lib/getRateFromPurpose';
import { deleteCompanyPurpose } from 'ducks/company/companyActions/purposes/deleteCompanyPurpose';
import { hideCompanyPurpose } from 'ducks/company/companyActions/purposes/hideCompanyPurpose';
import { updateCompanyPurpose } from 'ducks/company/companyActions/purposes/updateCompanyPurpose';
import { getDistanceMeasure } from 'ducks/users/selectors';
import { getMostRecentTaxRateOfUsersCountry } from 'ducks/countries/selectors';

export const mapStateToPurpose = (state, { purpose }) => ({
  teams: getTeamsArray(state),
  rate:
    getRateFromPurpose(purpose) || getMostRecentTaxRateOfUsersCountry(state),
  unitOfMeasure: getDistanceMeasure(state),
  costCenters: [{ name: 'one', id: 1 }, { name: 'two', id: 2 }]
});

export const mapDispatchToPurpose = {
  hideCompanyPurpose,
  deleteCompanyPurpose,
  updateCompanyPurpose
};
