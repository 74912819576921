import { convertUnitOfMeasurement } from 'lib/utils';
import round from 'components/EditTripDistanceAndTimeForm/lib/round';
import { getMetricMeasure } from 'ducks/users/selectors';
import numeral from 'numeral';

const convertDistance = (distance, metric) => {
  return round(convertUnitOfMeasurement(distance, metric, true), 2);
};

export const formatDistance = (state, distance) => {
  return numeral(distance).format('0,0.0');
};
