import { connect } from 'react-redux';
import ManagerPage from './ManagerPage';
import addSelection from 'components/Selector';

import {
  mapStateToManagerPage,
  mapDispatchToManagerPage
} from './managerPageMappings';

export default connect(
  mapStateToManagerPage,
  mapDispatchToManagerPage
)(addSelection(ManagerPage));
