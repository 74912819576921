import { getCurrentPage } from 'ducks/reports/selectors';
import { getById } from 'ducks/reports/selectors';
import { updateCompanyReports } from 'ducks/company/companyActions/reports/updateCompanyReports';
import { getTotalCount } from '../../ducks/reports/selectors';
import { getCompanyId } from '../../lib/auth';

export const mapStateToReportsPage = state => {
  const currentPage = getCurrentPage(state);
  return {
    pageName: 'reports',
    reports: currentPage && currentPage.map(id => getById(state, id)),
    totalCount: getTotalCount(state),
    companyId: getCompanyId()
  };
};

export const mapDispatchToReportsPage = {
  updateCompanyReports
};
