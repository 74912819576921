import { connect } from 'react-redux';
import EditedUsersElement from './EditedUsersElement';
import {
  mapStateToEditedUsersElement,
  mapDispatchToEditedUsersElement
} from 'components/CompanyPages/Admin/SettingsPage/UsersPage/EditUsersPage/EditedUsersElement/editedUsersElementMappings';

export default connect(
  mapStateToEditedUsersElement,
  mapDispatchToEditedUsersElement
)(EditedUsersElement);
