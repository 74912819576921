import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'class-names';

import './Button.css';

const Button = ({
  color,
  fullWidth,
  inline,
  className,
  link,
  external,
  children,
  ...props
}) => {
  const btnProps = {
    className: classNames('btn', className, {
      btn_save: color === 'save',
      btn_cancel: color === 'cancel',
      btn_delete: color === 'delete',
      'btn_full-width': fullWidth,
      btn_inline: inline
    }),
    ...props
  };
  const text = children || '';

  return link ? (
    external ? (
      <a {...btnProps}>{text}</a>
    ) : (
      <Link {...btnProps}>{text}</Link>
    )
  ) : (
    <button {...btnProps}>{text}</button>
  );
};

export default Button;
