import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import './Step.css';
export default ({ completed, description, number, title, action, url }) => (
  <div className="getting-started-step">
    <div className="circle">{number}</div>
    <h1 className="title">{title}</h1>
    <p className="description">{description}</p>
    {completed ? (
      <div className="checked-circle">
        <FontAwesome name="check-circle" />
      </div>
    ) : (
      <Link className="action-link" to={url}>
        <div className="action-button">{action}</div>
      </Link>
    )}
  </div>
);
