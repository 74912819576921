import moment from "moment";
import _ from "lodash";
import { getUsersCountryCode } from "../ducks/countries/selectors";
import users from "../ducks/users";
export const THIS_WEEK = "THIS_WEEK";
export const LAST_WEEK = "LAST_WEEK";
export const THIS_MONTH = "THIS_MONTH";
export const LAST_MONTH = "LAST_MONTH";
export const LAST_3_MONTHS = "LAST_3_MONTHS";
export const THIS_FISCAL_YEAR = "THIS_FISCAL_YEAR";
export const LAST_FISCAL_YEAR = "LAST_FISCAL_YEAR";
export const NEXT_FISCAL_YEAR = "NEXT_FISCAL_YEAR";
export const STRIPPED_UTC_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const COUNTRIES_WITH_FISCAL_YEAR_OPTION = [];
export const fiscalYears = {
  [THIS_FISCAL_YEAR]: `FY${moment().year()}`,
  [LAST_FISCAL_YEAR]: `FY${moment()
    .subtract(1, "year")
    .year()}`,
  [NEXT_FISCAL_YEAR]: `FY${moment()
    .add(1, "year")
    .year()}`
};
export const humanReadableTimePeriods = {
  [THIS_WEEK]: "This Week",
  [LAST_WEEK]: "Last Week",
  [THIS_MONTH]: "This Month",
  [LAST_MONTH]: "Last Month",
  [LAST_3_MONTHS]: "Last 3 Months"
};

export const getScopes = (earliestTrip, countryCode) => ({
  ...humanReadableTimePeriods,
  // only show fiscal year for countries that use them.
  ...(COUNTRIES_WITH_FISCAL_YEAR_OPTION.indexOf(countryCode) > -1 &&
    fiscalYears),
  // include all years between earliest trip (sent by API) and now
  ..._.range(moment(earliestTrip || undefined).year(), moment().year() + 1)
    .reverse()
    .reduce((obj, year) => ({ ...obj, [`year/${year}`]: year.toString() }), {})
});

export const defaultScope = `year/${moment().year()}`;
const dateRanges = {
  [THIS_WEEK]: {
    from: moment().startOf("week"),
    to: moment().endOf("week")
  },
  [LAST_WEEK]: {
    from: moment()
      .subtract(1, "week")
      .startOf("week"),
    to: moment().startOf("week")
  },
  [THIS_MONTH]: {
    from: moment().startOf("month"),
    to: moment().endOf("month")
  },
  [LAST_MONTH]: {
    from: moment()
      .subtract(1, "month")
      .startOf("month"),
    to: moment()
      .subtract(1, "month")
      .endOf("month")
  },
  [LAST_3_MONTHS]: {
    from: moment()
      .subtract(2, "month")
      .startOf("month"),
    to: moment().endOf("month")
  },
  [THIS_FISCAL_YEAR]: {
    timeScope: "this_year"
  },
  [LAST_FISCAL_YEAR]: {
    timeScope: "last_year"
  },
  [NEXT_FISCAL_YEAR]: {
    timeScope: "next_year"
  }
};

export const getDateRange = key => {
  if (dateRanges[key]) return dateRanges[key];
  let times;
  const splitKey = key.split("/");
  if (splitKey[0] === "year" && splitKey.length === 2) {
    times = {
      from: moment(splitKey[1], "YYYY").startOf("year"),
      to: moment(splitKey[1], "YYYY").endOf("year")
    };
    return times;
  }
};

export const getHuman = (key, countryCode) => {
  const humanReadable = {
    ...humanReadableTimePeriods,
    ...(COUNTRIES_WITH_FISCAL_YEAR_OPTION.indexOf(countryCode) > -1 &&
      fiscalYears)
  };
  if (humanReadable[key]) return humanReadable[key];
  const splitKey = key.split("/");
  if (splitKey[0] === "year" && splitKey.length === 2) {
    return splitKey[1];
  }
};

export const getTimeScopes = state => {
  const countryCode = getUsersCountryCode(state);
  const earliestTrip = _.get(users.selectors.getActive(state), "earliestTrip");
  return getScopes(earliestTrip, countryCode);
};

export const getHumanReadableTimeScopes = (state, key) => {
  const countryCode = getUsersCountryCode(state);
  return getHuman(key, countryCode);
};

export const getAdminFilterTimeScopes = state => {
  const timeScopes = getTimeScopes(state);
  return Object.keys(timeScopes).map(id => ({ name: timeScopes[id], id }));
};

export const dateHasPassed = date =>
  date ? moment(date).isBefore(moment()) : false;
