import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import Checkbox from 'components/Checkbox';
import confirm from 'components/Confirmation';
import KeyboardShortcutsModal from 'components/KeyboardShortcutsModal';
import Action from './Action';
import './TripToolbar.css';
const ConfirmDeleteAction = confirm(Action);
export default class TripToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = { keyboardShortcutsOpen: false };
    this.showKeyboardShortcuts = this.showKeyboardShortcuts.bind(this);
    this.hideKeyboardShortcuts = this.hideKeyboardShortcuts.bind(this);
  }
  showKeyboardShortcuts() {
    this.setState({ keyboardShortcutsOpen: true });
  }
  hideKeyboardShortcuts() {
    this.setState({ keyboardShortcutsOpen: false });
  }
  render() {
    const {
      selectAll,
      deselectAll,
      deleteSelected,
      selectedCount,
      totalTrips,
      allSelected,
      mergeSelected
    } = this.props;
    const { keyboardShortcutsOpen } = this.state;
    return (
      <div className="trip-toolbar">
        <KeyboardShortcutsModal
          isOpen={keyboardShortcutsOpen}
          onClose={this.hideKeyboardShortcuts}
        />
        <div className="trip-toobar__left">
          <Checkbox
            label="Select All"
            checked={allSelected}
            onChange={() => (allSelected ? deselectAll() : selectAll())}
          />
          <span className="selected-count">
            {selectedCount > 0 ? (
              <span>
                {selectedCount} out of {totalTrips} selected
              </span>
            ) : (
              <span>{totalTrips} trips</span>
            )}
          </span>
        </div>
        <div className="trip-toolbar__right">
          <Action to="/trips/new">
            <FontAwesome name="plus" /> Add
          </Action>
          <Action to="/trips/edit" disabled={selectedCount === 0}>
            <FontAwesome name="pencil" /> Edit
          </Action>
          <Action disabled={selectedCount !== 2} to="#" onClick={mergeSelected}>
            <FontAwesome name="compress" /> Merge
          </Action>
          <ConfirmDeleteAction
            confirm={'true'}
            message={
              <span>
                Permanently delete <b>{selectedCount}</b> trips?
              </span>
            }
            disabled={selectedCount === 0}
            to="#"
            onClick={deleteSelected}
            title={'Confirm Delete'}
          >
            <FontAwesome name="trash" /> Delete
          </ConfirmDeleteAction>
          <Action to="#" onClick={this.showKeyboardShortcuts}>
            <FontAwesome name="question-circle" />
          </Action>
        </div>
      </div>
    );
  }
}
