import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import purposes from 'ducks/purposes';
import FilterDropdown from 'components/FilterDropdown';

class PurposesFilter extends Component {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.getValueName = this.getValueName.bind(this);
  }
  getOptions() {
    const { purposes } = this.props;
    return [
      [null, 'All'],
      [-2, 'Autoclassified'],
      [-1, 'Unclassified'],
      ...purposes.map(purpose => [purpose.id, purpose.name])
    ];
  }
  getValueName(value) {
    return _.get(
      this.getOptions().find(option => option[0] === value),
      '[1]',
      'Categories'
    );
  }
  getLabel() {
    const { value } = this.props;
    if (_.isArray(value)) {
      return value.length
        ? value.length > 1
          ? 'Multiple'
          : this.getValueName(value[0])
        : 'Categories';
    } else {
      return this.getValueName(value);
    }
  }

  onSelect(newValue) {
    const { value, onChange } = this.props;

    if (newValue === null) {
      onChange([]);
    } else if (newValue === -2) {
      onChange([-2]);
    } else {
      const index = value.indexOf(newValue);
      if (index > -1) {
        // remove value from value
        onChange([...value.splice(0, index), ...value.splice(index + 1)]);
      } else {
        // add value to value
        onChange([...value, newValue]);
      }
    }
  }
  render() {
    const { value } = this.props;
    const options = this.getOptions();

    return (
      <FilterDropdown
        className="trip-filters__item dropdown_filter"
        icon="tags"
        value={this.getLabel()}
        options={options}
        onSelect={this.onSelect}
        selected={value}
        closeOnSelect={false}
      />
    );
  }
}

export default connect(state => ({
  purposes: purposes.selectors.getActive(state)
}))(PurposesFilter);
