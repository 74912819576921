import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import notifications from 'ducks/notifications';
import Notifications from './Notifications';

const mapStateToProps = state => ({
  notifications: notifications.selectors.getNotifications(state)
});

const mapDispatchToProps = dispatch => ({
  dismiss: bindActionCreators(notifications.actions.dismiss, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
