import {
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_FAILURE,
  UPDATE_LOCATION_SUCCESS
} from '../locationActionTypes';
import {
  locationRequest,
  locationFailure,
  locationSuccess
} from './locationActionCreators';
import formatLocationRequest from 'ducks/locations/lib/formatLocationRequest';
import { getCurrentUser } from 'ducks/users/selectors';
import { putUser } from 'api/endpoints/user/users';
import { matchSortUserLocations } from '../lib/matchSortLocations';
import { invalidate } from '../../trips/actions';

const action = 'save';

export const updateLocationRequest = locationRequest(
  UPDATE_LOCATION_REQUEST,
  action
);
export const updateLocationSuccess = locationSuccess(
  UPDATE_LOCATION_SUCCESS,
  action
);
export const updateLocationFailure = locationFailure(
  UPDATE_LOCATION_FAILURE,
  action
);

export const replaceLocationWithUpdate = (update, locations) =>
  locations.map(location => (location.id === update.id ? update : location));

export default (updatedLocation, alert) => (dispatch, getState) => {
  const { id, locations } = getCurrentUser(getState());
  const locationId = updatedLocation.id;
  const locationsWithUpdatedLocation = replaceLocationWithUpdate(
    updatedLocation,
    locations
  );
  const updateRequest = formatLocationRequest(id, locationsWithUpdatedLocation);
  dispatch(updateLocationRequest(locationId));
  return putUser(id, updateRequest.user)
    .then(({ data }) => {
      return dispatch(
        updateLocationSuccess(
          matchSortUserLocations(data.user, locations),
          alert
        )
      );
    })
    .catch(error => dispatch(updateLocationFailure(error, locationId)));
};
