import createReducer from 'redux/createReducer';
import { GET_COMPANY_MEMBERS_SUCCESS } from 'ducks/company/companyActionTypes';
import {
  REMOVE_USER_FROM_COMPANY_SUCCESS,
  UPDATE_COMPANY_USER_SUCCESS
} from '../companyActionTypes';

export const setMembers = (state, action) =>
  action && action.payload ? action.payload : state;

export const companyMembers = createReducer([], {
  ...[GET_COMPANY_MEMBERS_SUCCESS].reduce((state, action) => {
    state[action] = setMembers;
    return state;
  }, {}),

  [UPDATE_COMPANY_USER_SUCCESS]: (state, action) => {
    const user = action.payload;
    const userId = user && user.id;

    return user
      ? state.map(
          existing =>
            existing.id === userId ? { ...existing, ...user } : existing
        )
      : state;
  },

  [REMOVE_USER_FROM_COMPANY_SUCCESS]: (state, { payload }) => {
    return payload ? state.filter(({ id }) => id !== payload) : state;
  }
});

export default companyMembers;
