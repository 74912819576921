import { connect } from 'react-redux';
import CostCenterElement from './CostCenterElement';
import {
  mapStateToCostCenterElement,
  mapDispatchToCostCenterElement
} from './costCenterElementMappings';

export default connect(
  mapStateToCostCenterElement,
  mapDispatchToCostCenterElement
)(CostCenterElement);
