import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import buildPagination, { ELLIPSIS } from 'lib/buildPagination';
import './Pagination.css';

export default class Pagination extends Component {
  static propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
    getPage: PropTypes.func
  };

  static defaultProps = {
    current: 0,
    total: 0,
    getPage: () => null
  };

  renderPage(page) {
    const { current, getPage } = this.props;

    return page === current ? (
      <div key={`page/${page}`} className="pagination__current">
        {page}
      </div>
    ) : (
      <div
        key={`page/${page}`}
        onClick={() => getPage(page)}
        className="pagination__page"
      >
        {page}
      </div>
    );
  }

  renderEllipsis(key) {
    return (
      <div key={`ellipsis/${key}`} className="pagination__ellipsis">
        &hellip;
      </div>
    );
  }

  renderPages(current, total, nCells) {
    return buildPagination(current, total, nCells).map(
      (page, i) =>
        page === ELLIPSIS ? this.renderEllipsis(i) : this.renderPage(page)
    );
  }

  render() {
    const { current, total, getPage, nCells } = this.props;

    if (total <= 1) return null;

    const pages = this.renderPages(current, total, nCells);

    return (
      <div className="pagination">
        {current > 1 ? (
          <div
            onClick={() => getPage(current - 1)}
            className="pagination__prev"
          >
            <FontAwesome name="caret-left" />
          </div>
        ) : (
          <div className="pagination__wide-placeholder" />
        )}
        {/* <div className="pagination__spacer" /> */}
        {pages}
        {/* <div className="pagination__spacer" /> */}
        {current < total ? (
          <div
            onClick={() => getPage(current + 1)}
            className="pagination__next"
          >
            <FontAwesome name="caret-right" />
          </div>
        ) : (
          <div className="pagination__wide-placeholder" />
        )}
      </div>
    );
  }
}
