import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import FontAwesome from 'react-fontawesome';
import FilterDropdown from 'components/FilterDropdown';

class DateInput extends Component {
  render() {
    const { onClick, value } = this.props;
    return (
      <button onClick={onClick} className="date-range__input">
        {value}
      </button>
    );
  }
}

class TimeScopeFilter extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    timeScopeMode: PropTypes.bool,
    allowDates: PropTypes.bool,
    from: PropTypes.object,
    to: PropTypes.object,
    setFrom: PropTypes.func,
    setTo: PropTypes.func,
    earliestTrip: PropTypes.string
  };

  componentDidMount() {
    const { setTimeScopeMode, allowDates } = this.props;

    if (!allowDates) {
      // ensure we're in time scope mode if a custom date range isn't allowed
      setTimeScopeMode(true);
    }
  }

  render() {
    const {
      value,
      onChange,
      timeScopeMode,
      setTimeScopeMode,
      allowDates,
      from,
      setFrom,
      to,
      setTo,
      getTimeScopes,
      getHumanReadableTimeScopes
    } = this.props;

    const options = _.entries(getTimeScopes());

    if (allowDates) options.push([-1, 'Specific dates']);

    if (timeScopeMode) {
      return (
        <FilterDropdown
          className="trip-filters__item dropdown_filter"
          icon="calendar-o"
          value={getHumanReadableTimeScopes(value)}
          options={options}
          selected={value}
          onSelect={value =>
            value === -1 ? setTimeScopeMode(false) : onChange(value)
          }
        />
      );
    } else {
      return (
        <div className="trip-filters__item date-range">
          <FontAwesome name="calendar-o" />
          <div className="date-range__text">
            <DatePicker
              selected={from}
              onChange={setFrom}
              startDate={from}
              endDate={to}
              customInput={<DateInput />}
              subscripp
              todayButton="Today"
              selectsStart
            />

            <span className="date-range__seperator">-</span>

            <DatePicker
              selected={to}
              onChange={setTo}
              startDate={from}
              endDate={to}
              customInput={<DateInput />}
              todayButton="Today"
              selectsEnd
            />

            <a href="#" role="button" onClick={() => setTimeScopeMode(true)}>
              <FontAwesome name="times" fixedWidth />
            </a>
          </div>
        </div>
      );
    }
  }
}

export default TimeScopeFilter;
