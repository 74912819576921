export const teamRoles = [
  {
    id: 'member',
    value: 1,
    name: 'Member',
    description:
      'Members can log, view, classify their own trips and submit expenses for approval.'
  },
  {
    id: 'manager',
    value: 2,
    name: 'Manager',
    description:
      'Managers approve/return expenses submitted by the teams they manage.'
  }
];

export const roles = teamRoles.concat([
  {
    id: 'admin',
    value: 3,
    name: 'Admin',
    description: 'Admins can manage all non-billing aspects of a company account.'
  },
  {
    id: 'owner',
    value: 4,
    name: 'Owner',
    description: 'Admins can manage billing.'
  }
]);

export const rolesByName = roles.reduce((mapped, role) => {
  const { id } = role;
  mapped[id] = role;
  return mapped;
}, {});

export const roleValues = roles.reduce(
  (values, { name, value }) => ({
    ...values,
    ...(name && { [name.toLowerCase()]: value })
  }),
  {}
);

export default roles;
