import { connect } from 'react-redux';
import {
  getCurrentPage,
  getCurrentTrips,
  getTotalItems,
  getActive,
  getPages
} from 'ducks/trips/selectors';
import {
  getPage,
  classifyBusiness,
  classifyPersonal,
  setActive,
  unClassify
} from 'ducks/trips/actions';
import PaginatedTripList from './PaginatedTripList';

const mapStateToProps = state => ({
  total: getTotalItems(state),
  active: getActive(state),
  pages: getPages(state),
  currentPage: getCurrentPage(state),
  trips: getCurrentTrips(state)
});

const mapDispatchToProps = {
  getPage,
  setActive,
  classifyBusiness,
  classifyPersonal,
  unClassify
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginatedTripList);
