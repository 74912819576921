import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import InvitedUserList from './InvitedUserList';
import React, { Component } from 'react';
import AddButton from 'components/CompanyPages/lib/AddButton';
import CheckBox from 'components/CompanyPages/lib/CheckableField';
import InformationPopUp from 'components/InformationPopUp';
import InviteUsersPage from './InviteUsersPage';
import Modal from 'components/Modal';
import Filters from 'components/CompanyPages/lib/Filters';
import RenderPage from 'lib/RenderPage';
import EditUsersPage from './EditUsersPage';
import _ from 'lodash';
import './UsersPage.css';
import CSVUserInvitationsDisplay from './CSVUserInvitationsDisplay';

export default class UsersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allSelected: false,
      invitingUser: false,
      editingUsers: false,
      displayingCsv: false,
      csvResults: [],
      filters: {},
      selected: {},
      pageNumber: 1
    };

    this.search = this.search.bind(this);
    this.searchByEmail = _.debounce(this.searchByEmail.bind(this), 400, {
      trailing: true
    });

    this.openCsvDisplayModal = this.openCsvDisplayModal.bind(this);
    this.closeCsvDisplayModal = this.closeCsvDisplayModal.bind(this);
    this.getSelectedUsers = this.getSelectedUsers.bind(this);
    this.openInviteUsersModal = this.openInviteUsersModal.bind(this);
    this.closeInviteUsersModal = this.closeInviteUsersModal.bind(this);
    this.openEditUsersModal = this.openEditUsersModal.bind(this);
    this.closeEditUsersModal = this.closeEditUsersModal.bind(this);
    this.reloadAfterUserEdit = this.reloadAfterUserEdit.bind(this);
    this.loadMembers = this.loadMembers.bind(this);
    this.filterMembers = this.filterMembers.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
  }

  componentWillMount() {
    const {
      loadTeams,
      loadUsers,
      loadInvitations,
      pagNumber,
      setCompanyMembersPageSize
    } = this.props;

    [loadTeams, loadUsers, loadInvitations].forEach(
      loadResource => loadResource && loadResource()
    );

    setCompanyMembersPageSize && setCompanyMembersPageSize(15);

    this.loadMembers(pagNumber || 1);
  }

  search({ target }) {
    this.searchByEmail(target && target.value);
  }

  searchByEmail(email) {
    const {
      filters: { email: _, ...filters }
    } = this.state;

    this.filterMembers({
      ...filters,
      ...(email.length && { email })
    });
  }

  loadMembers(pageNumber) {
    const { getCompanyMembersPage } = this.props;
    const { filters } = this.state;

    getCompanyMembersPage &&
      getCompanyMembersPage({
        ...filters,
        page: pageNumber
      });

    this.setState({ pageNumber });
  }

  getSelectedUsers() {
    const { users, isSelected } = this.props;

    return users.filter(({ id }) => isSelected(id));
  }

  filterMembers(filters) {
    const status = filters.status;
    const { getCompanyMembersPage, setUserStatusFilter } = this.props;
    const { pageNumber } = this.state;

    setUserStatusFilter && setUserStatusFilter(status || null);

    getCompanyMembersPage &&
      getCompanyMembersPage({
        ...filters,
        page: pageNumber
      });

    this.setState({ filters });
  }

  reloadAfterUserEdit() {
    this.filterMembers(this.state.filters);
  }

  openInviteUsersModal() {
    this.setState({ invitingUser: true });
  }

  closeInviteUsersModal() {
    this.setState({ invitingUser: false });
  }

  openEditUsersModal() {
    this.setState({ editingUsers: true });
  }

  closeEditUsersModal() {
    this.setState({ editingUsers: false });
  }

  openCsvDisplayModal(csvResults) {
    this.setState({
      csvResults,
      invitingUser: false,
      displayingCsv: true
    });
  }

  closeCsvDisplayModal() {
    this.setState({ displayingCsv: false });
  }

  toggleSelectAll() {
    const { users, toggleSelectAll, allSelected } = this.props;
    const userIds = users.map(({ id }) => id);

    toggleSelectAll && toggleSelectAll(!allSelected && userIds);
  }

  render() {
    const {
      filters,
      isAdmin,
      requestsState,
      allSelected,
      isSelected,
      toggleSelection,
      amountSelected
    } = this.props;
    const {
      invitingUser,
      editingUsers,
      displayingCsv,
      csvResults
    } = this.state;
    const csvResultsLength = csvResults && csvResults.length;

    return (
      <div className="users-page">
        <Modal
          className="invite-users-modal"
          key="invite-users-modal"
          isOpen={invitingUser}
          title={'Invite Users'}
          onClose={this.closeInviteUsersModal}
        >
          <InviteUsersPage
            displayCsv={this.openCsvDisplayModal}
            onSubmit={this.closeInviteUsersModal}
          />
        </Modal>
        <Modal
          className="edit-users-modal"
          key="edit-users-modal"
          isOpen={editingUsers}
          title="Edit User(s)"
          onClose={this.closeEditUsersModal}
        >
          <EditUsersPage
            onSubmit={this.closeEditUsersModal}
            reload={this.reloadAfterUserEdit}
            users={this.getSelectedUsers()}
          />
        </Modal>
        <Modal
          className="display-csv-modal"
          key="display-csv-modal"
          isOpen={displayingCsv}
          title={`Previewing ${csvResultsLength} imported user invitation${
            csvResultsLength > 1 ? "'s" : ''
          }`}
          onClose={this.closeCsvDisplayModal}
        >
          <CSVUserInvitationsDisplay
            onSubmit={this.closeCsvDisplayModal}
            invitations={csvResults}
          />
        </Modal>
        <Filters {...filters} onChange={this.filterMembers} />
        <div className="users-search-and-invites">
          <CheckBox
            onClick={this.toggleSelectAll}
            isChecked={allSelected}
            className="users-select-all first-column users-page-column"
          >
            SELECT ALL
          </CheckBox>
          <div className="second-column users-page-column">
            <div className="search-box">
              <input
                onChange={this.search}
                placeholder="Search"
                className="search-input"
              />
              <div className="search-icon">
                <FontAwesome name="search" />
              </div>
            </div>
          </div>
          <div className="third-column users-page-column">
            <h1 className="team-title column-title users-page-column">Team</h1>
          </div>
          <div className="users-page-column">
            <h1 className="manager-title column-title">Expense rate</h1>
          </div>
          {isAdmin
            ? [
                <InformationPopUp className="users-page-info-pop-up">
                  In your initial 30 day trial you will have an unlimited number
                  of seats. Automatic billing will not begin until seats for
                  your members have been purchased. After the 30 day trial trips
                  will no longer be logged for members. Company members may be
                  assigned or removed from available seats at any time.
                </InformationPopUp>,
                <Link
                  className="invite-users-button add-button add-link"
                  to="/admin/settings/company_account"
                >
                  Buy Seats
                </Link>,
                <AddButton
                  className="invite-users-button"
                  onClick={this.openInviteUsersModal}
                >
                  Invite Users
                </AddButton>,
                <AddButton
                  disabled={!allSelected && !amountSelected}
                  className="invite-users-button"
                  onClick={this.openEditUsersModal}
                >
                  Edit
                </AddButton>
              ]
            : ''}
        </div>
        <RenderPage requestsState={requestsState}>
          <InvitedUserList
            onChange={toggleSelection}
            allSelected={allSelected}
            isSelected={isSelected}
            loadMembers={this.loadMembers}
          />
        </RenderPage>
      </div>
    );
  }
}
