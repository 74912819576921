export default (polyline, numberOfPoints = 2) => {
  const length = polyline.length;
  const firstPoint = polyline[0];
  const lastPoint = polyline[length - 1];
  const increment = Math.round(length / numberOfPoints);
  const points = [firstPoint];
  const lastIncrement = (numberOfPoints - 1) * increment;
  let i = increment;
  for (i; i < lastIncrement; i += increment) {
    points.push(polyline[i]);
  }
  points.push(lastPoint);
  return points;
};
