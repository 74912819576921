import React, { Component } from 'react';
import CostCenterElement from 'components/CompanyPages/Admin/SettingsPage/CostCentersPage/CostCenterElement';
import AddCostCenterPage from 'components/CompanyPages/Admin/SettingsPage/CostCentersPage/AddCostCenterPage';
import PageTitleHeader from 'components/CompanyPages/lib/PageTitleHeader';
import AddButton from 'components/CompanyPages/lib/AddButton';
import RulesList from 'components/CompanyPages/lib/RulesList';
import Filters from 'components/CompanyPages/lib/Filters';
import Modal from 'components/Modal';
import './CostCentersPage.css';

export default class CostCentersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false
    };
    this.openAddCostCenterModal = this.openAddCostCenterModal.bind(this);
    this.closeAddCostCenterModal = this.closeAddCostCenterModal.bind(this);
    this.deleteCostCenter = this.deleteCostCenter.bind(this);
  }
  componentWillMount() {
    const { loadPurposes, loadCostCenters, loadTeams } = this.props;
    loadPurposes();
    loadCostCenters();
    loadTeams();
  }
  openAddCostCenterModal() {
    this.setState({ modalIsOpen: true });
  }
  closeAddCostCenterModal() {
    this.setState({ modalIsOpen: false });
  }
  deleteCostCenter({ target }) {
    const id = target.id;
    const { removeCostCenter } = this.props;
    removeCostCenter && removeCostCenter(id);
  }
  render() {
    const { modalIsOpen } = this.state;
    const { costCenters, purposes, teams } = this.props;
    return [
      <div className="cost-centers-filter-bar-container">
        <Filters
          className="users-filter-header"
          description="Cost Centers Applicable for"
        >
          {[purposes, teams, ['Most Recent First', 'Oldest First']]}
        </Filters>
      </div>,
      <div className="cost-centers-page">
        <Modal
          className="add-cost-center-modal"
          title="Add Cost Center"
          isOpen={modalIsOpen}
          onClose={this.closeAddCostCenterModal}
        >
          <AddCostCenterPage onSubmit={this.closeAddCostCenterModal} />
        </Modal>
        <PageTitleHeader>Cost Centers</PageTitleHeader>
        <div className="cost-centers-header">
          <h1 className="cost-centers-page-description">
            Cost centers can be created and modified here. One day there will be
            text in this location describing in further detail the full scope of
            cost centers.
          </h1>
          <AddButton
            onClick={this.openAddCostCenterModal}
            className="cost-centers-page-add-button"
          >
            Add Cost Center
          </AddButton>
        </div>
        <div className="cost-center-labels">
          {[
            'Name',
            'Number',
            'Purpose (optional)',
            'Teams (optional)',
            'Created'
          ].map(title => (
            <div className="cost-center-label">
              <h1 className="cost-center-title">{title}</h1>
            </div>
          ))}
        </div>
        <RulesList
          rules={costCenters}
          className="cost-centers-list"
          onDelete={this.deleteCostCenter}
          component={args => <CostCenterElement {...args} />}
        />
      </div>
    ];
  }
}
