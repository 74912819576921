import { getAdministeredUsers } from 'api/endpoints/company/teams';
import {
  GET_COMPANY_MEMBERS_FAILURE,
  GET_COMPANY_MEMBERS_REQUEST,
  GET_COMPANY_MEMBERS_SUCCESS
} from 'ducks/company/companyActionTypes';

export const getMembersRequest = () => ({
  type: GET_COMPANY_MEMBERS_REQUEST
});

export const getMembersSuccess = payload => ({
  type: GET_COMPANY_MEMBERS_SUCCESS,
  payload
});

export const getMembersFailure = error => ({
  type: GET_COMPANY_MEMBERS_FAILURE,
  payload: error,
  error
});

export const getCompanyMembers = filters => dispatch => {
  dispatch(getMembersRequest());
  return getAdministeredUsers(filters)
    .then(({ data }) => dispatch(getMembersSuccess(data.users)))
    .catch(error => dispatch(getMembersFailure(error)));
};

export default getCompanyMembers;
