import { connect } from 'react-redux';
import {
  mapStateToConfirmInvitation,
  mapDispatchToConfirmInvitation
} from './confirmInvitationMappings';
import ConfirmInvitation from './ConfirmInvitation';

export default connect(
  mapStateToConfirmInvitation,
  mapDispatchToConfirmInvitation
)(ConfirmInvitation);
