import createReducer from 'redux/createReducer';
import { SELECT_COMPANY_LOCATION } from 'ducks/company/companyActionTypes';

export const setSelectedCompanyLocation = (selected, { payload }) =>
  payload ? payload : selected;

export const selectedCompanyLocation = createReducer(null, {
  ...[SELECT_COMPANY_LOCATION].reduce((actions, action) => {
    actions[action] = setSelectedCompanyLocation;
    return actions;
  }, {})
});

export default selectedCompanyLocation;
