import client from '../client';

export const purchaseSeats = params =>
  client.post('/stripe/purchase', { params });

export const renewSubscription = params =>
  client.post('/stripe/renew', { params });

export const saveCardDetails = params =>
  client.post('/stripe/save', { params });
