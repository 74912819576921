import { connect } from 'react-redux';
import InvitedUserElement from './InvitedUserElement';
import {
  mapStateToInvitedUserElement,
  mapDispatchToInvitedUserElement
} from './invitedUserElementMappings';

export default connect(
  mapStateToInvitedUserElement,
  mapDispatchToInvitedUserElement
)(InvitedUserElement);
