import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { Map, Marker } from 'react-leaflet';
import startIconPath from 'lib/locationPointImages/location-pin-A.png';
import TileLayer from 'components/TileLayer';
import './LocationsMap.css';

const INITIAL_MAP_FOCUS = [47.6062, -122.3321];
export default class AddTripMap extends Component {
  static propTypes = {
    coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    focus: PropTypes.arrayOf(PropTypes.number)
  };
  constructor(props) {
    super(props);
    this.icon = L.icon({
      iconUrl: startIconPath,
      iconSize: [30, 39],
      iconAnchor: [15, 38]
    });
  }
  render() {
    const { coordinates, focus } = this.props;
    return (
      <Map
        className="my-locations-map"
        boundsOptions={{ padding: [40, 40] }}
        center={focus || INITIAL_MAP_FOCUS}
        zoom={13}
      >
        <TileLayer />
        {coordinates &&
          coordinates.length &&
          coordinates.map((coordinate, index) => {
            return (
              <Marker key={index + 1} icon={this.icon} position={coordinate} />
            );
          })}
      </Map>
    );
  }
}
