import { connect } from 'react-redux';
import UserCreatedLocationForm from './UserCreatedLocation';
import AdminCreatedLocationForm from './AdminCreatedLocation';
import { mapDispatchToLocation } from './LocationMappings';
export const UserCreatedLocation = connect(null, mapDispatchToLocation)(
  UserCreatedLocationForm
);
export const AdminCreatedLocation = connect(null, mapDispatchToLocation)(
  AdminCreatedLocationForm
);
