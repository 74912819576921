const nameSpace = 'locations';

export const ADD_LOCATION_REQUEST = `${nameSpace}/ADD_LOCATION_REQUEST`;
export const ADD_LOCATION_SUCCESS = `${nameSpace}/ADD_LOCATION_SUCCESS`;
export const ADD_LOCATION_FAILURE = `${nameSpace}/ADD_LOCATION_FAILURE`;

export const UPDATE_LOCATION_REQUEST = `${nameSpace}/UPDATE_LOCATION_REQUEST`;
export const UPDATE_LOCATION_SUCCESS = `${nameSpace}/UPDATE_LOCATION_SUCCESS`;
export const UPDATE_LOCATION_FAILURE = `${nameSpace}/UPDATE_LOCATION_FAILURE`;

export const DELETE_LOCATION_REQUEST = `${nameSpace}/DELETE_LOCATION_REQUEST`;
export const DELETE_LOCATION_SUCCESS = `${nameSpace}/DELETE_LOCATION_SUCCESS`;
export const DELETE_LOCATION_FAILURE = `${nameSpace}/DELETE_LOCATION_FAILURE`;

export const HIDE_LOCATION_REQUEST = `${nameSpace}/HIDE_LOCATION_REQUEST`;
export const HIDE_LOCATION_SUCCESS = `${nameSpace}/HIDE_LOCATION_SUCCESS`;
export const HIDE_LOCATION_FAILURE = `${nameSpace}/HIDE_LOCATION_FAILURE`;

export const SELECT_LOCATION = `${nameSpace}/SELECT_LOCATION`;
export const ADD_LOCATION = `${nameSpace}/ADD_LOCATION`;
export const ADDRESS_NOT_FOUND = `${nameSpace}/ADDRESS_NOT_FOUND`;
export const STORE_LOCATION = `${nameSpace}/STORE_LOCATION`;
export const REMOVE_STORED_LOCATION = `${nameSpace}/REMOVE_STORED_LOCATION`;
export const REMOVE_LOCATION_IF_STORED = `${nameSpace}/REMOVE_LOCATION_IF_STORED`;

export const ADD_COMPANY_LOCATION_REQUEST = `${nameSpace}/ADD_LOCATION_REQUEST`;
export const ADD_COMPANY_LOCATION_SUCCESS = `${nameSpace}/ADD_LOCATION_SUCCESS`;
export const ADD_COMPANY_LOCATION_FAILURE = `${nameSpace}/ADD_LOCATION_FAILURE`;

export const UPDATE_COMPANY_LOCATION_REQUEST = `${nameSpace}/UPDATE_LOCATION_REQUEST`;
export const UPDATE_COMPANY_LOCATION_SUCCESS = `${nameSpace}/UPDATE_LOCATION_SUCCESS`;
export const UPDATE_COMPANY_LOCATION_FAILURE = `${nameSpace}/UPDATE_LOCATION_FAILURE`;

export const DELETE_COMPANY_LOCATION_REQUEST = `${nameSpace}/DELETE_LOCATION_REQUEST`;
export const DELETE_COMPANY_LOCATION_SUCCESS = `${nameSpace}/DELETE_LOCATION_SUCCESS`;
export const DELETE_COMPANY_LOCATION_FAILURE = `${nameSpace}/DELETE_LOCATION_FAILURE`;

export const HIDE_COMAPANY_LOCATION_REQUEST = `${nameSpace}/HIDE_LOCATION_REQUEST`;
export const HIDE_COMAPANY_LOCATION_SUCCESS = `${nameSpace}/HIDE_LOCATION_SUCCESS`;
export const HIDE_COMAPANY_LOCATION_FAILURE = `${nameSpace}/HIDE_LOCATION_FAILURE`;

export const SELECT_COMPANY_LOCATION = `${nameSpace}/SELECT_LOCATION`;
export const ADD_COMPANY_LOCATION = `${nameSpace}/ADD_LOCATION`;
export const ADDRESS_COMAPANY_NOT_FOUND = `${nameSpace}/ADDRESS_NOT_FOUND`;
export const STORE_COMPANY_LOCATION = `${nameSpace}/STORE_LOCATION`;
export const REMOVE_COMPANY_STORED_LOCATION = `${nameSpace}/REMOVE_STORED_LOCATION`;
export const REMOVE_COMPANY_LOCATION_IF_STORED = `${nameSpace}/REMOVE_LOCATION_IF_STORED`;
