import client from '../../client';

export const getTrips = (page, pageSize, filters, user_id) =>
  client.get('/trips', {
    params: {
      user_id,
      page,
      pageSize: pageSize,
      ...filters
    }
  });

export const postTrips = trip => client.post('/trips', { trip });

export const putTrips = (ids, params) =>
  client.put('/trips', { tripIds: ids, ...params });

export const deleteTrips = ids =>
  client.delete('/trips', { params: { trip_ids: ids } });

export const putTrip = (id, trip) => client.put(`/trips/${id}`, { trip });

export const postTripCopy = (id, fromDate, toDate) =>
  client.post(`/trips/${id}/copy`, { fromDate, toDate });

export const deleteTrip = id => client.delete(`/trip/${id}`);

export const getStatistics = filters =>
  client.get('/statistics', { params: filters });

export const mergeTrips = tripIds => client.post('/trips/merge', { tripIds });
