import React, { Component } from 'react';
import DropDown from 'components/CompanyPages/lib/DropDownSelector';
import AddButton from 'components/CompanyPages/lib/AddButton';
import renderCompanyTextField from 'components/CompanyPages/lib/CompanyFields/renderCompanyField';
import CompanyFormError from 'components/CompanyPages/lib/CompanyFormError';
import ExpenseRateField from 'components/CompanyPages/lib/ExpenseRateField';
import { required } from 'lib/validators';
import { reduxForm, Field } from 'redux-form';
import './AddTeamPage.css';

const form = 'add-team-page';

export default reduxForm({ form })(
  class AddTeamPage extends Component {
    constructor(props) {
      super(props);

      this.state = {
        managerId: null,
        costCenterId: null,
        managerName: null,
        costCenterName: null,
        customBusinessRate: null
      };

      this.addTeam = this.addTeam.bind(this);
      this.selectCostCenter = this.selectCostCenter.bind(this);
      this.selectManager = this.selectManager.bind(this);
    }

    addTeam({ teamName }) {
      const { createTeam, onSubmit, onClose } = this.props;
      const { managerId } = this.state;
      const managers = !isNaN(managerId) ? [managerId] : [];

      createTeam(teamName, [], managers);
      onSubmit && onSubmit({ managerId, teamName, managers });
      onClose && onClose();
    }

    selectCostCenter({ target }) {
      const { costCenters } = this.props;
      const costCenterIndex = target.id;
      const costCenter = costCenters[costCenterIndex];

      if (costCenter) {
        this.setState({
          costCenterId: costCenter.id,
          costCenterName: costCenter.name
        });
      }
    }

    selectManager({ target }) {
      const { users } = this.props;
      const managerIndex = target.id;
      const manager = users[managerIndex];

      if (manager) {
        this.setState({
          managerId: manager.id,
          managerName: manager.name
        });
      }
    }

    render() {
      const { managerName, costCenterName } = this.state;
      const {
        users,
        costCenters,
        handleSubmit,
        submitting,
        error,
        valid
      } = this.props;

      return (
        <form onSubmit={handleSubmit(this.addTeam)} className="add-team-page">
          <CompanyFormError>{error}</CompanyFormError>
          <div className="add-team-field">
            <h1 className="add-team-field-title">Team Name</h1>
            <Field
              name="teamName"
              className="add-team-name-input"
              component={renderCompanyTextField}
              validate={required}
            />
          </div>
          {[
            [users, 'Team Manager', this.selectManager, managerName]
            // [
            //   costCenters,
            //   'Cost Center (optional)',
            //   this.selectCostCenter,
            //   costCenterName
            // ]
          ].map(([array, title, select, name]) => (
            <div className="add-team-field">
              <h1 className="add-team-field-title">{title}</h1>
              <DropDown
                title={name || 'Select'}
                className="add-team-field-drop-down"
              >
                {array &&
                  array.map(({ name }, index) => (
                    <div
                      id={index}
                      className="add-team-field-drop-down-element"
                      onClick={select}
                    >
                      <h1
                        id={index}
                        className="add-team-field-drop-down-element-text"
                      >
                        {name}
                      </h1>
                    </div>
                  ))}
              </DropDown>
            </div>
          ))}
          <div className="add-team-field">
            <h1 className="add-team-field-title">Expense rate</h1>
            <ExpenseRateField name="customBusinessRate" placeholder="Rate" />
          </div>
          <AddButton
            disabled={submitting || !valid}
            className="add-team-page-button"
          >
            Add
          </AddButton>
        </form>
      );
    }
  }
);
