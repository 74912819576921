import React from 'react';
import DropDownSelector from 'components/CompanyPages/lib/DropDownSelector';
import './CompanyDropDown.css';

export default ({ children, value, className, onChange, disabled }) => (
  <DropDownSelector
    disabled={disabled}
    className={`company-drop-down-selector${className ? ` ${className}` : ''}`}
    title={value}
  >
    {children &&
      children.map &&
      children
        .map(
          ({ name }, index) =>
            name !== value && (
              <div
                key={`${name}-company-drop-down-element-${index}`}
                className="company-drop-down-element"
                id={index}
                onClick={onChange}
              >
                <h1 className="company-drop-down-text" id={index}>
                  {name}
                </h1>
              </div>
            )
        )
        .filter(element => !!element)}
  </DropDownSelector>
);
