import { connect } from 'react-redux';
import ExpenseComparisonBarChart from './ComparisonBarChart';
import {
  mapStateToComparisonBarChart,
  mapDispatchToComparisonBarChart
} from './comparisonBarChartMappings';

export default connect(
  mapStateToComparisonBarChart,
  mapDispatchToComparisonBarChart
)(ExpenseComparisonBarChart);
