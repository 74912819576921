import { connect } from 'react-redux';
import {
  mapStateToGenerateReportsPage,
  mapDispatchToGenerateReportsPage
} from './generateReportsPageMappings';
import GenerateReportsPage from './GenerateReportsPage';

export default connect(
  mapStateToGenerateReportsPage,
  mapDispatchToGenerateReportsPage
)(GenerateReportsPage);
