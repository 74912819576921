import { getCompanyStatistics } from 'api/endpoints/company/statistics';
import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import {
  GET_COMPANY_STATISTICS_REQUEST,
  GET_COMPANY_STATISTICS_SUCCESS,
  GET_COMPANY_STATISTICS_FAILURE
} from 'ducks/company/companyActionTypes';
import filtersToParams from 'ducks/trips/lib/filtersToParams';

export const getStatistics = createSimpleAction(
  (params = {}) =>
    getCompanyStatistics(filtersToParams({ ...params, timeScopeMode: true })),
  [
    GET_COMPANY_STATISTICS_REQUEST,
    GET_COMPANY_STATISTICS_SUCCESS,
    GET_COMPANY_STATISTICS_FAILURE
  ],
  'statistics'
);
