import React, { Component } from 'react';
import EditTripForm from 'components/EditTripForm';
import RecalculatedTrip from './RecalculatedTripMap';
import load from 'components/loadFormWrapper';
import Distance from 'components/Distance';
import './RecalculateTripRoute.css';

const RecalculatedTripMap = load(RecalculatedTrip);

export default class RecalculateTripRouteForm extends Component {
  componentDidMount() {
    const { tripId, getRecalculatedRoute } = this.props;

    getRecalculatedRoute(tripId);
  }

  render() {
    const {
      trip,
      startingPoint,
      endingPoint,
      polyline,
      distance,
      unitOfMeasure,
      status,
      ...props
    } = this.props;

    return (
      <EditTripForm
        buttonText={'Save'}
        saveIcon={'retweet'}
        trip={trip}
        {...props}
      >
        <div
          id={'recalculated-trip-map-wrapper'}
          className="recalculated-trip-map-wrapper"
        >
          <RecalculatedTripMap
            id={'recalculate-trip-map'}
            {...{ startingPoint, endingPoint, polyline, status }}
          />
        </div>
        {distance && (
          <p className="recalculated-distance">
            Distance: <Distance>{distance}</Distance>
          </p>
        )}
      </EditTripForm>
    );
  }
}
