import React from 'react';
import DateTimeSelector from 'components/DateTimeSelector';
import DistanceSelector from 'components/DistanceSelector';
import EditTripForm from 'components/EditTripForm';
import './EditTripDistanceAndTime.css';
export default ({ trip, ...props }) => (
  <EditTripForm buttonText={'Ok'} saveIcon={'pencil'} trip={trip} {...props}>
    <DateTimeSelector
      className="edit-from-date"
      defaultTime={trip.startTime}
      title={'from'}
    />
    <DateTimeSelector
      className="edit-to-date"
      defaultTime={trip.endTime}
      title={'to'}
    />
    <DistanceSelector
      className="edit-trip-distance"
      distance={trip.distance}
      title={'distance'}
    />
  </EditTripForm>
);
