import addSuccessAndFailureMessages from 'ducks/lib/addSuccessAndFailureMessages';
import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { postCompanyPurposes } from 'api/endpoints/company/purposes';
import {
  POST_COMPANY_PURPOSE_REQUEST,
  POST_COMPANY_PURPOSE_SUCCESS,
  POST_COMPANY_PURPOSE_FAILURE
} from 'ducks/company/companyActionTypes';

export const addCompanyPurpose = addSuccessAndFailureMessages(
  createSimpleAction(
    postCompanyPurposes,
    [
      POST_COMPANY_PURPOSE_REQUEST,
      POST_COMPANY_PURPOSE_SUCCESS,
      POST_COMPANY_PURPOSE_FAILURE
    ],
    'purpose'
  ),
  'Purpose added',
  'Unable to create purpose'
);

export default addCompanyPurpose;
