import React from 'react';
import classNames from 'class-names';
import ReactMarkdown from 'react-markdown';

const Typography = ({ className, markdown, children }) => (
  <div className={classNames('typography', className)}>
    {markdown && <ReactMarkdown source={markdown} />}
    {children}
  </div>
);

export default Typography;
