import { getUserRole } from 'ducks/users/usersSelectors/getUserRole';
import { rolesByName } from 'components/CompanyPages/lib/roles';

export const mapStateToViewNavigator = (state, { path: currentPath }) => {
  const userRole = getUserRole(state);
  const role = rolesByName[userRole];
  const title = role && role.name;
  const defaultTitle = 'Personal';
  const strippedPath = currentPath.replace('/admin', '');
  const defaultLink = { path: strippedPath, title: defaultTitle };
  const links = [
    { nameSpace: 'admin', path: `/admin${strippedPath}`, title }
    // { nameSpace: 'live', path: '/live', title: 'Live' },
  ];

  return {
    links: [...links, defaultLink],
    title: links.reduce((selected, { nameSpace, title }) => {
      return currentPath &&
        currentPath.includes &&
        currentPath.includes(nameSpace)
        ? title
        : selected;
    }, defaultTitle)
  };
};

export const mapDispatchToViewNavigator = {};
