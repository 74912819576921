import React from 'react';
import { connect } from 'react-redux';
import DropDown from 'components/DropDown';
import { getUsersCountry, getAllAlphabetical } from 'ducks/countries/selectors';
import _ from 'lodash';
const mapStateToProps = state => ({
  currentCountry: getUsersCountry(state),
  countryList: getAllAlphabetical(state)
});
const CountryDropDown = ({
  countryList,
  value,
  onChange,
  currentCountry,
  className,
  ...props
}) => {
  const countryId = _.isNumber(value)
    ? value
    : currentCountry && currentCountry.id;
  const country = countryList.find(c => c.id === countryId);
  let compare = function(a, b) {
    if (a.priority > b.priority) {
      return -1;
    }
    if (b.priority > a.priority) {
      return 1;
    };
    return a.fullName > b.fullName;
  };


  return (
    <DropDown
      value={country ? country.fullName : 'Select Country'}
      options={
        countryList && countryList.length
          ? countryList.sort(compare).reduce(
              (cs, c) => (c.fullName ? [...cs, [c.id, c.fullName]] : cs),
              []
            )
          : []
      }
      onSelect={onChange}
      className={className}
      {...props}
    />
  );
};
export default connect(mapStateToProps)(CountryDropDown);
