import { connect } from 'react-redux';
import InvitedUserList from './InvitedUserList';
import {
  mapDispatchToInvitedUserList,
  mapStateToInvitedUserList
} from './invitedUserListMappings';

export default connect(
  mapStateToInvitedUserList,
  mapDispatchToInvitedUserList
)(InvitedUserList);
