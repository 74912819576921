import { getTeam } from './getTeam';
import { getCompanyUsersObject } from 'ducks/company/companySelectors/companyMembers/getCompanyUsersObject';
import { sortByEmail } from 'ducks/company/lib/sortByEmail';

export const getTeamManagers = (state, teamId) => {
  const team = getTeam(state, teamId);
  const users = getCompanyUsersObject(state);
  const managers = team && team.managers;

  return managers && managers.length
    ? sortByEmail(
        managers.reduce((foundManagers, id) => {
          const manager = users[id];
          return manager ? [manager, ...foundManagers] : foundManagers;
        }, [])
      )
    : [];
};

export default getTeamManagers;
