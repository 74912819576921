import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import Trigger from 'rc-trigger';
import { reduxForm, Field } from 'redux-form';
import ContentWrap from 'components/ContentWrap';
import Panel from 'components/Panel';
import Money from 'components/Money';
import TextBoxField from 'components/TextBoxField';
import VehicleSelector from 'components/VehicleSelector';
import Button from 'components/Button';
import Sidebar from 'components/Sidebar';
import CompactTrip from '../CompactTrip';
import Value from 'components/Value';
import PurposesMenu from 'components/PurposesMenu';
import purposes from 'ducks/purposes';

import NotesIcon from '../AddTripPage/AddTripDetails/icon-edit.gif';
import ParkingIcon from '../AddTripPage/AddTripDetails/icon-p.gif';
import TollsIcon from '../AddTripPage/AddTripDetails/icon-s.gif';
import VehicleIcon from '../AddTripPage/AddTripDetails/icon-car.gif';

const renderVehicleField = ({ input: { value, onChange } }) => (
  <VehicleSelector selectedId={value} onSelect={onChange} />
);

const renderPurposesField = connect((state, { input: { value } }) => ({
  purpose: purposes.selectors.getById(state, value)
}))(({ input: { value, onChange }, purpose }) => (
  <Trigger
    action={['click']}
    popup={
      <PurposesMenu value={value} onChange={onChange} includeDefault left />
    }
    popupAlign={{ points: ['cr', 'cl'], offset: [-20, 0] }}
  >
    <Button type="button">{purpose ? purpose.name : 'Bulk Classify'}</Button>
  </Trigger>
));

class EditMultiplePage extends Component {
  render() {
    const {
      ids,
      handleSubmit,
      submitting,
      submitSucceeded,
      error,
      updateSelectedTrips,
      total,
      potential
    } = this.props;

    return (
      <ContentWrap>
        {submitSucceeded && <Redirect to="/trips" />}

        <Panel title="Trips" sidebar>
          <Scrollbars>
            {ids.map(id => (
              <CompactTrip key={id} id={id} />
            ))}
          </Scrollbars>
        </Panel>

        <Sidebar title="Bulk Edit">
          <form
            onSubmit={handleSubmit(updateSelectedTrips)}
            className="edit-multiple__sidebar"
          >
            {error && <div className="edit-multiple__error">{error}</div>}

            <div className="edit-multiple__total">{ids.length} trips</div>
            <div className="edit-multiple__values">
              <div>
                <Value className="edit-multiple-value" type="Business" big>
                  <Money value={total} round />
                </Value>
                <div className="edit-multiple__values__label">Classified</div>
              </div>
              <div className="edit-multiple__seperator" />
              <div>
                <Value className="edit-multiple-value" big>
                  <Money value={potential} round />
                </Value>
                <div className="edit-multiple__values__label">Potential</div>
              </div>
            </div>

            <div className="edit-multiple__classify-btn">
              <Field component={renderPurposesField} name="purposeId" />
            </div>

            <div className="edit-multiple__form">
              <div className="edit-multiple__control">
                <div className="edit-multiple__control__icon">
                  <img src={NotesIcon} alt="notes" />
                </div>

                <div className="edit-multiple__control__input">
                  <Field
                    component="textarea"
                    className="textbox"
                    name="notes"
                    placeholder="Notes"
                  />
                </div>
              </div>

              <div className="edit-multiple__control">
                <div className="edit-multiple__control__icon">
                  <img src={TollsIcon} alt="tolls" />
                </div>

                <div className="edit-multiple__control__input">
                  <TextBoxField placeholder="Tolls" name="tollExpense" />
                </div>
              </div>

              <div className="edit-multiple__control">
                <div className="edit-multiple__control__icon">
                  <img src={ParkingIcon} alt="parking" />
                </div>

                <div className="edit-multiple__control__input">
                  <TextBoxField placeholder="Parking" name="parkingExpense" />
                </div>
              </div>

              <div className="edit-multiple__control">
                <div className="edit-multiple__control__icon">
                  <img src={VehicleIcon} alt="vehicle" />
                </div>

                <div className="edit-multiple__control__input">
                  <Field name="vehicleId" component={renderVehicleField} />
                </div>
              </div>
            </div>

            <div className="edit-multiple__buttons">
              <Button
                className="edit-multiple-button"
                color="save"
                disabled={submitting}
                fullWidth
              >
                {submitting ? <span>Applying&hellip;</span> : 'Apply'}
              </Button>
              <Button
                className="edit-multiple-button"
                color="cancel"
                to="/trips"
                link
                fullWidth
              >
                Back
              </Button>
            </div>
          </form>
        </Sidebar>
      </ContentWrap>
    );
  }
}

export default reduxForm({ form: 'edit-multiple' })(EditMultiplePage);
