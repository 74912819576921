import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Redirect } from 'react-router-dom';
import PreLoginForm from 'components/PreLogin/PreLoginForm';
import PreLoginButton from 'components/PreLogin/PreLoginButton';
import api from 'api';
import notifications from 'ducks/notifications';
import * as validators from 'lib/validators';
import TextBoxField from 'components/TextBoxField';

const validations = {
  password: validators.compose(
    validators.required,
    validators.passwordLength,
    validators.passwordComplexity
  ),
  passwordConfirmation: validators.compose(
    validators.required,
    validators.passwordConfirmation
  )
};

const ResetPasswordForm = ({
  handleSubmit,
  error,
  pristine,
  submitSucceeded,
  submitting,
  dispatch,
  token,
  legacy
}) => (
  <PreLoginForm
    onSubmit={handleSubmit(({ password, passwordConfirmation }) =>
      api
        .putResetPassword(token, password, passwordConfirmation, legacy)
        .then(() =>
          dispatch(notifications.actions.notify('success', 'Password changed'))
        )
    )}
    error={error}
  >
    {!submitting && submitSucceeded && <Redirect to="/sign_in" />}

    <TextBoxField
      type="password"
      name="password"
      placeholder="New Password"
      validate={validations.password}
      prelogin
    />

    <TextBoxField
      type="password"
      name="passwordConfirmation"
      placeholder="Confirm New Password"
      validate={validations.passwordConfirmation}
      prelogin
    />

    <PreLoginButton disabled={pristine || submitting}>
      {submitting ? <span>Resetting&hellip;</span> : 'Reset password'}
    </PreLoginButton>
  </PreLoginForm>
);

export default reduxForm({ form: 'reset-password' })(
  connect()(ResetPasswordForm)
);
