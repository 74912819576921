import companyWorkHours from './companyWorkHours';
import userWorkHours from './userWorkHours';
import requestsById from './requestsById';
import requests from './requests';
import { combineReducers } from 'redux';

export default combineReducers({
  userWorkHours,
  companyWorkHours,
  requestsById,
  requests
});
