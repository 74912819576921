import { createRequestsByIdReducer } from 'redux/requestsByIdReducer';
import {
  UPDATE_TEAM_WORK_HOURS_FAILURE,
  UPDATE_TEAM_WORK_HOURS_REQUEST,
  UPDATE_TEAM_WORK_HOURS_SUCCESS
} from 'ducks/workHours/actionTypes';

export default createRequestsByIdReducer([
  [
    UPDATE_TEAM_WORK_HOURS_REQUEST,
    UPDATE_TEAM_WORK_HOURS_SUCCESS,
    UPDATE_TEAM_WORK_HOURS_FAILURE
  ]
]);
