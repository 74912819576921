import React from 'react';
import DateTimeSelector from 'components/DateTimeSelector';
import EditTripForm from 'components/EditTripForm';
import './AddReturnTripForm.css';
export default props => (
  <EditTripForm buttonText={'Save'} saveIcon={'car'} {...props}>
    <DateTimeSelector
      className="return-trip-from"
      defaultTime={props.startTime}
      title={'from'}
    />
    <DateTimeSelector
      className="return-trip-to"
      defaultTime={props.endTime}
      title={'to'}
    />
  </EditTripForm>
);
