import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { confirmUserInvitation } from 'api/endpoints/company/invitations';
import {
  CONFIRM_INVITATION_REQUEST,
  CONFIRM_INVITATION_SUCCESS,
  CONFIRM_INVITATION_FAILURE
} from 'ducks/company/companyActionTypes';

export const confirmInvitation = createSimpleAction(
  confirmUserInvitation,
  [
    CONFIRM_INVITATION_REQUEST,
    CONFIRM_INVITATION_SUCCESS,
    CONFIRM_INVITATION_FAILURE
  ],
  'user'
);

export default confirmInvitation;
