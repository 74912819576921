const nameSpace = `session`;

export const COMPANY_SIGN_IN = `${nameSpace}/COMPANY_SIGN_IN`;
export const SIGN_IN = `${nameSpace}/SIGN_IN`;
export const INVALIDATE = `${nameSpace}/INVALIDATE`;
export const REDIRECT = `${nameSpace}/REDIRECT`;

export const SET_MOBILE = `${nameSpace}/SET_MOBILE`;
export const SUBSCRIPTION_INACTIVE = `${nameSpace}/SUBSCRIPTION_INACTIVE`;
export const INVALID_EMAIL_OR_PASSWORD = `${nameSpace}/INVALID_EMAIL_OR_PASSWORD`;

export const COMPANY_SIGN_IN_REQUEST = `${nameSpace}/COMPANY_SIGN_IN_REQUEST`;
export const COMPANY_SIGN_IN_SUCCESS = `${nameSpace}/COMPANY_SIGN_IN_SUCCESS`;
export const COMPANY_SIGN_IN_FAILURE = `${nameSpace}/COMPANY_SIGN_IN_FAILURE`;
