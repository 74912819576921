import { globalWorkHoursObject } from 'ducks/company/lib/globalWorkHoursObject';
import { getSelectedWorkHoursId } from './getSelectedWorkHoursId';
import { getTeam } from 'ducks/teams/teamsSelectors/getTeam';

export const getWorkHoursSelection = state => {
  const selectedWorkHoursId = getSelectedWorkHoursId(state);
  return getTeam(state, selectedWorkHoursId) || globalWorkHoursObject;
};

export default getWorkHoursSelection;
