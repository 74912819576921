import React from 'react';
import FilterDropdown from 'components/FilterDropdown';

export default ({ value, onChange, locations }) => {
  const selected = locations.filter(({ id }) => id === value)[0];
  const name = selected && selected.name;
  const labels = locations.map(({ id, name }) => [id, name]);
  return (
    <FilterDropdown
      className="trip-filters__item dropdown_filter"
      icon="pencil"
      value={name || 'Locations'}
      options={[
        ...(locations.length
          ? [[null, 'All'], ...labels]
          : [[null, 'None'], ...labels])
      ]}
      onSelect={onChange}
      selected={value}
    />
  );
};
