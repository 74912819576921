import {
  COMPANY_SIGN_UP_FAILURE,
  COMPANY_SIGN_UP_SUCCESS,
  COMPANY_SIGN_UP_REQUEST,
  COMPANY_INVITE_USER_FAILURE,
  COMPANY_INVITE_USER_REQUEST,
  COMPANY_INVITE_USER_SUCCESS,
  REMOVE_USER_FROM_COMPANY_REQUEST,
  REMOVE_USER_FROM_COMPANY_SUCCESS,
  REMOVE_USER_FROM_COMPANY_FAILURE
} from 'ducks/company/companyActionTypes';
import { createRequestsByIdReducer } from 'redux/requestsByIdReducer';
export default createRequestsByIdReducer([
  [COMPANY_SIGN_UP_REQUEST, COMPANY_SIGN_UP_SUCCESS, COMPANY_SIGN_UP_FAILURE],
  [
    COMPANY_INVITE_USER_REQUEST,
    COMPANY_INVITE_USER_SUCCESS,
    COMPANY_INVITE_USER_FAILURE
  ],
  [
    REMOVE_USER_FROM_COMPANY_REQUEST,
    REMOVE_USER_FROM_COMPANY_SUCCESS,
    REMOVE_USER_FROM_COMPANY_FAILURE
  ]
]);
