import React from 'react';
import classNames from 'class-names';
import Money from 'components/Money';

import './Rate.css';

const Rate = ({ value, className, metric }) => (
  <span className={classNames('rate', className)}>
    <Money value={value} rate metric={metric} />
  </span>
);

export default Rate;
