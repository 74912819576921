import React from 'react';
import { NavLink } from 'react-router-dom';
import NavIcon from './NavIcon';
import SignOutButtonContainer from './SignOutButtonContainer';
import WhatsNew from 'components/WhatsNew';

import './Nav.css';

const routes = [
  { route: '/trips', icon: 'home', current: 'Trips', message: 'View Trips' },
  {
    route: '/statistics',
    icon: 'stats',
    current: 'Statistics',
    message: 'Statistics'
  },
  {
    route: '/reports',
    icon: 'reports',
    current: 'Reports',
    message: 'Reports'
  },
  {
    route: '/settings',
    icon: 'settings',
    current: 'Settings',
    message: 'Settings'
  }
];

const Nav = ({ admin, adminRole }) => (
  <div className="nav">
    {routes.map(({ route, icon, message }) => (
      <NavLink key={route} to={admin ? `/admin${route}` : route}>
        <NavIcon icon={icon} title={message} />
      </NavLink>
    ))}


    { adminRole ? (<a href="https://api.milecatcher.com/companies/users" rel="noopener noreferrer"><NavIcon icon="teams" title="Teams" /></a>) : ``}


    <div className="nav-seperator" />

    <a
      href="https://support.milecatcher.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      <NavIcon icon="help" title="Get help" />
    </a>
    <SignOutButtonContainer />
  </div>
);

export default Nav;
