import { getByUserAndVehicleId } from 'ducks/vehicles/selectors';
import { getCompanyMemberById } from 'ducks/company/companySelectors/companyMembers/getCompanyMemberById';
import { getById as getPurposeById } from 'ducks/purposes/selectors';
import { formatDistance } from 'lib/formatDistance';

export const mapStateToExpensedTrip = (state, { trip }) => {
  const { vehicleId, userId, purposeId, distance } = trip || {};
  const purpose = getPurposeById(state, purposeId);
  return {
    vehicle: getByUserAndVehicleId(state, userId, vehicleId),
    purpose: purpose && purpose.name,
    getUser: () => getCompanyMemberById(state, userId),
    distance: formatDistance(state, distance)
  };
};

export const mapDispatchToExpensedTrip = {};
