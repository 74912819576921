import { connect } from 'react-redux';
import { getPage } from 'ducks/reports/actions';
import {
  getTotalCount,
  getPages,
  getCurrentPage,
  getCurrentPageRequestState
} from 'ducks/reports/selectors';
import PaginatedReportList from './PaginatedReportList';

const mapStateToProps = state => ({
  total: getTotalCount(state),
  pages: getPages(state),
  currentPage: getCurrentPage(state),
  requestState: getCurrentPageRequestState(state)
});

const mapDispatchToProps = { getPage };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginatedReportList);
