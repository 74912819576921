import React, { Component } from 'react';
import moment from 'moment';
import { Field } from 'redux-form';
import DatePicker from 'react-datepicker';
import TextBox from 'components/TextBox';
import Checkbox from 'components/Checkbox';
import * as validators from 'lib/validators';
import EditTripForm from 'components/EditTripForm';
import './CopyForm.css';
const renderDatePickerField = ({ input: { value, onChange } }) => (
  <DatePicker
    maxDate={moment()}
    selected={value && moment(value)}
    onChange={value => onChange(value.format())}
    dateFormat="LL"
    customInput={<TextBox />}
  />
);

const FormCtrl = ({ className, label, ...props }) => (
  <div className={`copy-form__form-ctrl${className ? ' ' + className : ''}`}>
    {label && <label htmlFor={props.name}>{label}</label>}
    <Field {...props} />
  </div>
);

export default class CopyForm extends Component {
  constructor(props) {
    super(props);
    this.state = { range: false };
  }
  render() {
    const { range } = this.state;
    return (
      <EditTripForm buttonText={'Copy'} saveIcon={'clone'} {...this.props}>
        <div className="copy-form__form-ctrl">
          <label>Copy to</label>
          <div>
            <Checkbox
              name="range"
              label="Date"
              type="radio"
              checked={!range}
              onChange={() => this.setState({ range: false })}
            />

            <Checkbox
              name="range"
              label="Range"
              type="radio"
              checked={range}
              onChange={() => this.setState({ range: true })}
            />
          </div>
        </div>
        <div className="copy-form-date-selectors">
          <FormCtrl
            className="copy-form-date-selector"
            label={range ? 'From' : 'Date'}
            name="fromDate"
            component={renderDatePickerField}
            validate={validators.required}
          />

          {range && (
            <FormCtrl
              className="copy-form-date-selector"
              label="To"
              name="toDate"
              component={renderDatePickerField}
            />
          )}
        </div>
      </EditTripForm>
    );
  }
}
