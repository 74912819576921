import { createRequestsReducer } from 'redux/requestReducer';
import {
  GET_COMPANY_MEMBERS_FAILURE,
  GET_COMPANY_MEMBERS_REQUEST,
  GET_COMPANY_MEMBERS_SUCCESS
} from 'ducks/company/companyActionTypes';
import {
  GET_TEAMS_FAILURE,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS
} from 'ducks/teams/teamActionTypes';
import {
  GET_COST_CENTERS_FAILURE,
  GET_COST_CENTERS_REQUEST,
  GET_COST_CENTERS_SUCCESS
} from 'ducks/costCenters/costCentersActionTypes';

export default createRequestsReducer([
  [
    GET_COST_CENTERS_REQUEST,
    GET_COST_CENTERS_SUCCESS,
    GET_COST_CENTERS_FAILURE
  ],
  [
    GET_COMPANY_MEMBERS_REQUEST,
    GET_COMPANY_MEMBERS_SUCCESS,
    GET_COMPANY_MEMBERS_FAILURE
  ],
  [GET_TEAMS_REQUEST, GET_TEAMS_SUCCESS, GET_TEAMS_FAILURE]
]);
