import React from 'react';
import { reduxForm } from 'redux-form';
import Button from 'components/Button';
import TextboxField from 'components/TextBoxField';
import RateField from 'components/RateField';
import ValidationError from 'components/ValidationError';
import { required } from 'lib/validators';
import './AddPurposeForm.css';

const AddPurposeForm = ({ handleSubmit, pristine, error, submitting }) => (
  <form className="add-purpose-form" onSubmit={handleSubmit}>
    <div className="add-purpose-form__title">Add Purpose</div>

    {error && <ValidationError message={error} />}

    <div className="add-purpose-form__body">
      <TextboxField
        name="name"
        className="add-purpose-form__name"
        placeholder="Name"
        validate={required}
      />

      <RateField
        name="rate"
        placeholder="Rate"
        validate={required}
        activeUser
      />

      <div className="add-purpose-form__submit">
        <Button disabled={pristine || submitting}>
          {submitting ? 'Creating' : 'Create'}
        </Button>
      </div>
    </div>
  </form>
);

export default reduxForm({ form: 'addPurpose' })(AddPurposeForm);
