import React, { Component } from 'react';
import './Checkbox.css';

export default class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck(data) {
    const { onChange } = this.props;

    return onChange && onChange(data);
  }

  render() {
    const {
      id,
      label,
      dark,
      className,
      children,
      checked,
      ...props
    } = this.props;

    return (
      <label
        id={id}
        className={`checkbox${dark ? ' checkbox_dark' : ''}${
          className ? ` ${className}` : ''
        }`}
      >
        <input
          id={id}
          checked={checked}
          onChange={this.handleCheck}
          type="checkbox"
          {...props}
        />
        <span id={id} className="checkbox__box" />
        {children || label}
      </label>
    );
  }
}
