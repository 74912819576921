import { hideCompanyReport } from 'ducks/company/companyActions/reports/hideCompanyReport';
import userIsAdmin from 'ducks/users/usersSelectors/userIsAdmin';

export const mapStateToCompanyReport = state => ({
  isAdmin: userIsAdmin(state)
});

export const mapDispatchToCompanyReport = {
  hideCompanyReport
};
