import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ContentWrap from 'components/ContentWrap';
import Panel from 'components/Panel';
import Sidebar from 'components/Sidebar';
import PaginatedTripList from 'components/PaginatedTripList';
import ReportOptions from './ReportOptions';
import TripFiltersContainer from 'components/TripFiltersContainer';
import './CreateReportPage.css';
const CreateReportPage = () => (
  <ContentWrap>
    <div className="create-report-page-wrapper">
      <Panel sidebar>
        <TripFiltersContainer allowDates hideLabel />
        <PaginatedTripList nCells={15} />
      </Panel>
    </div>
    <Sidebar title="Options">
      <Scrollbars>
        <ReportOptions />
      </Scrollbars>
    </Sidebar>
  </ContentWrap>
);

export default CreateReportPage;
