import { connect } from 'react-redux';
import {
  mapStateToTeamsList,
  mapDispatchToTeamsList
} from './teamsListMappings';
import TeamsList from './TeamsList';

export default connect(
  mapStateToTeamsList,
  mapDispatchToTeamsList
)(TeamsList);
