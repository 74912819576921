import { connect } from 'react-redux';
import CSVUserInvitationsDisplay from './CSVUserInvitationsDisplay';
import {
  mapStateToCSVUserInvitationsDisplay,
  mapDispatchToCSVUserInvitationsDisplay
} from './csvUserInvitationsDisplayMappings';

export default connect(
  mapStateToCSVUserInvitationsDisplay,
  mapDispatchToCSVUserInvitationsDisplay
)(CSVUserInvitationsDisplay);
