import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { trackPage } from 'analytics';
import Panel from 'components/Panel';
import Sidebar from 'components/Sidebar';
import ContentWrap from 'components/ContentWrap';
import TabList from 'components/TabList';
import TabLink from 'components/TabLink';
import AccountInfo from 'components/AccountInfo';
import SubscriptionInfo from 'components/SubscriptionInfo';
import AccountManagement from 'components/AccountManagement';
import GeneralSettings from './GeneralSettings';
import Locations from 'components/MyLocations';
import Purposes from './Purposes';
import Garage from './Garage';
import WorkHours from './WorkHours';

const SettingsPage = () => (
  <ContentWrap>
    <Panel sidebar>
      <TabList>
        <TabLink to="/settings/general">General</TabLink>
        <TabLink to="/settings/purposes">Purposes</TabLink>
        <TabLink to="/settings/garage">Garage</TabLink>
        <TabLink to="/settings/locations">My Locations</TabLink>
        <TabLink to="/settings/work_hours">Work Hours</TabLink>
      </TabList>
      <Switch>
        <Route path="/settings/general" component={GeneralSettings} />
        <Route path="/settings/purposes" component={Purposes} />
        <Route path="/settings/garage" component={Garage} />
        <Route path="/settings/locations" component={Locations} />
        <Route path="/settings/work_hours" component={WorkHours} />
        <Redirect to="/settings/general" />
      </Switch>
    </Panel>
    <Sidebar title="My Account">
      <AccountInfo />
      <SubscriptionInfo />
      <AccountManagement />
    </Sidebar>
  </ContentWrap>
);

export default trackPage('settings')(SettingsPage);
