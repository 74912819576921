import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import trips from 'ducks/trips';
import TripFilters from './TripFilters';

const mapStateToProps = state => ({
  filters: trips.selectors.getFilters(state)
});

const mapDispatchToProps = dispatch => ({
  setFilters: bindActionCreators(trips.actions.setFilters, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TripFilters);
