import {
  mapDispatchToReturnTripForm,
  mapStateToReturnTripForm
} from './AddReturnTripContainer';
import AddReturnTripForm from './AddReturnTripForm';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
const Form = reduxForm({ form: 'add-return-trip-form' })(AddReturnTripForm);
export default connect(mapStateToReturnTripForm, mapDispatchToReturnTripForm)(
  Form
);
