import weekDays from 'lib/dates/daysOfTheWeek';
import { createFilter } from './createFilter';

export const weekDayFilter = createFilter(
  weekDays,
  'Week Days',
  'calendar',
  true,
  'name',
  true
);

export default weekDayFilter;
