import * as t from './actionTypes';

export default (state = [], action = {}) => {
  switch (action.type) {
    case t.SEND:
      return [action.payload, ...state];
    case t.DISMISS:
      return state.filter(n => n.id !== action.payload);
    case t.CLEAR:
      return [];
    default:
      return state;
  }
};
