import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { putCompanyInvitation } from 'api/endpoints/company/invitations';
import { updateCompanyUsersPage } from 'ducks/company/companyActions/users/updateCompanyUsersPage';
import {
  UPDATE_COMPANY_INVITE_REQUEST,
  UPDATE_COMPANY_INVITE_SUCCESS,
  UPDATE_COMPANY_INVITE_FAILURE
} from 'ducks/company/companyActionTypes';

export const updateCompanyInvite = (id, invite) => dispatch =>
  dispatch(
    createSimpleAction(
      putCompanyInvitation,
      [
        UPDATE_COMPANY_INVITE_REQUEST,
        UPDATE_COMPANY_INVITE_SUCCESS,
        UPDATE_COMPANY_INVITE_FAILURE
      ],
      'invitationToken'
    )(id, invite)
  ).then(() => {
    // TODO this is a hack to make up for server returning a company rather than the modified invitation object, make normal after api is fixed
    dispatch({
      type: UPDATE_COMPANY_INVITE_SUCCESS,
      payload: invite,
      meta: { alert: { type: 'success', message: 'Invitation was updated' } }
    });
    dispatch(updateCompanyUsersPage({ id, ...invite }));
  });

export default updateCompanyInvite;
