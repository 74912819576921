import React from 'react';
import CheckBox from 'components/Checkbox';

export default ({ className, children, onClick, isChecked, ...props }) => (
  <CheckBox
    className={className}
    onChange={onClick}
    checked={isChecked}
    label={children}
    {...props}
  />
);
