import client from '../../client';

export const getReports = (page, pageSize, parameters, userId) => {
  return client.get('/reports', {
    params: {
      page,
      pageSize,
      userId,
      ...parameters
    }
  });
};

export const putReport = report =>
  client.put(`/reports/${report.id}`, { report });

export const postReports = report => client.post('/reports', { report });
export const getReport = (id, userId) =>
  client.get(`/reports/${id}`, { params: { userId } });
export const deleteReport = id => client.delete(`/reports/${id}`);
