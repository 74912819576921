import React, { Component } from 'react';
import formatDate from 'components/MyLocations/lib/formatDate';
import { locationSearch } from 'lib/services/googlePlaces';
import AddressInput from 'components/AddTripPage/AddTripForm/AddressInput';
import DeleteButton from 'components/CompanyPages/lib/DeleteButton';
import confirmation from 'components/Confirmation';
import './Location.css';

const DeleteLocationButton = confirmation(DeleteButton);
const getValue = ({ target }) => target.value;

export default class UserCreatedLocation extends Component {
  constructor(props) {
    super(props);

    const { address, name } = props.location;

    this.state = { name, address };
    this.updateAddress = this.updateAddress.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.updateName = this.updateName.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  updateAddress(address) {
    const { location, updateLocation } = this.props;

    return locationSearch(address, [location.lat, location.lon]).then(
      ([lat, lon]) => {
        this.setState({ address });

        return updateLocation({
          ...location,
          address,
          lat,
          lon
        });
      }
    );
  }

  updateName(name) {
    const { location, updateLocation } = this.props;

    return updateLocation({ ...location, name });
  }

  onBlur() {
    const { name } = this.state;

    this.updateName(name);
  }

  onNameChange(data) {
    const name = getValue(data);

    this.setState({ name });
  }

  onAddressChange(data) {
    this.setState({ address: getValue(data) });
  }

  render() {
    const { location, select, selected, id, deleteLocation } = this.props;
    const { name, address } = this.state;
    const { lat, lon } = location;

    return (
      <li
        key={`location-li-${id}`}
        onClick={() => select(location)}
        className={`location ${
          selected ? 'selected-location' : 'location-separator'
        }`}
      >
        <input
          key={`location-name-${id}`}
          type="text"
          id="location-name"
          className="location-field"
          onBlur={this.onBlur}
          onChange={this.onNameChange}
          value={name}
        />
        <AddressInput
          key={`location-input-${id}`}
          location={[lat, lon]}
          id="location-address"
          className="location-field"
          value={address}
          onChange={this.onAddressChange}
          onSelect={this.updateAddress}
        />
        <p key={`location-created-at-${id}`} className="location-created-at">
          Added on {formatDate(location.createdAt)}
        </p>
        <DeleteLocationButton
          id="delete-location-button"
          className="location-button user-location-button"
          onClick={() => deleteLocation(id)}
          confirm={true}
          title="Delete location"
          message={`Are you sure you want to delete "${name}"`}
        />
      </li>
    );
  }
}
