import React from 'react';
import Gravatar from 'react-gravatar';
import './ImageContainer.css';

export default ({ image, defaultImage, className, email }) => {
  return (
    <div className={`circular-image-frame ${className ? className : ''}`}>
      {image ? (
        <img className="contained-image" src={image} alt={defaultImage} />
      ) : (
        <Gravatar
          className="contained-image"
          email={email}
          rating="pg"
          default="mm"
        />
      )}
    </div>
  );
};
