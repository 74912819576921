import { globalWorkHoursObject } from 'ducks/company/lib/globalWorkHoursObject';
import { getSelectedWorkHoursId } from './getSelectedWorkHoursId';
import getTeamsArray from 'ducks/teams/teamsSelectors/getTeamsArray';

export const getWorkHoursSelections = state => {
  const selectedId = getSelectedWorkHoursId(state);
  const teams = getTeamsArray(state);
  return [globalWorkHoursObject]
    .concat(teams ? teams : [])
    .filter(({ id }) => id !== selectedId);
};
