import { getApprovedExpenseChart } from './getApprovedExpenseChart';
import { getRejectedExpenseChart } from './getRejectedExpenseChart';
import moment from 'moment';

const formatData = format => data =>
  data.map(({ date, approved: [approved], rejected: [rejected] }) => ({
    title: format && format(date),
    approved,
    rejected
  }));

const formats = {
  day: formatData(date => moment(date).format('DD/MM')),
  week: formatData(date => `W ${moment(date).isoWeek()}`),
  month: formatData(date => moment(date).format('MMM'))
};

export const getExpenseComparisonData = state => {
  const { data: approvedData } = getApprovedExpenseChart(state) || {};
  const { data: rejectedData, step } = getRejectedExpenseChart(state) || {};
  const format = formats[step];
  const expenseChart = approvedData.map(({ date, values }, index) => {
    const { values: rejected } = rejectedData[index] || {};
    return { date, approved: values, rejected };
  });

  return format && format(expenseChart);
};

export default getExpenseComparisonData;
