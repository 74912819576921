import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { deleteUserFromTeam } from 'api/endpoints/company/teams';
import {
  DELETE_USER_FROM_TEAM_REQUEST,
  DELETE_USER_FROM_TEAM_SUCCESS,
  DELETE_USER_FROM_TEAM_FAILURE
} from 'ducks/teams/teamActionTypes';

export const removeUserFromTeam = createSimpleAction(
  deleteUserFromTeam,
  [
    DELETE_USER_FROM_TEAM_REQUEST,
    DELETE_USER_FROM_TEAM_SUCCESS,
    DELETE_USER_FROM_TEAM_FAILURE
  ],
  'team'
);

export default removeUserFromTeam;
