import {
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  ADD_LOCATION_REQUEST,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAILURE,
  ADD_COMPANY_LOCATION_SUCCESS,
  ADD_COMPANY_LOCATION_REQUEST,
  ADD_COMPANY_LOCATION_FAILURE,
  UPDATE_COMPANY_LOCATION_REQUEST,
  UPDATE_COMPANY_LOCATION_SUCCESS,
  UPDATE_COMPANY_LOCATION_FAILURE,
  DELETE_COMPANY_LOCATION_REQUEST,
  DELETE_COMPANY_LOCATION_SUCCESS,
  DELETE_COMPANY_LOCATION_FAILURE
} from 'ducks/locations/locationActionTypes';

import { createRequestsByIdReducer } from 'redux/requestsByIdReducer';
export default createRequestsByIdReducer([
  [
    UPDATE_LOCATION_REQUEST,
    UPDATE_LOCATION_SUCCESS,
    UPDATE_LOCATION_FAILURE,
    UPDATE_COMPANY_LOCATION_REQUEST,
    UPDATE_COMPANY_LOCATION_SUCCESS,
    UPDATE_COMPANY_LOCATION_FAILURE
  ],
  [
    DELETE_LOCATION_REQUEST,
    DELETE_LOCATION_SUCCESS,
    DELETE_LOCATION_FAILURE,
    DELETE_COMPANY_LOCATION_REQUEST,
    DELETE_COMPANY_LOCATION_SUCCESS,
    DELETE_COMPANY_LOCATION_FAILURE
  ],
  [
    ADD_LOCATION_REQUEST,
    ADD_LOCATION_SUCCESS,
    ADD_LOCATION_FAILURE,
    ADD_COMPANY_LOCATION_REQUEST,
    ADD_COMPANY_LOCATION_SUCCESS,
    ADD_COMPANY_LOCATION_FAILURE
  ]
]);
