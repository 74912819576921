import currencyCodeMap from 'currency-code-map';
import { getCurrentUser } from '../selectors';

export const getCurrencyCode = state => {
  const user = getCurrentUser(state);
  const countryCode = user && user.countryCode;
  return countryCode ? currencyCodeMap[countryCode] : null;
};

export default getCurrencyCode;
