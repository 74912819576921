import React, { Component } from 'react';
import CompanyDropDown from 'components/CompanyPages/lib/CompanyDropDown';
import TeamSelector from 'components/CompanyPages/lib/TeamSelector';
import _ from 'lodash';
import './CostCenterElement.css';

export default class CostCenterElement extends Component {
  constructor(props) {
    const { name, number, teams } = props;

    super(props);

    this.state = {
      name,
      number,
      teams,
      purpose: 'All purposes'
    };

    this.selectPurpose = this.selectPurpose.bind(this);
    this.updateName = this.updateName.bind(this);
    this.updateNumber = this.updateNumber.bind(this);
    this.updateTeams = this.updateTeams.bind(this);
  }

  componentWillMount() {
    const { updateCostCenter } = this.props;
    this.updateCostCenter = _.debounce((id, costCenter) => {
      updateCostCenter && updateCostCenter(id, costCenter);
    }, 200);
  }

  updateName({ target }) {
    const { id } = this.props;
    const name = target.value;
    this.setState({ name });
    this.updateCostCenter(id, { id, name });
  }

  updateNumber({ target }) {
    const { id } = this.props;
    const number = target.value;
    this.setState({ number });
    this.updateCostCenter(id, { number, id });
  }

  updateTeams(teams) {
    this.setState({ teams });
  }

  selectPurpose({ target }) {
    const index = target.id;
    const { purposes } = this.props;
    const purpose = purposes && purposes[index];
    this.setState({ purpose: purpose && purpose.name });
  }

  render() {
    const { name, number, purpose } = this.state;
    const { formattedDate, purposes } = this.props;

    return (
      <div className="cost-center-element">
        {[[name, this.updateName], [number, this.updateNumber]].map(
          ([value, updateMethod]) => (
            <div className="cost-center-element-section">
              <input
                className="cost-center-text-input cost-center-input"
                onChange={updateMethod}
                value={value}
              />
            </div>
          )
        )}
        <div className="cost-center-element-section">
          <CompanyDropDown
            className="cost-center-purposes-drop-down"
            onChange={this.selectPurpose}
            value={purpose}
          >
            {purposes}
          </CompanyDropDown>
        </div>
        <div className="cost-center-element-section">
          <TeamSelector
            className="cost-center-teams-drop-down"
            onChange={this.updateTeams}
          />
        </div>
        <div className="cost-center-text-element">
          <h1 className="cost-center-created-at-text">{formattedDate}</h1>
        </div>
      </div>
    );
  }
}
