import { connect } from 'react-redux';
import vehicles from 'ducks/vehicles';
import Vehicle from './Vehicle';

const mapStateToProps = (state, { id }) => ({
  vehicle: vehicles.selectors.getById(state, id),
  fetching: vehicles.selectors.getFetching(state, id)
});

const mapDispatchToProps = (dispatch, { id }) => ({
  update(params) {
    return dispatch(vehicles.actions.update({ ...params, id }));
  },
  setPrimary() {
    return dispatch(vehicles.actions.setPrimary(id));
  },
  destroy() {
    return dispatch(vehicles.actions.destroy(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);
