import _ from 'lodash';
import { combineReducers } from 'redux';
import * as t from './actionTypes';
import * as userTypes from 'ducks/users/usersActionTypes';
import { normalizeArray } from 'lib/utils';
import createReducer from 'redux/createReducer';

export const reorderItems = (purposes, positions) => {
  return positions.reduce((orderedPurposes, { id, index }) => {
    const purpose = purposes[id];
    if (purpose) {
      orderedPurposes[id] = { ...purpose, index };
    }
    return orderedPurposes;
  }, {});
};

const itemsReducer = createReducer(
  {},
  {
    [userTypes.GET_SUCCESS]: (state, { payload }) => ({
      ...state,
      ...normalizeArray(_.get(payload, 'purposes', []))
    }),
    [t.CREATE_SUCCESS]: (state, { payload }) => ({
      ...state,
      [payload.id]: payload
    }),
    [t.REORDER_REQUEST]: (state, action) => reorderItems(state, action.payload),
    [t.REORDER_SUCCESS]: (state, action) => ({
      ...state,
      ...normalizeArray(action.payload)
    }),
    [t.DESTROY_SUCCESS]: (state, action) => _.omit(state, action.payload)
  }
);

export default combineReducers({
  items: itemsReducer
});
