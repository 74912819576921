import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AddTripPage from 'components/AddTripPage';
import EditMultiplePage from 'components/EditMultiplePage';
import TripsPage from 'components/TripsPage';
import SettingsPage from 'components/SettingsPage';
import ReportsPage from 'components/ReportsPage';
import CreateReportPage from 'components/CreateReportPage';
import StatisticsPage from 'components/StatisticsPage';
import DeleteAccountPage from 'components/DeleteAccountPage';
import CancelRenewalPage from 'components/CancelRenewalPage';
import Async from 'components/Async';
import PayWall from 'components/Paywall';
import LocaleTest from 'components/LocaleTest';
import GettingStartedPage from 'components/GettingStartedPage';
import CompanyInvitePage from 'components/CompanyPages/CompanyInvitePage';
import AdminPage from 'components/CompanyPages/Admin';
import ConfirmInvitation from 'components/InvitationConfirmation/ConfirmInvitation';
import InvitationConfirmed from 'components/InvitationConfirmation/InvitationConfirmed';
import InvalidUuid from 'components/InvitationConfirmation/InvalidUuid';
import ConfirmOrConfirmed from 'components/InvitationConfirmation/ConfirmOrConfirmed';

class Portal extends Component {
  render() {
    const { request, activeUser, currentUser } = this.props;
    return (
      <Async request={request} item={activeUser} withWrapper firstLoad>
        <PayWall user={currentUser}>
          <Switch>
            {(process.env.REACT_APP_STAGING ||
              process.env.NODE_ENV === 'development') && (
              <Route path="/locale_test" component={LocaleTest} />
            )}
            <Route path="/invitation/confirm" component={ConfirmInvitation} />
            <Route
              path="/invitation/confirmed"
              component={InvitationConfirmed}
            />
            <Route path="/invitation/invalid" component={InvalidUuid} />
            <Route path="/confirm/invitation" component={ConfirmOrConfirmed} />
            <Route path="/delete" component={DeleteAccountPage} />
            <Route path ="/cancel" component={CancelRenewalPage} />
            <Route path="/getting_started" component={GettingStartedPage} />
            <Route path="/invites" component={CompanyInvitePage} />
            <Route path="/admin" component={AdminPage} />
            <Route path="/trips/new" component={AddTripPage} />
            <Route path="/trips/edit" component={EditMultiplePage} />
            <Route path="/trips" component={TripsPage} />
            <Route path="/statistics" component={StatisticsPage} />
            <Route path="/reports/new" component={CreateReportPage} />
            <Route path="/reports" component={ReportsPage} />
            <Route path="/settings" component={SettingsPage} />
            <Route path="/referral">
              <Redirect to="/settings"/>
            </Route>
            <Redirect to="/trips" />
          </Switch>
        </PayWall>
      </Async>
    );
  }
}
export default Portal;
