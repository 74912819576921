import React, { Component } from 'react';
import DropDown from 'components/CompanyPages/lib/CompanyDropDown/index';
import AddButton from 'components/CompanyPages/lib/AddButton/index';
import TextBox from 'components/TextBox/index';
import './AddReportPage.css';

const TitledDropDown = ({ className, value, onChange, title, children }) => (
  <div className="add-report-input-sector">
    <h1 className="add-report-input-title">{title}</h1>
    <DropDown
      className={className + ' add-report-drop-down'}
      value={value}
      onChange={onChange}
    >
      {children}
    </DropDown>
  </div>
);

export default class AddReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: 'All users',
      userIndex: null,
      action: 'Have expensed',
      actionIndex: null,
      amount: null,
      unitOfMeasure: 'USD',
      manager: 'Their corresponding managers',
      managerIndex: null,
      period: props.periods[0],
      periodIndex: null
    };

    this.selectUser = this.selectUser.bind(this);
    this.selectAction = this.selectAction.bind(this);
    this.selectManager = this.selectManager.bind(this);
    this.selectPeriod = this.selectPeriod.bind(this);
    this.selectUnitOfMeasure = this.selectUnitOfMeasure.bind(this);
    this.setAmount = this.setAmount.bind(this);
    this.addReport = this.addReport.bind(this);
  }

  addReport() {
    const { period, amount, unitOfMeasure, userIndex } = this.state;
    const { addKpiReport, users } = this.props;
    const user = users && users[userIndex];

    addKpiReport &&
      addKpiReport({
        userId: user && user.id,
        quantity: amount,
        unit: unitOfMeasure,
        period: period && period.value
      });
  }
  selectUser({ target }) {
    const { users } = this.props;
    const userIndex = target.id;
    const user = users && users[userIndex];
    this.setState({ user: user && user.name, userIndex });
  }
  selectAction({ target }) {
    const { actions } = this.props;
    const actionIndex = target.id;
    const action = actions && actions[actionIndex];
    this.setState({ action: action && action.id, actionIndex });
  }
  setAmount({ target }) {
    const { normalize } = this.props;
    this.setState({ amount: normalize(target.value) });
  }
  selectUnitOfMeasure({ target }) {
    const { unitsOfMeasure } = this.props;
    const unitOfMeasureIndex = target.id;
    const unitOfMeasure = unitsOfMeasure[unitOfMeasureIndex];
    this.setState({ unitOfMeasure, unitOfMeasureIndex });
  }
  selectManager({ target }) {
    const { managers } = this.props;
    const managerIndex = target.id;
    const manager = managers && managers[managerIndex];
    this.setState({ manager: manager && manager.name, managerIndex });
  }
  selectPeriod({ target }) {
    const { periods } = this.props;
    const periodIndex = target.id;
    const period = periods && periods[periodIndex];
    this.setState({ period, periodIndex });
  }
  render() {
    const { user, action, amount, unitOfMeasure, manager, period } = this.state;
    const { users, actions, unitsOfMeasure, managers, periods } = this.props;
    return (
      <div className="add-report-page">
        <TitledDropDown
          className="add-report-users-drop-down"
          value={user}
          onChange={this.selectUser}
          title="Report"
        >
          {users}
        </TitledDropDown>
        <TitledDropDown
          className="add-report-action-drop-down"
          value={action}
          onChange={this.selectAction}
          title="That"
        >
          {actions}
        </TitledDropDown>
        <div className="add-report-input-inline">
          <div className="add-report-input-amount-sector">
            <h1 className="add-report-input-title">Quantity</h1>
            <TextBox
              className="add-report-input-amount"
              onChange={this.setAmount}
              value={amount}
              placeHolder={'$ 0.00'}
              normalizeinput={true}
            />
          </div>
          <TitledDropDown
            className="add-report-input-units-of-measure"
            onChange={this.selectUnitOfMeasure}
            value={unitOfMeasure}
            title="Unit of measurement."
          >
            {unitsOfMeasure}
          </TitledDropDown>
        </div>
        <TitledDropDown
          className="add-report-periods-drop-down"
          title="For a period of time"
          onChange={this.selectPeriod}
          value={period && period.name}
        >
          {periods}
        </TitledDropDown>
        <TitledDropDown
          className="add-report-managers-drop-down"
          title="Send this report to"
          onChange={this.selectManager}
          value={manager}
        >
          {managers}
        </TitledDropDown>
        <AddButton onClick={this.addReport} className="add-report-button">
          Add
        </AddButton>
      </div>
    );
  }
}
