import { connect } from 'react-redux';
import { getCurrentUser } from 'ducks/users/selectors';
import AccountInfo from './AccountInfo';

const mapStateToProps = state => {
  const user = getCurrentUser(state);
  return {
    email: user && user.email,
    name: user && user.name
  };
};

export default connect(mapStateToProps)(AccountInfo);
