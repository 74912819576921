import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { getCostCenters } from 'api/endpoints/company/costCenters';
import {
  GET_COST_CENTERS_REQUEST,
  GET_COST_CENTERS_SUCCESS,
  GET_COST_CENTERS_FAILURE
} from 'ducks/costCenters/costCentersActionTypes';

export const getCompanyCostCenters = createSimpleAction(
  getCostCenters,
  [
    GET_COST_CENTERS_REQUEST,
    GET_COST_CENTERS_SUCCESS,
    GET_COST_CENTERS_FAILURE
  ],
  'costCenters'
);

export default getCompanyCostCenters;
