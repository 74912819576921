import addLocation from 'ducks/locations/locationActions/addLocation';
import updateLocation from 'ducks/locations/locationActions/updateLocation';
import getNearByLocations from 'ducks/locations/locationSelectors/getNearByLocations';
import storeLocation from 'ducks/locations/locationActions/storeLocation';
import removeOrRestoreLocation from 'ducks/locations/locationActions/removeOrRestoreLocation';
import removeLocationIfStored from 'ducks/locations/locationActions/removeLocationIfStored';
import { getStoredLocation } from 'ducks/locations/locationSelectors/getStoredLocation';

export const mapStateToLocationField = (state, { coordinates }) => ({
  nearByLocations: getNearByLocations(state, coordinates),
  storedLocationExists: location =>
    !!getStoredLocation(state, location && location.id)
});

export const mapDispatchToLocationField = {
  updateLocation,
  addLocation,
  storeLocation,
  removeOrRestoreLocation,
  removeLocationIfStored
};
