import { connect } from 'react-redux';
import {
  mapDispatchToInviteUsersPage,
  mapStateToInviteUsersPage
} from './inviteUsersPageMappings';
import InviteUsersPage from './InviteUsersPage';
export default connect(
  mapStateToInviteUsersPage,
  mapDispatchToInviteUsersPage
)(InviteUsersPage);
