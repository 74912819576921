import setPageNumber from './setPageNumber';
import setTotalCount from './setTotalCount';
import filtersToParams from 'ducks/trips/lib/filtersToParams';
import { getPageSize } from 'ducks/pagination/paginationSelectors/getPageSize';
import { setPage } from './setPage';
import setTotalExpense from './setTotalExpense';

export const getPage = (pageName, valuePath, getList) => params => (
  dispatch,
  getState
) => {
  const state = getState();
  const { page } = params || {};
  const pageSize = getPageSize(pageName)(state);
  const filteredParams = filtersToParams({
    ...params,
    pageSize,
    timeScopeMode: true
  });

  return getList(filteredParams).then(
    ({ data: { totalCount, totalExpense, ...data } }) => {
      const elements = data && data[valuePath];
      page === 1 && dispatch(setTotalCount(pageName)(totalCount));
      !isNaN(totalExpense) && dispatch(setTotalExpense(pageName)(totalExpense));
      dispatch(setPageNumber(pageName)(page));

      return dispatch(setPage(pageName)(page, elements));
    }
  );
};

export default getPage;
