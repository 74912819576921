import React from 'react';
import FontAwesome from 'react-fontawesome';
import './ErrorPage.css';

const ErrorPage = () => (
  <div className="error-page">
    <FontAwesome name="exclamation-triangle" size="5x" />
    <p>
      <b>Something went wrong.</b> Don't panic! The error has been logged and
      reported to the MileCatcher team.
    </p>
  </div>
);

export default ErrorPage;
