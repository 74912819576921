import { Component } from 'react';
import PropTypes from 'prop-types';
import { unsupported, mobile, iPad } from './notificationMessages';
export default class BrowserCheck extends Component {
  static propTypes = {
    notify: PropTypes.func.isRequired
  };
  componentDidMount() {
    const { notify, isMobile, isIpad, unsupportedBrowser } = this.props;
    const messages = [unsupported, mobile, iPad];
    const notification = [unsupportedBrowser, isMobile, isIpad].reduce(
      (message, detected, index) => (detected ? messages[index] : message),
      undefined
    );
    notify(notification);
  }
  render() {
    return null;
  }
}
