import client from '../../client';

export const getLocations = params => client.get(`/locations`, { params });

export const putLocations = (id, location) =>
  client.put(`/locations/${id}`, { location });

export const deleteLocations = id => client.delete(`/locations/${id}`);

export const postLocations = location =>
  client.post('/locations', { location });
