import { NAME as nameSpace } from 'ducks/companyTrips/constants';

export const GET_COMPANY_TRIPS_REQUEST = `${nameSpace}/GET_COMPANY_TRIPS_REQUEST`;
export const GET_COMPANY_TRIPS_SUCCESS = `${nameSpace}/GET_COMPANY_TRIPS_SUCCESS`;
export const GET_COMPANY_TRIPS_FAILURE = `${nameSpace}/GET_COMPANY_TRIPS_FAILURE`;

export const PUT_COMPANY_TRIP_REQUEST = `${nameSpace}/PUT_COMPANY_TRIPS_REQUEST`;
export const PUT_COMPANY_TRIP_SUCCESS = `${nameSpace}/PUT_COMPANY_TRIPS_SUCCESS`;
export const PUT_COMPANY_TRIP_FAILURE = `${nameSpace}/PUT_COMPANY_TRIPS_FAILURE`;

export const PUT_COMPANY_TRIPS_REQUEST = `${nameSpace}/PUT_COMPANY_TRIPS_REQUEST`;
export const PUT_COMPANY_TRIPS_SUCCESS = `${nameSpace}/PUT_COMPANY_TRIPS_SUCCESS`;
export const PUT_COMPANY_TRIPS_FAILURE = `${nameSpace}/PUT_COMPANY_TRIPS_FAILURE`;

export const POST_COMPANY_TRIPS_REQUEST = `${nameSpace}/POST_COMPANY_TRIPS_REQUEST`;
export const POST_COMPANY_TRIPS_SUCCESS = `${nameSpace}/POST_COMPANY_TRIPS_SUCCESS`;
export const POST_COMPANY_TRIPS_FAILURE = `${nameSpace}/POST_COMPANY_TRIPS_FAILURE`;

export const DELETE_COMPANY_TRIPS_REQUEST = `${nameSpace}/DELETE_COMPANY_TRIPS_REQUEST`;
export const DELETE_COMPANY_TRIPS_SUCCESS = `${nameSpace}/DELETE_COMPANY_TRIPS_SUCCESS`;
export const DELETE_COMPANY_TRIPS_FAILURE = `${nameSpace}/DELETE_COMPANY_TRIPS_FAILURE`;

export const SET_COMPANY_TRIPS_PAGE_NUMBER = `${nameSpace}/SET_COMPANY_TRIPS_PAGE_NUMBER`;
export const SET_TOTAL_COMPANY_TRIPS_COUNT = `${nameSpace}/SET_TOTAL_COMPANY_TRIPS_COUNT`;
export const SET_COMPANY_TRIPS_PAGE = `${nameSpace}/SET_COMPANY_TRIPS_PAGE`;
