import { combineReducers } from 'redux';
import createReducer from 'redux/createReducer';
import { getCompanyId, getUserId, getAuthToken } from 'lib/auth';
import {
  INVALIDATE,
  SIGN_IN,
  REDIRECT,
  SET_MOBILE,
  SUBSCRIPTION_INACTIVE,
  COMPANY_SIGN_IN_SUCCESS,
  COMPANY_SIGN_IN
} from './actionTypes';
import { DELETE_USER_SUCCESS } from '../users/actionTypes';
import { COMPANY_SIGN_UP_SUCCESS } from '../company/companyActionTypes';
import { createRequestsReducer } from '../../redux/requestReducer';
const setUserId = (_, { payload }) => payload.id;
const setId = (_, { payload }) => payload.id;
const setAuthToken = (_, { payload }) => payload.authToken;
const setRedirection = (_, { payload }) => payload.redirecting;
const setMobile = (_, { payload }) => payload.isMobile;
const setActiveSubscription = (_, { payload }) => payload.activeSubscription;
const setToNull = () => null;

const companyId = createReducer(getCompanyId, {
  ...[COMPANY_SIGN_IN_SUCCESS, COMPANY_SIGN_UP_SUCCESS, COMPANY_SIGN_IN].reduce(
    (actions, action) => {
      actions[action] = setId;
      return actions;
    },
    {}
  )
});

const id = createReducer(getUserId, {
  ...[INVALIDATE, SUBSCRIPTION_INACTIVE, DELETE_USER_SUCCESS].reduce(
    (actions, action) => {
      actions[action] = setToNull;
      return actions;
    },
    {}
  ),
  ...[REDIRECT, SIGN_IN].reduce((actions, action) => {
    actions[action] = setUserId;
    return actions;
  }, {})
});

const authToken = createReducer(getAuthToken, {
  ...[INVALIDATE, SUBSCRIPTION_INACTIVE, DELETE_USER_SUCCESS].reduce(
    (actions, action) => {
      actions[action] = setToNull;
      return actions;
    },
    {}
  ),
  ...[REDIRECT, SIGN_IN].reduce((actions, action) => {
    actions[action] = setAuthToken;
    return actions;
  }, {})
});

const redirect = createReducer(
  null,
  [REDIRECT, SIGN_IN, INVALIDATE].reduce((actions, action) => {
    actions[action] = setRedirection;
    return actions;
  }, {})
);

const activeSubscription = createReducer(
  true,
  [SIGN_IN].reduce((actions, action) => {
    actions[action] = setActiveSubscription;
    return actions;
  }, {})
);

export const sessionRequests = createRequestsReducer([]);

const mobile = createReducer(null, { [SET_MOBILE]: setMobile });

export default combineReducers({
  id,
  authToken,
  redirect,
  mobile,
  activeSubscription,
  companyId
});
