import { createFilter } from './createFilter';
import companyUsersSortedByEmail from 'ducks/company/companySelectors/companyMembers/companyUsersSortedByEmail';

export const getUserFilters = state =>
  createFilter(
    companyUsersSortedByEmail(state),
    'Users',
    'users',
    false,
    'email'
  );

export default getUserFilters;
