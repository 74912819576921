import React, { Component } from 'react';
import transformError from 'lib/transformError';
import PreLoginForm from 'components/PreLogin/PreLoginForm';
import confirmation from 'components/Confirmation';
import remoteSubmission from 'components/RemoteSubission';
import PreLoginBox, { PreloginBoxTitle } from 'components/PreLogin/PreLoginBox';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import './DeletAccountPage.css';

const form = 'delete-account-page';

const DeleteButton = confirmation(({ children, ...props }) => (
  <button {...props}>{children}</button>
));

const DeleteAccount = remoteSubmission(DeleteButton);

const InputField = ({ input, meta: _, ...props }) => (
  <input type="text" {...props} {...input} />
);

export default reduxForm({ form })(
  class DeleteAccountPage extends Component {
    constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);
    }
    onSubmit({ password, email }) {
      this.props.confirmAccountDeletion(email, password);
    }
    render() {
      const { handleSubmit, submitting, error, password, email } = this.props;
      const disabled = submitting || !password || !email;
      return (
        <div className="delete-account-page">
          <PreLoginBox>
            <PreloginBoxTitle className="delete-account-title">
              DELETE YOUR ACCOUNT
            </PreloginBoxTitle>
            <PreLoginForm
              onSubmit={handleSubmit(this.onSubmit)}
              error={transformError(error, 'deleteAccount')}
            >
              <Field
                className="account-deletion-input"
                type="email"
                name="email"
                placeholder="Email"
                component={InputField}
              />
              <Field
                className="account-deletion-input"
                type="password"
                name="password"
                placeholder="Password"
                component={InputField}
              />
              <DeleteAccount
                className={`delete-account-button ${
                  disabled
                    ? 'disabled-delete-account-button'
                    : 'enabled-delete-account-button'
                }`}
                confirmButtonColor="delete"
                title="Delete Account"
                type="button"
                message={`Are you sure you want to delete this account and all its associated data? THIS CANNOT BE UNDONE. If you have not cancelled your subscription first do so immediately to prevent further charges to your account. Check in-app help for further details.`}
                disabled={disabled}
                confirm={true}
                form={form}
              >
                {submitting ? (
                  <span>Deleting account&hellip;</span>
                ) : (
                  'Delete Account'
                )}
              </DeleteAccount>
              <Link className="return-to-mile-catcher-link" to="/">
                Click here to return to Milecatcher
              </Link>
            </PreLoginForm>
          </PreLoginBox>
        </div>
      );
    }
  }
);
