import { getUsersCountry, getByIdFromState } from 'ducks/countries/selectors';
import _ from 'lodash';
import { change, formValueSelector } from 'redux-form';
import { updateCurrentUser } from 'ducks/users/actions';
import { fetchCurrentUser } from 'ducks/users/actions';
import userDataToFormValues from 'components/SettingsPage/lib/userDataToFormValues';
import formValuesToUserData from 'components/SettingsPage/lib/formValuesToUserData';
import {
  countryUsesMetricUnits,
  getUsersCountryDecimal
} from 'ducks/countries/selectors';
import { getBusinessRate, getCurrentUser } from 'ducks/users/selectors';
import { getCompanyId } from 'lib/auth';

const form = 'generalSettings';
const selector = formValueSelector(form);

export const mapStateToGeneralSettingsForm = state => {
  const rate = getBusinessRate(state);
  const user = getCurrentUser(state);

  return {
    companyId: getCompanyId(),
    user: user,
    initialValues: userDataToFormValues(user, rate),
    enableReinitialize: true,
    password: selector(state, 'password'),
    getCountry: id => getByIdFromState(state, id),
    countryHasChanged: () => {
      const usersCountry = getUsersCountry(state);
      const selectedCountryId = selector(state, 'countryId');
      return (
        _.isNumber(selectedCountryId) &&
        usersCountry &&
        usersCountry.id !== selectedCountryId
      );
    },
    onSubmit: (data, dispatch) =>
      dispatch(
        updateCurrentUser(
          formValuesToUserData(
            data,
            user,
            rate,
            () => getUsersCountryDecimal(state),
            countryId =>
              countryUsesMetricUnits(getByIdFromState(state, countryId))
          )
        )
      )
  };
};

export const mapDispatchToGeneralSettingsForm = dispatch => ({
  load: fetchCurrentUser,
  update: isMetric => {
    dispatch(change(form, 'settings.metric', isMetric));
  }
});
