import { connect } from 'react-redux';
import Paginator from './Paginator';
import {
  mapStateToPaginator,
  mapDispatchToPaginator
} from './paginatorMappings';

export default connect(
  mapStateToPaginator,
  mapDispatchToPaginator
)(Paginator);
