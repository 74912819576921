import _ from 'lodash';
import { connect } from 'react-redux';
import trips from 'ducks/trips/index';
import TripMap from './TripMap';

const mapStateToProps = state => ({
  polyline: _.get(
    trips.selectors.getTrip(state, trips.selectors.getActive(state)),
    'polyline'
  )
});

export default connect(mapStateToProps)(TripMap);
