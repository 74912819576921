import userIsAdmin from 'ducks/users/usersSelectors/userIsAdmin';

const nameSpace = '/admin/settings';

export const mapStateToSettingsPage = state => {
  const isAdmin = userIsAdmin(state);
  return {
    isAdmin,
    options: [
      { title: 'Users', icon: 'users', path: `${nameSpace}/users` },
      { title: 'Teams', icon: 'th-large', path: `${nameSpace}/teams` },
      {
        title: 'Work Hours',
        icon: 'clock-o',
        path: `${nameSpace}/work_hours`
      },
      { title: 'Purposes', icon: 'tags', path: `${nameSpace}/purposes` },
      // { title: 'Rules', icon: 'check-square', path: `${nameSpace}/rules` },
      { title: 'Locations', icon: 'pencil', path: `${nameSpace}/locations` },
      // { title: 'Reports', icon: 'reports', path: `${nameSpace}/reports` },
      // { title: 'Integrations', icon: 'link', path: `${nameSpace}/integrations` },
      // {
      //   title: 'Cost Centers',
      //   icon: 'money-bill',
      //   path: `${nameSpace}/cost_centers`
      // },
      ...(isAdmin
        ? [
            {
              title: 'Company Account',
              icon: 'building',
              path: `${nameSpace}/company_account`
            }
          ]
        : [])
    ]
  };
};

export const mapDispatchToSettingsPage = {};
