export default ([lat1, lon1], [lat2, lon2]) => {
  const EARTHS_RADIUS_IN_KM = 6371;
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    0.5 -
    Math.cos(dLat) / 2 +
    (Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      (1 - Math.cos(dLon))) /
      2;
  return EARTHS_RADIUS_IN_KM * 2 * Math.asin(Math.sqrt(a));
};
