import createReducer from 'redux/createReducer';
import { SET_TOTAL_EXPENSE } from 'ducks/pagination/paginationActionTypes';

export const totalCount = createReducer(
  {},
  {
    [SET_TOTAL_EXPENSE]: (state, { payload, pageName }) =>
      isNaN(payload)
        ? state
        : {
            ...state,
            [pageName]: payload
          }
  }
);

export default totalCount;
