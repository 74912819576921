import createReducer from 'redux/createReducer';
import { GET_TEAMS_SUCCESS } from 'ducks/teams/teamActionTypes';
import {
  CREATE_TEAM_SUCCESS,
  DELETE_TEAM_SUCCESS,
  DELETE_USER_FROM_TEAM_SUCCESS,
  UPDATE_TEAM_SUCCESS
} from '../teamActionTypes';

export const updateTeam = (state, { payload }) =>
  payload ? { ...state, [payload.id]: payload } : state;

export default createReducer(
  {},
  {
    [GET_TEAMS_SUCCESS]: (state, { payload }) =>
      payload
        ? payload.reduce(
            (teams, team) => ({
              ...teams,
              [team.id]: team
            }),
            {}
          )
        : state,

    ...[
      CREATE_TEAM_SUCCESS,
      UPDATE_TEAM_SUCCESS,
      DELETE_USER_FROM_TEAM_SUCCESS
    ].reduce((actions, action) => {
      actions[action] = updateTeam;
      return actions;
    }, {}),

    [DELETE_TEAM_SUCCESS]: (state, { payload }) => {
      const { [`${payload}`]: element, ...rest } = state;
      return payload ? rest : state;
    }
  }
);
