import { inviteUsers } from 'ducks/company/companyActions/invitations/inviteUsers';
import { downloadExampleCsv } from 'lib/downloadExampleCsv';

export const mapStateToInviteUsersPage = state => ({
  downloadExampleCsv
});

export const mapDispatchToInviteUsersPage = {
  inviteUsers
};
