import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PreLoginForm from 'components/PreLogin/PreLoginForm/index';
import PreLoginButton from 'components/PreLogin/PreLoginButton/index';
import transformError from 'lib/transformError';
import PreLoginBox from 'components/PreLogin/PreLoginBox/index';
import './CompanyInvitePage.css';

const renderTextArea = ({ input, meta: _, className, ...props }) => (
  <textarea className={className} {...props} {...input} />
);

export default reduxForm({
  form: 'company-invites-page',
  onSubmitSuccess(data, dispatch, { history }) {
    history.push('/getting_started');
  }
})(
  class CompanyInvitePage extends Component {
    constructor(props) {
      super(props);

      this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit({ invitations }) {
      const { inviteUsers } = this.props;

      return inviteUsers(invitations);
    }

    render() {
      const { handleSubmit, submitting, error } = this.props;

      return (
        <PreLoginBox
          className="company-invite-spacing"
          content="company-invite-content"
        >
          <div className="company-invite-page">
            <PreLoginForm
              onSubmit={handleSubmit(this.onSubmit)}
              error={transformError(error, 'invite')}
            >
              <div className="company-invites-title">
                Invite Your Company Members
              </div>
              <p className="company-invites-description">
                Members will use the mobile app to log trips, classify and
                submit expenses for approval. Later you may define teams and
                approvers.
              </p>
              <div className="company-invites">
                <label className="title">
                  Enter users email addresses (comma separated)
                </label>
                <Field
                  className="input"
                  name="invitations"
                  component={renderTextArea}
                  placeholder="email1@domain.com, email2@domain.com"
                />
              </div>
              <div className="company-login-button-container">
                <PreLoginButton
                  disabled={submitting}
                  className="company-sign-up-button"
                >
                  {submitting ? (
                    <span>Sending invites&hellip;</span>
                  ) : (
                    'Send Invites'
                  )}
                </PreLoginButton>
              </div>
            </PreLoginForm>
          </div>
        </PreLoginBox>
      );
    }
  }
);
