import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { getInvites } from 'api/endpoints/company/invitations';
import {
  GET_INVITES_REQUEST,
  GET_INVITES_SUCCESS,
  GET_INVITES_FAILURE
} from 'ducks/company/companyActionTypes';

export const getCompanyInvites = createSimpleAction(
  getInvites,
  [GET_INVITES_REQUEST, GET_INVITES_SUCCESS, GET_INVITES_FAILURE],
  'invitationTokens'
);

export default getCompanyInvites;
