import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import * as validators from 'lib/validators';
import TextBoxField from 'components/TextBoxField/index';
import RateField from 'components/RateField';
import Button from 'components/Button/index';
import CheckboxField from 'components/CheckboxField';
import ValidationError from 'components/ValidationError/index';
import MetricField from './MetricField';
import CountryDropDown from 'components/CountryDropdown';
import confirmation from 'components/Confirmation/index';
import remoteSubmission from 'components/RemoteSubission/index';
import { countryUsesMetricUnits } from 'ducks/countries/selectors';
import { Link } from 'react-router-dom';
import Textbox from 'components/TextBox';
import ScrollBars from 'components/Scrollbars';
import './GeneralSettingsForm.css';

const SaveButton = ({ children, ...props }) => (
  <Button {...props}>{children}</Button>
);

const ConfirmationWrapper = confirmation(SaveButton);
const Submit = remoteSubmission(ConfirmationWrapper);
const form = 'generalSettings';

export default reduxForm({ form })(
  class GeneralSettings extends Component {
    constructor(props) {
      super(props);

      this.validateAdminEmail = this.validateAdminEmail.bind(this);
    }

    componentWillMount() {
      const { load } = this.props;

      load && load();
    }

    validateAdminEmail(adminEmail) {
      return adminEmail ? validators.email(adminEmail) : undefined;
    }

    render() {
      const {
        handleSubmit,
        pristine,
        reset,
        submitting,
        password,
        error,
        update,
        getCountry,
        countryHasChanged,
        companyId,
        user
      } = this.props;

      return (
        <form className="general-settings" onSubmit={handleSubmit}>
          <ScrollBars>
            <div className="general-settings-padding">
              <div className="general-settings__row">
                {error && <ValidationError message={error} />}
              </div>

              <div className="general-settings__row">
                <div className="general-settings__left-block">
                  <h4>Account Settings</h4>

                  <div className="general-settings__form-control">
                    <label>Full name</label>
                    <TextBoxField name="name" placeholder="Full name" />
                  </div>

                  <div className="general-settings__form-control">
                    <label>Email</label>
                    <TextBoxField
                      name="email"
                      placeholder="Email"
                      validate={[validators.email, validators.required]}
                    />
                  </div>

                  <div className="general-settings__form-control">
                    <label>Country</label>
                    <Field
                      component={({ input: { value, onChange } }) => (
                        <CountryDropDown
                          value={value}
                          onChange={data => {
                            const changes = onChange(data);
                            update(countryUsesMetricUnits(getCountry(data)));
                            return changes;
                          }}
                        />
                      )}
                      name="countryId"
                      onChange={update}
                    />
                  </div>

                  <h4>Password</h4>

                  <div className="general-settings__form-control">
                    <label>New password</label>
                    <TextBoxField
                      type="password"
                      name="password"
                      placeholder="New password"
                      autoComplete="off"
                    />
                  </div>

                  <div className="general-settings__form-control">
                    <label>Confirm password</label>
                    <TextBoxField
                      type="password"
                      placeholder="Confirm new password"
                      validate={validators.passwordConfirmation}
                      name="passwordConfirmation"
                      disabled={!(password && password.length)}
                      autoComplete="off"
                    />
                  </div>

                  <div className="general-settings__form-control">
                    <label>Current Password</label>
                    <TextBoxField
                      type="password"
                      name="currentPassword"
                      placeholder="Required to change password"
                      validate={
                        password && password.length
                          ? validators.required
                          : undefined
                      }
                      disabled={!(password && password.length)}
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="general-settings__right-block">
                  <h4>Reports</h4>

                  <div className="general-settings__form-control">
                    <label>Custom Business Rate</label>
                    {companyId ? <Textbox placeholder="Managed by your company" disabled={true}/> : <RateField
                        className="general-settings__rate-field"
                        name="customBusinessRate"
                        placeholder="Rate"
                    /> }


                  </div>

                  <div className="general-settings__form-control">

                    <label>Email reports</label>
                    <CheckboxField
                      name="settings.weeklyReport"
                      label="Weekly"
                    />
                    <CheckboxField
                      name="settings.monthlyReport"
                      label="Monthly"
                    />
                  </div>


                  <div className="general-settings__form-control">

                    <label>Email notifications</label>
                    <CheckboxField
                        name="settings.noTripEmailEnabled"
                        label="Trip gap alert emails"
                    />
                  </div>

                  {user.subscription.gateway === "play_store" &&
                      <div classname={"general-settings__right-block"}>
                        <h4>Battery saver</h4>
                        <p className="general-settings__text">
                          Lowers trip accuracy for increased battery life.
                        </p>
                        <div className="general-settings__form-control">
                          <CheckboxField
                              name="settings.batterySaver"
                              label="Enable battery saver"
                          />
                        </div>
                      </div>

                  }


                  <h4>Admin Access</h4>

                  <div className="general-settings__form-control">
                    <label>Administrator Access</label>
                    <div className="general-settings__form-control__input-group">
                      {companyId ? <Textbox placeholder="Managed by your company" disabled={true}/> : <TextBoxField
                          name="settings.adminEmail"
                          placeholder="Administrator email"
                          validate={this.validateAdminEmail}
                      />}

                      <CheckboxField
                        name="settings.adminDeductibleTrips"
                        label="Only grant access to deductible trips"
                      />
                    </div>
                  </div>


                  <p className="general-settings__text">
                    {companyId ? `Administrator access is managed by your company.` : `Type your Administrator/Accountant email address above to
                      allow another person access to your trips and reports.`}


                    {user.settings.adminDeductibleTrips ? ` Your administrator has access to your deductible trips. Personal and unclassified trips are not shared.` : ` Your administrator has access to all of your trips.`}
                  </p>


                  {user.speedGaugeRecordId != null &&
                  <div>
                    <h4>SpeedGauge Settings</h4>
                    <div className="general-settings__form-control">
                      <div className="general-settings__form-control__input-group">
                        <p>You are sending trip data associated with trips classified as Business (either with autoclassify or otherwise) to SpeedGauge as part of your company's insurance program.</p>
                        <p></p>
                        <p>To stop sending trip data to SpeedGauge click the link below. Please note this may impact your insurance plan/rates, make sure this does not impact your company before leaving. Allow up to seven business days for processing removal requests.</p>
                        <a href="mailto:support@milecatcher.com?subject=Please%20remove%20me%20from%20SpeedGauge&body=Hello%2C%0D%0A%0D%0APlease%20remove%20me%20from%20SpeedGauge.%0D%0A%0D%0AThanks">Click here to email MileCatcher support and request removal</a>

                      </div>
                    </div>
                  </div>



                  }
                  {(user.subscription.gateway == "chargebee" || user.subscription.gateway == "stripe") &&

                  <div>
                    <h4>Give 30% off, get $15!</h4>
                    <p>Your account currently has a credit of <b>${user.accountCredit}</b>. Share your referral link below to earn more!</p>

                    <br/>
                    <input value={"https://portal.milecatcher.com/sign_up/" + user.referralCode}></input>
                    <button onClick={() => {navigator.clipboard.writeText("https://portal.milecatcher.com/sign_up/" + user.referralCode)}}>Copy to clipboard</button>
                    <br/>
                    <br/>
                    <p>Share MileCatcher with a friend using your unique referral link! Anyone that signs up for an annual plan using your referral link will get 30% off their first year and $15 will be credited to your account for each successful referral.</p>
                    <br/><br/>
                    <p>By sharing your referral link you agree to our referral program terms, available <a href={"https://www.milecatcher.com/legalpro"}>here</a>. Account credit only applicable for subscriptions purchased through <a href="https://www.milecatcher.com/subscribe">milecatcher.com/subscribe</a>. See terms for more details.</p>

                  </div>
                  }

                </div>
              </div>

              <div className="general-settings__buttons">
                <Submit
                  confirm={countryHasChanged()}
                  message={
                    <span>
                      Changing country will impact rates and currency for future
                      trips classified and reported. Ready to proceed?
                    </span>
                  }
                  type="button"
                  color="save"
                  disabled={pristine || submitting}
                  form={form}
                  title={'Confirm Change'}
                  fullWidth
                >
                  {submitting ? 'Saving' : 'Save'}
                </Submit>

                <Button
                  type="button"
                  color="cancel"
                  onClick={reset}
                  disabled={pristine || submitting}
                  fullWidth
                >
                  Reset
                </Button>
              </div>
            </div>
          </ScrollBars>
        </form>
      );
    }
  }
);
