import _ from 'lodash';
import { difference } from 'lib/utils';
import { toNumber } from 'lib/localization/numericLocalization';
import userDataToFormValues from './userDataToFormValues';
export default (
  data,
  user,
  rate,
  getDecimalMark,
  getCountriesUnitOfMeasure
) => {
  const userData = difference(data, userDataToFormValues(user, rate));
  const selectedBusinessRate = userData.customBusinessRate;
  const countryId = userData.countryId;
  if (_.isNumber(countryId)) {
    userData.metricMeasure = getCountriesUnitOfMeasure(countryId);
  }
  if (selectedBusinessRate || selectedBusinessRate === '') {
    userData.customBusinessRate =
      (selectedBusinessRate &&
        parseFloat(
            toNumber(selectedBusinessRate, getDecimalMark())
          )
        ) ||
      null;
  }
  return userData;
};
