import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Step from './Step';
import './GettingStartedPage.css';

export default class extends Component {
  componentWillMount() {
    const { getTeams, getGlobalWorkHours } = this.props;

    [getTeams, getGlobalWorkHours].forEach(
      loadResource => loadResource && loadResource()
    );
  }

  render() {
    const { title, description, steps } = this.props;

    return (
      <div className="getting-started-page">
        <h1 className="getting-started-title">{title}</h1>
        <p className="getting-started-description">{description}</p>
        <div className="steps">
          {steps.map((data, index) => (
            <Step number={index + 1} {...data} />
          ))}
        </div>
        <p className="check-reports-message">
          <Link className="link" to="/admin/settings/users">
            Check users
          </Link>{' '}
          to see who has not yet installed MileCatcher.
        </p>
      </div>
    );
  }
}
