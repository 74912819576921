import { getUsersByTeam } from 'ducks/users/usersSelectors/getUsersByTeam';
import deleteTeam from 'ducks/teams/teamsActions/deleteTeam';

export const mapStateToTeam = (state, { id }) => ({
  members: getUsersByTeam(state, id)
});

export const mapDispatchToTeam = {
  deleteTeam
};
