import React, { Component } from 'react';
import L from 'leaflet';
import { TileLayer, Map, Marker, Polyline } from 'react-leaflet';
import { TILE_LAYER_URL } from 'lib/services/tilehosting';
import startIconPath from 'lib/locationPointImages/location-pin-A.png';
import endIconPath from 'lib/locationPointImages/location-pin-B.png';
import './RecalculatedTripMap.css';
import ErrorBoundary from '../../ErrorBoundary';

export default class TripMap extends Component {
  constructor(props) {
    super(props);
    this.startIcon = L.icon({
      iconUrl: startIconPath,
      iconSize: [30, 39],
      iconAnchor: [15, 38]
    });
    this.endIcon = L.icon({
      iconUrl: endIconPath,
      iconSize: [30, 39],
      iconAnchor: [15, 38]
    });
  }
  render() {
    const { polyline, startingPoint, endingPoint } = this.props;
    return (
      <ErrorBoundary>
        <Map
          className="recalculated-trip-map"
          bounds={polyline}
          boundsOptions={{ padding: [40, 40], animate: false }}
          zoom={13}
        >
          <TileLayer url={TILE_LAYER_URL} />
          <Polyline positions={polyline} />
          <Marker icon={this.startIcon} position={startingPoint} />
          <Marker icon={this.endIcon} position={endingPoint} />
        </Map>
      </ErrorBoundary>
    );
  }
}
