import getFormattedCompanyWorkDays from 'ducks/workHours/workHourSelectors/getFormattedCompanyWorkDays';
import { getWorkHoursSelections } from 'ducks/company/companySelectors/companyWorkHours/getWorkHoursSelections';
import { getTeams } from 'ducks/teams/teamsActions/getTeams';
import { getCompanyWorkHoursEnabled } from 'ducks/workHours/workHourSelectors/getCompanyWorkHoursEnabled';
import { selectWorkHours } from 'ducks/company/companyActions/selectWorkHours';
import { getWorkHoursSelection } from 'ducks/company/companySelectors/companyWorkHours/getWorkHoursSelection';
import { getGlobalWorkHours } from 'ducks/workHours/workHourActions/getGlobalWorkHours';
import { setDayEnabled } from 'ducks/workHours/workHourActions/setDayEnabled';
import { setWorkHoursEnabled } from 'ducks/workHours/workHourActions/setWorkHoursEnabled';
import { updateWorkHours } from 'ducks/workHours/workHourActions/updateWorkHours';
import setTime from 'ducks/workHours/workHourActions/setWorkDayTimes';
import getCompanyWorkHoursPageRequests from 'ducks/company/companySelectors/companyRequests/getCompanyWorkHoursPageRequests';

export const mapStateToWorkHoursPage = state => ({
  requestsState: getCompanyWorkHoursPageRequests(state),
  selected: getWorkHoursSelection(state),
  workHoursSelections: getWorkHoursSelections(state),
  usingWorkHours: getCompanyWorkHoursEnabled(state),
  workHours: getFormattedCompanyWorkDays(state)
});

export const mapDispatchToWorkHoursPage = {
  getGlobalWorkHours,
  getTeams,
  selectWorkHours,
  setDayEnabled,
  setWorkHoursEnabled,
  setTime,
  updateWorkHours
};
