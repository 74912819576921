import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { Map, Marker } from 'react-leaflet';
import locationIcon from 'lib/locationPointImages/location-pin-A.png';
import TileLayer from 'components/TileLayer';
import './AddLocationMap.css';
export default class AddTripMap extends Component {
  static propTypes = {
    coordinates: PropTypes.arrayOf(PropTypes.number),
    focus: PropTypes.arrayOf(PropTypes.number),
    setPoint: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.locationIcon = L.icon({
      iconUrl: locationIcon,
      iconSize: [30, 39],
      iconAnchor: [15, 38]
    });
    this.onClick = this.onClick.bind(this);
  }
  onClick(event) {
    const { setPoint } = this.props;
    const { lat, lng } = event.latlng;
    setPoint([lat, lng], true, true);
  }
  createDragEndHandler() {
    const { setPoint } = this.props;
    return ({ target }) => {
      const { lat, lng } = target.getLatLng();
      setPoint([lat, lng], true, true);
    };
  }
  render() {
    const { coordinates, focus, zoom } = this.props;
    return (
      <div className={'add-location-map-wrap'}>
        <Map
          className="add-location-map"
          boundsOptions={{ padding: [40, 40] }}
          onClick={this.onClick}
          zoom={zoom}
          center={coordinates || focus}
        >
          {coordinates &&
            coordinates.length && (
              <Marker
                icon={this.locationIcon}
                position={coordinates}
                onDragEnd={this.createDragEndHandler(true)}
                draggable
              />
            )}
          <TileLayer />
        </Map>
      </div>
    );
  }
}
