import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HighlightSelected from 'components/HighlightSelected';
import Scrollbars from 'components/Scrollbars';
import Pagination from 'components/CompanyPages/Admin/ManagerPage/CompanyTripsPagination';
import './Paginator.css';

const noop = () => null;

class Paginator extends Component {
  static propTypes = {
    className: PropTypes.string,
    pages: PropTypes.object,
    getPage: PropTypes.func,
    numberOfElements: PropTypes.number,
    elementsPerPage: PropTypes.number
  };

  static defaultProps = {
    pages: [],
    total: 0,
    getNextPage: noop
  };

  constructor(props) {
    super(props);

    this.state = {
      elementHeight: 0,
      selectedIndex: 0,
      pageNumber: 1
    };

    this.setElementHeight = this.setElementHeight.bind(this);
    this.setSelected = this.setSelected.bind(this);
  }

  getPage(pageNumber) {
    const { getPage } = this.props;

    getPage && getPage(pageNumber).then(() => this.setState({ pageNumber }));
  }

  setElementHeight(elementHeight) {
    this.setState({ elementHeight });
  }

  setSelected(selectedIndex) {
    const { getSelectedIndex } = this.props;

    this.setState({ selectedIndex });

    getSelectedIndex && getSelectedIndex(selectedIndex);
  }

  render() {
    const {
      children,
      getPage,
      numberOfElements,
      pageNumber,
      pageSize
    } = this.props;

    const { elementHeight, selectedIndex } = this.state;
    const numberOfPages = Math.ceil(numberOfElements / pageSize);

    return (
      <div className="pagination-container">
        <Scrollbars
          elementHeight={elementHeight}
          indexOfCurrentElement={selectedIndex}
        >
          <HighlightSelected
            onChange={this.setSelected}
            getElementHeight={this.setElementHeight}
          >
            {children}
          </HighlightSelected>
        </Scrollbars>
        <Pagination
          total={numberOfPages}
          current={pageNumber}
          getPage={getPage}
        />
      </div>
    );
  }
}

export default Paginator;
