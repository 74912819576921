import React from 'react';
import Modal from 'components/Modal';

const shortcuts = [
  {
    name: 'Move up',
    keys: ['up', 'w', 'k']
  },
  {
    name: 'Move down',
    keys: ['down', 's', 'j']
  },
  {
    name: 'Classify business',
    keys: ['b']
  },
  {
    name: 'Classify personal',
    keys: ['p']
  },
  {
    name: 'Unclassify',
    keys: ['u']
  },
  {
    name: 'Focus parking',
    keys: ['shift + enter']
  }
];

const toSentence = components => {
  if (!components || !components.length || components.length < 2)
    return components;
  const joined = components.reduce((prev, curr) => [prev, ', ', curr]);
  joined[joined.length - 2] = ' or ';
  return joined;
};

const KeyboardShortcutsModal = props => (
  <Modal title="Keyboard Shortcuts" {...props}>
    <table className="keyboard-shortcuts">
      {shortcuts.map((shortcut, index) => (
        <tr key={index} className="keyboard-shortcuts__shortcut">
          <td className="keyboard-shortcuts__shortcut__keys">
            {toSentence(
              shortcut.keys.map((key, index) => (
                <span key={index} className="key">
                  {key}
                </span>
              ))
            )}
          </td>

          <td className="keyboard-shortcuts__shortcut__name">
            {shortcut.name}
          </td>
        </tr>
      ))}
    </table>
  </Modal>
);

export default KeyboardShortcutsModal;
