export const CREATE_REQUEST = 'purposes/CREATE_REQUEST';
export const CREATE_FAILURE = 'purposes/CREATE_FAILURE';
export const CREATE_SUCCESS = 'purposes/CREATE_SUCCESS';

export const REORDER_REQUEST = 'purposes/REORDER_REQUEST';
export const REORDER_FAILURE = 'purposes/REORDER_FAILURE';
export const REORDER_SUCCESS = 'purposes/REORDER_SUCCESS';

export const DESTROY_REQUEST = 'purposes/DESTROY_REQUEST';
export const DESTROY_FAILURE = 'purposes/DESTROY_FAILURE';
export const DESTROY_SUCCESS = 'purposes/DESTROY_SUCCESS';
