import React from 'react';
import Money from 'components/Money';
import Value from 'components/Value';
export default ({ className, estimatedValue, type, ...props }) => {
  return (
    <Value className={className} type={type}>
      <Money value={estimatedValue} {...props} />
    </Value>
  );
};
