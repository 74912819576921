import { getKeyPerformanceIndexReports } from 'api/endpoints/company/kpiReports';
import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import {
  GET_KPI_REPORT_REQUEST,
  GET_KPI_REPORT_SUCCESS,
  GET_KPI_REPORT_FAILURE
} from '../kpiReportActionTypes';

export const getKpiReports = createSimpleAction(getKeyPerformanceIndexReports, [
  GET_KPI_REPORT_REQUEST,
  GET_KPI_REPORT_SUCCESS,
  GET_KPI_REPORT_FAILURE
]);

export default getKpiReports;
