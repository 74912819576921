import { createRequestsReducer } from 'redux/requestReducer';
import {
  GET_COMPANY_TRIPS_FAILURE,
  GET_COMPANY_TRIPS_REQUEST,
  GET_COMPANY_TRIPS_SUCCESS
} from 'ducks/companyTrips/companyTripActionTypes';
import {
  GET_COMPANY_MEMBERS_FAILURE,
  GET_COMPANY_MEMBERS_REQUEST,
  GET_COMPANY_MEMBERS_SUCCESS
} from 'ducks/company/companyActionTypes';
import {
  GET_TEAMS_FAILURE,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS
} from 'ducks/teams/teamActionTypes';
import {
  GET_COMPANY_LOCATIONS_FAILURE,
  GET_COMPANY_LOCATIONS_REQUEST,
  GET_COMPANY_LOCATIONS_SUCCESS,
  GET_COMPANY_PURPOSES_FAILURE,
  GET_COMPANY_PURPOSES_REQUEST,
  GET_COMPANY_PURPOSES_SUCCESS,
  GET_COMPANY_VEHICLES_FAILURE,
  GET_COMPANY_VEHICLES_REQUEST,
  GET_COMPANY_VEHICLES_SUCCESS
} from 'ducks/company/companyActionTypes';

export default createRequestsReducer([
  [
    GET_COMPANY_TRIPS_REQUEST,
    GET_COMPANY_TRIPS_SUCCESS,
    GET_COMPANY_TRIPS_FAILURE
  ],
  [
    GET_COMPANY_MEMBERS_REQUEST,
    GET_COMPANY_MEMBERS_SUCCESS,
    GET_COMPANY_MEMBERS_FAILURE
  ],
  [GET_TEAMS_REQUEST, GET_TEAMS_SUCCESS, GET_TEAMS_FAILURE],
  [
    GET_COMPANY_PURPOSES_REQUEST,
    GET_COMPANY_PURPOSES_SUCCESS,
    GET_COMPANY_PURPOSES_FAILURE
  ],
  [
    GET_COMPANY_LOCATIONS_REQUEST,
    GET_COMPANY_LOCATIONS_SUCCESS,
    GET_COMPANY_LOCATIONS_FAILURE
  ],
  [
    GET_COMPANY_VEHICLES_REQUEST,
    GET_COMPANY_VEHICLES_SUCCESS,
    GET_COMPANY_VEHICLES_FAILURE
  ]
]);
