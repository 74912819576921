import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import TextboxField from 'components/TextBoxField';
import CheckboxField from 'components/CheckboxField';
import Button from 'components/Button';
import ReportInfo from './ReportInfo';
import './ReportOptions.css';

const ReportOptions = ({ handleSubmit, submitting }) => (
  <form onSubmit={handleSubmit}>
    <div className="report-options">
      <div className="report-options__info">
        <ReportInfo />
      </div>

      <div className="report-options__form-control">
        <label htmlFor="submitter">Submitter</label>
        <TextboxField id="submitter" name="submitter" placeholder="Submitter" />
      </div>

      <div className="report-options__form-control">
        <label htmlFor="ccEmails">Email copy to</label>
        <TextboxField
          id="ccEmails"
          name="ccEmails"
          placeholder="Comma seperated emails"
        />
      </div>

      <div className="report-options__checkbox">
        <CheckboxField name="markAsReported" label="Mark trips reported" />
      </div>

      <div className="report-options__checkbox">
        <CheckboxField
          name="deductibleOnly"
          label="Exclude non-deductible trips"
        />
      </div>

      <div className="report-options__buttons">
        <Button color="save" disabled={submitting} fullWidth>
          {submitting ? <span>Submitting&hellip;</span> : 'Submit'}
        </Button>
        <Button color="cancel" to="/trips" disabled={submitting} link fullWidth>
          Cancel
        </Button>
      </div>
    </div>
  </form>
);

export default connect()(
  reduxForm({
    form: 'createReport',
    initialValues: { markAsReported: true }
  })(ReportOptions)
);
