import { getTeamsArray } from 'ducks/teams/teamsSelectors/getTeamsArray';
import deleteTeam from 'ducks/teams/teamsActions/deleteTeam';
import getTeamManagers from 'ducks/teams/teamsSelectors/getTeamManagers';
import userIsAdmin from 'ducks/users/usersSelectors/userIsAdmin';
import { getDistanceMeasure } from 'ducks/users/selectors';

export const mapStateToTeamsList = state => ({
  isAdmin: userIsAdmin(state),
  teams: getTeamsArray(state),
  unitOfMeasure: getDistanceMeasure(state),
  getTeamManagers: id => getTeamManagers(state, id)
});

export const mapDispatchToTeamsList = {
  deleteTeam
};
