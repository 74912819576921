import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { RULE_NAME_SPACE } from 'ducks/rules/constants';

export const createAutoApprovalRuleAction = (
  apiCall,
  REQUEST,
  SUCCESS,
  FAILURE
) => createSimpleAction(apiCall, [REQUEST, SUCCESS, FAILURE], RULE_NAME_SPACE);

export default createAutoApprovalRuleAction;
