import { createAutoApprovalRuleAction } from 'ducks/rules/lib/createAutoApprovalRuleAction';
import { postAutoApprovalRule } from 'api/endpoints/company/autoApprovalRules';

import {
  POST_AUTO_APPROVAL_RULES_FAILURE,
  POST_AUTO_APPROVAL_RULES_REQUEST,
  POST_AUTO_APPROVAL_RULES_SUCCESS
} from '../rulesAcitionTypes';

export const addAutoApprovalRule = createAutoApprovalRuleAction(
  postAutoApprovalRule,
  POST_AUTO_APPROVAL_RULES_REQUEST,
  POST_AUTO_APPROVAL_RULES_SUCCESS,
  POST_AUTO_APPROVAL_RULES_FAILURE
);

export default addAutoApprovalRule;
