export const applyReducerToActions = (reducer, ...actions) =>
  actions.reduce(
    (all, action) => ({
      ...all,
      [action]: reducer
    }),
    {}
  );

export default applyReducerToActions;
