import { GET_COMPANY_STATISTICS_SUCCESS } from 'ducks/company/companyActionTypes';
import createReducer from 'redux/createReducer';

export const companyStatistics = createReducer(
  {},
  {
    [GET_COMPANY_STATISTICS_SUCCESS]: (state, { payload }) =>
      payload ? payload : state
  }
);

export default companyStatistics;
