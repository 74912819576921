import _ from 'lodash';
import { createSelector } from 'reselect';
import { requestState } from 'lib/requests';
import * as constants from './constants';
import getDefaultTimesForReturnTrip from './lib/getDefaultTimesForReturnTrip';

/*
 * Trips selectors for computing derived data about trips from the store.
 */

export const getState = state => state[constants.NAME];

// pages/pagination
export const getNumberOfPages = state =>
  Math.ceil(getTotalItems(state) / getPageSize(state));

/*
 * Gets list of trip id's based on the current page.
 */
export const getCurrentTrips = state => {
  const currentPage = getCurrentPage(state);
  return (
    currentPage &&
    currentPage.ids &&
    currentPage.ids.map(id => getTrip(state, id))
  );
};

/*
 * Selects all trips.
 */
export const getTrips = state => getState(state).trips;

/*
 * Selects recalculated trips.
 */
export const getRecalculatedTrips = state => getState(state).recalculatedTrips;

/*
 * Selects current pages.
 */
export const getPages = state => {
  const currentState = getState(state);
  return currentState ? currentState.pages : currentState;
};

/*
 * Selects pagination schema.
 */
export const getPagination = state => getState(state).pagination;

/*
 * Selects current page from pages returned from getPages.
 */
export const getPage = (state, pageNum) => {
  const pages = getPages(state);
  return pages ? pages[pageNum] : pages;
};

/*
 * Selects current page number from pagination schema.
 */
export const getCurrentPageNum = state => getPagination(state).current;

/*
 * Selects total trip count.
 */
export const getTotalItems = state => getPagination(state).totalCount;

/*
 * Selects total expense.
 */
export const getTotalExpense = state => getPagination(state).totalExpense;

/*
 * Selects page size, or number of trips on page (default of 15).
 */
export const getPageSize = state => getPagination(state).pageSize;

/*
 * From the current page, select the state of the current page, which
 * can be one of either LOADING, LOADED, ABSENT, or ERROR.
 */
export const getCurrentPageRequestState = state =>
  requestState(getCurrentPage(state));

/*
 * Select current page from state and current page number.
 */
export const getCurrentPage = state => {
  return getPage(state, getCurrentPageNum(state));
};

/*
 * A memoized selector for getting the trip id's for all pages from a
 * transform on the getPages selector.
 */
export const getTripIdsForAllPages = createSelector(getPages, pages => {
  const pageNums = _.keys(pages)
    .map(x => Number(x))
    .sort();
  return _.flatten(pageNums.map(pageNum => pages[pageNum].ids));
});

/*
 * Selects current trip from getTrips selector.
 */
export const getTrip = (state, id) => getTrips(state)[id];

/*
 * Select currently used trip filters.
 */
export const getFilters = state => {
  const currentState = getState(state);
  return currentState ? currentState.filters : currentState;
};

/*
 * Selects currently used trip distance filter.
 */
export const getDistanceFilters = state => getFilters(state).distanceFilters;

/*
 * Selects the current request, likely made to trips endpoint.
 */
export const getRequest = (state, id) => getState(state).requests[id];

/*
 * Select request state from current request.
 */
export const getRequestState = (state, id) =>
  requestState(getRequest(state, id));

/*
 * Select recalculated trip by id.
 */
export const getRecalculatedTrip = (state, id) =>
  getRecalculatedTrips(state)[id];

/*
 * Select trip statistics.
 */
export const getStatistics = state => getState(state).statistics;

/*
 * Select currently selected trip .
 */
export const getActive = state => getState(state).active;

/*
 * Select default start and end times from trip, returning
 *
 *     {
 *        startTime: ...
 *        endTime: ...
 *     }
 */
export const getDefaultTripStartAndEndTimes = (state, tripId) => {
  const trip = getTrip(state, tripId);
  return trip
    ? { ...getDefaultTimesForReturnTrip(trip.startTime, trip.endTime) }
    : {};
};

/*
 * Select status of distance filter modal (open or not?)
 */
export const distanceFilterModalIsOpen = state =>
  getState(state).distanceFilterModal;

/*
 * Select default start and end time from trip.
 */
export const getDefaultStartTime = (state, tripId) => {
  return getDefaultTripStartAndEndTimes(state, tripId).startTime;
};

/*
 * Select default end time from trip.
 */
export const getDefaultEndTime = (state, tripId) => {
  return getDefaultTripStartAndEndTimes(state, tripId).endTime;
};
