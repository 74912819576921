import React from 'react';
import classNames from 'class-names';
import FontAwesome from 'react-fontawesome';

const Notification = ({ type, children, ...props }) => (
  <div className="notification">
    <div
      className={classNames('notification__inner', {
        notification_error: type === 'error',
        notification_success: type === 'success',
        notification_info: type === 'info'
      })}
      {...props}
    >
      {type === 'info' && <FontAwesome name="info-circle" fixedWidth />}
      {type === 'success' && <FontAwesome name="check" fixedWidth />}
      {type === 'error' && (
        <FontAwesome name="exclamation-triangle" fixedWidth />
      )}{' '}
      {children}
    </div>
  </div>
);

export default Notification;
