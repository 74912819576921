import React, { Component } from 'react';
import moment from 'moment';
import SplitIntoDatesAndHighlight from 'components/CompanyPages/lib/SplitIntoDatesAndHighlight';
import TripMap from 'components/TripMap/components/TripMap';
import Filters from 'components/CompanyPages/lib/Filters';
import Pagination from 'components/Pagination/Pagination';
import Scrollbars from 'components/Scrollbars';
import RenderPage from 'lib/RenderPage';
import Money from 'components/Money';
import Distance from 'components/Distance';
import AddButton from 'components/CompanyPages/lib/AddButton';
import confirm from 'components/Confirmation';
import CancelButton from 'components/CompanyPages/lib/CancelButton';
import 'components/CompanyPages/Admin/ManagerPage/ManagerPage.css';
import 'components/Paginator/Paginator.css';
import './GenerateReportsPage.css';

const GenerateReportsButton = confirm(AddButton);

export default class GenerateReportsPage extends Component {
  constructor(props) {
    const { trips } = props;

    super(props);

    this.state = {
      elementHeight: 0,
      selectedIndex: 0,
      pageNumber: 1,
      selectedTrip: trips && trips[0],
      params: {}
    };

    this.filterTrips = this.filterTrips.bind(this);
    this.loadTrips = this.loadTrips.bind(this);
    this.getVehicleNameById = this.getVehicleNameById.bind(this);
    this.formatTripsSplitByDate = this.formatTripsSplitByDate.bind(this);
    this.setElementHeight = this.setElementHeight.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.generateReports = this.generateReports.bind(this);
    this.redirectToReports = this.redirectToReports.bind(this);
  }

  componentWillMount() {
    const {
      loadMembers,
      loadTeams,
      loadLocations,
      loadVehicles,
      loadPurposes,
      getCompanyTripsPage,
      setTripsPageSize
    } = this.props;

    setTripsPageSize && setTripsPageSize(15);

    [
      () =>
        getCompanyTripsPage &&
        getCompanyTripsPage({
          page: 1
        }),
      loadMembers,
      loadTeams,
      loadLocations,
      loadPurposes,
      loadVehicles
    ].forEach(loadResource => loadResource && loadResource());
  }

  redirectToReports() {
    const { history } = this.props;

    history.push('/admin/reports/view');
  }

  setElementHeight(elementHeight) {
    this.setState({
      elementHeight
    });
  }

  setSelected(selectedIndex) {
    const { trips } = this.props;

    this.setState({
      selectedIndex,
      selectedTrip: trips[selectedIndex]
    });
  }

  getVehicleNameById(vehicleId) {
    const { vehicles } = this.props;
    const vehicle = vehicles && vehicles.find(({ id }) => vehicleId === id);
    const { nickname, model } = vehicle || {};

    return nickname || model;
  }

  loadTrips(pageNumber) {
    const { getCompanyTripsPage } = this.props;
    const { params } = this.state;

    getCompanyTripsPage &&
      getCompanyTripsPage({
        page: pageNumber,
        ...params
      });

    this.setState({ pageNumber });
  }

  filterTrips(filters) {
    const { pageNumber, params } = this.state;
    const { getCompanyTripsPage } = this.props;

    getCompanyTripsPage &&
      getCompanyTripsPage({
        ...params,
        ...filters,
        page: pageNumber
      });

    this.setState({ params: filters });
  }

  formatTripsSplitByDate(trips, date) {
    return (
      <div className="trips-by-date">
        <h1 className="date-of-trips">{date}</h1>
        <div className="trips-on-date">{trips}</div>
      </div>
    );
  }

  generateReports() {
    const { trips, submitReports } = this.props;
    const { params } = this.state;

    submitReports && submitReports(trips.map(({ id }) => id), params);
  }

  render() {
    const {
      trips,
      filters,
      // unitOfMeasure,
      requestsState,
      total: amountOfTrips,
      totalExpense,
      pageNumber,
      pageSize,
      numberOfUsers
    } = this.props;

    const { elementHeight, selectedIndex, selectedTrip } = this.state;
    const numberOfPages = Math.ceil(amountOfTrips / pageSize);

    return (
      <div className="generate-reports-page">
        <Filters onChange={this.filterTrips} {...filters} />
        <div className="generate-reports-page-header">
          <h1>{amountOfTrips} trips worth</h1>
          <h1 className="total-trip-expense">
            <Money>{totalExpense || 0}</Money>
          </h1>
          <CancelButton
            className="cancel-admin-report-generation generate-reports-header-button"
            onClick={this.redirectToReports}
          >
            Return to reports page
          </CancelButton>
          <GenerateReportsButton
            className="generate-reports-button generate-reports-header-button"
            title="Generate reports for selected trips"
            message={`Are you sure you want to generate reports for ${numberOfUsers} user${
              numberOfUsers > 1 ? "'s" : ''
            } from the displayed trips?`}
            onClick={this.generateReports}
            confirm={true}
          >
            Generate reports
          </GenerateReportsButton>
        </div>
        <div className="generate-reports-page-body">
          <div className="expensed-trips">
            <RenderPage requestsState={requestsState}>
              <div className="pagination-container">
                <Scrollbars
                  elementHeight={elementHeight}
                  indexOfCurrentElement={selectedIndex}
                >
                  <SplitIntoDatesAndHighlight
                    elements={trips}
                    onChange={this.setSelected}
                    getElementHeight={this.setElementHeight}
                    format={this.formatTripsSplitByDate}
                  >
                    {trips && trips.map
                      ? trips.map(
                          ({
                            startTime,
                            endTime,
                            departureAddress,
                            destinationAddress,
                            distance,
                            expense,
                            isApproved,
                            isPaid,
                            vehicleId
                          }) => (
                            <div className="generate-reports-trip trip">
                              <div className="times">
                                <h1>
                                  {moment(startTime).format('HH:mm') + ' '}
                                </h1>
                                <h1>{moment(endTime).format('HH:mm') + ' '}</h1>
                              </div>
                              <div className="trip-addresses">
                                <h1> {departureAddress}</h1>
                                <h1> {destinationAddress}</h1>
                              </div>
                              <div className="trip-vehicle-name">
                                <h1>{this.getVehicleNameById(vehicleId)}</h1>
                              </div>
                              <div className="trip-distance">
                                <h1>
                                  <Distance>{distance}</Distance>
                                </h1>
                              </div>
                              <div
                                className={
                                  expense
                                    ? 'trip-expense-highlighted'
                                    : 'trip-expense'
                                }
                              >
                                <h1>
                                  <Money>{expense}</Money>
                                </h1>
                              </div>
                              <div className="trip-approval-status">
                                <h1>
                                  {isApproved
                                    ? 'Approved'
                                    : isPaid
                                      ? 'Paid'
                                      : 'Pending'}
                                </h1>
                              </div>
                            </div>
                          )
                        )
                      : []}
                  </SplitIntoDatesAndHighlight>
                </Scrollbars>
                <Pagination
                  total={numberOfPages}
                  current={pageNumber}
                  getPage={this.loadTrips}
                />
              </div>
            </RenderPage>
          </div>
          <TripMap
            className="generate-reports-page-map"
            polyline={selectedTrip && selectedTrip.polyline}
          />
        </div>
      </div>
    );
  }
}
