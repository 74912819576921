import createReducer from 'redux/createReducer';

export default (UPDATE_WORK_HOURS, UPDATE_WORK_DAY, UPDATE_ENABLED_STATUS) =>
  createReducer(
    {},
    {
      [UPDATE_ENABLED_STATUS]: (state, action) => {
        return action && action.payload !== undefined
          ? {
              ...state,
              enabled: action.payload
            }
          : state;
      },
      [UPDATE_WORK_DAY]: (state, action) => {
        if (action && action.payload) {
          const modification = action.payload;
          return {
            ...state,
            days: state.days.map(current => {
              return current.day === modification.day
                ? { ...current, ...modification }
                : current;
            })
          };
        }
        return state;
      },
      [UPDATE_WORK_HOURS]: (state, action) =>
        action && action.payload ? action.payload : state
    }
  );
