import trips from 'ducks/trips/index';
import { getNumberOfPages, getCurrentPageNum } from 'ducks/trips/selectors';
import {
  getNumberOfPages as getNumberOfReportPages,
  getCurrentPageNum as getCurrentReportPageNum
} from 'ducks/reports/selectors';
import { getPage } from 'ducks/reports/actions';

export const mapStateToPagination = (state, { reports }) =>
  reports
    ? {
        total: getNumberOfReportPages(state),
        current: getCurrentReportPageNum(state)
      }
    : {
        total: getNumberOfPages(state),
        current: getCurrentPageNum(state)
      };

export const mapDispatchToPagination = (dispatch, { reports }) => ({
  getPage: page => dispatch((reports ? getPage : trips.actions.getPage)(page))
});
