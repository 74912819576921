import _ from 'lodash';
import localize from 'lib/localization/numericLocalization';
import { convertUnitOfMeasurement } from 'lib/utils';
export default (user, rate, toMiles) => {
  return {
    ...user,
    customBusinessRate:
      (_.isNumber(rate) &&
        localize(
          user.countryCode,
          rate
        )) ||
      ''
  };
};
