import locationModifier from 'ducks/locations/locationActions/lib/locationModifier';
import {
  HIDE_LOCATION_FAILURE,
  HIDE_LOCATION_REQUEST,
  HIDE_LOCATION_SUCCESS
} from '../locationActionTypes';

export default locationModifier('delete', [
  HIDE_LOCATION_SUCCESS,
  HIDE_LOCATION_REQUEST,
  HIDE_LOCATION_FAILURE
]);
