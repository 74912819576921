import createReducer from 'redux/createReducer';
import {
  GET_INVITES_SUCCESS,
  DELETE_COMPANY_INVITE_SUCCESS,
  UPDATE_COMPANY_INVITE_SUCCESS,
  COMPANY_INVITE_USERS_SUCCESS,
  CONFIRM_INVITATION_SUCCESS
} from '../companyActionTypes';
import { setAuthToken, setCompanyId, setUserId } from 'lib/auth';

export const setInvites = (state, action) => (action ? action.payload : state);

export default createReducer([], {
  ...[GET_INVITES_SUCCESS].reduce((state, action) => {
    state[action] = setInvites;
    return state;
  }, {}),

  [COMPANY_INVITE_USERS_SUCCESS]: (state, { payload }) =>
    payload ? [...state, ...payload] : state,

  [DELETE_COMPANY_INVITE_SUCCESS]: (state, { payload }) =>
    state.filter(({ id }) => Number(id) !== Number(payload)),

  [UPDATE_COMPANY_INVITE_SUCCESS]: (state, action) => {
    const updated = action.payload;
    const id = updated && updated.id;
    return state.map(
      invite =>
        Number(invite.id) === Number(id) ? { ...invite, ...updated } : invite
    );
  },
  [CONFIRM_INVITATION_SUCCESS]: (state, { payload }) => {
    const { id, companyId, authToken } = payload;

    setCompanyId(companyId);
    setUserId(id);
    setAuthToken(authToken);

    return state;
  }
});
