import { NAME as nameSpace } from 'ducks/users/constants';

export const GET_REQUEST = `${nameSpace}/GET_REQUEST`;
export const GET_SUCCESS = `${nameSpace}/GET_SUCCESS`;
export const GET_FAILURE = `${nameSpace}/GET_FAILURE`;

export const UPDATE_REQUEST = `${nameSpace}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${nameSpace}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${nameSpace}/UPDATE_FAILURE`;

export const DELETE_USER_REQUEST = `${nameSpace}/DELETE_USER_REQUEST`;
export const DELETE_USER_SUCCESS = `${nameSpace}/DELETE_USER_SUCCESS`;
export const DELETE_USER_FAILURE = `${nameSpace}/DELETE_USER_FAILURE`;

export const SET_ACTIVE = `${nameSpace}/SET_ACTIVE`;
