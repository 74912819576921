import * as t from './actionTypes';
import * as constants from './constants';
import React from 'react';
import InactiveSubscriptionMessage from 'components/invalidSubscriptionMessage';

export const showInvalidSubscriptionMessage = redirect =>
  send({
    id: new Date().getTime(),
    type: 'info',
    message: <InactiveSubscriptionMessage onClick={redirect} />
  });

export const send = notification => ({
  type: t.SEND,
  payload: notification
});

export const dismiss = id => ({
  type: t.DISMISS,
  payload: id
});

export const clear = () => ({
  type: t.CLEAR
});

export const notify = (type, message) => dispatch => {
  const notification = {
    id: new Date().getTime(),
    type,
    message
  };
  dispatch(send(notification));
  setTimeout(() => dispatch(dismiss(notification.id)), constants.DELAY);
};
