import { setNameForPaginatedStores } from 'ducks/lib/Pagination/setNameForPaginatedStores';
import selectionSelectors from 'ducks/listSelection/selectionSelectors';

export const mapStateToSelector = (state, { pageName }) => {
  const { getSelected, allSelected } = setNameForPaginatedStores(
    pageName,
    selectionSelectors
  );
  const selected = getSelected(state);

  return {
    isSelected: id => selected && selected[id],
    amountSelected: Object.keys(selected || {}).length,
    allSelected: allSelected(state)
  };
};
