import { connect } from 'react-redux';
import StatisticsPage from './StatisticsPage';
import {
  mapStateToStatisticsPage,
  mapDispatchToStatisticsPage
} from './statisticsPageMappings';

export default connect(
  mapStateToStatisticsPage,
  mapDispatchToStatisticsPage
)(StatisticsPage);
