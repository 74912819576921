import { getCompanyUsers } from 'api/endpoints/company/users';
import { getPage } from 'ducks/pagination/paginationActions/getPage';
import { GET_COMPANY_MEMBERS_SUCCESS } from '../../companyActionTypes';
import { COMPANY_MEMBERS } from 'ducks/company/constants';

const getReports = getPage(COMPANY_MEMBERS, 'users', getCompanyUsers);

export const getCompanyMembersPage = params => dispatch =>
  dispatch(getReports(params)).then(() =>
    dispatch({
      type: GET_COMPANY_MEMBERS_SUCCESS
    })
  );

export default getCompanyMembersPage;
