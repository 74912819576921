import './RulesPage.css';
import React, { Component } from 'react';
import Title from 'components/CompanyPages/lib/PageTitleHeader';
import AddAutoApprovalRulePage from 'components/CompanyPages/Admin/SettingsPage/RulesPage/AddAutoApprovalRulePage';
import DropDown from 'components/CompanyPages/lib/DropDownSelector';
import AddButton from 'components/CompanyPages/lib/AddButton';
import Modal from 'components/Modal';
import { RulesList } from 'components/CompanyPages/lib/RulesList';

const periodMappings = {
  monthly: 'month',
  yearly: 'year',
  daily: 'day',
  weekly: 'week'
};

const distanceMappings = {
  km: 'kilometers',
  mi: 'miles'
};

export default class RulesPage extends Component {
  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false };
    this.selectSubmissionRule = this.selectSubmissionRule.bind(this);
    this.deleteAutoApprovalRule = this.deleteAutoApprovalRule.bind(this);
    this.openAddAutoApprovalRuleModal = this.openAddAutoApprovalRuleModal.bind(
      this
    );
    this.closeAddAutoApprovalRuleModal = this.closeAddAutoApprovalRuleModal.bind(
      this
    );
  }
  componentWillMount() {
    const { getAutoApprovalRules, getTeams, loadPurposes } = this.props;
    getAutoApprovalRules();
    loadPurposes();
    getTeams();
  }
  selectSubmissionRule() {}
  openAddAutoApprovalRuleModal() {
    this.setState({ modalIsOpen: true });
  }
  closeAddAutoApprovalRuleModal() {
    this.setState({ modalIsOpen: false });
  }
  deleteAutoApprovalRule({ target }) {
    const { removeAutoApprovalRule } = this.props;
    removeAutoApprovalRule(target.id);
  }
  render() {
    const {
      autoSubmissionRules,
      selectedAutoSubmissionRule,
      autoApprovalRules,
      purposes,
      teams
    } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <div className="padded-page">
        <Modal
          isOpen={modalIsOpen}
          className="add-rule-modal"
          key="add-rule-modal"
          onClose={this.closeAddAutoApprovalRuleModal}
          title={'Add Rule'}
        >
          <AddAutoApprovalRulePage
            onSubmit={this.closeAddAutoApprovalRuleModal}
          />
        </Modal>
        <Title>Auto Submission Rules</Title>
        <div className="auto-submission-rules-section">
          <div className="auto-submission-description auto-approval-rule-content">
            <h1>
              Expenses classified as business with notes or a purpose will
              automatically be approved when expensed.
            </h1>
          </div>
          {autoSubmissionRules && (
            <DropDown
              title={selectedAutoSubmissionRule}
              className="auto-submission-rules-drop-down auto-approval-rule-content"
            >
              {autoSubmissionRules.map((rule, index) => (
                <div
                  onClick={this.selectSubmissionRule}
                  id={index}
                  className="auto-submission-rule"
                >
                  <h1>{rule}</h1>
                </div>
              ))}
            </DropDown>
          )}
        </div>
        <Title>Auto Approval Rules</Title>
        <div className="auto-approval-rules-section">
          <div className="auto-approval-description rule-content">
            <h1>
              Any expense below a certain amount, for a specific category,
              submitted by predefined user(s) that will automatically be
            </h1>
          </div>
          <AddButton
            onClick={this.openAddAutoApprovalRuleModal}
            className="add-auto-approval-rule-button auto-approval-rule-content"
          >
            Add Rule
          </AddButton>
        </div>
        <RulesList
          onDelete={this.deleteAutoApprovalRule}
          rules={autoApprovalRules}
          component={({ teamId, purposeId, amount, distance, period }) => {
            const purpose = purposes && purposes[purposeId];
            return (
              <h1>
                Expenses{teamId && teams ? ` for ${teams[teamId]}` : ''}
                {purposeId && purposes
                  ? ` classified as ${purpose && purpose.name}`
                  : ''}
                {amount ? ` under $${amount}` : ''}
                {distance
                  ? `${amount ? ' and ' : ''} below ${distance} ${
                      distanceMappings['mi']
                    }`
                  : ''}{' '}
                approve automatically each {periodMappings[period]}.
              </h1>
            );
          }}
        />
      </div>
    );
  }
}
