import createReducer from 'redux/createReducer';
import { SET_PAGE_SIZE } from '../paginationActionTypes';

export const pageSize = createReducer(
  {},
  {
    [SET_PAGE_SIZE]: (state, { payload, pageName }) =>
      !isNaN(payload)
        ? {
            ...state,
            [pageName]: payload
          }
        : state
  }
);

export default pageSize;
