import { connect } from 'react-redux';
import CompanySignUp from './CompanySignUp';
import { withRouter } from 'react-router-dom';
import {
  mapDispatchToCompanySignUp,
  mapStateToCompanySignUp
} from './CompanySignUpMappings';
export default withRouter(
  connect(mapStateToCompanySignUp, mapDispatchToCompanySignUp)(CompanySignUp)
);
