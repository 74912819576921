import React from 'react';
import moment from 'moment';
import Async from 'components/Async';
import Money from 'components/Money';
import Distance from 'components/Distance';
import NoConversionDistance from "../Distance/NoConversionDistance";

function buildRatesTable(rates, metric) {
  const rows = [];
  rates.forEach(ratesPerMileage => {
    const { length } = ratesPerMileage;
    let row;
    ratesPerMileage.forEach(({ current, previous }) => {
      const rateHasMultipleRanges = length > 1;
      const { name, start, end } = current;
      row = [current];
      if (rateHasMultipleRanges) {
        row = [
          <div>
            {name}
            <div className="purposes-rates-table__mileage-range">
              (<NoConversionDistance value={start} metric={metric} round />
              {end > 0 ? (
                <span>
                  &ndash;
                  <NoConversionDistance value={end} metric={metric} round />
                </span>
              ) : (
                ' and up'
              )}
              )
            </div>
          </div>
        ];
      }
      row = row.concat(
        previous.map(rate => (rate ? <Money value={rate} rate /> : rate))
      );
      rows.push(row);
    });
  });
  return rows;
}

const PurposesRatesTable = ({ request, name, taxPeriods, rates, metric }) => {
  // grab the last three tax periods
  let rows = taxPeriods ? buildRatesTable(rates, metric) : [];
  return (
    <Async request={request}>
      <div className="purposes-rates-table">
        <div className="purposes-rates-table__title">{name} Tax Rates</div>
        <table className="purposes-rates-table__body">
          <thead>
            <tr className="purposes-rates-table__head">
              <th className="purposes-rates-table__column">Category</th>
              {taxPeriods ? (
                taxPeriods.map(taxPeriod => (
                  <th
                    key={taxPeriod.id}
                    className="purposes-rates-table__column"
                  >
                    {moment(
                      moment(taxPeriod.startDate).dayOfYear() < 183
                        ? taxPeriod.startDate
                        : taxPeriod.endDate
                    ).year()}
                  </th>
                ))
              ) : (
                <th>
                  <h1>No tax periods found.</h1>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr key={i} className="purposes-rates-table__row">
                {row.map((cell, j) => (
                  <td key={j} className="purposes-rates-table__column">
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Async>
  );
};

export default PurposesRatesTable;
