import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import classNames from 'class-names';
import { formatVehicle } from 'lib/formatters';
import { range } from 'lib/utils';
import * as normalizers from 'lib/normalizers';
import AsyncTextbox from 'components/AsyncTextbox';
import Checkbox from 'components/Checkbox';
import DeleteButton from 'components/DeleteButton';

import './Vehicle.css';

const VehicleInput = ({ name, vehicle, update, ...props }) =>
  <AsyncTextbox
    name={name}
    value={vehicle[name]}
    onChange={value => update({ [name]: value })}
    {...props}
  />;

export default class Vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = { minYear: new Date().getFullYear() };
    this.addYear = this.addYear.bind(this);
  }

  componentWillMount() {
    const { vehicle: { odometers } } = this.props;

    if (odometers && odometers.length) {
      const minYear = Math.min(...odometers.map(odometer => odometer.year));
      this.setState({ minYear });
    }
  }

  addYear() {
    this.setState(state => ({ minYear: state.minYear - 1 }));
  }

  render() {
    const { vehicle, update, fetching, setPrimary, destroy } = this.props;
    const { minYear } = this.state;

    return (
      <div className="vehicle">
        <div className="vehicle__head">
          <div className="vehicle__name">
            {formatVehicle(vehicle)}{' '}
            {vehicle.isPrimary &&
              <span className="vehicle__default">Default</span>}
            {fetching &&
              <span>
                {' '}<FontAwesome name="circle-o-notch" fixedWidth spin />
              </span>}
          </div>
          <div
            className={classNames('vehicle__destroy', {
              vehicle__destroy_disabled: vehicle.isPrimary
            })}
          >
            {!vehicle.isPrimary &&
              <DeleteButton
                onClick={() =>
                  window.confirm(
                    `Are you sure you want to delete your ${formatVehicle(
                      vehicle
                    )}?`
                  )
                    ? destroy()
                    : Promise.resolve()}
              />}
          </div>
        </div>

        <div className="vehicle__body">
          <div className="vehicle__left">
            <div className="vehicle__inputs">
              <div className="vehicle__form-control">
                <VehicleInput
                  vehicle={vehicle}
                  update={update}
                  name="make"
                  placeholder="Make"
                />
              </div>

              <div className="vehicle__form-control">
                <VehicleInput
                  vehicle={vehicle}
                  update={update}
                  name="model"
                  placeholder="Model"
                />
              </div>

              <div className="vehicle__form-control">
                <VehicleInput
                  vehicle={vehicle}
                  update={update}
                  name="year"
                  placeholder="Year"
                  normalize={normalizers.integer}
                />
              </div>

              <div className="vehicle__form-control">
                <VehicleInput
                  vehicle={vehicle}
                  update={update}
                  name="nickname"
                  placeholder="Nickname"
                />
              </div>
            </div>

            <div className="vehicle__odometers">
              {range(minYear, new Date().getFullYear() + 1)
                .reverse()
                .map(year => [
                  year,
                  vehicle.odometers.find(o => o.year === year)
                ])
                .map(([year, odometer]) =>
                  <div key={year} className="vehicle__form-control">
                    <label htmlFor={`odometer[${year}]`}>
                      Odometer start {year}
                    </label>

                    <AsyncTextbox
                      name={`odometer[${year}]`}
                      value={odometer ? odometer.miles : ''}
                      onChange={miles =>
                        update({ odometers: [{ year, miles }] })}
                      normalize={normalizers.integer}
                    />
                  </div>
                )}

              <div className="vehicle__form-control">
                <div className="vehicle__add-odometer">
                  <a href="#add-vehicle" role="button" onClick={this.addYear}>
                    Add previous year
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="vehicle__right">
            <Checkbox
              label="Default"
              checked={vehicle.isPrimary}
              onChange={e => {
                if (e.target.checked) {
                  setPrimary(vehicle.id);
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
