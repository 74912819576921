import { getCompanyMembers } from './getCompanyMembers';
import { getCompanyInvites } from '../getCompanyInvites';

export const getCompanyUsers = state => {
  const members = (getCompanyMembers(state) || []).map(member => ({
    status: 'active',
    ...member
  }));

  const invites = (getCompanyInvites(state) || []).map(invite => ({
    status: invite.cancelled ? 'cancelled' : 'invited',
    ...invite
  }));

  return [...members, ...invites];
};

export default getCompanyUsers;
