import { connect } from 'react-redux';
import purposes from 'ducks/purposes';
import Classify from './Classify';

const mapStateToProps = state => ({
  business: purposes.selectors.getActiveBusiness(state),
  personal: purposes.selectors.getActivePersonal(state),
  other: purposes.selectors.getActiveWithoutDefault(state)
});

export default connect(mapStateToProps)(Classify);
