import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'class-names';

import './Action.css';

const Action = ({ className, children, disabled, ...props }) => (
  <Link
    className={classNames('action', { action_disabled: disabled }, className)}
    {...props}
  >
    {children}
  </Link>
);

export default Action;
