import { companyApi } from '../../client';

export const getCompanyReports = params =>
  companyApi.get('/reports', { params });

export const putCompanyReports = (reportIds, params) =>
  companyApi.put('/reports', {
    reportIds,
    report: params
  });

export const postCompanyReports = (tripIds, { allSelected, ...params }) =>
  companyApi.post('/reports', {
    tripIds,
    selectAll: allSelected,
    report: {
      approvalStatus: 'submitted',
      ...params
    }
  });

export const getCompanyAdminReport = () => companyApi.get('/admin_report');

export const putCompanyReport = (id, report) =>
  companyApi.put(`/reports/${id}`, { report });
