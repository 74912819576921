import { postKeyPerformanceIndexReport } from 'api/endpoints/company/kpiReports';
import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import {
  POST_KPI_REPORT_REQUEST,
  POST_KPI_REPORT_SUCCESS,
  POST_KPI_REPORT_FAILURE
} from '../kpiReportActionTypes';

export const addKpiReport = createSimpleAction(postKeyPerformanceIndexReport, [
  POST_KPI_REPORT_REQUEST,
  POST_KPI_REPORT_SUCCESS,
  POST_KPI_REPORT_FAILURE
]);

export default addKpiReport;
