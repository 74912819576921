import React from 'react';
import classNames from 'class-names';
import ErrorBoundary from 'components/ErrorBoundary';

import './Sidebar.css';

const Sidebar = ({ children, title, thickTitle, ...props }) => (
  <div className="sidebar" {...props}>
    {title && (
      <div
        className={classNames('sidebar__title', {
          sidebar__title_thick: thickTitle
        })}
      >
        {title}
      </div>
    )}
    <ErrorBoundary>{children}</ErrorBoundary>
  </div>
);

export default Sidebar;
