import React from 'react';
import classNames from 'class-names';

import './Value.css';

const Value = ({ className, children, type, big, purpose }) => {
  if ((!type || !type.length) && purpose) {
    type = purpose.name;
  }
  return (
    <span
      className={classNames('value', className, {
        value_business: type === 'Business',
        value_personal: type === 'Personal',
        value_big: big
      })}
    >
      {children}
    </span>
  );
};

export default Value;
