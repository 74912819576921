import moment from 'moment/moment';
import React from 'react';
import TimePicker from '../../TimePicker/TimePicker';
export default ({ initialValue, parent, input: { value, onChange } }) => (
  <TimePicker
    value={moment(value || initialValue)}
    onChange={value => onChange(value.format())}
    parent={parent}
  />
);
