import { UNABLE_TO_MATCH_CREDENTIALS } from '../constants';
import { INVALID_EMAIL_OR_PASSWORD } from '../actionTypes';
import _ from 'lodash';

export const invalidEmailOrPassword = message => ({
  type: INVALID_EMAIL_OR_PASSWORD,
  meta: {
    alert: {
      type: 'error',
      message:
        message ||
        'Invalid login. Please be sure your email and password are entered correctly.'
    }
  }
});

export const handleSignInError = error => dispatch => {
  const response = error.response;
  return response && response.status === UNABLE_TO_MATCH_CREDENTIALS
    ? dispatch(invalidEmailOrPassword())
    : dispatch(invalidEmailOrPassword(response));
};

export const signInToApp = (email, password, signInMethod, path) => dispatch =>
  signInMethod(email, password)
    .then(({ data }) => _.get(data, path, {}))
    .catch(error => dispatch(handleSignInError(error)));

export default signInToApp;
