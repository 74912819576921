import createReducer from 'redux/createReducer';
import { applyReducerToActions } from 'ducks/lib/applyReducerToActions';
import {
  GET_COMPANY_REPORTS_SUCCESS,
  PUT_COMPANY_REPORT_SUCCESS,
  POST_COMPANY_REPORTS_SUCCESS
} from 'ducks/company/companyActionTypes';
import { PUT_COMPANY_REPORTS_SUCCESS } from '../companyActionTypes';

export const setCompanyReports = (state, { payload }) =>
  payload ? payload : state;

export const updateCompanyReport = (state, { payload }) => {
  const reportId = payload && payload.id;
  return reportId
    ? state.map(report => (`${report.id}` === `${reportId}` ? payload : report))
    : state;
};

export const companyReports = createReducer([], {
  ...applyReducerToActions(
    setCompanyReports,
    GET_COMPANY_REPORTS_SUCCESS,
    POST_COMPANY_REPORTS_SUCCESS
  ),
  ...applyReducerToActions(updateCompanyReport, PUT_COMPANY_REPORT_SUCCESS)
});

export default companyReports;
