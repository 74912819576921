import { deleteAutoApprovalRule } from 'api/endpoints/company/autoApprovalRules';
import {
  DELETE_AUTO_APPROVAL_RULE_FAILURE,
  DELETE_AUTO_APPROVAL_RULE_REQUEST,
  DELETE_AUTO_APPROVAL_RULE_SUCCESS
} from 'ducks/rules/rulesAcitionTypes';

export const deleteAutoApprovalRuleSuccess = payload => ({
  type: DELETE_AUTO_APPROVAL_RULE_SUCCESS,
  payload
});

export const deleteAutoApprovalRuleRequest = payload => ({
  type: DELETE_AUTO_APPROVAL_RULE_REQUEST,
  payload
});

export const deleteAutoApprovalRuleFailure = (error, id) => ({
  type: DELETE_AUTO_APPROVAL_RULE_FAILURE,
  payload: { id },
  error
});

export const removeAutoApprovalRule = id => dispatch => {
  dispatch(deleteAutoApprovalRuleRequest(id));
  return deleteAutoApprovalRule(id)
    .then(() => dispatch(deleteAutoApprovalRuleSuccess(id)))
    .catch(error => dispatch(deleteAutoApprovalRuleFailure(error, id)));
};

export default removeAutoApprovalRule;
