import _ from 'lodash';
import createReducer from 'redux/createReducer';
import * as t from './actionTypes';
import * as tripTypes from 'ducks/trips/actionTypes';

// use union/difference to avoid duplicate entries
const addToSelection = (state, ids) => _.union(state, ids);

const removeFromSelection = (state, ids) => _.difference(state, ids);

export default createReducer([], {
  [t.SELECT_TRIP]: (state, { payload }) => addToSelection(state, [payload]),
  [t.DESELECT_TRIP]: (state, { payload }) =>
    removeFromSelection(state, [payload]),
  [t.SELECT_ALL]: (state, { payload }) => addToSelection(state, payload.ids),
  [t.DESELECT_ALL]: (state, { payload }) =>
    removeFromSelection(state, payload.ids),
  [tripTypes.DELETE_SUCCESS]: (state, { payload }) =>
    removeFromSelection(state, payload.ids),
  [tripTypes.INVALIDATE]: () => []
});
