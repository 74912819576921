import React, { Component } from 'react';
import LocationHint from './LocationHint';
export default class LocationFieldDropDown extends Component {
  constructor(props) {
    super(props);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }
  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, true);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, true);
  }
  handleOutsideClick(event) {
    const { onClose } = this.props;
    (!this.dropDown || !this.dropDown.contains(event.target)) &&
      onClose &&
      onClose(event);
  }
  render() {
    const { elements, onSelect, key } = this.props;
    return (
      <div
        ref={node => (this.dropDown = node)}
        key={key}
        className="location-hints"
      >
        {elements.map((location, index) => (
          <LocationHint
            key={`location-hint-${index}`}
            className="location-hint"
            location={location}
            onClick={onSelect}
          />
        ))}
      </div>
    );
  }
}
