import { createSelector } from 'reselect';
import * as constants from './constants';
import { getTrips } from 'ducks/trips/selectors';
import purposes from 'ducks/purposes';

const getState = state => state[constants.NAME];

export const countSelected = state => getState(state).length;

export const isIdInMask = createSelector(
  [getState, (_, id) => id],
  (selection, id) => selection.indexOf(id) > -1
);

export const isSelected = createSelector(
  [getState, (_, id) => id],
  (selection, id) => selection.indexOf(id) > -1
);

export const getSelectedIds = state => getState(state);

export const getSelectedTrips = createSelector(
  [getTrips, getSelectedIds],
  (trips, ids) => ids.map(id => trips[id])
);

export const getSelectedValue = createSelector(getSelectedTrips, trips => {
  const classified = trips.filter(trip => trip.tripType !== 'Unclassified');
  return classified.reduce((sum, trip) => sum + trip.expense, 0);
});

export const getSelectedPotentialValue = createSelector(
  [getSelectedTrips, purposes.selectors.getActiveBusiness],
  (trips, purpose) => {
    const purposeRate = purpose && purpose.rate;
    const mileageRanges = purposeRate && purposeRate.mileageRanges;
    const range = mileageRanges && mileageRanges[0];
    const rate = range && range.rate;

    return trips.reduce(
      (sum, trip) =>
        sum + trip.distance * rate + trip.tollExpense + trip.parkingExpense,
      0
    );
  }
);
