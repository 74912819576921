import React from 'react';
import FontAwesome from 'react-fontawesome';
import TextBox from 'components/TextBox';
import 'components/TextBoxField/TextboxField.css';
import './CompanyField.css';

export const renderCompanyTextField = ({
  meta: { touched, error, warning },
  input,
  className,
  ...props
}) => [
  <TextBox type="text" className={className} {...props} {...input} />,
  ...(touched && (error || warning)
    ? [
        (error && (
          <div className="textbox-field__error company-field-error">
            <FontAwesome name="exclamation-circle" /> {error}
          </div>
        )) ||
          (warning && (
            <div className="textbox-field__warning">
              <FontAwesome name="exclamation-circle company-field-warning" />{' '}
              {warning}
            </div>
          ))
      ]
    : [])
];

export default renderCompanyTextField;
