import { combineReducers } from 'redux';
import { createItemsReducer } from 'redux/itemsReducer';
import { createRequestReducer } from 'redux/requestReducer';
import { createRequestsByIdReducer } from 'redux/requestsByIdReducer';
import {
  GET_SUCCESS,
  GET_REQUEST,
  GET_FAILURE,
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  DESTROY_SUCCESS,
  CREATE_SUCCESS
} from './actionTypes';

export default combineReducers({
  items: createItemsReducer({
    setAll: [GET_SUCCESS],
    setItem: [UPDATE_SUCCESS, CREATE_SUCCESS],
    removeItem: [DESTROY_SUCCESS]
  }),
  request: createRequestReducer([[GET_REQUEST, GET_SUCCESS, GET_FAILURE]]),
  itemRequests: createRequestsByIdReducer([
    [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE]
  ])
});
