import React, { Component } from 'react';
import Title from 'components/CompanyPages/lib/PageTitleHeader';
import AddButton from 'components/CompanyPages/lib/AddButton';
import AddReportPage from 'components/CompanyPages/Admin/SettingsPage/ReportsPage/AddReportPage';
import RulesList from 'components/CompanyPages/lib/RulesList';
import Modal from 'components/Modal';
import './ReportsPage.css';

export default class ReportsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { addReportModalIsOpen: false };
    this.openAddReportRuleModal = this.openAddReportRuleModal.bind(this);
    this.closeAddReportRuleModal = this.closeAddReportRuleModal.bind(this);
    this.deleteReportRule = this.deleteReportRule.bind(this);
  }

  componentWillMount() {
    const { loadKpiReports, loadUsers } = this.props;
    loadKpiReports && loadKpiReports();
    loadUsers && loadUsers();
  }

  deleteReportRule({ target }) {
    const { deleteReportRule } = this.props;
    deleteReportRule(target.id);
  }

  openAddReportRuleModal() {
    this.setState({ addReportModalIsOpen: true });
  }

  closeAddReportRuleModal() {
    this.setState({ addReportModalIsOpen: false });
  }

  render() {
    const { addReportModalIsOpen } = this.state;
    const { reportRules, users } = this.props;
    return (
      <div className="company-reports-page">
        <Modal
          title={'Add Report Rule'}
          className="add-report-modal"
          isOpen={addReportModalIsOpen}
          onClose={this.closeAddReportRuleModal}
        >
          <AddReportPage onSubmit={this.closeAddReportRuleModal} />
        </Modal>
        <Title>Key Performance Index Reports</Title>
        <div className="company-reports-page-header">
          <h1 className="company-reports-page-description">
            Generate automatic KPI reports of users who have expensed below or
            above set amount/distance/time for predefined period of time.
          </h1>
          <AddButton
            className="add-report-rule-page-button"
            onClick={this.openAddReportRuleModal}
          >
            Add Rule
          </AddButton>
        </div>
        <RulesList
          onDelete={this.deleteReportRule}
          rules={reportRules}
          component={({
            userId,
            action,
            period,
            managerId,
            amount,
            unitOfMeasure,
            id
          }) => {
            const user = users && users.find(({ id }) => id === userId);
            const manager = users && users.find(({ id }) => id === managerId);
            const userName = (user && user.name) || 'all users';
            const managerName =
              (manager && manager.name) || 'their corresponding managers';
            return (
              <h1 id={id}>
                Reports made by {userName} that {action || 'have expensed'}{' '}
                {amount || '20'} {unitOfMeasure || 'USD'} in a {period || 'day'}{' '}
                will be sent to {managerName} for review.
              </h1>
            );
          }}
        />
      </div>
    );
  }
}
