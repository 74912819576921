import Raven from 'raven-js';
import moment from 'moment';

const ENVIRONMENT = process.env.NODE_ENV;
const raven = Raven.config(process.env.REACT_APP_SENTRY_DSN, {
  environment: process.env.NODE_ENV,
  autoBreadcrumbs: { console: false }
}).install();

let environment = ENVIRONMENT;

export const getEnvironment = () => environment;
export const setEnvironment = (env = ENVIRONMENT) => {
  environment = env;
};
export const logError = (error, context) => {
  const locale = moment.locale();
  if (environment === 'production')
    raven.captureException(error, { extra: { ...context, locale } });
  console.error('[logged to Sentry]', error, context);
};
export default raven;
