import React from 'react';
import LoadingPage from 'components/LoadingPage';
import ErrorPage from 'components/ErrorPage';
import { LOADED, ERROR } from 'lib/requests';
export default Form => ({ status, error, children, ...props }) => {
  switch (status) {
    case LOADED:
      return <Form {...props}>{children}</Form>;
    case ERROR:
      return <ErrorPage error={error} />;
    default:
      return <LoadingPage />;
  }
};
