import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

export default WrappedComponent =>
  connect()(({ form, children, onClick, dispatch, ...props }) => {
    const click = event => {
      onClick && onClick(event);

      return dispatch(submit(form));
    };

    return (
      <WrappedComponent onClick={click} {...props}>
        {children}
      </WrappedComponent>
    );
  });
