import { connect } from 'react-redux';
import vehicles from 'ducks/vehicles';
import Garage from './Garage';

const mapStateToProps = state => ({
  vehicles: vehicles.selectors.getCurrentVehicles(state)
});

const mapDispatchToProps = dispatch => ({
  create: () => dispatch(vehicles.actions.create())
});

export default connect(mapStateToProps, mapDispatchToProps)(Garage);
