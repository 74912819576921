import React, { Component } from 'react';
import FilterBar from 'components/CompanyPages/lib/FilterBar';
import Filter from 'components/CompanyPages/lib/Filter';
import './Filters.css';

export default class Filters extends Component {
  constructor(props) {
    const { filters } = props;

    super(props);

    this.onChange = this.onChange.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.keys = filters ? Object.keys(filters) : [];
    this.state = {
      ...this.keys.reduce(
        (filterValues, key) => ({
          ...filterValues,
          [key]: null
        }),
        {}
      )
    };
  }

  getFilters() {
    const { filters } = this.props;

    return filters && this.keys.map(key => filters[key]);
  }

  onChange(keyIndex) {
    const key = this.keys[keyIndex];
    const { onChange, format, formatFilters, multiSelect } = this.props;

    return (value, index) => {
      const isMultiSelect = multiSelect && multiSelect[keyIndex];
      const stateChange = { [key]: isMultiSelect ? value : index };
      const changes = {
        ...this.state,
        ...stateChange
      };

      const formatted = formatFilters(format ? format(changes) : changes);

      this.setState(stateChange);

      onChange && onChange(formatted);
    };
  }

  render() {
    const {
      className,
      description,
      multiSelect,
      icons,
      space,
      defaultValues
    } = this.props;
    const filters = this.getFilters();

    return (
      <FilterBar
        spacing={space}
        className={className}
        description={description}
      >
        {filters &&
          filters.map &&
          filters.map((filter, index) => {
            return (
              <Filter
                icon={icons && icons[index]}
                multiSelect={multiSelect && multiSelect[index]}
                title={filter && filter[0]}
                key={`${className}-filter-${index}`}
                onChange={this.onChange(index)}
                defaultValue={defaultValues[index]}
              >
                {filter}
              </Filter>
            );
          })}
      </FilterBar>
    );
  }
}
