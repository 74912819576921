export const COUNTRIES_USING_COMMAS_TO_PERIOD = [
  'Australia',
  'Bangladesh',
  'Botswana',
  'British West Indies',
  'Brunei',
  'Cambodia',
  'Canada',
  'China',
  'Hong Kong',
  'Macau',
  'Dominican Republic',
  'Egypt',
  'El Salvador',
  'Ghana',
  'Guatemala',
  'Honduras',
  'India',
  'Ireland',
  'Israel',
  'Japan',
  'Jordan',
  'Kenya',
  'Korea, North',
  'Korea, South',
  'Lebanon',
  'Liechtenstein',
  'Luxembourg',
  'Malaysia',
  'Maldives',
  'Malta',
  'Mexico',
  'Myanmar',
  'Nepal',
  'New Zealand',
  'Nicaragua',
  'Nigeria',
  'Pakistan',
  'Palestine',
  'Panama',
  'Philippines',
  'Puerto Rico',
  'Singapore',
  'Sri Lanka',
  'Switzerland',
  'Taiwan',
  'Tanzania',
  'Thailand',
  'Uganda',
  'United Kingdom',
  'United States',
  'Zimbabwe'
];
export const COUNTRIES_USING_SPACES_TO_COMMA = [
  'SI-French',
  'Albania',
  'Bulgaria',
  'Canada-French',
  'Costa Rica',
  'Croatia',
  'Czech Republic',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Hungary',
  'Kosovo',
  'Latin Europe',
  'Norway',
  'Peru',
  'Poland',
  'Portugal',
  'Russia',
  'Serbia',
  'Slovakia',
  'South Africa',
  'Sweden',
  'Switzerland',
  'Ukraine'
];

export const COUNTRIES_USING_COMMAS_TO_SQUARE = [
  'Ireland',
  'Malaysia',
  'Malta',
  'Philippines',
  'Singapore',
  'Taiwan',
  'Thailand'
];

export const COUNTRIES_USING_PERIODS_TO_COMMA = [
  'Argentina',
  'Austria',
  'Belgium',
  'Bosnia',
  'Herzegovina',
  'Brazil',
  'Chile',
  'Denmark',
  'Greece',
  'Indonesia',
  'Italy',
  'Netherlands',
  'Romania',
  'Slovenia',
  'Spain'
];
