import _ from 'lodash';
export const arrayToString = (array, and) =>
  _.isArray(array) && array.length
    ? array.reduce((string, value, index) => {
        if (and && !array[index + 1]) {
          return `${string} and ${value}`;
        } else {
          return `${string ? `${string}, ` : ''}${value ? value : ''}`;
        }
      })
    : '';

export default arrayToString;
