import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { putCompanyReport } from 'api/endpoints/company/reports';
import { updateCompanyReportsPage } from './updateCompanyReportsPage';
import {
  PUT_COMPANY_REPORT_REQUEST,
  PUT_COMPANY_REPORT_SUCCESS,
  PUT_COMPANY_REPORT_FAILURE
} from 'ducks/company/companyActionTypes';

export const updateCompanyReport = (...data) => dispatch =>
  dispatch(
    createSimpleAction(
      putCompanyReport,
      [
        PUT_COMPANY_REPORT_REQUEST,
        PUT_COMPANY_REPORT_SUCCESS,
        PUT_COMPANY_REPORT_FAILURE
      ],
      'report'
    )(...data)
  ).then(report => dispatch(updateCompanyReportsPage(report)));

export default updateCompanyReport;
