import React from 'react';
import GetStartedLink from './GetStartedLink';
import ViewNavigator from './ViewNavigator';
import './CompanyNavigator.css';

export default props => (
  <div className="company-nav">
    <GetStartedLink {...props} />
    <ViewNavigator {...props} />
  </div>
);
