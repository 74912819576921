import React from 'react';
import { LOADING, ERROR } from 'lib/requests';
import LoadingPage from 'components/LoadingPage';
import ErrorPage from 'components/ErrorPage';
import './RenderPage.css';

export default ({ children, requestsState }) =>
  requestsState === ERROR ? (
    <ErrorPage className="request-error" />
  ) : requestsState === LOADING ? (
    <LoadingPage className="request-loading" />
  ) : (
    children
  );
