import { combineReducers } from 'redux';
import { isNumber } from 'lodash';
import * as t from './usersActionTypes';
import { INVALIDATE } from 'ducks/session/actionTypes';
import createReducer from 'redux/createReducer';
import { createRequestsByIdReducer } from 'redux/requestsByIdReducer';
import {
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  ADD_LOCATION_REQUEST,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAILURE,
  SELECT_LOCATION
} from 'ducks/locations/locationActionTypes';
import { GET_USERS_SUCCESS } from './usersActionTypes';
import { GET_USERS_FAILURE } from './usersActionTypes';
import { GET_USERS_REQUEST } from './usersActionTypes';
import { users as usersReducer } from './usersReducer/users';
import userRequests from './usersReducer/userRequests';

export const activeId = createReducer(null, {
  [t.SET_ACTIVE]: (state, action) => {
    const payload = action && action.payload;
    const id = payload && payload.id;
    return id || isNumber(id) ? id : state;
  },
  [INVALIDATE]: () => null
});

export const setUser = (state = {}, action) => {
  const payload = action && action.payload;
  const id = payload && payload.id;
  return { ...state, [id]: payload };
};

export const users = createReducer(
  {},
  {
    [GET_USERS_SUCCESS]: (_, { payload }) =>
      payload &&
      payload.reduce((users, user) => ({
        ...users,
        [user.id]: user
      }))
  }
);

export const items = createReducer(
  {},
  {
    [INVALIDATE]: () => ({}),
    ...[
      t.UPDATE_SUCCESS,
      UPDATE_LOCATION_SUCCESS,
      DELETE_LOCATION_SUCCESS,
      ADD_LOCATION_SUCCESS,
      t.GET_SUCCESS
    ].reduce((actions, action) => {
      actions[action] = setUser;
      return actions;
    }, {})
  }
);

export const requests = createRequestsByIdReducer([
  [t.GET_REQUEST, t.GET_SUCCESS, t.GET_FAILURE],
  [t.UPDATE_REQUEST, t.UPDATE_SUCCESS, t.UPDATE_FAILURE],
  [UPDATE_LOCATION_REQUEST, UPDATE_LOCATION_SUCCESS, UPDATE_LOCATION_FAILURE],
  [DELETE_LOCATION_REQUEST, DELETE_LOCATION_SUCCESS, DELETE_LOCATION_FAILURE],
  [ADD_LOCATION_REQUEST, ADD_LOCATION_SUCCESS, ADD_LOCATION_FAILURE],
  [GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE]
]);

export const selectedLocation = createReducer(null, {
  [SELECT_LOCATION]: (state, { payload }) => (payload ? payload : state),
  [DELETE_LOCATION_REQUEST]: () => null
});

export default combineReducers({
  activeId,
  items,
  userRequests,
  requests,
  selectedLocation,
  users: usersReducer
});
