import _ from 'lodash';
import moment from 'moment';
import { combineReducers } from 'redux';
import createReducer from 'redux/createReducer';
import { createRequestsByIdReducer } from 'redux/requestsByIdReducer';
import { getDateRange, THIS_MONTH, defaultScope } from 'lib/timeScopes';
import { normalizeArray } from 'lib/utils';
import * as t from './actionTypes';
import * as sT from 'ducks/selection/actionTypes';
import daysOfTheWeek from 'lib/dates/daysOfTheWeek';
const active = createReducer(null, {
  [t.SET_ACTIVE]: (state, action) => action.payload,
  [t.GET_PAGE_SUCCESS]: (state, { payload }) =>
    !state && payload.trips.length ? payload.trips[0].id : state
});

const defaultFilters = {
  timeScope: defaultScope,
  category: [],
  vehicle: null,
  includeReported: true,
  timeScopeMode: true,
  weekDays: daysOfTheWeek.map(({ id }) => id),
  from: getDateRange(THIS_MONTH).from,
  to: getDateRange(THIS_MONTH).to,
  distanceFilters: null,
  location: null
};

const filters = createReducer(defaultFilters, {
  [t.SET_FILTERS]: (state, action) => ({ ...state, ...action.payload })
});

const getIdsForTrips = trips =>
  // because we need to sort all tripsStore by start time, _then_ map by id
  // see https://trello.com/c/
  trips
    .sort((a, b) => moment.utc(b.startTime).diff(moment.utc(a.startTime)))
    .map(t => t.id);

const pagination = createReducer(
  { current: 0, pageSize: 15, totalCount: 0, totalExpense: 0 },
  {
    [t.GET_PAGE_REQUEST]: (state, { payload }) => ({
      ...state,
      current: payload.page
    }),
    [t.GET_PAGE_SUCCESS]: (state, { payload }) =>
      payload.page === 1
        ? {
            ...state,
            totalCount: payload.totalCount,
            totalExpense: payload.totalExpense
          }
        : state,
    [t.SET_PAGE]: (state, { payload }) => ({ ...state, current: payload })
  }
);

const pages = createReducer(
  {},
  {
    [t.GET_PAGE_REQUEST]: (state, { payload }) => ({
      ...state,
      [payload.page]: {
        ids: [],
        loading: true,
        error: null,
        selectAll: false
      }
    }),
    [t.GET_PAGE_SUCCESS]: (state, { payload }) => ({
      ...state,
      [payload.page]: {
        ids: getIdsForTrips(payload.trips),
        loading: false,
        error: null
      }
    }),
    [t.GET_PAGE_FAILURE]: (state, { payload, error }) => ({
      ...state,
      [payload.page]: {
        loading: false,
        error
      }
    }),
    [sT.SELECT_ALL]: (state, { payload }) => ({
      ...state,
      [payload.page]: {
        ...state[payload.page],
        selectAll: true
      }
    }),
    [sT.DESELECT_ALL]: (state, { payload }) => ({
      ...state,
      [payload.page]: {
        ...state[payload.page],
        selectAll: false
      }
    }),
    [t.INVALIDATE]: () => ({}),
    // remove deleted trip ids
    [t.DELETE_SUCCESS]: (state, { payload }) =>
      _.mapValues(state, page => ({
        ...page,
        ids: _.difference(page.ids, payload.ids)
      }))
  }
);

const requests = createRequestsByIdReducer([
  [t.UPDATE_REQUEST, t.UPDATE_SUCCESS, t.UPDATE_FAILURE],
  [
    t.UPDATE_MULTIPLE_REQUEST,
    t.UPDATE_MULTIPLE_SUCCESS,
    t.UPDATE_MULTIPLE_FAILURE
  ],
  [t.DELETE_REQUEST, t.DELETE_SUCCESS, t.DELETE_FAILURE],
  [t.MERGE_REQUEST, t.MERGE_SUCCESS, t.MERGE_FAILURE],
  [
    t.RECALCULATE_TRIP_REQUEST,
    t.RECALCULATE_TRIP_SUCCESS,
    t.RECALCULATE_TRIP_FAILURE
  ]
]);

const trips = createReducer(
  {},
  {
    [t.GET_PAGE_SUCCESS]: (state, { payload }) => ({
      ...state,
      ...normalizeArray(payload.trips)
    }),
    [t.UPDATE_SUCCESS]: (state, { payload }) => ({
      ...state,
      [payload.id]: payload
    }),
    [t.DELETE_SUCCESS]: (state, { payload }) => _.omit(state, payload.ids),
    [t.INVALIDATE]: () => ({}) // no reason to keep invalidated tripsStore around
  }
);

const recalculatedTrips = createReducer(
  {},
  {
    [t.RECALCULATE_TRIP_SUCCESS]: (
      state,
      { payload: { id, ...tripData } }
    ) => ({ ...state, [id]: tripData })
  }
);

const statistics = createReducer(null, {
  [t.STATISTICS_SUCCESS]: (state, action) => action.payload,
  [t.INVALIDATE]: () => null
});

const distanceFilterModal = createReducer(false, {
  [t.OPEN_DISTANCE_FILTER_MODAL]: () => true,
  [t.CLOSE_DISTANCE_FILTER_MODAL]: () => false
});

export default combineReducers({
  pages,
  recalculatedTrips,
  pagination,
  trips,
  filters,
  requests,
  statistics,
  active,
  distanceFilterModal
});
