import { updateCompanyInvite } from './updateCompanyInvite';
import { UPDATE_COMPANY_INVITE_SUCCESS } from 'ducks/company/companyActionTypes';
import { UPDATE_COMPANY_INVITE_FAILURE } from '../../companyActionTypes';

export const cancelInvitation = id => dispatch =>
  dispatch(updateCompanyInvite(id, { id, cancelled: true }))
    .then(() =>
      dispatch({
        type: UPDATE_COMPANY_INVITE_SUCCESS,
        payload: id,
        meta: { alert: { type: 'success', message: 'Invitation deactivated' } }
      })
    )
    .catch(() =>
      dispatch({
        type: UPDATE_COMPANY_INVITE_FAILURE,
        payload: id,
        meta: {
          alert: { type: 'success', message: 'Failed to cancel invitation' }
        }
      })
    );

export default cancelInvitation;
