import React, { Component } from 'react';
import L from 'leaflet';
import { Map, Polyline, Marker } from 'react-leaflet';
import TileLayer from 'components/TileLayer';
import ErrorBoundary from 'components/ErrorBoundary';
import polyUtil from 'polyline-encoded';
import startIconPath from '../images/location-pin-A.png';
import endIconPath from '../images/location-pin-B.png';
import '../TripMap.css';

const cleanLatLngs = latLngs =>
  latLngs.filter(([lat, lng]) => !isNaN(lat) && !isNaN(lng));

const INITIAL_MAP_FOCUS = [47.6062, -122.3321];

export default class TripMap extends Component {
  constructor(props) {
    super(props);

    this.startIcon = L.icon({
      iconUrl: startIconPath,
      iconSize: [30, 39],
      iconAnchor: [15, 38]
    });

    this.endIcon = L.icon({
      iconUrl: endIconPath,
      iconSize: [30, 39],
      iconAnchor: [15, 38]
    });
  }

  render() {
    const { polyline, className, focus } = this.props;
    const latLngs = polyline && cleanLatLngs(polyUtil.decode(polyline));

    return (
      <ErrorBoundary>
        <Map
          className={className}
          bounds={latLngs}
          boundsOptions={{ padding: [40, 40], animate: false }}
          center={focus || INITIAL_MAP_FOCUS}
          zoom={13}
        >
          <TileLayer />
          {latLngs && [
            <Polyline key="polyline" positions={latLngs} />,
            <Marker key="start" icon={this.startIcon} position={latLngs[0]} />,
            <Marker
              key="end"
              icon={this.endIcon}
              position={latLngs[latLngs.length - 1]}
            />
          ]}
        </Map>
      </ErrorBoundary>
    );
  }
}
