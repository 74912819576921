import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import './DropDownSelector.css';

export default class DropDownSelector extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.component = null;
    this.onClick = this.onClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setreference = this.setreference.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.showDropDown = this.showDropDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  setreference(reference) {
    this.component = reference;
  }

  onClick() {
    const { disabled } = this.props;
    const { isOpen } = this.state;

    !disabled && this.setState({ isOpen: !isOpen });
  }

  handleClick({ target }) {
    if (this.component && !this.component.contains(target)) {
      this.setState({ isOpen: false });
    }
  }

  onSelect() {
    this.setState({ isOpen: false });
  }

  showDropDown() {
    const { isOpen } = this.state;
    const { children } = this.props;

    return isOpen && children && children.length;
  }

  render() {
    const { className, title, children, icon, disabled } = this.props;

    return (
      <div
        ref={this.setreference}
        onClick={this.onClick}
        className={`drop-down-component${className ? ` ${className}` : ''}${
          disabled ? ' disabled' : ''
        }`}
      >
        <div className="drop-down-title drop-down-element">
          {icon && <FontAwesome className="drop-down-icon" name={icon} />}
          <h1>{title}</h1>
        </div>
        <div
          className={this.showDropDown() ? 'drop-down-view' : 'hide-element'}
        >
          {children &&
            children.map((element, index) => (
              <div
                key={`drop-down-element-${index}`}
                className="drop-down-element"
                onClick={this.onClick}
              >
                {element}
              </div>
            ))}
        </div>
      </div>
    );
  }
}
