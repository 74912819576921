import React, { Component } from 'react';
import classNames from 'class-names';
import Checkbox from 'components/Checkbox';
import TimeScopeFilter from 'components/TimeScopeFilter';
import PurposesFilter from 'components/PurposesFilter';
import VehiclesFilter from 'components/VehiclesFilter';
import WeekDayFilter from 'components/WeekDayFilter';
import LocationsFilter from 'components/LocationFilter';
import './TripFilters.css';
export default class TripFilters extends Component {
  render() {
    const { filters, setFilters, allowDates, hideLabel, slim } = this.props;

    return (
      <div
        className={classNames('trip-filters', { 'trip-filters_slim': slim })}
      >
        {!hideLabel && (
          <div className="trip-filters__item trip-filters-label">
            Filter Trips By
          </div>
        )}
        <TimeScopeFilter
          value={filters.timeScope}
          onChange={timeScope => setFilters({ timeScope })}
          timeScopeMode={filters.timeScopeMode}
          setTimeScopeMode={timeScopeMode => setFilters({ timeScopeMode })}
          allowDates={allowDates}
          from={filters.from}
          setFrom={from => setFilters({ from })}
          to={filters.to}
          setTo={to => setFilters({ to })}
        />
        <PurposesFilter
          value={filters.category}
          onChange={category => setFilters({ category })}
        />
        <VehiclesFilter
          value={filters.vehicle}
          onChange={vehicle => setFilters({ vehicle })}
        />
        <LocationsFilter
          value={filters.location}
          onChange={location => setFilters({ location })}
        />
        <WeekDayFilter
          value={filters.weekDays}
          onChange={weekDays => setFilters({ weekDays })}
        />
        <div className="trip-filters__item">
          <Checkbox
            label="Include Reported"
            checked={filters.includeReported}
            onChange={({ target }) =>
              setFilters({ includeReported: target.checked })
            }
            dark
          />
        </div>
      </div>
    );
  }
}
