import * as t from './actionTypes';
import * as selectors from './selectors';
import {
  putReport,
  getReports,
  postReports,
  getReport as getReportById,
  deleteReport as deleteReportById
} from 'api/endpoints/user/reports';
import trips from 'ducks/trips';
import { PAGE_SIZE } from './constants';
import filterParameters from 'ducks/trips/lib/filtersToParams';
import { getActiveId, getCurrentUser } from '../users/selectors';

export const invalidate = () => ({ type: t.INVALIDATE });

const getPageRequest = page => ({
  type: t.GET_PAGE_REQUEST,
  payload: { page }
});

const getPageFailure = (page, error) => ({
  type: t.GET_PAGE_FAILURE,
  payload: { page },
  error
});

const getPageSuccess = (page, reports, totalCount) => ({
  type: t.GET_PAGE_SUCCESS,
  payload: { page, reports, totalCount }
});

const requestPage = (page, dispatch, state) => {
  const userId = getActiveId(state);
  dispatch(getPageRequest(page));
  return getReports(
    page,
    PAGE_SIZE,
    selectors.getSelectedFilters(state),
    userId
  ).then(
    response =>
      dispatch(
        getPageSuccess(page, response.data.reports, response.data.totalCount)
      ),
    error => dispatch(getPageFailure(page, error))
  );
};

export const filterReports = () => (dispatch, getState) => {
  const state = getState();
  return requestPage(selectors.getCurrentPageNum(state), dispatch, state);
};

export const getPage = page => (dispatch, getState) => {
  const state = getState();
  return requestPage(page, dispatch, state);
};

export const getNextPage = () => (dispatch, getState) => {
  const state = getState();
  const current = selectors.getCurrentPageNum(state);

  dispatch(getPage(current + 1));
};

export const getReport = id => (dispatch, getState) => {
  const userId = getActiveId(getState());
  dispatch({ type: t.GET_SINGLE_REQUEST, payload: { id } });

  return getReportById(id, userId).then(
    response =>
      dispatch({
        type: t.GET_SINGLE_SUCCESS,
        payload: { report: response.data.report }
      }),
    error =>
      dispatch({
        type: t.GET_SINGLE_FAILURE,
        payload: { id },
        error
      })
  );
};

export const filtersToReportParams = filters => {
  const parameters = filterParameters(filters);
  if (filters.timeScopeMode) {
    parameters.from = parameters.filter_start;
    parameters.to = parameters.filter_end;
  }
  return parameters;
};

const createRequest = () => ({ type: t.CREATE_REQUEST });

const createFailure = error => ({
  type: t.CREATE_FAILURE,
  error
});

export const createReport = report => (dispatch, getState) => {
  dispatch(createRequest());

  const filters = trips.selectors.getFilters(getState());
  report = { ...report, ...filtersToReportParams(filters) };

  return postReports(report).then(
    () => dispatch(invalidate()),
    error => dispatch(createFailure(error))
  );
};

const deleteRequest = () => ({ type: t.DELETE_REQUEST });

const deleteFailure = error => ({
  type: t.DELETE_FAILURE,
  error
});

const deleteSuccess = id => ({
  type: t.DELETE_SUCCESS,
  payload: { id },
  meta: { alert: { type: 'success', message: 'Report deleted' } }
});

const starFailure = error => ({
  type: t.STAR_FAILURE,
  error
});

export const deleteReport = id => dispatch => {
  dispatch(deleteRequest(id));
  return deleteReportById(id).then(
    () => dispatch(deleteSuccess(id)),
    error => dispatch(deleteFailure(error))
  );
};
export const starRequest = report => ({ type: t.TOGGLE_STARRED, ...report });
export const starReport = id => (dispatch, getState) => {
  const starred = !selectors.getStarStatus(getState(), id);
  dispatch(starRequest({ id, starred }));
  return putReport({ id, starred }).catch(error =>
    dispatch(starFailure(error))
  );
};
export const updateFilters = filters => ({
  type: t.UPDATE_FILTERS,
  filters
});
export const removeFilters = filters => ({
  type: t.REMOVE_FILTERS,
  filters
});
