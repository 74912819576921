import React, { Component } from 'react';
import { Field } from 'redux-form';
import PreLoginForm from 'components/PreLogin/PreLoginForm';
import PreLoginTextBox from 'components/PreLogin/PreLoginTextbox';
import PreLoginButton from 'components/PreLogin/PreLoginButton';
import transformError from 'lib/transformError';
import {Helmet} from "react-helmet";
const renderField = ({ input, meta: _, ...props }) => (
  <PreLoginTextBox {...input} {...props} />
);
export default class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onSubmit({ email, password }) {
    const { signIn } = this.props;
    return signIn(email.trim(), password);
  }
  render() {
    const { handleSubmit, submitting, error } = this.props;
    return (
      <PreLoginForm
        onSubmit={handleSubmit(this.onSubmit)}
        error={transformError(error, 'userSignIn')}
      >
        <Helmet>
          <title>Sign in to MileCatcher</title>
          <meta name="description" content="Sign in to the MileCatcher portal to add or classify trips and generate reports." />

        </Helmet>
        <Field component={renderField} name="email" placeholder="Email" />
        <Field
          component={renderField}
          type="password"
          name="password"
          placeholder="Password"
        />
        <PreLoginButton disabled={submitting}>
          {submitting ? <span>Logging in&hellip;</span> : 'Log in'}
        </PreLoginButton>
        or
        <div id="appleid-signin" data-color="black" data-border="true" data-type="sign in"></div>
      </PreLoginForm>
    );
  }
}
