import { getKpiReports as loadKpiReports } from 'ducks/kpiReports/kpiReportActions/getKpiReports';
import { getKpiReports } from 'ducks/kpiReports/kpiReportSelectors/getKpiReports';
import { deleteKpiReport } from 'ducks/kpiReports/kpiReportActions/deleteKpiReport';
import { getUsers as loadUsers } from 'ducks/users/usersActions/getUsers';

export const mapStateToReportsPage = state => ({
  reportRules: getKpiReports(state)
});

export const mapDispatchToReportsPage = {
  deleteReportRule: deleteKpiReport,
  loadKpiReports,
  loadUsers
};
