import React from 'react';
import { Link } from 'react-router-dom';

import milecatcherLogo from './milecatcher.png';

const Logo = () => (
  <Link className="logo" to="/" tabIndex={-1}>
    <img src={milecatcherLogo} alt="MileCatcher" />
  </Link>
);

export default Logo;
