import simpleActionCreator from 'ducks/lib/simpleActionCreator';
import { removeUserFromCompanyUsersPage } from './removeUserFromCompanyUsersPage';
import { removeCompanyUser } from 'api/endpoints/company/users';
import {
  REMOVE_USER_FROM_COMPANY_REQUEST,
  REMOVE_USER_FROM_COMPANY_SUCCESS,
  REMOVE_USER_FROM_COMPANY_FAILURE
} from 'ducks/company/companyActionTypes';

export const removeUserFromCompany = id => dispatch =>
  dispatch(
    simpleActionCreator(removeCompanyUser, [
      REMOVE_USER_FROM_COMPANY_REQUEST,
      'REMOVE_USER_FROM_COMPANY_WAS_A_SUCCESS',
      REMOVE_USER_FROM_COMPANY_FAILURE
    ])(id)
  ).then(() => {
    dispatch({
      type: REMOVE_USER_FROM_COMPANY_SUCCESS,
      payload: id,
      meta: {
        alert: {
          type: 'success',
          message: 'User was deactivated'
        }
      }
    });
    dispatch(removeUserFromCompanyUsersPage(id));
  });

export default removeUserFromCompany;
