import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import Button from 'components/Button';
import LoadingPage from 'components/LoadingPage';
import { genericApi } from '../../api/client';

import './SubscriptionInfo.css';

const propTypes = {
  subscription: PropTypes.shape({
    gateway: PropTypes.oneOf(['play_store', 'app_store', 'chargebee'])
      .isRequired,
    chargebeeId: PropTypes.string,
    status: PropTypes.oneOf(['in_trial', 'active', 'non_renewing', 'cancelled'])
      .isRequired,
    expiresOn: PropTypes.string.isRequired,
    stripeSubscriptionId: PropTypes.string
  })
};

function retrieve_updated_card_hosted_page() {
  var url = genericApi.post(`/chargebee_sessions_update`);
  url.then(function(result) {
    window.location.href = result.data;
  });
};

function redirect_to_cancel_renewal_page() {
  window.location.href='https://portal.milecatcher.com/cancel'
}

function redirect_to_app_store_cancel_page() {
  window.location.href='https://www.milecatcher.com/support/how-to-cancel-your-milecatcher-itunes-subscription'
}

function redirect_to_stripe_page(plan, mode) {
  if (mode === "add_card") {
    var url = genericApi.post(`/stripe_sessions/add_card`);
    url.then(function(result) {
      window.location.href = result.data.url;
    });
    // Generate hosted page for subscribing now
  } else if (mode === "manage_account") {
    var url = genericApi.post(`/stripe_sessions`);
    url.then(function(result) {
      window.location.href = result.data.url;
    });
  } else {
    var sixMonth = "https://buy.stripe.com/4gw17i5BQdgM8j6288"
    var annual = "https://buy.stripe.com/bIYaHSd4i2C8fLyfYZ"
    if (plan == "annual") {
      window.location.href = annual;
    } else {
      window.location.href = sixMonth;
    }
  }




}

function redirect_to_google_play_cancel_page() {
  window.location.href='https://www.milecatcher.com/support/how-to-cancel-your-google-play-milecatcher-subscription'
}

const SubscriptionInfo = ({ subscription }) => {
  if (subscription) {
    const { gateway, stripeCustomerId, status, expiresOn } = subscription;

    return (
      <div className="subscription-info">
        <div className="subscription-info__title panel__title">
          Subscription
        </div>

        <div className="subscription-info__body">
          {gateway == 'company' && (
              <p className="subscription-info__text">
                Your MileCatcher subscription is managed by your company.<br/>
                <a href={'https://api.milecatcher.com/companies/billing'}>Manage billing</a>
              </p>
          )}
          {gateway !== 'company' && gateway !== "stripe" && status === 'in_trial' && (
            <p className="subscription-info__text">
              Your MileCatcher trial period will end on{' '}
              <b>{moment(expiresOn).format('LL')}</b>.

              <br/><br/>
              <b>Subscribe now</b>



              <Button
                  className="subscription-info__btn"
                  onClick={() => redirect_to_stripe_page("annual")}
              > $3.99 / month
              </Button>
              (1 year subscription, pay US$47.88 now)


              <Button
                  className="subscription-info__btn"
                  onClick={() => redirect_to_stripe_page("semiannual")}
              > $5.00 / month
              </Button>
              (6 month subscription, pay US$30.00 now)

              <hr/>
            </p>
          )}

          {gateway === "stripe" && stripeCustomerId && status === 'in_trial' && (
              <p className="subscription-info__text">
                Your MileCatcher trial period will end on{' '}
                <b>{moment(expiresOn).format('LL')}</b>.

                <br/><br/>
                <b>Add a card</b>
                <p>To use your 30% off gift make sure to add a card before your trial expires!</p>

                <Button
                    className="subscription-info__btn"
                    onClick={() => redirect_to_stripe_page("annual", 'add_card')}
                > Add/Update Card
                </Button>




                <hr/>
                <p className="tos-text">If you add a card your subcription will renew for $33.52 for your first year and $47.88 afterwards. 30% discount is only available for annual subscriptions.</p>

              </p>
          )}
          {status === 'cancelled' && (
            <p className="subscription-info__text">
              You are <b>unsubscribed</b>.
            </p>
          )}
          {gateway !== 'company' && status === 'non_renewing' &&
          // gateway === 'play_store' || gateway === 'app_store' || gateway === 'chargebee' &&
          (
              <p className="subscription-info__text">
                Your MileCatcher subscription will expire on{' '}
                <b>{moment(expiresOn).format('LL')}</b>.
              </p>
          )}
          {status === 'active' &&
          // gateway === 'play_store' || gateway === 'app_store' || gateway === 'chargebee' &&
          (
              <p className="subscription-info__text">
                Your MileCatcher subscription will automatically renew on{' '}
                <b>{moment(expiresOn).format('LL')}</b>.
              </p>
          )}
          {gateway !== 'stripe' && status === 'non_renewing' &&
          // gateway === 'play_store' || gateway === 'app_store' || gateway === 'chargebee' &&
          (
              <p className="subscription-info__text">
                Your MileCatcher subscription will expire on{' '}
                <b>{moment(expiresOn).format('LL')}</b>.
              </p>
          )}
          {/* `gateway` can be 'chargebee', 'play_store', or 'app_store' */}
          { gateway == 'app_store' ? (
            <Button
              className="subscription-info__btn"
              onClick={redirect_to_app_store_cancel_page}
              > Cancel via iTunes
            </Button>
          ): gateway == 'play_store' ? (
            <Button
              className="subscription-info__btn"
              onClick={redirect_to_google_play_cancel_page}
            > Cancel via Google Play
            </Button>
          ): gateway == 'stripe' ? (
                  <Button
                      className="subscription-info__btn"
                      onClick={() => redirect_to_stripe_page("annual", "manage_account")}
                  > Manage Subscription
                  </Button>
          ): gateway == 'chargebee' ? (
            <div>
              <Button
                className="subscription-info__btn"
                onClick={retrieve_updated_card_hosted_page}
                external
                link
                >Update Credit Card
              </Button>
              <Button
              className="subscription-info__btn"
              onClick={redirect_to_cancel_renewal_page}
              > Cancel Auto Renewal
              </Button>

              <br/><br/><br/>
              Need to make changes to your plan? <a href="mailto:support@milecatcher.com">Contact support</a>
            </div>

          ) : null
          }
        </div>
      </div>
    );
  }
  return <LoadingPage />;
};

SubscriptionInfo.propsTypes = propTypes;

export default SubscriptionInfo;
