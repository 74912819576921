import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { deleteCompanyTeam } from 'api/endpoints/company/teams';
import addSuccessAndFailureMessages from 'ducks/lib/addSuccessAndFailureMessages';
import {
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE
} from 'ducks/teams/teamActionTypes';

export const removeUserFromTeam = id => dispatch =>
  dispatch(
    addSuccessAndFailureMessages(
      createSimpleAction(
        deleteCompanyTeam,
        [DELETE_TEAM_REQUEST, 'DELETED_TEAM', DELETE_TEAM_FAILURE],
        'team'
      ),
      'Team deleted',
      'Unable to delete team'
    )(id)
  ).then(() =>
    dispatch({
      type: DELETE_TEAM_SUCCESS,
      payload: id
    })
  );

export default removeUserFromTeam;
