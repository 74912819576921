import * as t from './actionTypes';
import api from 'api';

const getRequest = () => ({ type: t.GET_REQUEST });
const getSuccess = vehicles => ({ type: t.GET_SUCCESS, payload: vehicles });

const getFailure = error => ({
  type: t.GET_FAILURE,
  error
});

export const getVehicles = () => (dispatch, getState) => {
  dispatch(getRequest());

  return api
    .getVehicles()
    .then(
      response => dispatch(getSuccess(response.data.vehicles)),
      error => dispatch(getFailure(error))
    );
};

const updateRequest = id => ({ type: t.UPDATE_REQUEST, payload: { id } });
const updateSuccess = vehicle => ({ type: t.UPDATE_SUCCESS, payload: vehicle });
const updateFailure = (id, error) => ({
  type: t.UPDATE_FAILURE,
  payload: id,
  error
});

export const update = ({ id, ...vehicle }) => dispatch => {
  dispatch(updateRequest(id));

  return api
    .putVehicle(id, vehicle)
    .then(
      response => dispatch(updateSuccess(response.data.vehicle)),
      error => dispatch(updateFailure(id, error))
    );
};

const destroyRequest = id => ({ type: t.DESTROY_REQUEST, payload: { id } });
const destroySuccess = id => ({ type: t.DESTROY_SUCCESS, payload: { id } });
const destroyFailure = (id, error) => ({
  type: t.DESTROY_FAILURE,
  payload: { id },
  error
});

export const destroy = id => (dispatch, getState) => {
  dispatch(destroyRequest(id));

  return api
    .deleteVehicle(id)
    .then(
      response => dispatch(destroySuccess(id)),
      error => dispatch(destroyFailure(error))
    );
};

export const setPrimary = id => (dispatch, getState) => {
  return dispatch(update({ id, isPrimary: true })).then(() =>
    dispatch(getVehicles())
  );
};

const createRequest = () => ({ type: t.CREATE_REQUEST });

const createSuccess = vehicle => ({ type: t.CREATE_SUCCESS, payload: vehicle });

const createFailure = error => ({
  type: t.CREATE_FAILURE,
  error
});

export const create = id => (dispatch, getState) => {
  dispatch(createRequest(id));

  return api
    .postVehicles({ isPrimary: false })
    .then(
      response => dispatch(createSuccess(response.data.vehicle)),
      error => dispatch(createFailure(error))
    );
};
