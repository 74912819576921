import React from 'react';
import { Field } from 'redux-form';
import TimePicker from './lib/TimePickerField';
import DatePicker from './lib/DatePickerField';
import './DateTimeSelector.css';
export default ({ className, title, defaultTime, parent }) => {
  return (
    <div className={`date-time-selector${className ? ' ' + className : ''}`}>
      <h2 className="date-time-selector-element">{`${title}:  `}</h2>
      <div className="date-time-selector-element time-pick">
        <Field
          component={TimePicker}
          name={`${title}Time`}
          initialValue={defaultTime}
          parent={parent}
        />
      </div>
      <div className={'date-time-selector-element date-pick'}>
        <Field
          name={`${title}Date`}
          component={DatePicker}
          initialValue={`${defaultTime}`}
        />
      </div>
    </div>
  );
};
