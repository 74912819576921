import createReducer from 'redux/createReducer';
import {
  GET_COMPANY_TRIPS_SUCCESS,
  DELETE_COMPANY_TRIPS_SUCCESS,
  PUT_COMPANY_TRIP_SUCCESS,
  POST_COMPANY_TRIPS_SUCCESS,
  PUT_COMPANY_TRIPS_SUCCESS
} from '../companyTripActionTypes';

export const updateCompanyTrips = (trips, { payload }) =>
  trips.map(trip => (payload && payload.id === trip.id ? payload : trip));

export const deleteCompanyTrip = (state, { payload: tripId }) =>
  tripId ? state.filter(({ id }) => Number(id) !== Number(tripId)) : state;

export const setCompanyTrips = (state, { payload }) =>
  payload ? payload : state;

export const companyTrips = createReducer([], {
  ...[
    PUT_COMPANY_TRIP_SUCCESS,
    POST_COMPANY_TRIPS_SUCCESS,
    PUT_COMPANY_TRIPS_SUCCESS
  ].reduce((state, action) => {
    state[action] = updateCompanyTrips;
    return state;
  }, {}),
  ...[DELETE_COMPANY_TRIPS_SUCCESS].reduce((state, action) => {
    state[action] = deleteCompanyTrip;
    return state;
  }, {}),
  ...[GET_COMPANY_TRIPS_SUCCESS].reduce((state, action) => {
    state[action] = setCompanyTrips;
    return state;
  }, {})
});

export default companyTrips;
