import { SELECT_WORK_HOURS } from 'ducks/company/companyActionTypes';
import { GLOBAL_WORK_HOURS_ID } from 'ducks/company/constants';
import getGlobalWorkHours from 'ducks/workHours/workHourActions/getGlobalWorkHours';
import getTeamWorkHours from 'ducks/workHours/workHourActions/getTeamWorkHours';

export const selectCompanyWorkHours = payload => ({
  type: SELECT_WORK_HOURS,
  payload
});

export const selectWorkHours = id => dispatch => {
  dispatch(selectCompanyWorkHours(id));
  return dispatch(
    id === GLOBAL_WORK_HOURS_ID ? getGlobalWorkHours() : getTeamWorkHours(id)
  );
};
