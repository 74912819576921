import React from 'react';
import { connect } from 'react-redux';
import vehicles from 'ducks/vehicles';
import FilterDropdown from 'components/FilterDropdown';
import { formatVehicle } from 'lib/formatters';

const VehiclesFilter = ({ value, onChange, vehicles }) => (
  <FilterDropdown
    className="trip-filters__item dropdown_filter"
    icon="car"
    value={
      value
        ? formatVehicle(vehicles.find(vehicle => vehicle.id === value))
        : 'Vehicles'
    }
    options={[
      [null, 'All'],
      ...vehicles.map(vehicle => [vehicle.id, formatVehicle(vehicle)])
    ]}
    onSelect={onChange}
    selected={value}
  />
);

export default connect(state => ({
  vehicles: vehicles.selectors.getActive(state)
}))(VehiclesFilter);
