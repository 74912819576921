export const TAKEN_ERROR = 'has already been taken';
export const getSubmissionErrorMessage = error => {
  const errors = error && error.errors;
  return errors && errors._error;
};
export default (error, action) => {
  switch (getSubmissionErrorMessage(error)) {
    case TAKEN_ERROR:
      return 'An identical location already exists';
    default:
      return `Unable to ${action} location`;
  }
};
