import { getUsersLocations } from '../../users/selectors';
import calculateDistance from 'lib/getHaversineDistance';
const DISTANCE_CONSIDERED_NEARBY_IN_KM = 3;
export default (state, coordinates) => {
  const locations = getUsersLocations(state);
  return locations.filter(
    ({ lat, lon }) =>
      DISTANCE_CONSIDERED_NEARBY_IN_KM >=
      calculateDistance(coordinates, [lat, lon])
  );
};
