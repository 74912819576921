import React from 'react';
import { reduxForm } from 'redux-form';
import PreloginForm from 'components/PreLogin/PreLoginForm';
import TextboxField from 'components/TextBoxField';
import PreloginButton from 'components/PreLogin/PreLoginButton';
import { PreloginBoxTitle } from 'components/PreLogin/PreLoginBox';
import * as validators from 'lib/validators';
import api from 'api';
import {Helmet} from "react-helmet";
const ResetPasswordForm = ({
  handleSubmit,
  error,
  pristine,
  submitSucceeded,
  submitting,
  valid
}) => (
  <div>
      <Helmet>
          <title>MileCatcher Portal - Forgot your Password?</title>
          <meta name="description" content="Forgot your password? Type your email and get a password recovery link." />
      </Helmet>
    <PreloginBoxTitle>
      {submitSucceeded ? 'Email sent' : 'Forgot your password?'}
    </PreloginBoxTitle>
    <p>
      {submitSucceeded
        ? "If an account with that email exists, we'll send instructions on " +
          'how to reset your password in a few minutes.'
        : "Don't sweat it! Fill out your email below and we'll send a link to" +
          ' reset your password.'}
    </p>
    <PreloginForm
      onSubmit={handleSubmit(({ email }) => api.postRecoveryEmail(email))}
      error={error}
    >
      {!submitSucceeded && (
        <TextboxField
          name="email"
          placeholder="Email"
          validate={validators.email}
          prelogin
        />
      )}
      <PreloginButton disabled={pristine || submitting || !valid}>
        {submitting ? (
          <span>Sending&hellip;</span>
        ) : submitSucceeded ? (
          'Resend email'
        ) : (
          'Send email'
        )}
      </PreloginButton>
    </PreloginForm>
  </div>
);
export default reduxForm({ form: 'forgot-password' })(ResetPasswordForm);
