import { SubmissionError } from 'redux-form';
import api from 'api';
import { getTrips } from 'api/endpoints/user/trips';
import { NUMBER_OF_ROUTE_POINTS } from './constants';
import * as t from './actionTypes';
import * as selectors from './selectors';
import { getActive as getActiveUser } from 'ducks/users/selectors';
import filtersToParams from './lib/filtersToParams';
import purposes from 'ducks/purposes';
import selection from 'ducks/selection';
import notifications from 'ducks/notifications';
import polyUtil from 'polyline-encoded';
import moment from 'moment';
import { route } from '../../lib/services/tripRouting';
import { setTimeOnDate } from 'lib/utils';
import getTripPoints from './lib/getTripPoints';
import { OPEN_DISTANCE_FILTER_MODAL } from './actionTypes';
import { CLOSE_DISTANCE_FILTER_MODAL } from './actionTypes';
import convertDistanceFilters from './lib/convertDistanceFilters';
import { getMetricMeasure } from '../users/selectors';
import { refreshCurrentPage } from './actions/refreshCurrentPage';

export const unClassify = id => dispatch =>
  dispatch(updateTrip({ id, purposeId: null }));

// get page
// ----------------------------------------------------------------------------

export const getPageRequest = page => ({
  type: t.GET_PAGE_REQUEST,
  payload: { page }
});

export const getPageSuccess = (page, data) => ({
  type: t.GET_PAGE_SUCCESS,
  payload: { ...data, page }
});

const getPageError = (page, error) => ({
  type: t.GET_PAGE_FAILURE,
  payload: { page },
  error
});

const setPage = page => ({
  type: t.SET_PAGE,
  payload: page
});

export const getPage = page => (dispatch, getState) => {
  const state = getState();
  if (selectors.getPage(state, page) === undefined) {
    const pageSize = selectors.getPageSize(state);
    const filters = selectors.getFilters(state);
    const activeUser = getActiveUser(state);
    dispatch(getPageRequest(page));
    return getTrips(
      page,
      pageSize,
      filtersToParams({
        ...filters,
        distanceFilters: convertDistanceFilters(
          filters && filters.distanceFilters,
          getMetricMeasure(state)
        )
      }),
      activeUser && activeUser.id
    ).then(
      response => dispatch(getPageSuccess(page, response.data)),
      error => dispatch(getPageError(page, error))
    );
  } else {
    dispatch(setPage(page));
  }
};
export const getNextPage = () => (dispatch, getState) => {
  const state = getState();
  const current = selectors.getCurrentPageNum(state);
  dispatch(getPage(current + 1));
};
export const invalidate = () => ({ type: t.INVALIDATE });

export const setFilters = filters => dispatch => {
  dispatch({
    type: t.SET_FILTERS,
    payload: filters
  });
  return dispatch(invalidate());
};
export const setDistanceFilters = (distance_gt, distance_lt) => dispatch =>
  dispatch(setFilters({ distanceFilters: { distance_lt, distance_gt } }));

// add trip
// ----------------------------------------------------------------------------

const addRequest = () => ({ type: t.ADD_REQUEST });
const addFailure = error => ({
  type: t.ADD_FAILURE,
  error
});
const addSuccess = data => ({
  type: t.ADD_SUCCESS,
  payload: data,
  meta: { alert: { type: 'success', message: 'Trip successfully created' } }
});

export const addTrip = (trip, browserHistory) => dispatch => {
  dispatch(addRequest());
  return api.postTrips(trip).then(
    response => {
      dispatch(invalidate());
      browserHistory.goBack();
      return dispatch(addSuccess(response.data.trip));
    },
    error => {
      dispatch(addFailure(error));
      if (error instanceof SubmissionError) {
        return Promise.reject(error);
      }
    }
  );
};

// update trip
// ----------------------------------------------------------------------------

const updateRequest = id => ({ type: t.UPDATE_REQUEST, payload: { id } });

export const updateTripFailure = (error, id) => ({
  type: t.UPDATE_FAILURE,
  error,
  payload: { id }
});

const updateSuccess = data => ({ type: t.UPDATE_SUCCESS, payload: data });

export const updateTrip = ({ id, ...trip }) => dispatch => {
  dispatch(updateRequest(id));
  return api
    .putTrip(id, trip)
    .then(
      response => dispatch(updateSuccess(response.data.trip)),
      error => dispatch(updateTripFailure(error, id))
    );
};

export const updateTripTimeAndDistance = ({ id, ...trip }) => dispatch => {
  return api.putTrip(id, { modified: true, ...trip }).then(
    () => {
      dispatch(refreshCurrentPage());
      dispatch(notifications.actions.notify('success', 'Changes saved'));
    },
    error => {
      dispatch(addFailure(error));
      if (error instanceof SubmissionError) {
        return Promise.reject(error);
      }
    }
  );
};

// update multiple trips
// ----------------------------------------------------------------------------

// I was going through a phase of trying of not writing action creators for
// thunk'd locationActions. I sincerely aplogize.
export const updateSelectedTrips = params => (dispatch, getState) => {
  const state = getState();
  const ids = selection.selectors.getSelectedIds(state);

  dispatch({ type: t.UPDATE_MULTIPLE_REQUEST, payload: { ids } });
  return api.putTrips(ids, params).then(
    () => {
      dispatch(refreshCurrentPage());
      return dispatch({
        type: t.UPDATE_MULTIPLE_SUCCESS,
        payload: { ids },
        meta: {
          alert: { type: 'success', message: `${ids.length} trips updated` }
        }
      });
    },
    error =>
      dispatch({ type: t.UPDATE_MULTIPLE_FAILURE, payload: { ids }, error })
  );
};

// delete trips
// ----------------------------------------------------------------------------

const deleteRequest = ids => ({ type: t.DELETE_REQUEST, payload: { ids } });

const deleteFailure = (error, ids) => ({
  type: t.DELETE_FAILURE,
  error,
  payload: { ids }
});

const deleteSuccess = ids => ({
  type: t.DELETE_SUCCESS,
  payload: { ids },
  meta: {
    alert: {
      type: 'success',
      message: ids.length > 1 ? `${ids.length} trips deleted` : 'Trip deleted'
    }
  }
});

export const deleteTrips = ids => dispatch => {
  dispatch(deleteRequest(ids));
  return api.deleteTrips(ids).then(
    () => {
      dispatch(refreshCurrentPage());
      return dispatch(deleteSuccess(ids));
    },
    error => dispatch(deleteFailure(error, ids))
  );
};

export const deleteSelected = () => (dispatch, getState) => {
  const ids = selection.selectors.getSelectedIds(getState());
  return dispatch(deleteTrips(ids));
};

// merge trips
// ----------------------------------------------------------------------------

const mergeRequest = ids => ({ type: t.DELETE_REQUEST, payload: { ids } });

const mergeFailure = (error, ids) => ({
  type: t.DELETE_FAILURE,
  error,
  payload: { ids },
  meta: { alert: { type: 'error', message: 'unable to merge trips' } }
});

const mergeFailureForDate = (error, ids) => ({
  type: t.DELETE_FAILURE,
  error,
  payload: { ids },
  meta: {
    alert: {
      type: 'error',
      message: 'Unable to merge trips because they occur on different days.'
    }
  }
});

const mergeFailureForPurpose = (error, ids) => ({
  type: t.DELETE_FAILURE,
  error,
  payload: { ids },
  meta: {
    alert: {
      type: 'error',
      message: `Unable to merge trips because they don't have matching purposes.`
    }
  }
});

const mergeSuccess = ids => ({
  type: t.DELETE_SUCCESS,
  payload: { ids },
  meta: { alert: { type: 'success', message: `${ids.length} trips merged` } }
});

export const mergeTrips = ids => dispatch => {
  dispatch(mergeRequest(ids));
  return api.mergeTrips(ids).then(
    response => {
      // note that it would be more graceful to instead remove the old trips and
      // insert the newly merged trip in the pagination reducer
      dispatch(refreshCurrentPage());
      dispatch(mergeSuccess(ids));
    },
    error => dispatch(mergeFailure(error, ids))
  );
};
const mergeError = Error(
  'Failed to merge, trips must be on the same date and have the same purpose'
);
const toDate = dateTimeString => moment(dateTimeString).format('MM/DD/YYYY');
const mergedTripsAreOnSameDay = (ids, state) => {
  const firstTrip = selectors.getTrip(state, ids[0]);
  return ids.reduce((mergeable, tripId) => {
    const { startTime } = selectors.getTrip(state, tripId);
    return toDate(firstTrip.startTime) === toDate(startTime) && mergeable;
  }, true);
};
const mergedTripsHaveSamePurpose = (ids, state) => {
  const firstTrip = selectors.getTrip(state, ids[0]);
  return ids.reduce((mergeable, tripId) => {
    const { purposeId } = selectors.getTrip(state, tripId);
    return firstTrip.purposeId === purposeId && mergeable;
  }, true);
};
export const mergeSelected = () => (dispatch, getState) => {
  const state = getState();
  const ids = selection.selectors.getSelectedIds(state);
  if (!mergedTripsAreOnSameDay(ids, state)) {
    return dispatch(mergeFailureForDate(mergeError), ids);
  }
  if (!mergedTripsHaveSamePurpose(ids, state)) {
    return dispatch(mergeFailureForPurpose(mergeError, ids));
  }
  return dispatch(mergeTrips(ids));
};

// statistics
// ----------------------------------------------------------------------------

const statisticsRequest = () => ({ type: t.STATISTICS_REQUEST });

const statisticsFailure = error => ({
  type: t.STATISTICS_FAILURE,
  error
});

const statisticsSuccess = statistics => ({
  type: t.STATISTICS_SUCCESS,
  payload: statistics
});

export const getStatistics = () => (dispatch, getState) => {
  dispatch(statisticsRequest());
  const state = getState();

  return api
    .getStatistics(filtersToParams(selectors.getFilters(state)))
    .then(
      response => dispatch(statisticsSuccess(response.data.statistics)),
      error => dispatch(statisticsFailure(error))
    );
};

// misc
// ----------------------------------------------------------------------------

export const setActive = id => ({ type: t.SET_ACTIVE, payload: id });

export const classifyBusiness = id => (dispatch, getState) => {
  const purpose = purposes.selectors.getActiveBusiness(getState());
  return dispatch(updateTrip({ id, purposeId: purpose.id }));
};

export const classifyPersonal = id => (dispatch, getState) => {
  const purpose = purposes.selectors.getActivePersonal(getState());
  return dispatch(updateTrip({ id, purposeId: purpose.id }));
};

export const copyTrip = ({ id, fromDate, toDate }) => dispatch =>
  api.postTripCopy(id, fromDate, toDate).then(() => {
    dispatch(refreshCurrentPage());
    return dispatch(notifications.actions.notify('success', 'Trip copied'));
  });

export const addReturnTrip = ({ tripId, start, end }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const trip = selectors.getTrip(state, tripId);
  const decodedPolyline = polyUtil.decode(trip.polyline);
  const reversedPolyline = polyUtil.encode(decodedPolyline.reverse());
  const returnTrip = Object.assign({}, trip, {
    departureAddress: trip.destinationAddress,
    destinationAddress: trip.departureAddress,
    startTime: start,
    endTime: end,
    speeds: decodedPolyline.map(() => 25),
    polyline: reversedPolyline
  });
  return api.postTrips(returnTrip).then(
    () => {
      dispatch(refreshCurrentPage());
      return dispatch(
        notifications.actions.notify('success', 'Return trip created')
      );
    },
    error => {
      dispatch(addFailure(error));
      if (error instanceof SubmissionError) {
        return Promise.reject(error);
      }
    }
  );
};
export const getRecalculatedRoute = id => (dispatch, getState) => {
  const state = getState();
  const existingPolyline = selectors.getTrip(state, id).polyline;
  const decodedPolyline = polyUtil.decode(existingPolyline);
  const length = decodedPolyline.length;
  const startingPoint = decodedPolyline[0];
  const endingPoint = decodedPolyline[length - 1];
  dispatch(recalculateRouteRequest(id));
  return route(getTripPoints(decodedPolyline, NUMBER_OF_ROUTE_POINTS))
    .then(({ distance, polyline }) => ({
      id,
      distance,
      startingPoint,
      endingPoint,
      polyline: polyUtil.decode(polyline)
    }))
    .then(payload => {
      dispatch(recalculateRouteSuccess(payload));
    })
    .catch(error => dispatch(recalculateRouteFailure(error, id)));
};

export const recalculateRouteSuccess = payload => {
  return {
    type: t.RECALCULATE_TRIP_SUCCESS,
    payload
  };
};

export const recalculateRouteRequest = id => ({
  type: t.RECALCULATE_TRIP_REQUEST,
  payload: { id }
});

export const recalculateRouteFailure = (error, id) => {
  return {
    type: t.RECALCULATE_TRIP_FAILURE,
    payload: { id },
    error,
    meta: {
      alert: {
        type: 'error',
        message: `Address could not be found, unable to recalculate trip.`
      }
    }
  };
};

export const closeModal = () => {
  // TODO, dont reload page on updates with invalidate,
  // just update what needs to be updated.
};

export const updateTripRoute = ({ tripId }) => (dispatch, getState) => {
  const state = getState();
  const trip = selectors.getTrip(state, tripId);
  const { distance, polyline } = selectors.getRecalculatedTrip(state, tripId);
  const recalculatedTrip = Object.assign({}, trip, {
    id: tripId,
    distance: distance,
    polyline: polyUtil.encode(polyline),
    speeds: polyline.map(() => 25)
  });
  return dispatch(updateTrip(recalculatedTrip)).then(() => {
    dispatch(refreshCurrentPage()); // dispatch(closeModal());
    dispatch(
      notifications.actions.notify('success', 'Trip route successfully updated')
    );
  });
};

export const submitTrip = ({
  fromDate,
  toDate,
  fromTime,
  toTime,
  tripId,
  startTime,
  endTime
}) => dispatch => {
  const start = setTimeOnDate(fromDate || startTime, fromTime);
  const end = setTimeOnDate(toDate || endTime, toTime);
  return dispatch(addReturnTrip({ start, end, tripId }));
};
export const closeDistanceFilterModal = () => dispatch =>
  dispatch({ type: CLOSE_DISTANCE_FILTER_MODAL });
export const openDistanceFilterModal = () => dispatch =>
  dispatch({ type: OPEN_DISTANCE_FILTER_MODAL });
