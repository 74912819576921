import React, { Component } from 'react';
import Mousetrap from 'mousetrap';
import './HighlightSelected.css';

export default class HighlightSelected extends Component {
  constructor(props) {
    super(props);

    this.select = this.select.bind(this);
    this.getElementHeight = this.getElementHeight.bind(this);
    this.activateNext = this.activateNext.bind(this);
    this.activatePrevious = this.activatePrevious.bind(this);
    this.elements = [];
    this.state = { selected: 0 };
  }

  getElementHeight() {
    const { selected } = this.state;
    const { getElementHeight } = this.props;
    const element = this.elements[selected];
    const height = (element && element.clientHeight) || 0;

    getElementHeight && getElementHeight(height);

    return height;
  }

  select(selected) {
    const { onChange } = this.props;

    this.setState({ selected });
    this.getElementHeight();

    onChange && onChange(selected);
  }

  // keyboard shortcuts
  componentWillMount() {
    Mousetrap.bind(['up', 'w', 'k'], () => {
      this.activatePrevious();

      return false;
    });

    Mousetrap.bind(['down', 's', 'j'], () => {
      this.activateNext();

      return false;
    });
  }

  componentWillUnmount() {
    Mousetrap.unbind(['up', 'w', 'k', 'down', 's', 'j']);
  }

  activateNext() {
    const { selected } = this.state;
    const { children } = this.props;
    const lastElement = children && children.length - 1;
    const nextElement = selected + 1;
    const firstElement = 0;

    if (selected >= firstElement) {
      this.select(selected < lastElement ? nextElement : firstElement);
    }
  }

  activatePrevious() {
    const { selected } = this.state;
    const previousElement = selected - 1;
    const firstElement = 0;

    if (selected > firstElement) {
      this.select(previousElement);
    }
  }

  render() {
    const { selected } = this.state;
    const { children } = this.props;
    const className = 'highlight-able-element';

    this.elements = [];

    return (
      children &&
      children.map((element, index) => (
        <div
          key={`${className}-${index}`}
          ref={element => element && this.elements.push(element)}
          className={`${className}${
            index === selected ? ' highlighted-element' : ''
          }`}
          onClick={() => this.select(index)}
        >
          {element}
        </div>
      ))
    );
  }
}
