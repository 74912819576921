import _ from "lodash";
import moment from "moment";
import Big from "big.js";
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/* strings */

export const camelize = string =>
  string.replace(/(_\w)/g, match => match[1].toUpperCase());

export const underscore = string =>
  string.replace(/([A-Z])/g, match => "_" + match[0].toLowerCase());

/* objects */

export const isObject = object =>
  Object.prototype.toString.call(object) === "[object Object]";

export const deepMapKeys = (object, func) =>
  isObject(object)
    ? _.keys(object).reduce((newObject, key) => {
        newObject[func(key)] = deepMapKeys(object[key], func);
        return newObject;
      }, {})
    : Array.isArray(object)
      ? object.map(item => deepMapKeys(item, func))
      : object;

export const camelizeKeys = object => deepMapKeys(object, camelize);

export const underscoreKeys = object => deepMapKeys(object, underscore);

export const normalizeArray = arr =>
  arr.reduce((map, item) => ({ ...map, [item.id]: item }), {});

export const difference = (object, base) => {
  const changes = (object, base) =>
    _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  return changes(object, base);
};

/* arrays */
export const range = (start, end) =>
  [...new Array(end - start).keys()].map(i => i + start);

/* units */
export const MI_TO_KM = 1.609344;
export const KI_TO_MI = 1 / MI_TO_KM;
export const convertKilometersToMiles = kilometers =>
  parseFloat(new Big(kilometers).div(MI_TO_KM));
export const convertMilesToKilometers = miles =>
  parseFloat(new Big(miles).times(MI_TO_KM));


export const convertUnitOfMeasurement = (value, metric, inverse) => {
  return isNaN(value) || value === null || metric
    ? value
    : inverse
      ? convertKilometersToMiles(value)
      : convertMilesToKilometers(value);
};
export function precisionRound(number, precision) {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}
export function setTimeOnDate(date, time) {
  const momentInTime = moment(time);
  return (
    date &&
    (time
      ? moment(date).set({
          hour: momentInTime.get("hour"),
          minute: momentInTime.get("minute"),
          second: momentInTime.get("second")
        })
      : moment(date)
    ).format()
  );
}
