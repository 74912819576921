import createReducer from 'redux/createReducer';
import {
  GET_COST_CENTERS_SUCCESS,
  POST_COST_CENTERS_SUCCESS,
  PUT_COST_CENTERS_SUCCESS,
  DELETE_COST_CENTERS_SUCCESS
} from 'ducks/costCenters/costCentersActionTypes';

export const setCostCenters = (state, action) => {
  const costCenters = action && action.payload;
  return costCenters ? costCenters : state;
};

export const removeCostCenter = (state, action) => {
  const removed = action && action.payload;
  return removed !== undefined
    ? state.filter(({ id }) => Number(id) !== Number(removed))
    : state;
};

export const costCenters = createReducer([], {
  ...[
    GET_COST_CENTERS_SUCCESS,
    POST_COST_CENTERS_SUCCESS,
    PUT_COST_CENTERS_SUCCESS
  ].reduce((state, action) => {
    state[action] = setCostCenters;
    return state;
  }, {}),
  [DELETE_COST_CENTERS_SUCCESS]: removeCostCenter
});

export default costCenters;
