import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { deleteCostCenter } from 'api/endpoints/company/costCenters';
import {
  DELETE_COST_CENTERS_REQUEST,
  DELETE_COST_CENTERS_SUCCESS,
  DELETE_COST_CENTERS_FAILURE
} from 'ducks/costCenters/costCentersActionTypes';

export const removeCostCenter = id => dispatch =>
  dispatch(
    createSimpleAction(
      deleteCostCenter,
      [
        DELETE_COST_CENTERS_REQUEST,
        DELETE_COST_CENTERS_SUCCESS,
        DELETE_COST_CENTERS_FAILURE
      ],
      'costCenters'
    )(id)
  ).then(() => dispatch({ type: DELETE_COST_CENTERS_SUCCESS, payload: id }));

export default removeCostCenter;
