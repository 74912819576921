import { addSuccessMessage } from './addSuccessMessage';
import { addFailureMessage } from './addFailureMessage';

export const addSuccessAndFailureMessages = (
  action,
  successMessage,
  failureMessage
) =>
  addFailureMessage(addSuccessMessage(action, successMessage), failureMessage);

export default addSuccessAndFailureMessages;
