import './IconButton.css';
import FontAwesome from 'react-fontawesome';
import React from 'react';

export const IconButton = ({
  icon,
  className,
  selected,
  onClick,
  value,
  ...rest
}) => (
  <div
    id={value}
    onClick={onClick}
    className={`icon-button${className ? ` ${className}` : ''}${
      selected ? ' selected-icon-button' : ''
    }${onClick ? '' : ' non-selectable-icon'}`}
    {...rest}
  >
    <FontAwesome id={value} name={icon} />
  </div>
);

export default IconButton;
