import React from 'react';
import { Form } from 'redux-form';
import './PreloginForm.css';

const PreLoginForm = ({ children, error, ...props }) => (
  <Form className="prelogin-form" {...props}>
    {error && <p className="prelogin-form__error">{error}</p>}
    {children}
  </Form>
);

export default PreLoginForm;
