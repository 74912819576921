import { connect } from 'react-redux';
import Pagination from './Pagination';
import {
  mapStateToPagination,
  mapDispatchToPagination
} from './paginationMappings';

export default connect(
  mapStateToPagination,
  mapDispatchToPagination
)(Pagination);
