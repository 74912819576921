import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createStore from './createStore';
import api from './api';
import Application from './components/Application';
import './polyfills';
import './styles/base.css';
import { detectUserDevice } from './ducks/session/sessionActions';
const store = createStore();
detectUserDevice()(store.dispatch);
api.setStore(store);
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Application />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
