import React from 'react';
import ColoredList from 'components/CompanyPages/lib/ColoredList';
import ScrollBars from 'components/Scrollbars';
import arrayToString from 'lib/arrayToString';
import Team from 'components/CompanyPages/Admin/SettingsPage/TeamsPage/TeamsList/Team';
import DeleteButton from 'components/CompanyPages/lib/DeleteButton';
import confirm from 'components/Confirmation';
import Money from 'components/Money';
import './TeamsList.css';

const ConfirmDeleteTeamButton = confirm(DeleteButton);

export default ({
  teams,
  onDrop,
  onClick,
  onDragEnter,
  onDragOver,
  onDragLeave,
  highlighted,
  deleteTeam,
  getTeamManagers,
  unitOfMeasure,
  isAdmin
}) => (
  <div className="teams-list">
    <Team first="Name" second="Manager(s)" third="Expense rate" />
    <ScrollBars>
      <div className="list-of-teams">
        {teams && (
          <ColoredList
            className="inner-teams-list"
            highlighted={highlighted}
            uniqueKey="teams-list"
          >
            {teams.map(
              ({ id, name, managers, members, customBusinessRate }, index) => [
                <div
                  id={id}
                  key={`team-${index}`}
                  onDrop={onDrop(index, id)}
                  onDragEnter={onDragEnter(index)}
                  onDragLeave={onDragLeave(index)}
                  onDragOver={onDragOver}
                  className="teams-list-element"
                >
                  <Team
                    onClick={onClick}
                    deletable={true}
                    onDrop={onDrop(index, id)}
                    onDragOver={onDragOver}
                    id={id}
                    first={name}
                    amountOfUsers={managers.length + members.length}
                    second={arrayToString(
                      (getTeamManagers(id) || []).map(
                        ({ name, email }) => name || email
                      )
                    )}
                    third={
                      <h1 id={id}>
                        <Money>{customBusinessRate}</Money> / {unitOfMeasure}
                      </h1>
                    }
                  />
                </div>,
                ...(isAdmin
                  ? [
                      <ConfirmDeleteTeamButton
                        id={id}
                        confirm={true}
                        name={name}
                        title={`Delete ${name ? name : 'team'}`}
                        message={`Are you sure you want to delete ${
                          name ? name : 'team'
                        }?`}
                        className="team-delete-button"
                        onClick={() => deleteTeam(id)}
                      />
                    ]
                  : [])
              ]
            )}
          </ColoredList>
        )}
      </div>
    </ScrollBars>
  </div>
);
