import { selectCompanyLocation } from 'ducks/company/companyActions/locations/selectCompanyLocation';
import { submitCompanyLocation } from 'ducks/company/companyActions/locations/submitCompanyLocation';
import { getCompanyLocations as loadCompanyLocations } from 'ducks/company/companyActions/locations/getCompanyLocations';
import { getCompanyLocations } from 'ducks/company/companySelectors/companyLocations/getCompanyLocations';
import { getSelectedCompanyLocation } from 'ducks/company/companySelectors/companyLocations/getSelectedCompanyLocation';
import { getSelectedCompanyLocationId } from 'ducks/company/companySelectors/companyLocations/getSelectedCompanyLocationId';
import { addCompanyLocation } from 'ducks/company/companyActions/locations/addCompanyLocation';
import { getCompanyMembers as loadCompanyMembers } from 'ducks/company/companyActions/users/getCompanyMembers';
import getSelectedCompanyLocationCoordinates from 'ducks/company/companyActions/locations/getSelectedCompanyLocationCoordinates';
import getCompanyLocationCoordinates from 'ducks/company/companySelectors/companyLocations/getCompanyLocationCoordinates';
import getCompanyManagers from 'ducks/company/companySelectors/companyMembers/getCompanyManagers';
import description from 'components/CompanyPages/Admin/SettingsPage/CompanyLocationsPage/lib/companyLocationsDescription';
import { combineFilters } from 'components/CompanyPages/lib/Filters/lib/combineFilters';
import { createFilter } from 'components/CompanyPages/lib/Filters/lib/createFilter';
import { getManagerFilters } from 'components/CompanyPages/lib/Filters/lib/getManagerFilters';
import getCompanyLocationsPageRequests from 'ducks/company/companySelectors/companyRequests/getCompanyLocationPageRequests';

export const mapStateToCompanyLocationsPage = state => {
  const managers = [
    { id: null, name: 'All Managers' },
    ...getCompanyManagers(state)
  ];
  return {
    requestsState: getCompanyLocationsPageRequests(state),
    locations: getCompanyLocations(state),
    selectedId: getSelectedCompanyLocationId(state),
    selectedLocation: getSelectedCompanyLocation(state),
    selectedCoordinate: getSelectedCompanyLocationCoordinates(state),
    coordinates: getCompanyLocationCoordinates(state),
    sorting: [false, true],
    filters: combineFilters({
      userId: getManagerFilters(state),
      createdAt: createFilter([
        { name: 'Earliest created location', id: 'asc' },
        { name: 'Latest created location', id: 'desc' }
      ])
    }),
    description,
    managers
  };
};

export const mapDispatchToCompanyLocationsPage = {
  selectLocation: selectCompanyLocation,
  submitLocation: submitCompanyLocation,
  addLocation: addCompanyLocation,
  loadCompanyLocations,
  loadCompanyMembers
};
