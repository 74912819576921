import * as purposes from 'ducks/purposes/selectors';
import { getBusinessRate, getMetricMeasure } from 'ducks/users/selectors';
import calculateEstimatedValue from './lib/calculateEstimatedValue';
const unclassified = 'Unclassified';
export const mapStateToEstimatedValue = (
  state,
  { cost, date, purposeId, distance }
) => {
  const purpose = purposeId
    ? purposes.getById(state, purposeId)
    : purposes.getActiveBusiness(state);
  const taxRate = purposes.getPurposesTaxRate(state, date, purposeId);
  const businessRate = getBusinessRate(state);
  const purposeRate = purposes.getPurposesRate(state, purposeId);
  const metric = getMetricMeasure(state);
  const estimatedValue =
    cost ||
    calculateEstimatedValue(
      distance,
      purposeRate || businessRate || taxRate,
      metric
    );
  const type = purposeId && purpose ? purpose.name : unclassified;
  return { estimatedValue, type };
};
