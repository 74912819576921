import React from 'react';
import SideMenu from 'components/SideMenu';

const PurposesMenu = ({ purposes, value, onChange, left }) => (
  <SideMenu
    selected={value}
    onClick={onChange}
    items={[
      ...purposes.map(purpose => ({
        id: purpose.id,
        label: purpose.name
      })),
      { id: null, label: 'Unclassify' }
    ]}
    left={left}
  >
    {!purposes.length && (
      <div className="purposes-menu__empty">
        <b>No other purposes.</b> Define custom purposes in your settings.
      </div>
    )}
  </SideMenu>
);

export default PurposesMenu;
