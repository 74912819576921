import { deleteKeyPerformanceIndexReport } from 'api/endpoints/company/kpiReports';
import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import {
  DELETE_KPI_REPORT_REQUEST,
  DELETE_KPI_REPORT_SUCCESS,
  DELETE_KPI_REPORT_FAILURE
} from '../kpiReportActionTypes';

export const deleteKpiReport = id => dispatch =>
  dispatch(
    createSimpleAction(deleteKeyPerformanceIndexReport, [
      DELETE_KPI_REPORT_REQUEST,
      DELETE_KPI_REPORT_SUCCESS,
      DELETE_KPI_REPORT_FAILURE
    ])(id)
  ).then(() => dispatch({ type: DELETE_KPI_REPORT_SUCCESS, payload: id }));

export default deleteKpiReport;
