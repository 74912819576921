import React from 'react';
import { Field } from 'redux-form';
import Checkbox from 'components/Checkbox';

const renderInput = ({ label, input: { onChange, value, ...input } }) => (
  <Checkbox
    label={label}
    checked={value}
    onChange={() => onChange(!value)}
    {...input}
  />
);

const CheckboxField = props => <Field component={renderInput} {...props} />;

export default CheckboxField;
