import React from 'react';
import classNames from 'class-names';

import './PreloginTextbox.css';

const PreloginTextbox = ({ className, ...props }) => (
  <input
    type="text"
    className={classNames(className, 'prelogin-textbox')}
    {...props}
  />
);

export default PreloginTextbox;
