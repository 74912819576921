import { DELETE_FROM_PAGE } from '../paginationActionTypes';
import getPageNumber from '../paginationSelectors/getPageNumber';

export const deleteFromPage = pageName => id => (dispatch, getState) => {
  const state = getState && getState();
  const pageNumber = getPageNumber(pageName)(state);

  return dispatch({
    type: DELETE_FROM_PAGE,
    payload: { pageNumber, id },
    pageName
  });
};

export default deleteFromPage;
