import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { Map, Marker, Polyline } from 'react-leaflet';
import TileLayer from 'components/TileLayer';
import polyUtil from 'polyline-encoded';
import startIconPath from './location-pin-A.png';
import endIconPath from './location-pin-B.png';
import './AddTripMap.css';

export default class AddTripMap extends Component {
  static propTypes = {
    startCoords: PropTypes.arrayOf(PropTypes.number),
    endCoords: PropTypes.arrayOf(PropTypes.number),
    polyline: PropTypes.string,
    focus: PropTypes.arrayOf(PropTypes.number),
    setPoint: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.startIcon = L.icon({
      iconUrl: startIconPath,
      iconSize: [30, 39],
      iconAnchor: [15, 38]
    });

    this.endIcon = L.icon({
      iconUrl: endIconPath,
      iconSize: [30, 39],
      iconAnchor: [15, 38]
    });

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    const { setPoint } = this.props;
    const { lat, lng } = event.latlng;
    setPoint([lat, lng], undefined, true);
  }

  createDragEndHandler(isStartMarker) {
    const { setPoint } = this.props;
    return ({ target }) => {
      const { lat, lng } = target.getLatLng();
      setPoint([lat, lng], isStartMarker, true);
    };
  }

  render() {
    const { startCoords, endCoords, polyline, focus } = this.props;

    // calculate what to center on base on available information
    let view;
    const positions = [startCoords, endCoords].filter(x => x);
    const length = positions.length;

    if (length < 1) {
      view = { center: focus };
    } else if (length === 1) {
      view = { center: positions[0] };
    } else {
      view = { bounds: positions };
    }

    // avoid sending "zoom" param again on re-render
    if (!startCoords && !endCoords && !polyline) view.zoom = 11;

    return (
      <div
        className={
          'map-wrap leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom'
        }
      >
        <Map
          className="add-trip-map"
          boundsOptions={{ padding: [40, 40] }}
          onClick={this.onClick}
          {...view}
        >
          {startCoords &&
            startCoords.length && (
              <Marker
                icon={this.startIcon}
                position={startCoords}
                onDragEnd={this.createDragEndHandler(true)}
                draggable
              />
            )}

          {endCoords &&
            endCoords.length && (
              <Marker
                icon={this.endIcon}
                position={endCoords}
                onDragEnd={this.createDragEndHandler(false)}
                draggable
              />
            )}

          {polyline && <Polyline positions={polyUtil.decode(polyline)} />}
          <TileLayer />
        </Map>
      </div>
    );
  }
}
