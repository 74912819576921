// Cost Centers
import { companyApi } from '../../client';

export const getCostCenters = () => companyApi.get('/cost_centers');

export const postCostCenter = costCenter =>
  companyApi.post('/cost_centers', { costCenter });

export const putCostCenter = (id, costCenter) =>
  companyApi.put(`/cost_centers/${id}`, { costCenter });

export const deleteCostCenter = id => companyApi.delete(`/cost_centers/${id}`);
