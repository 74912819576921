import { getStoredLocation } from 'ducks/locations/locationSelectors/getStoredLocation';
import updateLocation from 'ducks/locations/locationActions/updateLocation';
import deleteLocation from 'ducks/locations/locationActions/deleteLocation';
import removeStoredLocation from 'ducks/locations/locationActions/removeStoredLocation';
export default (id, alert) => (dispatch, getState) => {
  const state = getState();
  const location = getStoredLocation(state, id);
  location
    ? dispatch(updateLocation(location, alert))
    : dispatch(deleteLocation(id, alert));
  dispatch(removeStoredLocation(id));
};
