import _ from 'lodash';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import users from 'ducks/users';
import session from 'ducks/session';
import countries from 'ducks/countries';
import trips from 'ducks/trips';
import selection from 'ducks/selection';
import listSelection from 'ducks/listSelection';
import vehicles from 'ducks/vehicles';
import purposes from 'ducks/purposes';
import notifications from 'ducks/notifications';
import reports from 'ducks/reports';
import kpiReports from 'ducks/kpiReports';
import locations from 'ducks/locations';
import workHours from 'ducks/workHours';
import companies from 'ducks/company';
import teams from 'ducks/teams';
import rules from 'ducks/rules';
import costCenters from 'ducks/costCenters';
import companyTrips from 'ducks/companyTrips';
import pagination from 'ducks/pagination';

const ducks = [
  users,
  session,
  countries,
  trips,
  selection,
  vehicles,
  purposes,
  purposes,
  notifications,
  reports,
  locations,
  workHours,
  companies,
  rules,
  teams,
  costCenters,
  kpiReports,
  companyTrips,
  pagination,
  listSelection
];

const reducers = {
  form: formReducer,
  // maps each duck to `{ [duck.constants.NAME]: duck.reducer }`
  ..._.mapValues(_.keyBy(ducks, d => d.constants.NAME), 'reducer')
};
export default combineReducers(reducers);
