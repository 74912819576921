import { connect } from 'react-redux';
import TimeScopeFilter from './TimeScopeFilter';
import { getTimeScopes, getHumanReadableTimeScopes } from 'lib/timeScopes';

const mapStateToProps = state => ({
  getTimeScopes: () => getTimeScopes(state),
  getHumanReadableTimeScopes: value => getHumanReadableTimeScopes(state, value)
});

export default connect(mapStateToProps)(TimeScopeFilter);
