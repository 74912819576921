import _ from 'lodash';
import { getDateRange, STRIPPED_UTC_FORMAT } from 'lib/timeScopes';

function parseCategories(categories) {
  // categories is an array of purpose ids plus `-1` for unclassified and `-2`
  // for auto-classified
  if (_.isEmpty(categories)) {
    return {};
  }

  // we can simply pass unclassified as `-1`
  const purposeIds = categories.filter(c => c > -2);
  const autoClassified = categories.indexOf(-2) > -1;

  return {
    purpose_ids: purposeIds,
    ...(autoClassified && { autoclassified: true })
  };
}

export default ({
  timeScopeMode,
  timeScope,
  from,
  to,
  category,
  vehicle,
  includeReported,
  distanceFilters,
  weekDays,
  location,
  ...rest
}) => {
  const distanceFilter =
    distanceFilters &&
    distanceFilters.distance_lt === distanceFilters.distance_gt
      ? { distance: distanceFilters.distance_lt }
      : distanceFilters;

  let dateRange;
  let timeSpan;
  if (timeScopeMode && timeScope) {
    dateRange = getDateRange(timeScope);
    timeSpan = dateRange.timeScope
      ? { time_scope: dateRange.timeScope }
      : {
          filter_start: dateRange.from.format(STRIPPED_UTC_FORMAT),
          filter_end: dateRange.to.format(STRIPPED_UTC_FORMAT)
        };
  } else {
    from = from && from.format(STRIPPED_UTC_FORMAT);
    to = to && to.format(STRIPPED_UTC_FORMAT);
  }
  return {
    ...(timeSpan || { from, to }),
    ...parseCategories(category),
    ...(vehicle && { vehicle_id: vehicle }),
    include_reported: includeReported,
    weekdays: weekDays,
    ...distanceFilter,
    ...rest,
    location
  };
};
