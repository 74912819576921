import React from 'react';
import { Link } from 'react-router-dom';
import PreLoginBox, {
  PreloginBoxTitle,
  PreloginBoxLink,
  PreloginBoxFoot
} from 'components/PreLogin/PreLoginBox';
import SignIn from 'components/PreLogin/SignInOrUp/SignInOrUp';
export default () => (
  <div className="sign-in-page">
    <PreLoginBox>
      <PreloginBoxTitle>Log In to Your Dashboard</PreloginBoxTitle>
      <SignIn />
      <PreloginBoxLink to="/forgot_password">
        Forgot your password?
      </PreloginBoxLink>
    </PreLoginBox>
    <PreloginBoxFoot>
      Don't have an account? <Link to="/sign_up">Sign up here</Link>.
    </PreloginBoxFoot>
  </div>
);
