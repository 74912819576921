import React, { Component } from 'react';
import { scalePoint, scaleLinear, axisRight, select, max } from 'd3';

export default class ComparisonBarChart extends Component {
  constructor(props) {
    super(props);

    const { data, spacing, height } = props;
    const amountOfData = data.length;
    const spaceBetween = spacing || 1;
    const barWidth =
      height / ((1 + spaceBetween) * amountOfData - spaceBetween);

    this.state = {
      barWidth,
      spacing: spaceBetween * barWidth
    };

    this.svg = null;

    this.spaceBars = this.spaceBars.bind(this);
    this.setSvgRef = this.setSvgRef.bind(this);
  }

  componentDidMount() {
    this.renderSvg();
  }

  setSvgRef(svg) {
    this.svg = svg;
  }

  spaceBars(barNumber) {
    const { barWidth } = this.state;

    return barNumber * barWidth;
  }

  renderSvg() {
    const { axis, data, width, height } = this.props;

    const showAxis = axis !== false;
    const margin = showAxis ? 80 : 0;
    const { barWidth, spacing } = this.state;
    const widthWithoutMargin = width - margin;
    const maximum = max(data, ({ value }) => value);
    const first = data && data[0];
    const titleDefined = !!(first && first.title);

    const x = scaleLinear()
      .range([0, widthWithoutMargin])
      .domain([0, maximum]);

    // create svg and append graph
    const svg = select(this.svg)
      .attr('width', width)
      .attr('height', height)
      .append('g');

    if (titleDefined && showAxis) {
      const y = scalePoint()
        .range([height, 0])
        .domain(data.map(({ title }) => title))
        .padding(0.2);

      const yAxis = axisRight(y);

      svg
        .append('g')
        .attr('class', 'y axis')
        .attr('transform', `translate(${widthWithoutMargin + margin / 5}, 0)`)
        .call(yAxis);
    }

    const bars = svg
      .selectAll('.bar')
      .data(data)
      .enter();

    bars
      .append('rect')
      .attr('class', `bar`)
      .attr('y', (_, index) => this.spaceBars(index) + spacing * index)
      .attr('height', barWidth)
      .attr('x', 0)
      .attr('width', ({ value }) => x(value));
  }

  render() {
    const { width, height, className } = this.props;
    return (
      <svg
        ref={this.setSvgRef}
        width={width}
        height={height}
        className={className}
      />
    );
  }
}
