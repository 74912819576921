import { getCompanyVehicles as getVehicles } from 'api/endpoints/company/vehicles';
import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import {
  GET_COMPANY_VEHICLES_REQUEST,
  GET_COMPANY_VEHICLES_SUCCESS,
  GET_COMPANY_VEHICLES_FAILURE
} from 'ducks/company/companyActionTypes';

export const getCompanyVehicles = createSimpleAction(
  getVehicles,
  [
    GET_COMPANY_VEHICLES_REQUEST,
    GET_COMPANY_VEHICLES_SUCCESS,
    GET_COMPANY_VEHICLES_FAILURE
  ],
  'vehicles'
);

export default getCompanyVehicles;
