import { connect } from 'react-redux';
import {
  setEnabled,
  setDayEnabled,
  addRule,
  removeRule,
  setRule,
  previewRule
} from 'ducks/workHours/workHourActions/userActions';
import { getCompanyId } from 'lib/auth';
import WorkHours from './WorkHours';
import getWorkHours from 'ducks/workHours/workHourSelectors/getUserWorkHours';

const mapStateToProps = state => ({
  workHours: getWorkHours(state),
  companyId: getCompanyId()
});

const mapDispatchToProps = {
  setEnabled,
  setDayEnabled,
  addRule,
  removeRule,
  setRule,
  previewRule
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkHours);
