import React from 'react';
import { arrayToString } from 'lib/arrayToString';
import './EditedUsersElement.css';

export default ({ users }) => {
  const amount = users.length;

  return (
    <div className="edited-users-element">
      <h1 className="edited-users-list">
        {amount > 2
          ? `Editing ${amount} selected users`
          : arrayToString(users.map(({ name, email }) => name || email))}
      </h1>
    </div>
  );
};
