import _ from 'lodash';

export const ELLIPSIS = 'ELLIPSIS';

export default (page, total, nCells = 9) => {
  const midCell = Math.ceil(nCells / 2);

  if (total <= nCells) {
    return _.range(1, total + 1);
  } else if (page <= midCell) {
    return [..._.range(1, nCells - 1), ELLIPSIS, total];
  } else if (page > total - midCell) {
    return [1, ELLIPSIS, ..._.range(total - nCells + 3, total + 1)];
  } else {
    const flank = Math.floor((nCells - 4) / 2);

    return [
      1,
      ELLIPSIS,
      ..._.range(page - flank, page + flank + 1),
      ELLIPSIS,
      total
    ];
  }
};
