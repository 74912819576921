import React, { Component } from 'react';
import classNames from 'class-names';
import TextBoxField from 'components/TextBoxField';
export default class DistanceSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }
  render() {
    const props = this.props;
    const { className, normalize, distanceMeasure, title } = props;
    return (
      <div className={classNames(className, 'input-group distance-selector')}>
        <h2 className="date-time-selector-element distance-selector-title">{`${title}:  `}</h2>
        <TextBoxField
          className="input-group__input"
          name={'distance'}
          value={this.state.value}
          onChange={({ target }) =>
            this.setState({ value: normalize(target.value) })
          }
          normalizeinput={true}
          {...props}
        />
        <div className="input-group__suffix">{distanceMeasure}</div>
      </div>
    );
  }
}
