import _ from 'lodash';

export const apiCallRequest = (payload, type) => ({
  payload,
  type
});

export const apiCallSuccess = (payload, type, message) => ({
  payload,
  type,
  ...(message && { meta: { alert: { type: 'success', message } } })
});

export const apiCallFailure = (error, type, message) => ({
  payload: error,
  error,
  type,
  ...(message && {
    meta: {
      alert: {
        type: 'failure',
        message
      }
    }
  })
});

export const createSimpleAction = (
  apiCall,
  [REQUEST, SUCCESS, FAILURE],
  path,
  successMessage,
  failureMessage
) => (...args) => dispatch => {
  dispatch(apiCallRequest(args[0], REQUEST));
  return apiCall(...args)
    .then(({ data }) => {
      const value = _.get(data, path, data);
      dispatch(apiCallSuccess(value, SUCCESS, successMessage));
      return Promise.resolve(value);
    })
    .catch(error => dispatch(apiCallFailure(error, FAILURE, failureMessage)));
};

export default createSimpleAction;
