import React from 'react';
import csv from 'lib/images/csv.png';
import pdf from 'lib/images/pdf.png';
import xls from 'lib/images/xls.png';
import FontAwesome from 'react-fontawesome';
import './ReportDownloadLinks.css';

export const ReportDownloadLinks = ({ status, xlsUrl, csvUrl, pdfUrl }) => (
  <div className="report__status">
    {status === 'generating' ? (
      <span className="report__generating">
        <FontAwesome name="cog" fixedWidth spin /> Generating&hellip;
      </span>
    ) : status === 'error' ? (
      <span className="report__error">
        <FontAwesome name="exclamation-circle" fixedWidth /> Error
      </span>
    ) : (
      <div className="report__links">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="report__link"
          href={xlsUrl}
        >
          <img alt="xls" src={xls} />
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          className="report__link"
          href={csvUrl}
        >
          <img alt="csv" src={csv} />
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          className="report__link"
          href={pdfUrl}
        >
          <img alt="pdf" src={pdf} />
        </a>
      </div>
    )}
  </div>
);

export default ReportDownloadLinks;
