import React, { Component } from 'react';
import Filters from 'components/CompanyPages/lib/Filters';
import MyLocations from 'components/MyLocations/MyLocationsPage';
import './CompanyLocationsPage.css';

export default class CompanyLocationsPage extends Component {
  componentWillMount() {
    const { loadCompanyMembers, loadCompanyLocations } = this.props;

    loadCompanyMembers && loadCompanyMembers();
    loadCompanyLocations && loadCompanyLocations();
  }

  render() {
    const { filters, loadCompanyLocations, ...props } = this.props;

    return (
      <div className="company-locations-page">
        <Filters
          className="company-locations-page-filter-bar"
          onChange={loadCompanyLocations}
          space={true}
          {...filters}
        />
        <MyLocations {...props} />
      </div>
    );
  }
}
