import React from 'react';
import { reduxForm, Field } from 'redux-form';
import * as validators from 'lib/validators';
import PreLoginForm from 'components/PreLogin/PreLoginForm';
import PreLoginButton from 'components/PreLogin/PreLoginButton';
import CountryDropDown from 'components/CountryDropdown';
import TextBoxField from 'components/TextBoxField';
import classNames from "class-names";

const validations = {
  email: validators.check(validators.required, validators.email),
  password: validators.check(
    validators.required,
    validators.passwordLength,
    validators.passwordComplexity
  ),
  passwordConfirmation: validators.check(
    validators.required,
    validators.passwordConfirmation
  )
};

const renderCountrySelect = ({ input: { value, onChange } }) => (
  <CountryDropDown value={value} onChange={onChange} prelogin />
);

const SignUpForm = ({ handleSubmit, error, submitting, signUp, valid, r }) => (
  <PreLoginForm onSubmit={handleSubmit(signUp)} error={error}>
      {r && r.length > 0 &&
      <div>
          <p>You're one step away from <b>saving 30%</b> off your first year of an annual plan! Get started with a 30 day trial.</p>
      </div>}
    <TextBoxField
      name="email"
      placeholder="Email"
      validate={validations.email}
      prelogin
    />
    <Field component="input" name="r"  className="hide" />
    <TextBoxField name="name" placeholder="Full Name (optional)" prelogin />
    <Field
      component={renderCountrySelect}
      name="countryId"
      validate={validators.required}
    />
    <TextBoxField
      type="password"
      name="password"
      placeholder="Password"
      validate={validations.password}
      prelogin
    />
    <TextBoxField
      type="password"
      name="passwordConfirmation"
      placeholder="Confirm Password"
      validate={validations.passwordConfirmation}
      prelogin
    />
    <PreLoginButton disabled={submitting || !valid}>
      {submitting ? <span>Signing up &hellip;</span> : 'Sign up'}
    </PreLoginButton>
  </PreLoginForm>
);
export default reduxForm({ form: 'sign-up' })(SignUpForm);
