import { connect } from 'react-redux';
import {
  mapStateToTeamsPage,
  mapDispatchToTeamsPage
} from './teamsPageMappings';
import TeamsPage from './TeamsPage';
export default connect(
  mapStateToTeamsPage,
  mapDispatchToTeamsPage
)(TeamsPage);
