import { getSelectedCompanyLocation } from 'ducks/company/companySelectors/companyLocations/getSelectedCompanyLocation';
import updateLocation from './updateCompanyLocation';
import addLocation from './addCompanyLocation';

export const submitCompanyLocation = data => (dispatch, getState) => {
  const location = getSelectedCompanyLocation(getState);
  return dispatch(
    (location ? updateLocation : addLocation)({ ...location, ...data })
  );
};

export default submitCompanyLocation;
