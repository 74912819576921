import { connect } from 'react-redux';
import HighlightSelected from './HighlightSelected';
import {
  mapStateToHighlightSelected,
  mapDispatchToHighlightSelected
} from './highlightSelectedMappings';

export default connect(
  mapStateToHighlightSelected,
  mapDispatchToHighlightSelected
)(HighlightSelected);
