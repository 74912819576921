import { connect } from 'react-redux';
import DeleteAccountPage from './DeleteAccountPage';
import {
  mapStateToDeleteAccountPage,
  mapDispatchToDeleteAccountPage
} from 'components/DeleteAccountPage/deleteAccountPageMappings';

export default connect(
  mapStateToDeleteAccountPage,
  mapDispatchToDeleteAccountPage
)(DeleteAccountPage);
