import React, { Component } from 'react';
import PageTitleHeader from 'components/CompanyPages/lib/PageTitleHeader';
import './IntegrationsPage.css';

export default class IntegrationsPage extends Component {
  render() {
    return (
      <div className="integrations-page">
        <PageTitleHeader>Integrations</PageTitleHeader>
        <h1 className="integrations-description">
          We are still figuring out what this will look like.
        </h1>
        <h1 className="integrations-coming-soon">Coming Soon!</h1>
      </div>
    );
  }
}
