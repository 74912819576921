import React from 'react';
import PropTypes from 'prop-types';
import Gravatar from 'react-gravatar';
import './AccountInfo.css';

const AccountInfo = ({ name, email }) => (
  <div className="account-info">
    {name === 'Bill Murray' &&
      <img
        src="https://www.fillmurray.com/90/90"
        className="account-info__pic"
        alt="Bill Murray"
      />
    }

    {name && <div className="account-info__name">{name}</div>}
    <div className="account-info__email">{email}</div>
  </div>
);

AccountInfo.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string.isRequired
};

export default AccountInfo;
