import _ from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { setLocaleByCountryCode } from 'localization';
import users from 'ducks/users';

const mapStateToProps = state => ({
  countryCode: _.get(users.selectors.getCurrentUser(state), 'countryCode')
});

class Locale extends Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.countryCode !== nextProps.countryCode) {
      setLocaleByCountryCode(nextProps.countryCode);
    }
  }

  render() {
    return this.props.children;
  }
}

export default connect(mapStateToProps)(Locale);
