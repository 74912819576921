import React from 'react';
import SmallUserDisplay from './SmallUserDisplay';
import './StatisticsUsersDisplay.css';

export const StatisticsUsersDisplay = ({ expensers, inactiveUsers }) => (
  <div className="statistics-users-display">
    <div className="top-expensers-display">
      <h1 className="admin-statistics-title">Top 5 expensers</h1>
      <div className="admin-statistics-expensers">
        {expensers && expensers.map(user => <SmallUserDisplay {...user} />)}
      </div>
    </div>
    <div className="inactive-users-display">
      <h1 className="admin-statistics-title">
        Users who have not expensed any miles in the past thirty days
      </h1>
      <div className="admin-statistics-inactive-users">
        {inactiveUsers.map(({ name, image, email }) => (
          <SmallUserDisplay name={name} image={image} email={email} />
        ))}
      </div>
    </div>
  </div>
);

export default StatisticsUsersDisplay;
