import { NAME as nameSpace } from 'ducks/costCenters/constants';

export const GET_COST_CENTERS_REQUEST = `${nameSpace}/GET_COST_CENTERS_REQUEST`;
export const GET_COST_CENTERS_SUCCESS = `${nameSpace}/GET_COST_CENTERS_SUCCESS`;
export const GET_COST_CENTERS_FAILURE = `${nameSpace}/GET_COST_CENTERS_FAILURE`;

export const POST_COST_CENTERS_REQUEST = `${nameSpace}/POST_COST_CENTERS_REQUEST`;
export const POST_COST_CENTERS_SUCCESS = `${nameSpace}/POST_COST_CENTERS_SUCCESS`;
export const POST_COST_CENTERS_FAILURE = `${nameSpace}/POST_COST_CENTERS_FAILURE`;

export const PUT_COST_CENTERS_REQUEST = `${nameSpace}/PUT_COST_CENTERS_REQUEST`;
export const PUT_COST_CENTERS_SUCCESS = `${nameSpace}/PUT_COST_CENTERS_SUCCESS`;
export const PUT_COST_CENTERS_FAILURE = `${nameSpace}/PUT_COST_CENTERS_FAILURE`;

export const DELETE_COST_CENTERS_REQUEST = `${nameSpace}/DELETE_COST_CENTERS_REQUEST`;
export const DELETE_COST_CENTERS_SUCCESS = `${nameSpace}/DELETE_COST_CENTERS_SUCCESS`;
export const DELETE_COST_CENTERS_FAILURE = `${nameSpace}/DELETE_COST_CENTERS_FAILURE`;
