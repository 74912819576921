import { combineReducers } from 'redux';
import requests from './locationRequests';
import selectedLocation from './locationSelection';
import addLocationModal from './addLocationModal';
import storedLocations from './storedLocations';
import companyLocations from './companyLocations';

export default combineReducers({
  requests,
  selectedLocation,
  addLocationModal,
  storedLocations,
  companyLocations
});
