import { deleteAccount } from 'ducks/users/userActions/deleteAccount';
import { getCurrentUser } from 'ducks/users/selectors';

export const mapDispatchToAccountManagement = { deleteAccount };


export const mapStateToAccountManagement = state => {
  const user = getCurrentUser(state);
  return {
    subscription: user && user.subscription
  };
};