import React from 'react';
import { TileLayer as Layer } from 'react-leaflet';
import copyRightInfo from 'lib/mapCopyrightCredits';
import { TILE_LAYER_URL } from 'lib/services/tilehosting';
export const TileLayer = ({ children, ...props }) => (
  <Layer attribution={copyRightInfo} url={TILE_LAYER_URL} {...props}>
    {children}
  </Layer>
);
export default TileLayer;
