import React, { Component } from 'react';
import { logError } from 'raven';
import PropTypes from 'prop-types';
import RavenUserContext from 'components/RavenUserContext';
import Locale from 'components/Locale';
import PreLogin from 'components/PreLogin';
import Portal from 'components/Portal';
import Header from 'components/Header';
import Footer from 'components/Footer';
import BrowserCheck from 'components/BrowserCheck';
import NotificationContainer from 'components/NotificationContainer';
import RenderPage from 'lib/RenderPage';
import { LOADED } from 'lib/requests';
import FetchUser from 'components/FetchUser';
import './Application.css';

class Application extends Component {
  static propTypes = {
    signedIn: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.showPortal = this.showPortal.bind(this);
    this.showHeader = this.showHeader.bind(this);
    this.showAdminView = this.showAdminView.bind(this);
  }

  componentDidMount() {
    const { getCountries } = this.props;
    getCountries();
  }

  componentDidCatch(error) {
    logError(error);
  }

  showHeader() {
    const { location, signedIn, redirecting, isMobile } = this.props;
    return (
      signedIn &&
      !redirecting &&
      !isMobile &&
      !['/invites', '/delete', '/invitation'].reduce((inPath, path) => {
        const url = location.pathname;
        return (url && url.includes(path)) || inPath;
      }, false)
    );
  }

  showPortal() {
    const { signedIn, redirecting, isMobile } = this.props;

    return signedIn && !redirecting && !isMobile;
  }

  showAdminView() {
    const { location } = this.props;
    return ['/admin', '/manager'].reduce((inPath, path) => {
      const url = location.pathname;
      return (url && url.includes(path)) || inPath;
    }, false);
  }

  render() {
    const { signedIn, defaultSignInPage, location, requestsState, role } = this.props;
    const showPortal = this.showPortal();
    return (
      <RavenUserContext>
        <Locale>
          {signedIn}
          {showPortal && <FetchUser />}
          <div className="application">
            <RenderPage requestsState={signedIn ? requestsState : LOADED}>
              {this.showHeader() && (
                <Header
                  showAdminView={false}
                  role={role}
                  path={location && location.pathname}
                />
              )}
              <div className="application__content">
                {showPortal ? (
                  <Portal />
                ) : (
                  <PreLogin defaultPage={defaultSignInPage} />
                )}
              </div>
            </RenderPage>
            <Footer />
            <NotificationContainer />
          </div>
        </Locale>
      </RavenUserContext>
    );
  }
}
export default Application;
