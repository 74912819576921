import * as constants from './constants';
import {
  getCompanyId as getLocalId,
  getAuthToken as getToken,
  getUserId
} from 'lib/auth';
const getState = state => state[constants.NAME];
export const getAuthToken = state => getState(state).authToken;
export const getId = state => {
  const currentState = getState(state);
  return currentState ? currentState.id : currentState;
};
export const getCompanyId = state => getState(state).companyId;
export const isSignedIn = state =>
  !!((getUserId() || getLocalId()) && getToken());
export const isRedirecting = state => getState(state).redirect;
export const isMobile = state => getState(state).mobile;
export const determineSignInPage = state =>
  isMobile(state) ? '/subscription' : '/sign_in';
