import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Panel from 'components/Panel';
import ContentWrap from 'components/ContentWrap';
import Scrollbars from 'components/Scrollbars';
import { locales } from 'locales.json';
import { setLocaleByCountryCode, formatCurrency } from 'localization';

const mapStateToProps = state => ({
  countries: state.countries.items
});

moment.locale('x-pseudo');

class LocaleTest extends React.Component {
  renderLocale(countryCode, i) {
    setLocaleByCountryCode(countryCode);

    if (
      formatCurrency(1234.56, { countryCode }) !==
      locales[countryCode].symbolPlacement
    )
      console.warn(
        'incorrect currency rendering',
        `expected: ${locales[countryCode].symbolPlacement}`,
        `actual: ${formatCurrency(1234.56, { countryCode })}`
      );

    return (
      <tr
        key={countryCode}
        style={{
          background: i % 2 === 1 ? '#fafafa' : null,
          lineHeight: '28px'
        }}
      >
        <td style={{ fontWeight: 'bold' }}>{countryCode}</td>
        <td style={{ fontWeight: 'bold' }}>{locales[countryCode].country}</td>
        <td>{moment().format('ddd ll')}</td>
        <td>{moment().format('LT')}</td>
        <td>{formatCurrency(1234.45, { countryCode })}</td>
        <td>
          {formatCurrency(0.123456, {
            countryCode,
            rate: true,
            metric: true
          })}
        </td>
      </tr>
    );
  }

  render() {
    return (
      <ContentWrap>
        <Panel style={{ padding: '20px' }}>
          <Scrollbars>
            <table
              style={{
                fontSize: '14px',
                lineHeight: '22px',
                width: '100%',
                textAlign: 'left'
              }}
            >
              <thead
                style={{
                  fontWeight: 'bold',
                  borderBottom: '1px solid #d7d7d7'
                }}
              >
                <tr>
                  <th>Code</th>
                  <th>Country</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Currency</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                {_.entries(locales).map(([countryCode, locale], i) =>
                  this.renderLocale(countryCode, i)
                )}
              </tbody>
            </table>
          </Scrollbars>
        </Panel>
      </ContentWrap>
    );
  }
}

export default connect(mapStateToProps)(LocaleTest);
