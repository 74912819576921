import React, { Component } from 'react';
import AddButton from 'components/CompanyPages/lib/AddButton/index';
import Filters from 'components/CompanyPages/lib/Filters/index';
import Paginator from 'components/Paginator/index';
import Checkbox from 'components/Checkbox/index';
import RenderPage from 'lib/RenderPage/index';
import Report from './CompanyReport/index';
import { Link } from 'react-router-dom';
import './ViewReportsPage.css';
import ShowConditionally from '../../../../ShowConditionally';

export default class ReportsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHidden: false,
      pageNumber: 1,
      params: {}
    };

    this.toggleShowHidden = this.toggleShowHidden.bind(this);
    this.setPageNumber = this.setPageNumber.bind(this);
    this.loadReports = this.loadReports.bind(this);
    this.filterReports = this.filterReports.bind(this);
  }

  componentWillMount() {
    const {
      loadMembers,
      loadTeams,
      loadReports,
      setCompanyReportsPageSize
    } = this.props;

    setCompanyReportsPageSize && setCompanyReportsPageSize(15);

    [() => loadReports({ page: 1 }), loadTeams, loadMembers].forEach(
      resource => resource && resource()
    );
  }

  setPageNumber(pageNumber) {
    this.setState({ pageNumber });
  }

  toggleShowHidden({ target }) {
    this.setState({ showHidden: target && target.checked });
  }

  loadReports(pageNumber) {
    const { loadReports } = this.props;
    const { params } = this.state;

    loadReports &&
      loadReports({
        page: pageNumber,
        ...params
      });

    this.setState({ pageNumber });
  }

  filterReports(filters) {
    const { pageNumber, params } = this.state;
    const { loadReports } = this.props;

    loadReports &&
      loadReports({
        ...params,
        ...filters,
        page: pageNumber
      });

    this.setState({ params: filters });
  }

  render() {
    const { showHidden } = this.state;
    const {
      filters,
      reports,
      isAdmin,
      requestsStatus,
      pageSize,
      numberOfElements,
      exportReports
    } = this.props;

    const { pageNumber } = this.state;

    const headerLabels = [
      'Submitted by',
      'Submission Date',
      'Date Range',
      'Value',
      `Reject/Approve${isAdmin ? '/Pay' : ''}`,
      'Download',
      'Hide'
    ].map(title => ({
      title,
      className: `column-${title
        .replace(' ', '-')
        .split('/')
        .join('')
        .toLowerCase()}`
    }));

    const classNames = headerLabels.map(({ className }) => className);

    return (
      <div className="admin-reports-page">
        <Filters
          className="admin-reports-page-filters"
          {...filters}
          onChange={this.filterReports}
        />
        <div className="admin-reports-page-header">
          <div className="admin-reports-page-show-hidden-selector">
            <Checkbox
              onChange={this.toggleShowHidden}
              label="Show hidden reports"
            />
          </div>
          <ShowConditionally show={isAdmin}>
            <div className="admin-reports-page-export-reports-button">
              <AddButton
                onClick={exportReports}
                className="admin-reports-report-to-admin"
              >
                Export reports
              </AddButton>
            </div>
          </ShowConditionally>
          <Link className="admin-reports-generate-reports-link" to="generate">
            <h1>Generate Reports</h1>
          </Link>
        </div>
        <RenderPage requestsState={requestsStatus}>
          <div className="admin-reports-table-container">
            <table className="admin-reports-table">
              <thead>
                <tr className="admin-reports-table-header">
                  {headerLabels.map(({ title, className }) => (
                    <th className={className}>{title}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="admin-reports-table-body">
                <Paginator
                  className="admin-reports-pagination"
                  pageNumber={pageNumber}
                  numberOfElements={numberOfElements}
                  pageSize={pageSize}
                >
                  {reports &&
                    reports
                      .filter(({ hidden }) => showHidden || !hidden)
                      .map((data, index) => (
                        <Report
                          classNames={classNames}
                          index={index}
                          {...data}
                        />
                      ))}
                </Paginator>
              </tbody>
            </table>
          </div>
        </RenderPage>
      </div>
    );
  }
}
