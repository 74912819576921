import { getCompanyReports } from 'api/endpoints/company/reports';
import { getPage } from 'ducks/pagination/paginationActions/getPage';
import { GET_COMPANY_REPORTS_SUCCESS } from '../../companyActionTypes';
import { COMPANY_REPORTS } from 'ducks/company/constants';

const getReports = getPage(COMPANY_REPORTS, 'reports', getCompanyReports);

export const getCompanyReportsPage = params => dispatch =>
  dispatch(getReports(params)).then(() =>
    dispatch({ type: GET_COMPANY_REPORTS_SUCCESS })
  );

export default getCompanyReportsPage;
