import { connect } from 'react-redux';
import EditTeamPage from './EditTeamPage';
import {
  mapStateToEditTeamPage,
  mapDispatchToEditTeamPage
} from './editTeamPageMappings';

export default connect(
  mapStateToEditTeamPage,
  mapDispatchToEditTeamPage
)(EditTeamPage);
