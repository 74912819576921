import {
  ADD_COMPANY_LOCATION_SUCCESS,
  DELETE_COMPANY_LOCATION_SUCCESS,
  UPDATE_COMPANY_LOCATION_SUCCESS
} from 'ducks/locations/locationActionTypes';
import createReducer from 'redux/createReducer';
import { INVALIDATE } from 'ducks/session/actionTypes';

export const setLocations = (state, locations) => {
  const payload = locations && locations.payload;
  return payload || state;
};

export const items = createReducer(
  {},
  {
    [INVALIDATE]: () => ({}),
    ...[
      UPDATE_COMPANY_LOCATION_SUCCESS,
      DELETE_COMPANY_LOCATION_SUCCESS,
      ADD_COMPANY_LOCATION_SUCCESS
    ].reduce((actions, action) => {
      actions[action] = setLocations;
      return actions;
    }, {})
  }
);

export default items;
