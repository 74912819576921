import {
  ADD_LOCATION,
  ADD_COMPANY_LOCATION,
  ADD_LOCATION_SUCCESS,
  ADD_COMPANY_LOCATION_SUCCESS
} from 'ducks/locations/locationActionTypes';
import _ from 'lodash';
import createReducer from 'redux/createReducer';

export const addLocation = (state, { payload }) =>
  _.isBoolean(payload) ? payload : state;

export default createReducer(false, {
  ...[ADD_LOCATION, ADD_COMPANY_LOCATION].reduce((all, action) => {
    all[action] = addLocation;
    return all;
  }, {}),
  ...[ADD_LOCATION_SUCCESS, ADD_COMPANY_LOCATION_SUCCESS].reduce(
    (all, action) => {
      all[action] = () => false;
      return all;
    },
    {}
  )
});
