import createReducer from 'redux/createReducer';
import {
  GET_COMPANY_LOCATIONS_SUCCESS,
  PUT_COMPANY_LOCATIONS_SUCCESS,
  POST_COMPANY_LOCATIONS_SUCCESS,
  DELETE_COMPANY_LOCATIONS_SUCCESS
} from 'ducks/company/companyActionTypes';

export const deleteCompanyLocation = (locations, { payload }) =>
  payload ? locations.filter(({ id }) => id !== payload.id) : locations;

export const setCompanyLocations = (locations, { payload }) =>
  payload ? payload : locations;

export const updateCompanyLocation = (locations, { payload }) =>
  payload
    ? locations.map(
        location => (location.id === payload.id ? payload : location)
      )
    : locations;

export const addCompanyLocation = (locations, { payload }) =>
  payload ? [payload, ...locations] : locations;

export const companyLocations = createReducer([], {
  ...[GET_COMPANY_LOCATIONS_SUCCESS].reduce((actions, action) => {
    actions[action] = setCompanyLocations;
    return actions;
  }, {}),

  ...[PUT_COMPANY_LOCATIONS_SUCCESS].reduce((actions, action) => {
    actions[action] = updateCompanyLocation;
    return actions;
  }, {}),

  ...[POST_COMPANY_LOCATIONS_SUCCESS].reduce((actions, action) => {
    actions[action] = addCompanyLocation;
    return actions;
  }, {}),

  ...[DELETE_COMPANY_LOCATIONS_SUCCESS].reduce((actions, action) => {
    actions[action] = deleteCompanyLocation;
    return actions;
  }, {})
});

export default companyLocations;
