import React from 'react';
import FontAwesome from 'react-fontawesome';
const overlappingTripsMessage =
  'A trip already exists with the same start/end times';
const mapping = {
  'Start time has already been taken End time has already been taken User is invalid': overlappingTripsMessage,
  "Start time has already been taken Start time must not overlap with another trip's start time End time has already been taken End time must not overlap with another trip's end time User is invalid": overlappingTripsMessage
};

const transform = message => mapping[message] || message;

const ValidationError = ({ message }) => {
  return (
    <div className="validation-error">
      <FontAwesome name="exclamation-circle" /> {transform(message)}
    </div>
  );
};
export default ValidationError;
