import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { genericApi } from '../api/client';

function changing_renewal_status() {
  genericApi.post(`/chargebee_sessions_cancel`).then(function() {
    alert("Your auto-renewal has been turned off");
  }) ;
}

class CancelRenewalPage extends Component {
  render() {
    return (
    <div>
      Are you sure you want to cancel auto-renewal?
      <br/>
      <button onClick={changing_renewal_status}>
        Yes, Cancel Renewal
      </button>
      <br/>
      <Link className="return-to-mile-catcher-link" to="/">
      No, return to Milecatcher
      </Link>
    </div>
    )
  }
}

export default CancelRenewalPage; 
