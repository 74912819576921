import _ from 'lodash';
import * as auth from 'lib/auth';
import * as gateways from 'lib/gateways';
import {
  SIGN_IN,
  REDIRECT,
  INVALIDATE,
  SET_MOBILE,
  SUBSCRIPTION_INACTIVE,
  INVALID_EMAIL_OR_PASSWORD
} from './actionTypes';

import { redirectToChargebeePortal, redirectTo } from 'lib/chargebee';
import { isMobileDevice } from 'lib/userAgentCheck';
import { dateHasPassed } from 'lib/timeScopes';
import { postUserSignIn, postUsers } from 'api/endpoints/user/users';
import { showInvalidSubscriptionMessage } from 'ducks/notifications/actions';
import { signInToApp } from './lib/signIntoApp';

import {
  MILE_CATCHER_SUBSCRIBE_URL,
  UNABLE_TO_MATCH_CREDENTIALS
} from './constants';
import {setSpeedGaugeRecordId} from "lib/auth";

export const redirectToSubscribe = () => redirectTo(MILE_CATCHER_SUBSCRIBE_URL);
export const routeToSubscription = gateway => {
  const url = gateways[gateway];
  return (gateway === 'chargebee'
    ? redirectToChargebeePortal()
    : url
      ? Promise.resolve(redirectTo(url))
      : Promise.resolve(redirectToSubscribe())
  )
    .then(url => (url ? { type: 'test', url } : accountNotFound()))
    .catch(accountNotFound);
};

export const subscriptionIsActive = status => ({
  type: SUBSCRIPTION_INACTIVE,
  payload: { activeSubscription: status }
});

export const invalidEmailOrPassword = () => ({
  type: INVALID_EMAIL_OR_PASSWORD,
  meta: {
    alert: {
      type: 'error',
      message:
        'Invalid login. Please be sure your email and password are entered correctly.'
    }
  }
});

export const setSignIn = (
  authToken,
  userId,
  companyId,
  speedGaugeRecordId,
  referralCode,
  role,
  activeSubscription = false
) => {
  auth.setAuthToken(authToken);
  auth.setCompanyId(companyId);
  auth.setUserId(userId);
  auth.setRole(role);
  auth.setSpeedGaugeRecordId(speedGaugeRecordId);
  auth.setReferralCode(referralCode);

  return {
    type: SIGN_IN,
    payload: {
      authToken,
      id: userId,
      companyId,
      referralCode,
      speedGaugeRecordId,
      activeSubscription,
      redirecting: false
    },
    meta: {
      alert: {
        type: 'success',
        message: 'Successfully signed in'
      }
    }
  };
};

export const setRedirect = (authToken, id) => ({
  type: REDIRECT,
  payload: { authToken, id, redirecting: true },
  meta: {
    alert: {
      type: 'success',
      message: 'Redirecting to your subscription.'
    }
  }
});

export const handleSignInError = error => {
  const response = error.response;
  return response && response.status === UNABLE_TO_MATCH_CREDENTIALS
    ? invalidEmailOrPassword()
    : { type: 'ERROR', payload: error, error };
};

export const subscriptionExpired = (email, password) => dispatch => {
  const redirect = () => dispatch(modifySubscription(email, password));

  dispatch(showInvalidSubscriptionMessage(redirect));

  return dispatch(subscriptionIsActive(false));
};

export const checkExpiration = (email, password, userData) => dispatch => {
  const { authToken, id, subscription, companyId, speedGaugeRecordId } = userData || {};
  const expired = dateHasPassed(subscription && subscription.expiresOn);
  return expired
    ? Promise.reject(dispatch(subscriptionExpired(email, password, expired)))
    : Promise.resolve({ authToken, id, expired, companyId, speedGaugeRecordId });
};

export const userSignIn = (email, password) => dispatch =>
  dispatch(signInToApp(email, password, postUserSignIn, 'user'))
    .then(user => dispatch(checkExpiration(email, password, user)))
    .then(({ authToken, id, companyId, speedGaugeRecordId, referralCode, expired, role }) =>
      dispatch(setSignIn(authToken, id, companyId, speedGaugeRecordId, referralCode, role, expired))
    )
    .catch(() => null);

export const setSessionCredentials = ({
  authToken,
  companyId,
  speedGaugeRecordId,
  id,
  role
}) => dispatch => dispatch(setSignIn(authToken, id, companyId, speedGaugeRecordId, role));

export const getUserByLogin = (email, password) =>
  postUserSignIn(email, password).then(({ data }) => _.get(data, 'user', {}));

export const signUp = params => dispatch => {
  return postUsers(params).then(({ data }) =>
    dispatch(setSessionCredentials(data.user))
  );
};

export const signInToSubscription = (email, password) => dispatch => {
  return postUserSignIn(email, password)
    .then(response => {
      const { authToken, id, subscription } = _.get(response, 'data.user', {});
      dispatch(setRedirect(authToken, id));
      return subscription && subscription.gateway;
    })
    .catch(error => dispatch(handleSignInError(error)));
};

export const modifySubscription = (email, password) => dispatch =>
  dispatch(signInToSubscription(email, password)).then(gateway =>
    routeToSubscription(gateway)
      .then(dispatch)
      .then(() => dispatch(invalidate()))
  );

export const detectUserDevice = () => dispatch =>
  dispatch(usingMobileDevice(isMobileDevice()));

// invalidate() and signOut() are pretty much the same two locationActions, except that
// one happens silently and the other triggers a success message.

export const invalidate = () => {
  auth.clearLocalStorage();
  return { type: INVALIDATE, payload: { redirecting: false } };
};

export const signOut = () => ({
  ...invalidate(),
  alert: {
    type: 'info',
    message: 'successfully signed out'
  }
});

export const usingMobileDevice = isMobile => ({
  type: SET_MOBILE,
  payload: { isMobile }
});

export const accountNotFound = () => {
  auth.clearLocalStorage();
  return {
    type: INVALIDATE,
    payload: { redirecting: true }
  };
};
