import { connect } from 'react-redux';
import ReportsPage from './ViewReportsPage';
import {
  mapStateToReportPage,
  mapDispatchToReportPage
} from 'components/CompanyPages/Admin/ReportsPage/ViewReportsPage/viewReportsPageMappings';

export default connect(
  mapStateToReportPage,
  mapDispatchToReportPage
)(ReportsPage);
