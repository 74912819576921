import { NAME as nameSpace } from './constants';

export const POST_KPI_REPORT_REQUEST = `${nameSpace}/POST_KPI_REPORT_REQUEST`;
export const POST_KPI_REPORT_SUCCESS = `${nameSpace}/POST_KPI_REPORT_SUCCESS`;
export const POST_KPI_REPORT_FAILURE = `${nameSpace}/POST_KPI_REPORT_FAILURE`;

export const PUT_KPI_REPORT_REQUEST = `${nameSpace}/PUT_KPI_REPORT_REQUEST`;
export const PUT_KPI_REPORT_SUCCESS = `${nameSpace}/PUT_KPI_REPORT_SUCCESS`;
export const PUT_KPI_REPORT_FAILURE = `${nameSpace}/PUT_KPI_REPORT_FAILURE`;

export const DELETE_KPI_REPORT_REQUEST = `${nameSpace}/DELETE_KPI_REPORT_REQUEST`;
export const DELETE_KPI_REPORT_SUCCESS = `${nameSpace}/DELETE_KPI_REPORT_SUCCESS`;
export const DELETE_KPI_REPORT_FAILURE = `${nameSpace}/DELETE_KPI_REPORT_FAILURE`;

export const GET_KPI_REPORT_REQUEST = `${nameSpace}/GET_KPI_REPORT_REQUEST`;
export const GET_KPI_REPORT_SUCCESS = `${nameSpace}/GET_KPI_REPORT_SUCCESS`;
export const GET_KPI_REPORT_FAILURE = `${nameSpace}/GET_KPI_REPORT_FAILURE`;
