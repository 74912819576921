import {
  mapStateToCompanyAccountPage,
  mapDispatchToCompanyAccountPage
} from './companyAccountMappings';
import CompanyAccountPage from './CompanyAccountPage';
import { connect } from 'react-redux';

export default connect(
  mapStateToCompanyAccountPage,
  mapDispatchToCompanyAccountPage
)(CompanyAccountPage);
