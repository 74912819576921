import React, { Component } from 'react';
import TripMap from 'components/TripMap/components/TripMap';
import ExpensedTrip from './ExpensedTrip';
import Filters from 'components/CompanyPages/lib/Filters';
import Paginator from 'components/Paginator';
import Checkbox from 'components/Checkbox';
import RenderPage from 'lib/RenderPage';
import './ManagerPage.css';

export default class ManagerPage extends Component {
  constructor(props) {
    const { setTripsPageSize } = props;

    super(props);

    this.filterTrips = this.filterTrips.bind(this);
    this.loadTrips = this.loadTrips.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.onSelect = this.onSelect.bind(this);

    this.state = {
      selectedTrip: {},
      params: {},
      pageNumber: 1
    };

    setTripsPageSize && setTripsPageSize(15);
  }

  loadTrips(pageNumber) {
    const { getCompanyTripsPage } = this.props;
    const { params } = this.state;

    getCompanyTripsPage &&
      getCompanyTripsPage({
        page: pageNumber,
        ...params
      });

    this.setState({ pageNumber });
  }

  filterTrips(filters) {
    const { pageNumber, params } = this.state;
    const { getCompanyTripsPage } = this.props;

    getCompanyTripsPage &&
      getCompanyTripsPage({
        ...params,
        ...filters,
        page: pageNumber
      });

    this.setState({ params: filters });
  }

  componentWillMount() {
    const {
      getCompanyTripsPage,
      loadMembers,
      loadLocations,
      loadTeams,
      loadPurposes,
      loadVehicles
    } = this.props;

    [
      () => getCompanyTripsPage({ page: 1 }),
      loadMembers,
      loadLocations,
      loadPurposes,
      loadTeams,
      loadVehicles
    ].forEach(loadResource => loadResource && loadResource());
  }

  onSelect(index) {
    const { trips } = this.props;
    const selectedTrip = trips && trips[index];

    this.setState({ selectedTrip });
  }

  toggleSelectAll() {
    const { trips, toggleSelectAll, allSelected } = this.props;
    const tripIds = trips.map(({ id }) => id);

    toggleSelectAll && toggleSelectAll(!allSelected && tripIds);
  }

  render() {
    const { selectedTrip } = this.state;

    const {
      trips,
      filters,
      unitOfMeasure,
      requestsState,
      total,
      pageNumber,
      pageSize,
      allSelected,
      toggleSelection,
      isSelected,
      amountSelected
    } = this.props;

    return (
      <div className="admin-page-content">
        <div className="manager-page">
          <Filters onChange={this.filterTrips} {...filters} />
          <div className="manager-page-header-spacing">
            <div className="manager-page-select-all-check-box">
              <Checkbox
                label="SELECT ALL"
                onChange={this.toggleSelectAll}
                checked={allSelected}
              />
            </div>
            {!isNaN(total) && (
              <h1 className="manager-page-amount-selected">
                {amountSelected
                  ? `${amountSelected} out of ${total} selected`
                  : `${total} Trips`}
              </h1>
            )}
          </div>
          <div className="manager-page-body">
            <div className="expensed-trips">
              <RenderPage requestsState={requestsState}>
                <Paginator
                  getPage={this.loadTrips}
                  numberOfElements={total}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                >
                  {trips
                    ? trips.map((trip, index) => (
                        <ExpensedTrip
                          id={index}
                          unitOfMeasure={unitOfMeasure}
                          onClick={this.onSelect}
                          onCheck={toggleSelection}
                          checked={isSelected(trip.id)}
                          trip={trip}
                        />
                      ))
                    : []}
                </Paginator>
              </RenderPage>
            </div>
            <TripMap
              className="manager-page-map"
              polyline={selectedTrip && selectedTrip.polyline}
            />
          </div>
        </div>
      </div>
    );
  }
}
