import {
  GET_WORK_HOURS_FAILURE,
  GET_WORK_HOURS_REQUEST,
  GET_WORK_HOURS_SUCCESS
} from 'ducks/workHours/actionTypes';
import { createRequestsReducer } from 'redux/requestReducer';
import {
  GET_TEAMS_FAILURE,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS
} from 'ducks/teams/teamActionTypes';

export default createRequestsReducer([
  [GET_WORK_HOURS_REQUEST, GET_WORK_HOURS_SUCCESS, GET_WORK_HOURS_FAILURE],
  [GET_TEAMS_REQUEST, GET_TEAMS_SUCCESS, GET_TEAMS_FAILURE]
]);
