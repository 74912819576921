import React, { Component } from 'react';
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc';
import AddPurposeForm from '../AddPurposeForm';
import PurposeCard from './PurposeCard';

import './PurposesList.css';

const PurposesElement = SortableElement(({ purpose, destroy, metric }) => (
  <div className="purposes-list__item">
    <PurposeCard purpose={purpose} destroy={destroy} metric={metric} />
  </div>
));

const PurposesContainer = SortableContainer(({ purposes, destroy, metric }) => (
  <div className="purposes-list__items">
    {console.log('purposes', purposes)}
    {purposes.map(purpose => (
      <PurposesElement
        key={purpose.id}
        index={purpose.index}
        purpose={purpose}
        destroy={destroy}
        metric={metric}
      />
    ))}
  </div>
));

class PurposesList extends Component {
  constructor(props) {
    super(props);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { purposes, reorder } = this.props;
    const indexes = arrayMove(purposes, oldIndex, newIndex)
      .map((purpose, index) => ({ [purpose.id]: index }))
      .reduce((map, item) => ({ ...map, ...item }), {});
    reorder(indexes);
  }

  handleSubmit(purpose) {
    const { create, format } = this.props;
    return create(format(purpose));
  }

  render() {
    const { purposes, destroy, metric } = this.props;
    return (
      <div className="purposes-list">
        <AddPurposeForm metric={metric} onSubmit={this.handleSubmit} />
        <PurposesContainer
          purposes={purposes}
          onSortEnd={this.onSortEnd}
          transitionDuration={0}
          lockAxis="y"
          destroy={destroy}
          useDragHandle
          metric={metric}
        />
      </div>
    );
  }
}
export default PurposesList;
