import _ from 'lodash';
import { normalizeArray } from 'lib/utils';
import createReducer from 'redux/createReducer';

/* items by id reducer */
export const getItems = action =>
  _.isArray(action.payload)
    ? action.payload
    : _.get(action, 'payload.items', []);

export const setAll = (state, action) => ({
  ...state,
  ...normalizeArray(getItems(action))
});
export const setItem = (state, { payload }) => ({
  ...state,
  [payload.id]: payload
});
export const removeItem = (state, { payload }) => _.omit(state, payload.id);
export const appendItems = (state, action) => ({
  ...state,
  ...normalizeArray(getItems(action))
});
export const appendItem = (state, { payload }) => ({
  ...state,
  [payload.id]: payload
});

export const createActions = (actions, action) =>
  actions &&
  actions.reduce &&
  actions.reduce((obj, actionType) => ({ [actionType]: action, ...obj }), {});

export const passThrough = state => state;
export const getById = (getItems = passThrough) => (state, id) =>
  getItems(state)[id];
export const getByUserId = (getItems = passThrough) => (state, userId) =>
  _.values(getItems(state)).filter(item => item.userId === userId);

export const itemActions = {
  setAll,
  setItem,
  getItems,
  removeItem,
  appendItem,
  appendItems
};

export const itemSelectors = {
  getById,
  getByUserId
};

export const createItemActions = (actionTypes, actions = itemActions) =>
  Object.keys(actionTypes).reduce(
    (obj, actionType) => ({
      ...createActions(actionTypes[actionType], actions[actionType]),
      ...obj
    }),
    {}
  );

export const createItemsReducer = (actionTypes = {}, actions = itemActions) =>
  createReducer({}, createItemActions(actionTypes, actions));

export const createItemsSelectors = (getItems, getters = itemSelectors) =>
  Object.keys(getters).reduce(
    (selectors, selector) => ({
      [selector]: getters[selector](getItems),
      ...selectors
    }),
    {}
  );
