import getStartingPoint from 'lib/polyline/getStartingPoint';
import getEndPoint from 'lib/polyline/getEndPoint';
import moment from 'moment';

export function matchCoordinates([a, b], { lat, lon }) {
  return a === lat && b === lon;
}

export const matchLocationOrAddress = (location, address) => comparison => {
  return (
    matchCoordinates(location, comparison) || comparison.address === address
  );
};

export const filterLocationsByDate = (locations, date) =>
  locations &&
  locations.filter(location =>
    moment(location.appliedAt).isSameOrBefore(moment(date))
  );

export const getLocationsForTrip = (
  locations,
  { polyline, departureAddress, destinationAddress, createdAt }
) => {
  const filteredLocations = filterLocationsByDate(locations, createdAt);
  const start = getStartingPoint(polyline);
  const end = getEndPoint(polyline);

  const startLocation = filteredLocations.find(
    matchLocationOrAddress(start, departureAddress)
  );

  const endLocation = filteredLocations.find(
    matchLocationOrAddress(end, destinationAddress)
  );

  return { startLocation, endLocation };
};
