import getTimeFromWorkHour from 'lib/getTimeFromWorkHour';

export const formatWorkHourRule = ({ to, from }) => ({
  to: getTimeFromWorkHour(to),
  from: getTimeFromWorkHour(from)
});

export const formatWorkHourRules = rules =>
  rules && rules.map(formatWorkHourRule);

export default formatWorkHourRules;
