import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { reduxForm, Field } from 'redux-form';
import PreLoginForm from 'components/PreLogin/PreLoginForm';
import PreLoginTextBox from 'components/PreLogin/PreLoginTextbox';
import PreLoginButton from 'components/PreLogin/PreLoginButton';
import transformError from 'lib/transformError';
import PreLoginBox from 'components/PreLogin/PreLoginBox';
import './ConfirmInvitation.css';

const renderField = ({ input, meta: _, ...props }) => (
  <PreLoginTextBox {...input} {...props} />
);

export default reduxForm({
  form: 'confirmInvitation'
})(
  withRouter(
    class CompanySignIn extends Component {
      constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.setPasswordConfirmation = this.setPasswordConfirmation.bind(this);
        this.setName = this.setName.bind(this);
        this.getUuid = this.getUuid.bind(this);
        this.state = {
          password: '',
          confirmation: '',
          name: ''
        };
      }

      onSubmit({ name, password, passwordConfirmation }) {
        const { confirmInvitation, history } = this.props;
        const uuid = this.getUuid();

        confirmInvitation &&
          confirmInvitation(uuid, name, password, passwordConfirmation)
            .then(() => history.push('/sign_in'))
            .catch(() => history.push('/invitation/invalid'));
      }

      setPassword({ target }) {
        this.setState({ password: target.value });
      }

      setPasswordConfirmation({ target }) {
        this.setState({ confirmation: target.value });
      }

      setName({ target }) {
        this.setState({ name: target.value });
      }

      getUuid() {
        const splitUrl = window.location.href.split('/');

        return splitUrl[splitUrl.length - 1];
      }

      render() {
        const { handleSubmit, submitting, error } = this.props;
        const { password, confirmation, name } = this.state;
        const disabled =
          submitting || !(password.length && password === confirmation);

        return (
          <PreLoginBox>
            <div className="confirm-invite">
              <PreLoginForm
                onSubmit={handleSubmit(this.onSubmit)}
                error={transformError(error, 'confirmInvitation')}
              >
                <div className="confirm-invite-title">
                  Please enter your name and a password to accept your
                  company's invitation.
                </div>
                <Field
                  className="confirm-invite-input"
                  type="text"
                  name="name"
                  placeHolder="Name"
                  component={renderField}
                  value={name}
                  onChange={this.setName}
                />
                <Field
                  className="confirm-invite-input"
                  type="password"
                  name="password"
                  placeHolder="Password"
                  component={renderField}
                  value={password}
                  onChange={this.setPassword}
                />
                <Field
                  className="confirm-invite-input"
                  name="passwordConfirmation"
                  type="password"
                  placeHolder="Confirm Password"
                  component={renderField}
                  value={confirmation}
                  onChange={this.setPasswordConfirmation}
                />
                <PreLoginButton
                  disabled={disabled}
                  className={
                    disabled
                      ? 'disabled-confirm-invite-button'
                      : 'confirm-invite-button'
                  }
                >
                  {submitting ? (
                    <span>Confirming&hellip;</span>
                  ) : (
                    'Accept invitation'
                  )}
                </PreLoginButton>
              </PreLoginForm>
            </div>
          </PreLoginBox>
        );
      }
    }
  )
);
