import createSimpleAction from 'ducks/lib/simpleActionCreator';
import { putUpdateWorkHoursForTeam } from 'api/endpoints/company/teams';
import {
  UPDATE_TEAM_WORK_HOURS_FAILURE,
  UPDATE_TEAM_WORK_HOURS_REQUEST,
  GET_WORK_HOURS_SUCCESS
} from 'ducks/workHours/actionTypes';
import addSuccessAndFailureMessages from '../../lib/addSuccessAndFailureMessages';
import { getTeam } from 'ducks/teams/teamsSelectors/getTeam';

export const updateTeamWorkHours = (...data) => (dispatch, getState) => {
  const [teamId] = data;
  const team = getTeam(getState(), teamId);
  const teamName = (team && team.name) || 'team';

  return dispatch(
    addSuccessAndFailureMessages(
      createSimpleAction(
        putUpdateWorkHoursForTeam,
        [
          UPDATE_TEAM_WORK_HOURS_REQUEST,
          GET_WORK_HOURS_SUCCESS,
          UPDATE_TEAM_WORK_HOURS_FAILURE
        ],
        'workHours'
      ),
      `Work hours updated for ${teamName}`,
      `Unable to update work hours for ${teamName}`
    )(...data)
  );
};

export default updateTeamWorkHours;
