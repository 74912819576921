import HorizontalBarChart from 'components/CompanyPages/lib/HorizontalBarChart';
import Money from 'components/Money';
import React from 'react';
import classNames from 'class-names';
import './TopFiveExpensesBarChart.css';

export const TopFiveExpensesBarChart = ({ className, ...data }) => {
  return (
    <div
      className={classNames('top-five-expenses-bar-chart', {
        className: className
      })}
    >
      <HorizontalBarChart {...data} axis={false} />
      <div className="top-five-expenses-bar-chart-labels">
        {data &&
          data.data &&
          data.data.map(({ title, value, percentage }) => (
            <div className="top-five-expenses-bar-chart-label">
              <h1>{title}</h1>
              <h1>
                <Money>{value}</Money> ({percentage}
                %)
              </h1>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TopFiveExpensesBarChart;
