import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { postCompanyBulkInvitations } from 'api/endpoints/company/invitations';
import addSuccessAndFailureMessages from 'ducks/lib/addSuccessAndFailureMessages';
import {
  COMPANY_INVITE_USERS_IN_BULK_REQUEST,
  COMPANY_INVITE_USERS_IN_BULK_SUCCESS,
  COMPANY_INVITE_USERS_IN_BULK_FAILURE
} from 'ducks/company/companyActionTypes';

export const inviteUsersInBulk = addSuccessAndFailureMessages(
  createSimpleAction(postCompanyBulkInvitations, [
    COMPANY_INVITE_USERS_IN_BULK_REQUEST,
    COMPANY_INVITE_USERS_IN_BULK_SUCCESS,
    COMPANY_INVITE_USERS_IN_BULK_FAILURE
  ]),
  'Invitations sent',
  'Unable to complete invitations'
);

export default inviteUsersInBulk;
