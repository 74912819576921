import { SET_WORK_HOURS_FOR_DAY } from 'ducks/workHours/actionTypes';
import getCompanyWorkDay from 'ducks/workHours/workHourSelectors/getCompanyWorkDay';

export const setEnabledOnWorkDay = payload => ({
  type: SET_WORK_HOURS_FOR_DAY,
  payload
});

export const setDayEnabled = (dayIndex, enabled) => (dispatch, getState) => {
  const workDay = getCompanyWorkDay(getState(), dayIndex);
  if (workDay) {
    return dispatch(setEnabledOnWorkDay({ ...workDay, enabled }));
  }
};

export default setDayEnabled;
