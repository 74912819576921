import React from 'react';
import classNames from 'class-names';

export default function({ selection, onClick, children, key }) {
  const className = 'dropdown__item';
  return (
    <div
      key={key}
      className={classNames(className, {
        dropdown__item_selected: selection.selected
      })}
      onClick={() => onClick(selection)}
    >
      {children}
    </div>
  );
}
