import { connect } from 'react-redux';
import SettingsPage from './SettingsPage';
import {
  mapStateToSettingsPage,
  mapDispatchToSettingsPage
} from './settingsPageMappings';

export default connect(
  mapStateToSettingsPage,
  mapDispatchToSettingsPage
)(SettingsPage);
