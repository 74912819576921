import { getCompanyMembers as loadUsers } from 'ducks/company/companyActions/users/getCompanyMembers';
import { getTeams as loadTeams } from 'ducks/teams/teamsActions/getTeams';
import { getTeams as getTeamsFromStore } from 'ducks/teams/teamsSelectors/getTeams';
import { updateTeam } from 'ducks/teams/teamsActions/updateTeam';
import { getCompanyCostCenters as loadCostCenters } from 'ducks/costCenters/costCentersActions/getCompanyCostCenters';
import userIsAdmin from 'ducks/users/usersSelectors/userIsAdmin';
import getCompanyMembers from 'ducks/company/companySelectors/companyMembers/getCompanyMembers';
import getCompanyTeamsPageRequests from 'ducks/company/companySelectors/companyRequests/getTeamsPageRequests';

export const mapStateToTeamsPage = state => ({
  requestsState: getCompanyTeamsPageRequests(state),
  isAdmin: userIsAdmin(state),
  members: getCompanyMembers(state),
  teams: getTeamsFromStore(state)
});

export const mapDispatchToTeamsPage = {
  loadTeams,
  loadUsers,
  loadCostCenters,
  updateTeam
};
