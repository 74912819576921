import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { getCompanyObject } from 'api/endpoints/company/company';
import {
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE
} from '../companyActionTypes';

export const getCompany = createSimpleAction(
  getCompanyObject,
  [GET_COMPANY_REQUEST, GET_COMPANY_SUCCESS, GET_COMPANY_FAILURE],
  'company'
);

export default getCompany;
