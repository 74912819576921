import { createRequestSelectors } from 'redux/requestsByIdReducer';
import { dateHasPassed } from 'lib/timeScopes';
import session from 'ducks/session';
import { NAME } from './constants';

const getState = state => state[NAME];

const getRequests = state => getState(state).requests;

const getUsers = state => {
  const currentState = getState(state);
  return currentState ? currentState.items : currentState;
};

export const getCurrentId = state => session.selectors.getId(state);

export const getActiveId = state =>
  getState(state).activeId || getCurrentId(state);

/* requests */
export const { getRequestStateById, getRequestById } = createRequestSelectors(
  getRequests
);

/* users */
export const getCustomBusinessRate = state => {
  const user = getCurrentUser(state);
  return user && user.customBusinessRate;
};

export const getMileageRanges = state => {
  const customBusinessRate = getCustomBusinessRate(state);
  return customBusinessRate && customBusinessRate.mileageRanges;
};

export const getFirstMileageRange = state => {
  const mileageRanges = getMileageRanges(state);
  return mileageRanges && mileageRanges[0];
};

export const getBusinessRate = state => {
  const firstMileageRange = getFirstMileageRange(state);
  return firstMileageRange && firstMileageRange.rate;
};

const getUserById = (state, id) => {
  const users = getUsers(state);
  return users ? users[id] : users;
};

export const getCurrentUser = state => getUserById(state, getCurrentId(state));

export const getActive = state => getUserById(state, getActiveId(state));

export const getCurrentUserSettings = state => {
  const user = getCurrentUser(state);
  return user && user.settings;
};

export const getMetricMeasure = state => {
  const settings = getCurrentUserSettings(state);
  return settings ? settings.metric : settings;
};

export const getDistanceMeasure = state =>
  getMetricMeasure(state) ? 'km' : 'mi';

export const getAdministratedUsers = state => {
  const user = getCurrentUser(state);
  const accessToUsers = user && user.accessToUsers;
  return accessToUsers || [];
};

export const getSubscription = state => {
  const user = getCurrentUser(state);
  return user && user.subscription;
};

export const getSubscriptionType = state => {
  const subscription = getSubscription(state);
  return subscription && subscription.status;
};

export const getExpiration = state => {
  const subscription = getSubscription(state);
  return subscription && subscription.expiresOn;
};

export const subscriptionIsExpired = state => {
  const expiration = getExpiration(state);
  return !expiration || dateHasPassed(expiration);
};

export const getUsersLocations = state => {
  const user = getCurrentUser(state);
  const locations = user && user.locations;
  return locations || [];
};

export const getUsersLocationCoordinates = state => {
  return getUsersLocations(state).map(({ lat, lon }) => [lat, lon]);
};
export const getSelectedLocation = state => getState(state).selectedLocation;

export const getSelectedLocationId = state => {
  const selected = getSelectedLocation(state);
  return selected ? selected.id : null;
};

export const getSelectedLocationCoordinates = state => {
  const location = getSelectedLocation(state);
  return location ? [location.lat, location.lon] : null;
};
