import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import purposes from 'ducks/purposes';
import users from 'ducks/users';
import PurposesList from './PurposesList';
import formatPurpose from 'components/SettingsPage/lib/formatPurpose';

const mapStateToProps = state => {
  const user = users.selectors.getCurrentUser(state);
  return {
    purposes: purposes.selectors.getAllForSettings(state),
    format: purpose => formatPurpose(purpose, state),
    metric: user && user.settings.metric
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(purposes.actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PurposesList);
