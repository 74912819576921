import React from 'react';
import { requestState, ABSENT, LOADED, LOADING, ERROR } from 'lib/requests';
import ContentWrap from 'components/ContentWrap';
import LoadingPage from 'components/LoadingPage';
import ErrorPage from 'components/ErrorPage';

const Async = ({ request, state, withWrapper, item, firstLoad, children }) => {
  if (state === undefined) state = requestState(request);
  if (firstLoad && request) {
    if (!item && request.error) {
      state = ERROR;
    } else if (!item && request.loading) {
      state = LOADING;
    } else if (!item) {
      state = ABSENT;
    } else {
      state = LOADED;
    }
  } else if (firstLoad) {
    state = item ? LOADED : LOADING;
  }

  switch (state) {
    case LOADED:
      return children;
    case LOADING:
      return withWrapper ? (
        <ContentWrap>
          <LoadingPage />
        </ContentWrap>
      ) : (
        <LoadingPage />
      );
    default:
      return withWrapper ? (
        <ContentWrap>
          <ErrorPage />
        </ContentWrap>
      ) : (
        <ErrorPage />
      );
  }
};

export default Async;
