import React from 'react';
import classNames from 'class-names';
import ReactSlider from 'react-slider';
import FontAwesome from 'react-fontawesome';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import getTimeFromWorkHour from 'lib/getTimeFromWorkHour';
import './WorkHours.css';
import Scrollbars from '../../Scrollbars';

const formatTime = time => getTimeFromWorkHour(time).format('LT');
const formatRule = rule => `${formatTime(rule.from)} to ${formatTime(rule.to)}`;

const Day = ({
  day,
  setEnabled,
  addRule,
  removeRule,
  setRule,
  previewRule,
  disabled
}) => (
  <div className={classNames('day', { day_disabled: disabled })}>
    <div className="day__enabled">
      <Checkbox
        label={day.day}
        checked={day.enabled}
        onChange={() => setEnabled(!day.enabled)}
      />
    </div>

    <div className="day__rules">
      {day.rules.map((rule, index) => (
        <div className="rule" key={index + day.rules.length * 10}>
          <div className="rule__label">{formatRule(rule)}</div>
          <div className="rule__slider">
            <ReactSlider
              onAfterChange={values => setRule(index, values)}
              onChange={values => previewRule(index, values)}
              value={[rule.from, rule.to]}
              max={48}
              withBars
            />
          </div>
          <div className="rule__controls">
            {day.rules.length > 1 && (
              <Button
                className="rule__remove-button"
                onClick={() => removeRule(index)}
                inline
              >
                <FontAwesome name="minus-circle" fixedWidth />
              </Button>
            )}
            {index === day.rules.length - 1 &&
              day.rules.length < 2 && (
                <Button className="rule__add-button" onClick={addRule} inline>
                  <FontAwesome name="plus-circle" fixedWidth />
                </Button>
              )}
          </div>
        </div>
      ))}
    </div>
  </div>
);

const WorkHours = ({
  workHours,
  setEnabled,
  setDayEnabled,
  addRule,
  removeRule,
  setRule,
  previewRule,
  companyId
}) => {
  return (
    <div
      className={classNames('work-hours', {
        'work-hours_disabled': workHours && !workHours.enabled
      })}
    >
      <div className="work-hours__head">
        <div className="work-hours__enabled">
          <Checkbox
            label="Use Work Hours"
            checked={workHours.enabled}
            onChange={() => setEnabled(!workHours.enabled)}
          />
        </div>
      </div>
      <div className="work-hours-body">
        <Scrollbars>
          {workHours &&
            workHours.days &&
            workHours.days.map(day => (
              <div key={day.day} className="work-hours__day">
                <Day
                  day={day}
                  disabled={!(workHours.enabled && day.enabled)}
                  setEnabled={enabled => setDayEnabled(day.day, enabled)}
                  addRule={() => addRule(day.day)}
                  removeRule={index => removeRule(day.day, index)}
                  setRule={(index, values) => setRule(day.day, index, values)}
                  previewRule={(index, values) =>
                    previewRule(day.day, index, values)
                  }
                />
              </div>
            ))}
        </Scrollbars>
      </div>
    </div>
  );
};
export default WorkHours;
