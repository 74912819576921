import React from 'react';
import ImageContainer from 'components/CompanyPages/lib/ImageContainer';
import Money from 'components/Money';
import './SmallUserDisplay.css';

export const SmallUserDisplay = ({ name, email, expense, image }) => {
  const firstAndLast = name ? name.split(' ') : email.split('@');
  const lastElement = firstAndLast.length - 1;
  const firstName = firstAndLast && firstAndLast[0];
  const lastString = firstAndLast && firstAndLast[lastElement];
  const lastName = name ? lastString : `@${lastString}`;

  return (
    <div className="small-user-display-element">
      <ImageContainer
        className="small-user-display-element-image"
        image={image}
        email={email}
      />
      <div className="small-user-display-element-name">
        <h1>{firstName}</h1>
        <h1>{lastName}</h1>
      </div>
      {expense && (
        <h1 className="small-user-display-element-expense">
          <Money>{expense}</Money>
        </h1>
      )}
    </div>
  );
};

export default SmallUserDisplay;
