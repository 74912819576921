import React from 'react';
import './FilterBar.css';

export default ({ className, children, description, spacing }) => (
  <div className={`${className ? `${className} ` : ''}filter-bar`}>
    <div className="filter-bar-list">
      {description && (
        <div className="filter-bar-element">
          <div className="filter-bar-content">
            <h1>{description}</h1>
          </div>
        </div>
      )}
      {spacing && (
        <div className="filter-bar-spacing filter-bar-element">
          <div className="filter-bar-content" />
          <div className="filter-bar-divider" />
        </div>
      )}
      {children &&
        children.map((filterElement, index) => (
          <div
            key={`${className}-element-${index}`}
            className="filter-bar-element"
          >
            <div className="filter-bar-content">{filterElement}</div>
            <div className="filter-bar-divider" />
          </div>
        ))}
    </div>
  </div>
);
