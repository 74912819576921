import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'class-names';
import './Textbox.css';

export default class TextBox extends Component {
  render() {
    const props = this.props;
    const { autoComplete, className, inputref, normalizeinput, value } = props;

    return (
      <input
        {...props}
        className={classNames('textbox', className)}
        ref={inputref}
        value={normalizeinput ? undefined : value}
        autoComplete={autoComplete || 'off'}
      />
    );
  }

  componentDidUpdate() {
    const { value, type, normalizeinput, normalizer } = this.props;
    const node = ReactDOM.findDOMNode(this);

    if (normalizeinput && (type === 'text' || !type)) {
      let length = node.value.length;
      let position = node.selectionStart;
      node.value = normalizer ? normalizer(value) : value;
      let nextPosition = Math.max(0, node.value.length - length + position);
      node.selectionStart = node.selectionEnd = nextPosition;
    }
  }
}
