import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import classNames from 'class-names';
import users from 'ducks/users';
import { locales } from 'locales.json';
import TextBoxField from 'components/TextBoxField';
import { numeric } from 'lib/normalizers';
import countries from 'ducks/countries';
import { getDistanceMeasure } from '../ducks/users/selectors';
const { getUsersCountry } = countries.selectors;
const { getActive, getCurrentUser } = users.selectors;

class RateField extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
  }

  render() {
    const props = this.props;
    const { className, normalize, distanceMeasure, symbol } = props;

    return (
      <div className={classNames(className, 'input-group')}>
        <div className="input-group__prefix">{symbol}</div>
        <TextBoxField
          className="input-group__input"
          value={this.state.value}
          onChange={({ target }) =>
            this.setState({ value: normalize(target.value) })
          }
          normalizeinput={true}
          {...props}
        />
        <div className="input-group__suffix">/{distanceMeasure}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, { activeUser }) => {
  const user = (activeUser ? getActive : getCurrentUser)(state);
  const distanceMeasure = getDistanceMeasure(state);
  return {
    normalize: value => {
      const country = getUsersCountry(state);
      return country && numeric(country.countryCode, value);
    },
    distanceMeasure: distanceMeasure,
    symbol: user ? _.get(locales[user.countryCode], 'currencySymbol', '$') : '$'
  };
};

export default connect(mapStateToProps)(RateField);
