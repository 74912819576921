import createReducer from 'redux/createReducer';
import { paginateHandlers } from 'ducks/lib/Pagination/paginateHandlers';
import {
  SELECT_ALL,
  SELECT,
  DESELECT_ALL,
  DESELECT,
  TOGGLE_SELECT,
  TOGGLE_SELECT_ALL,
  ADD_PAGE_TO_SELECTION
} from 'ducks/listSelection/selectionActionTypes';
import applyReducerToActions from '../../lib/applyReducerToActions';

export const select = (state, action) => {
  const id = action && action.payload;

  return isNaN(id) ? state : { ...state, [id]: true };
};

export const deselect = (state, action) => {
  const id = action && action.payload;
  const { [`${id}`]: _, ...rest } = state;

  return isNaN(id) ? state : rest;
};

export const clear = (actions, action) => ({
  ...actions,
  [action]: () => ({})
});

export const toggleSelections = (state, action) => {
  const id = action && action.payload;

  return id
    ? state[id]
      ? deselect(state, action)
      : select(state, action)
    : state;
};

export const selectAll = (state, action) => {
  const ids = action && action.payload;
  return ids && ids.length
    ? ids.reduce((all, id) => select(all, { payload: id }), state)
    : {};
};

export const addPage = state => state;

export default createReducer(
  {},
  paginateHandlers(
    {
      [ADD_PAGE_TO_SELECTION]: addPage,
      [SELECT]: select,
      [DESELECT]: deselect,
      [TOGGLE_SELECT]: toggleSelections,
      [DESELECT_ALL]: clear,
      ...applyReducerToActions(selectAll, SELECT_ALL, TOGGLE_SELECT_ALL)
    },
    {}
  )
);
