import { Switch, Route, Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import { trackPage } from 'analytics';
import Settings from './SettingsPage';
import Management from './ManagerPage';
import Statistics from './StatisticsPage';
import Reports from './ReportsPage';
import PlayGround from './playground';
import './AdminPage.css';

export default trackPage('admin')(
  class AdminPage extends Component {
    render() {
      return (
        <div className="admin-content-wrap">
          <div className="admin-page">
            <Switch>
              <Route path="/admin/playground" component={PlayGround} />
              <Route path="/admin/trips" component={Management} />
              <Route path="/admin/settings" component={Settings} />
              <Route path="/admin/statistics" component={Statistics} />
              <Route path="/admin/reports" component={Reports} />
              <Redirect to="/admin/trips" />
            </Switch>
          </div>
        </div>
      );
    }
  }
);
