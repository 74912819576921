import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as requests from 'lib/requests';
import Report from 'components/Report';
import Scrollbars from 'components/Scrollbars';
import LoadingPage from 'components/LoadingPage';
import ErrorPage from 'components/ErrorPage';
import Pagination from 'components/Pagination';
import Checkbox from '../Checkbox/Checkbox';

const noOp = () => null;

class PaginatedReportList extends Component {
  static propTypes = {
    className: PropTypes.string,
    pages: PropTypes.object,
    total: PropTypes.number, // total # of reports on server
    getPage: PropTypes.func,
    nCells: PropTypes.number,
    requestState: PropTypes.oneOf(requests.states).isRequired
  };

  static defaultProps = {
    pages: [],
    total: 0,
    getNextPage: noOp,
    editable: false
  };

  // check if we need  the first page on mount and update
  componentDidMount() {
    this.loadFirstPage();
  }

  componentDidUpdate() {
    this.loadFirstPage();
  }

  loadFirstPage() {
    const { getPage, requestState } = this.props;
    if (requestState === requests.ABSENT) {
      getPage(1);
    }
  }

  render() {
    const {
      className,
      total,
      currentPage,
      nCells,
      requestState,
      companyId,
      selectReport,
      isSelected,
      submitReport
    } = this.props;

    return total === 0 && requestState === requests.LOADED ? (
      <div className="trips-page__no-trips">
        <b>No reports found.</b> Generate a report by selecting a date range
        from the dropdown above.
      </div>
    ) : (
      // You might ask yourself: why are there inline styles here, instead of
      // CSS? The answer: it's been a long day and I don't want to make a whole
      // stylesheet.
      <div
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        className={className}
      >
        <Scrollbars>
          {requestState === requests.LOADING && <LoadingPage />}
          {requestState === requests.ERROR && <ErrorPage />}
          {requestState === requests.LOADED &&
            currentPage.map(id => (
              <Report
                key={id}
                id={id}
                submitReport={submitReport}
                companyId={companyId}
                isChecked={isSelected(id)}
                onCheck={({ target }) => {
                  selectReport && selectReport(id, target.checked);
                }}
              />
            ))}
        </Scrollbars>
        <Pagination nCells={nCells} reports />
      </div>
    );
  }
}

export default PaginatedReportList;
