import React, { Component } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import AutoComplete from 'components/Autocomplete';
import { autoComplete } from '../../../lib/services/googlePlaces';
class AddressInput extends Component {
  static propTypes = {
    location: PropTypes.arrayOf(PropTypes.number).isRequired,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    value: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = { features: [] };
    this.autoComplete = throttle(this.autoComplete.bind(this), 500);
    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  autoComplete(value) {
    const { location } = this.props;
    autoComplete(value, location).then(features => {
      return this.setState({ features });
    });
  }

  onChange(event) {
    const { onChange } = this.props;
    const { value } = event.target;

    if (onChange) {
      onChange(event);
    }

    if (value.length > 3) {
      this.autoComplete(value);
    } else {
      this.setState({ features: [] });
    }
  }

  onSelect(value) {
    const { onSelect } = this.props;
    onSelect(value);
    this.setState({ features: [] });
  }

  onBlur() {
    const { value } = this.props;
    value.length > 10 && this.onSelect(value);
  }

  render() {
    const { onChange: _, location: __, ...props } = this.props;
    const { features } = this.state;
    return (
      <AutoComplete
        items={features}
        onChange={this.onChange}
        onSelect={this.onSelect}
        {...props}
      />
    );
  }
}

export default AddressInput;
