import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Field, propTypes } from 'redux-form';
import * as validators from 'lib/validators';
import moment from 'moment';
import polyUtil from 'polyline-encoded';
import { reduxForm } from 'redux-form';
import { reverseGeocode } from 'lib/services/opencage';
import { route } from 'lib/services/tripRouting';
import ContentWrap from 'components/ContentWrap';
import Panel from 'components/Panel';
import Sidebar from 'components/Sidebar';
import ValidationError from 'components/ValidationError';
import AddTripMap from './AddTripMap';
import AddTripForm from './AddTripForm';
import AddTripDetails from './AddTripDetails';
import getUserLocation from 'lib/getUserLocation';

// coordinates to use for map centering and reverse geocoding until we grab
// the users location from the brower
// NOTE: a better approach might be to fall back on the users ip, previous
// trips, or country but this works for now
const INITIAL_MAP_FOCUS = [47.6062, -122.3321];
const DEFAULT_SPEED = 25;

const combineDateAndTime = (date, time) =>
  moment(date)
    .startOf('day')
    .hour(moment(time).hour())
    .minute(moment(time).minute());

const generateSpeedPoints = polyline =>
  polyline && polyline.length
    ? _.times(polyUtil.decode(polyline).length, _.constant(DEFAULT_SPEED))
    : [];

// hack to validate presence of polyline
const renderPolylineField = ({ input: { value }, ...props }) => (
  <AddTripMap polyline={value} {...props} />
);

class AddTripPage extends Component {
  static propTypes = {
    ...propTypes,
    addTrip: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      startCoords: null,
      endCoords: null,
      lastPlacedWasStart: false,
      mapFocus: INITIAL_MAP_FOCUS
    };
    this.setPoint = this.setPoint.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    getUserLocation().then(location => this.setState({ mapFocus: location }));
  }

  componentDidUpdate(_, prevState) {
    // fetch polyline if we have both sets of coordinates and they've changed
    if (!prevState) return;

    const { startCoords, endCoords } = this.state;
    const { change } = this.props;

    if (
      startCoords &&
      endCoords &&
      (startCoords !== prevState.startCoords ||
        endCoords !== prevState.endCoords)
    ) {
      route([startCoords, endCoords]).then(({ polyline, distance }) => {
        change('polyline', polyline);
        change('distance', distance);
      });
    }
  }

  setPoint(coords, isStartMarker, shouldGeocode) {
    const { change, failedToFindCoordinatesError } = this.props;

    // unless explicit, place the marker opposite the one last placed
    isStartMarker =
      isStartMarker === undefined
        ? !this.state.lastPlacedWasStart
        : isStartMarker;

    if (shouldGeocode) {
      reverseGeocode(coords)
        .then(address =>
          change(
            isStartMarker ? 'departureAddress' : 'destinationAddress',
            address
          )
        )
        .catch(
          () => failedToFindCoordinatesError && failedToFindCoordinatesError()
        );
    }

    this.setState({
      [isStartMarker ? 'startCoords' : 'endCoords']: coords,
      lastPlacedWasStart: isStartMarker
    });
  }

  onSubmit(data) {
    const { addTrip } = this.props;
    const { date, startTime, endTime, ...trip } = data;

    // merge date and times into datetimes
    trip.startTime = combineDateAndTime(date, startTime).format();
    trip.endTime = combineDateAndTime(date, endTime).format();

    // generate arbitrary speed points
    trip.speeds = generateSpeedPoints(trip.polyline);

    return addTrip(trip);
  }

  render() {
    const { handleSubmit, error, submitting, valid } = this.props;
    const { startCoords, endCoords, mapFocus } = this.state;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <ContentWrap>
          <Panel title="Add New Trip" sidebar>
            {error && (
              <div className="add-trip-page__error">
                <ValidationError message={error} />
              </div>
            )}
            <AddTripForm mapFocus={mapFocus} setPoint={this.setPoint} />
            <Field
              name="polyline"
              component={renderPolylineField}
              focus={mapFocus}
              startCoords={startCoords}
              endCoords={endCoords}
              setPoint={this.setPoint}
              validate={validators.required}
            />
          </Panel>
          <Sidebar title="Details">
            <AddTripDetails valid={valid} submitting={submitting} />
          </Sidebar>
        </ContentWrap>
      </form>
    );
  }
}

export default reduxForm({
  form: 'add-trip',
  initialValues: {
    date: moment()
      .startOf('day')
      .subtract(1, 'day')
      .format(),
    startTime: moment()
      .startOf('day')
      .hour(10)
      .format(),
    endTime: moment()
      .startOf('day')
      .hour(11)
      .format(),
    manuallyCreated: true
  }
})(AddTripPage);
