import React from 'react';
import { Link } from 'react-router-dom';
import { trackPage } from 'analytics';
import PreloginBox, {
  PreloginBoxTitle,
  PreloginBoxFoot
} from 'components/PreLogin/PreLoginBox';

import SignUpFormContainer from './SignUpFormContainer';
const SignUpPage = ({ match: { params }, location: { search } }) => (
  <div className="sign-up-page">
    <PreloginBox>
      <PreloginBoxTitle>Sign Up</PreloginBoxTitle>
      <SignUpFormContainer r={search}/>
    </PreloginBox>
    <PreloginBoxFoot>
      Already have an account? <Link to="/sign_in">Sign in here</Link>.
    </PreloginBoxFoot>
  </div>
);

export default trackPage('sign up')(SignUpPage);
