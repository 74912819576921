import { Switch, Route, Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import { trackPage } from 'analytics';
import Reports from './ViewReportsPage';
import GenerateReportsPage from './GenerateReportsPage';

export default trackPage('admin')(
  class AdminPage extends Component {
    render() {
      return (
        <Switch>
          <Route
            path="/admin/reports/generate"
            component={GenerateReportsPage}
          />
          <Route path="/admin/reports/view" component={Reports} />
          <Redirect to="/admin/reports/view" />
        </Switch>
      );
    }
  }
);
