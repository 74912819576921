import { getTrips } from '../selectors';
export const getAllNotes = state => {
  const trips = getTrips(state);
  return Object.keys(
    Object.keys(trips).reduce((notes, tripId) => {
      const { notes: note } = trips[tripId];
      if (note) {
        notes[note] = note;
      }
      return notes;
    }, {})
  );
};
