import { connect } from 'react-redux';
import TopFiveExpensesBarChart from './TopFiveExpensesBarChart';
import {
  mapStateToTopFiveExpensesBarChart,
  mapDispatchToTopFiveExpensesBarChart
} from './topFiveExpensesBarChartMappings';

export default connect(
  mapStateToTopFiveExpensesBarChart,
  mapDispatchToTopFiveExpensesBarChart
)(TopFiveExpensesBarChart);
