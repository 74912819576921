import React, { Component } from 'react';
import DropDownList from './DropDownList';
import classNames from 'class-names';
import { Scrollbars } from 'react-custom-scrollbars';
import FontAwesome from 'react-fontawesome';
import defaults from './dropDownDefaultInputs';
import './DropDown.css';

export default class DropDownControl extends Component {
  constructor(props) {
    const { update, selections, multipleSelections } = props;
    super(props);
    this.state = { active: false };
    this.possibleSelections = ['selections', 'multipleSelections'];
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    if (update && selections && multipleSelections) {
      update(selections.concat(multipleSelections));
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, true);
  }

  handleOutsideClick(event) {
    if (
      this.state.active &&
      (!this.node || !this.node.contains(event.target))
    ) {
      this.setState({ active: false });
    }
  }

  toggle() {
    this.setState({ active: !this.state.active });
  }

  clearAllSelections() {
    const props = this.props;
    const { update } = props;
    this.possibleSelections.forEach(selectionType => {
      const selections = props[selectionType];
      selections.forEach(selection => {
        selection.selected = false;
      });
      update(selections);
    });
  }

  select(selection) {
    const { onSelect, update, selections } = this.props;
    if (selections.indexOf(selection) > -1) {
      this.clearAllSelections();
      this.setState({ active: false });
    }
    selection.selected = true;
    update([selection]);
    onSelect && onSelect();
    return selection;
  }

  render() {
    const props = this.props;
    const { active } = this.state;
    const {
      disabled,
      children,
      icon,
      className,
      preLogin,
      selections,
      multipleSelections
    } = props;
    const options = (
      <DropDownList
        onClick={this.select}
        {...{ selections, multipleSelections }}
      />
    );
    return (
      <div
        className={classNames('dropdown', className, {
          dropdown_disabled: disabled,
          dropdown_open: active,
          dropdown_prelogin: preLogin
        })}
        ref={node => (this.node = node)}
      >
        <button
          type="button"
          className={classNames('dropdown__button', {
            dropdown__button_open: active
          })}
          aria-haspopup="true"
          aria-expanded={active.toString()}
          onClick={this.toggle}
        >
          {icon && (
            <FontAwesome className="dropdown__button__icon" name={icon} />
          )}
          <span className="dropdown__button__inner">{children}</span>
        </button>
        <div
          className={classNames('dropdown__menu', {
            dropdown__menu_open: active
          })}
        >
          {preLogin ? <Scrollbars>{options}</Scrollbars> : options}
        </div>
      </div>
    );
  }
}

DropDownControl.defaultProps = defaults;
