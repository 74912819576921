import { getCompanyStore } from 'ducks/company/companySelectors/getCompanyStore';
import { createRequestsSelector } from 'redux/requestReducer';

export const getCompanyStatisticsPageRequests = createRequestsSelector(
  state => {
    const companyStore = getCompanyStore(state);
    return companyStore && companyStore.statisticsPageRequests;
  }
);

export default getCompanyStatisticsPageRequests;
