import axios from 'axios';
import { underscoreKeys, camelizeKeys } from 'lib/utils';
import { getAuthToken, getCompanyId, getUserId } from 'lib/auth';
import { userCanViewAdminPages } from 'ducks/users/usersSelectors/userCanViewAdminPages';
import { SubmissionError } from 'redux-form';
import session from 'ducks/session';
import notifications from 'ducks/notifications';
import { getActiveId } from '../ducks/users/selectors';

let store;

const API_VERSION = 'v1';

const addUserIds = configuration => {
  const loggedInUserId = getUserId();
  const activeId = getActiveId(store.getState());
  const params = configuration && configuration.params;
  const userId = params && params.userId;
  const config = {
    ...configuration,
    params: {
      ...params,
      ...(activeId !== loggedInUserId && {
        impersonatedId: activeId
      })
    }
  };

  return !userCanViewAdminPages() && Number(loggedInUserId) === Number(userId)
    ? {
        ...config,
        params: {
          ...params,
          userId: undefined
        }
      }
    : config;
};

const configuration = {
  params: { client: 'portal' },
  baseURL: `${process.env.REACT_APP_API_BASE}/${API_VERSION}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },

  // translate data from snake_case to camelCase
  transformRequest: [underscoreKeys, JSON.stringify],

  transformResponse: [
    data => {
      try {
        return JSON.parse(data);
      } catch (e) {
        return data;
      }
    },
    camelizeKeys
  ]
};

const mergeConfig = (config = {}) => {
  return Object.keys(config).reduce(
    (combinedConfigurations, parameterName) => {
      combinedConfigurations[parameterName] = {
        ...combinedConfigurations[parameterName],
        ...config[parameterName]
      };
      return combinedConfigurations;
    },
    { ...configuration }
  );
};

const transformParameters = config => {
  const parameters = config && config.params;
  return parameters
    ? {
        ...config,
        params: underscoreKeys(parameters)
      }
    : config;
};

const showError = message => {
  if (store) store.dispatch(notifications.actions.notify('error', message));
};

export const instance = axios.create(configuration);

instance.interceptors.request.use(config => {
  if (store) {
    const state = store.getState();
    const authToken = getAuthToken(state);
    if (authToken) config.headers['Authorization'] = authToken;
  }
  return transformParameters(addUserIds(config));
});

// error request if response includes "errors"
instance.interceptors.response.use(
  response => {
    if (response.data && response.data.errors) {
      throw new SubmissionError({ _error: response.data.errors.join(' ') });
    }
    return response;
  },
  error => {
    if (!error.response) {
      showError(
        "Unable to reach MileCatcher's servers. Check your network connection, or try again later."
      );
    } else if (error.response && error.response.status >= 500) {
      showError('Server error! The MileCatcher team has been notified.');
    } else if (error.response && error.response.status === 401) {
      if (store) store.dispatch(session.actions.invalidate());
    } else if (error.response.data && error.response.data.errors) {
      throw new SubmissionError({
        _error: error.response.data.errors.join(' ')
      });
    }
    return Promise.reject(error);
  }
);

export const transformConfig = config =>
  mergeConfig(transformParameters(config));

export const client = ['get', 'delete', 'head'].reduce((wrapper, action) => {
  const method = instance[action];
  wrapper[action] = (path, config) => {
    return method && method(path, transformConfig(config));
  };
  return wrapper;
}, instance);

export const companyApi = ['put', 'post', 'get', 'delete'].reduce(
  (company, action) => {
    company[action] = (path = '', data, config) =>
      client[action](
        `/company/${getCompanyId()}${path}`,
        data,
        mergeConfig(transformParameters(config))
      );
    return company;
  },
  { ...client }
);

export const genericApi = ['put', 'post', 'get', 'delete'].reduce(
  (company, action) => {
    company[action] = (path = '', data, config) =>
      client[action](
        path,
        data,
        mergeConfig(transformParameters(config))
      );
    return company;
  },
  { ...client }
);


export const setStore = store_ => {
  store = store_;
};

export default client;
