import { resendInvitation } from 'ducks/company/companyActions/invitations/resendInvitation';
import { cancelInvitation } from 'ducks/company/companyActions/invitations/cancelInvitation';
import getCompanyMembersTotalCount from 'ducks/company/companySelectors/companyMembers/getCompanyMembersTotalCount';
import getCurrentCompanyMembersPage from 'ducks/company/companySelectors/companyMembers/getCurrentCompanyMembersPage';
import getCompanyMembersPageNumber from 'ducks/company/companySelectors/companyMembers/getCompanyMembersPageNumber';
import getCompanyMembersPageSize from 'ducks/company/companySelectors/companyMembers/getCompanyMembersPageSize';

export const mapStateToInvitedUserList = state => ({
  users: getCurrentCompanyMembersPage(state),
  total: getCompanyMembersTotalCount(state),
  pageNumber: getCompanyMembersPageNumber(state),
  pageSize: getCompanyMembersPageSize(state)
});

export const mapDispatchToInvitedUserList = {
  resendInvitation,
  cancelInvitation
};
