import commaSeparatedStringToArray from './commaSeparatedStringToArray';
import arrayToString from './arrayToString';

export const INVALID_PASSWORD_MESSAGE =
  'Password must be at least 8 characters long.';
export const INVALID_EMAIL_MESSAGE = 'Please enter valid email';
export const MINIMUM_PASSWORD_LENGTH = 8;
export const required = value => {
  return value ? undefined : 'Required';
};

export const number = value =>
  value && isNaN(Number(value)) ? 'Please enter a number' : undefined;

export const emailIsValid = value => email(value) === undefined;

export const email = value =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(value.trim())
    ? undefined
    : INVALID_EMAIL_MESSAGE;

export const emails = (text, helo) => {
  const commaSeparatedEmails = commaSeparatedStringToArray(text);
  const errorEmails =
    commaSeparatedEmails &&
    commaSeparatedEmails.reduce(
      (allErrors, singleEmail) =>
        email(singleEmail) ? [...allErrors, singleEmail] : allErrors,
      []
    );

  return errorEmails && errorEmails.length
    ? `Email addresses: ${arrayToString(errorEmails)} are invalid`
    : undefined;
};

export const passwordConfirmation = (value, { password }) => {
  const result =
    value !== password ? "Password confirmation doesn't match" : undefined;
  return result;
};

export const passwordComplexity = value =>
  value && value.length >= MINIMUM_PASSWORD_LENGTH &&
  !/\s/.test(value)
    ? undefined
    : INVALID_PASSWORD_MESSAGE;

export const minLength = length => value =>
  value && value.length < length
    ? `Must be at least ${length} characters`
    : undefined;

export const passwordLength = minLength(8);

export const check = (...functions) => (...args) => {
  let l = functions.length;
  let error;
  while (l--) {
    error = functions[l](...args);
    if (error) {
      return error;
    }
  }
};

export const compose = (...funcs) => (...args) => {
  let i = 0;
  let ret;
  for (i; i < funcs.length; i++) {
    ret = funcs[i](...args);
    if (ret) return ret;
  }
};
