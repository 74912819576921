import { getTeams } from 'ducks/teams/teamsActions/getTeams';
import { getCompanyPurposes as loadPurposes } from 'ducks/company/companyActions/purposes/getCompanyPurposes';
import { getCompanyPurposes } from 'ducks/company/companySelectors/getCompanyPurposes';
import { updateCompanyPurpose } from 'ducks/company/companyActions/purposes/updateCompanyPurpose';
import { addCompanyPurpose } from 'ducks/company/companyActions/purposes/addCompanyPurpose';
import getCompanyCostCenters from 'ducks/costCenters/costCentersActions/getCompanyCostCenters';
import getCompanyPurposesPageRequests from 'ducks/company/companySelectors/companyRequests/getCompanyPurposesPageRequests';

export const mapStateToPurposesPage = state => ({
  requestsState: getCompanyPurposesPageRequests(state),
  purposes: getCompanyPurposes(state)
});

export const mapDispatchToPurposesPage = {
  getTeams,
  loadPurposes,
  getCompanyCostCenters,
  addCompanyPurpose,
  updateCompanyPurpose
};
