import React from 'react';
import PurposesList from './PurposesList';
import PurposesRatesTable from 'components/PurposesRatesTable';
import ScrollBars from 'components/Scrollbars';
import './Purposes.css';

const Purposes = () => (
  <ScrollBars>
    <div className="purposes">
      <div className="purposes__left">
        <PurposesList />
      </div>
      <div className="purposes__right">
        <p className="purposes__help-text">
          Define the purposes you want to use for deductible classifications.
          This list will be available when you classify trips.
        </p>

        <p className="purposes__help-text">
          The standard rates are displayed below for a reference.
        </p>
        <PurposesRatesTable />
      </div>
    </div>
  </ScrollBars>
);

export default Purposes;
