import { applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import notifications from 'ducks/notifications/middleware';
import errors from 'redux/errorMiddleware';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [thunk, errors, notifications];
if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger());
}
export default composeEnhancers(applyMiddleware(...middlewares));
