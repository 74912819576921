import React from 'react';
import { trackPage } from 'analytics';
import ContentWrap from 'components/ContentWrap';
import Panel from 'components/Panel';
import TripToolbarContainer from 'components/TripToolbarContainer';
import TripList from 'components/PaginatedTripList';
import TripMap from 'components/TripMap';
import ErrorBoundary from 'components/ErrorBoundary';
import TripFiltersContainer from 'components/TripFiltersContainer';
import './TripsPage.css';

const TripsPage = trackPage('trips')(() => (
  <ContentWrap>
    <Panel>
      <TripFiltersContainer allowDates />
      <TripToolbarContainer />
      <div className="trips-page__lower">
        <div className="trips-page__left">
          <TripList className="trips-page__scroll" editable />
        </div>
        <div className="trips-page__right">
          <ErrorBoundary>
            <TripMap className="trip-map" />
          </ErrorBoundary>
        </div>
      </div>
    </Panel>
  </ContentWrap>
));

export default TripsPage;
