import { SET_WORK_HOURS_FOR_DAY } from 'ducks/workHours/actionTypes';
import getCompanyWorkDay from 'ducks/workHours/workHourSelectors/getCompanyWorkDay';
import { getWorkHourFromMoment } from 'lib/getWorkHourFromMoment';

export const setTimeOnWorkDay = payload => ({
  type: SET_WORK_HOURS_FOR_DAY,
  payload
});

export const replaceRule = (index, rules, rule) => [
  ...rules.slice(0, index),
  rule,
  ...rules.slice(index + 1)
];

export const setWorkDayTimes = (dayIndex, ruleIndex, origin, time) => (
  dispatch,
  getState
) => {
  const workDay = getCompanyWorkDay(getState(), dayIndex);
  let rules;
  let rule;
  if (workDay) {
    rules = workDay.rules;
    rule = rules[ruleIndex];
    dispatch(
      setTimeOnWorkDay({
        ...workDay,
        rules: replaceRule(ruleIndex, rules, {
          ...rule,
          [origin]: getWorkHourFromMoment(time)
        })
      })
    );
  }
};

export default setWorkDayTimes;
