import _ from 'lodash';
import {
  SET_USER_WORK_HOURS,
  SET_USER_WORK_HOURS_FOR_DAY,
  SET_USER_WORK_HOURS_STATUS
} from 'ducks/workHours/actionTypes';
import { GET_SUCCESS } from 'ducks/users/usersActionTypes';
import createReducer from 'redux/createReducer';

export const userWorkHours = createReducer(
  {},
  {
    [GET_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        ..._.get(payload, 'workHours', {})
      };
    },
    [SET_USER_WORK_HOURS_STATUS]: (state, action) => {
      const payload = action && action.payload;
      const { enabled } = payload || {};
      return payload !== undefined && enabled !== undefined
        ? {
            ...state,
            enabled
          }
        : state;
    },
    [SET_USER_WORK_HOURS_FOR_DAY]: (state, action) => {
      if (action && action.payload) {
        const modification = action.payload;
        return {
          ...state,
          days: state.days.map(current => {
            return current.day === modification.day
              ? { ...current, ...modification }
              : current;
          })
        };
      }
      return state;
    },
    [SET_USER_WORK_HOURS]: (state, action) =>
      action && action.payload ? action.payload : state
  }
);

export default userWorkHours;
