import React from 'react';
const message = 'click here';
export default ({ onClick }) => (
  <span>
    <strong>Your MileCatcher subscription has ended.</strong> If you'd like to
    access all the great features available to premium users&mdash;including
    this web portal&mdash;
    <strong>
      <a onClick={onClick}>{message}</a>
    </strong>{' '}
    to subscribe.
  </span>
);
