import AddPurposePage from './AddPurposePage';
import { connect } from 'react-redux';
import {
  mapStateToAddPurposePage,
  mapDispatchToAddPurposePage
} from './addPurposePageMappings';

export default connect(
  mapStateToAddPurposePage,
  mapDispatchToAddPurposePage
)(AddPurposePage);
