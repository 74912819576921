import { connect } from 'react-redux';
import TeamMember from './TeamMember';
import {
  mapStateToTeamMember,
  mapDispatchToTeamMember
} from './teamMemberMappings';

export default connect(
  mapStateToTeamMember,
  mapDispatchToTeamMember
)(TeamMember);
