import { connect } from 'react-redux';
import StatisticsUserDisplay from './StatisticsUserDisplay';
import {
  mapStateToStatisticsUserDisplay,
  mapDispatchToStatisticsUserDisplay
} from './statisticsUserDisplayMappings';

export default connect(
  mapStateToStatisticsUserDisplay,
  mapDispatchToStatisticsUserDisplay
)(StatisticsUserDisplay);
