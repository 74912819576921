import React from 'react';
import FontAwesome from 'react-fontawesome';
import './HideButton.css';

export default ({ className, onClick, id, hidden }) => (
  <div
    id={id}
    onClick={onClick}
    className={`hide-button${className ? ` ${className}` : ''}${
      hidden ? ' hidden' : ''
    }`}
  >
    <FontAwesome id={id} name="eye-slash" />
  </div>
);
