import { createTeam } from 'ducks/teams/teamsActions/createTeam';
import { getCostCenters } from 'ducks/costCenters/costCentersSelectors/getCostCenters';
import getCompanyManagers from 'ducks/company/companySelectors/companyMembers/getCompanyManagers';
import { getMostRecentTaxRateOfUsersCountry } from 'ducks/countries/selectors';

export const mapStateToAddTeamPage = state => ({
  users: getCompanyManagers(state),
  costCenters: getCostCenters(state),
  initialValues: {
    customBusinessRate: getMostRecentTaxRateOfUsersCountry(state)
  }
});

export const mapDispatchToAddTeamPage = {
  createTeam
};
