import { GET_SUCCESS, GET_FAILURE, GET_REQUEST } from './actionTypes';
import { getCountries as countries } from 'api/endpoints/countries';
import _ from 'lodash';
const getRequest = () => ({ type: GET_REQUEST });
const getSuccess = countries => ({ type: GET_SUCCESS, payload: countries });
const getFailure = error => ({ type: GET_FAILURE, error });

export const getCountries = () => dispatch => {
  dispatch(getRequest());
  return countries().then(
    response => dispatch(getSuccess(_.get(response, 'data.countries', null))),
    error => dispatch(getFailure(error))
  );
};
