import { convertUnitOfMeasurement } from 'lib/utils';
export default (distanceFilters, isMetric) =>
  distanceFilters
    ? {
        distance_lt: convertUnitOfMeasurement(
          distanceFilters.distance_lt,
          isMetric
        ),
        distance_gt: convertUnitOfMeasurement(
          distanceFilters.distance_gt,
          isMetric
        )
      }
    : distanceFilters;
