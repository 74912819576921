import React from 'react';
import Spinner from 'components/Spinner';
import './LoadingPage.css';

const LoadingPage = () =>
  <div className="loading-page">
    <Spinner />
  </div>;

export default LoadingPage;
