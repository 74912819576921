import { updateTeamWorkHours } from './updateTeamWorkHours';
import { updateGlobalWorkHours } from './updateGlobalWorkHours';
import { getSelectedWorkHoursId } from 'ducks/company/companySelectors/companyWorkHours/getSelectedWorkHoursId';
import { GLOBAL_WORK_HOURS_ID } from 'ducks/company/constants';
import getCompanyWorkHours from 'ducks/workHours/workHourSelectors/getCompanyWorkHours';

export const updateWorkHours = () => (dispatch, getState) => {
  const state = getState();
  const workHours = getCompanyWorkHours(state);
  const selectedWorkHoursId = getSelectedWorkHoursId(state);
  return dispatch(
    selectedWorkHoursId === GLOBAL_WORK_HOURS_ID || !selectedWorkHoursId
      ? updateGlobalWorkHours(workHours)
      : updateTeamWorkHours(selectedWorkHoursId, workHours)
  );
};

export default updateTeamWorkHours;
