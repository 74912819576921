import _ from 'lodash';
import { connect } from 'react-redux';
import users from 'ducks/users';
import moment from 'moment';
import WhatsNew from './WhatsNew';

const mapStateToProps = state => ({
  lastReadNews: _.get(
    users.selectors.getCurrentUser(state),
    'lastReadNews',
    moment().format() // fall back to the current datetime
  )
});

const mapDispatchToProps = dispatch => ({
  updateLastReadNews: lastReadNews =>
    dispatch(
      users.actions.updateCurrentUser(
        {
          lastReadNews
        },
        true // silent (suppress "Settings updated!" notification)
      )
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(WhatsNew);
