import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ReportOptions from './ReportOptions';
import reports from 'ducks/reports';
import users from 'ducks/users';

const mapStateToProps = state => {
  const user = users.selectors.getActive(state);

  return {
    initialValues: {
      submitter: _.get(user, 'name', ''),
      company: _.get(user, 'companyName', '')
    }
  };
};

const mapDispatchToProps = (dispatch, { history }) => ({
  onSubmit: values => {
    if (values.ccEmails && values.ccEmails.length)
      values.ccEmails = values.ccEmails.split(',').map(_.trim);

    dispatch(reports.actions.createReport(values)).then(() =>
      history.push('/reports')
    );
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportOptions)
);
