import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import ConfirmationModal from 'components/Confirmation/ConfirmationModal';
import './TripLocationField.css';
import LocationFieldDropDown from './LocationFieldDropDown';
const ENTER_KEY = 13;
export default class TripLocationField extends Component {
  constructor(props) {
    const { address, location } = props;
    super(props);
    this.state = {
      location,
      text: location ? location.name : address,
      confirming: false,
      displayingHints: false,
      selectedLocation: false
    };
    this.inputElement = null;

    // bind functions to trip location field
    this.modifyLocation = this.modifyLocation.bind(this);
    this.addLocation = this.addLocation.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
    this.clearAndResetOnBlur = this.clearAndResetOnBlur.bind(this);
    this.cancel = this.cancel.bind(this);
    this.openConfirmationModal = this.openConfirmationModal.bind(this);
    this.reset = this.reset.bind(this);
    this.displayLocationHints = this.displayLocationHints.bind(this);
    this.hideLocationHints = this.hideLocationHints.bind(this);
    this.getCurrentName = this.getCurrentName.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.deFocus = this.deFocus.bind(this);
    this.setreference = this.setreference.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setText = this.setText.bind(this);
  }

  // remove focus from input when outside click is detected
  deFocus() {
    const inputElement = this.inputElement;
    inputElement.focus();
    inputElement.blur();
  }

  // create a reference to the input element so that we can call its methods
  setreference(inputElement) {
    this.inputElement = ReactDOM.findDOMNode(inputElement);
  }

  // set the text for the input element
  setText(text) {
    this.setState({ text });
  }

  // monitor key press events for submitting data
  handleKeyPress(event) {
    (event.charCode === ENTER_KEY || event.keyCode === ENTER_KEY) &&
      this.openConfirmationModal();
  }

  // monitor click events so that we can perform the necessary actions on click
  handleClick() {
    !this.props.location && this.setText('');
    this.setState({ enteringName: true });
    this.displayLocationHints();
  }

  // check weather the input value is different from it's initial values
  inputHasChanged() {
    const { address, location } = this.props;
    const { text } = this.state;
    return text !== address || (location && text !== location.name);
  }

  // set text for input
  modifyLocation({ target }) {
    this.setText(target.value);
  }

  closeConfirmationModal() {
    this.setState({ confirming: false });
  }

  openConfirmationModal() {
    const { text } = this.state;
    _.isString(text) && text.length && this.setState({ confirming: true });
  }

  displayLocationHints() {
    this.setState({ displayingHints: true });
  }

  hideLocationHints() {
    this.setState({ displayingHints: false });
  }

  // handle location selection by user
  selectLocation(location) {
    this.hideLocationHints();
    this.setState({ selectedLocation: location });
    this.openConfirmationModal();
  }

  // handle cancel selection by user
  cancel() {
    this.reset();
    this.closeConfirmationModal();
  }

  addLocation() {
    const { text, selectedLocation } = this.state;
    const {
      addLocation,
      applicationDate,
      updateLocation,
      removeOrRestoreLocation,
      removeLocationIfStored,
      changeTripLocation,
      storeLocation,
      address,
      coordinates,
      location,
      tripId
    } = this.props;
    const alert = true;
    const [lat, lon] = coordinates;
    const locationToBeUpdated = selectedLocation || location;
    const id = locationToBeUpdated ? locationToBeUpdated.id : undefined;
    const name = selectedLocation ? selectedLocation.name : text;
    const newLocation = {
      id,
      address,
      name,
      appliedAt: applicationDate,
      coordinates,
      lat,
      lon
    };

    this.closeConfirmationModal();
    this.hideLocationHints();
    this.deFocus();

    // if the name is the same as the address then the previous address or location
    // has been selected, we need to revert back to whichever was selected
    if (name === address) {
      removeOrRestoreLocation(location && location.id);
      this.setState({ location: undefined });

      // if there is text input we need to update the location/trip
    } else if (_.isString(name) && name.length) {
      // if a location was selected we need to switch to the selected location
      if (selectedLocation) {
        // store the previously selected location in case a user wants to
        // undo their selection
        storeLocation(selectedLocation);
        changeTripLocation(tripId, selectedLocation);

        // if a location exists we need to update it
      } else if (location) {
        updateLocation(newLocation, alert);

        // if no location exists then we need to create a new one
      } else {
        addLocation(newLocation, alert);
        removeLocationIfStored(newLocation);
      }
      this.setText(name);
    }
  }

  getCurrentName() {
    const { location, address } = this.props;
    return (location && location.name) || address;
  }

  reset() {
    const { location, address } = this.props;
    this.setText(location ? location.name : address);
    this.setState({
      location,
      selectedLocation: false,
      enteringName: false
    });
  }

  clearAndResetOnBlur() {
    const { confirming } = this.state;
    return !confirming && this.reset();
  }

  matchHintsWithText() {
    const { nearByLocations } = this.props;
    const { text } = this.state;
    return this.inputHasChanged()
      ? nearByLocations.filter(({ name }) => name.includes(text))
      : nearByLocations;
  }

  render() {
    const state = this.state;
    const {
      confirming,
      displayingHints,
      selectedLocation,
      enteringName
    } = state;
    const text = enteringName ? state.text : this.getCurrentName();
    const { address, storedLocationExists, location } = this.props;
    const previousLocation = state.location;
    const name = (selectedLocation && selectedLocation.name) || text;
    const removing = name === address;
    const saving = !location;
    const removeTitle = 'Remove Location';
    const saveTitle = 'Save Location';
    const changeTitle = 'Change Location';
    const saveMessage = `Save location as ${name}?`;
    const changeMessage = `Rename address to ${name}?`;
    const removeMessage = storedLocationExists(location)
      ? 'Use the original address for this location?'
      : 'Delete location and use original address?';
    const hints =
      displayingHints &&
      (location ? [{ name: address }] : this.matchHintsWithText());
    if (
      hints &&
      previousLocation &&
      location &&
      location.name !== previousLocation.name
    ) {
      hints.push(previousLocation);
    }
    return [
      <ConfirmationModal
        key={'location-field-confirmation-modal'}
        isOpen={confirming}
        onConfirm={this.addLocation}
        onCancel={this.cancel}
        title={removing ? removeTitle : saving ? saveTitle : changeTitle}
        message={
          removing ? removeMessage : saving ? saveMessage : changeMessage
        }
      />,
      <input
        key="location-field-name-input"
        className="trip-location-field"
        type="text"
        value={text}
        placeholder={'Name this location...'}
        onChange={this.modifyLocation}
        onBlur={this.clearAndResetOnBlur}
        onClick={this.handleClick}
        onKeyPress={this.handleKeyPress}
        ref={this.setreference}
      />,
      ...(hints && hints.length > 0
        ? [
            <LocationFieldDropDown
              key="location-field-hints-drop-down"
              onClose={this.hideLocationHints}
              onSelect={this.selectLocation}
              isOpen={displayingHints}
              elements={hints}
            />
          ]
        : [])
    ];
  }
}
