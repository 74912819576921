import createReducer from 'redux/createReducer';
import {
  onRequest,
  onSuccess,
  onError,
  requestState,
  ABSENT,
  ERROR,
  LOADED,
  LOADING
} from 'lib/requests';

export const createRequestObject = ([request, success, error]) => ({
  [request]: onRequest,
  [success]: onSuccess,
  [error]: (_, action) => onError(action.error)
});

export const createRequestsObject = ([request, success, error]) => ({
  [request]: state => ({ ...state, [request]: onRequest() }),
  [success]: state => ({ ...state, [request]: onSuccess() }),
  [error]: (state, action) => ({ ...state, [request]: onError(action.error) })
});

export const createRequestReducer = actions =>
  createReducer(null, Object.assign({}, ...actions.map(createRequestObject)));

export const createRequestSelectors = getRequest => ({
  getRequestState: state => requestState(getRequest(state))
});

export const createRequestsReducer = actions =>
  createReducer(
    Object.assign(
      {},
      ...actions.map(([request]) => ({ [request]: onRequest() }))
    ),
    Object.assign({}, ...actions.map(createRequestsObject))
  );

// TODO TEST THIS!
export const createRequestsSelector = getRequestsStore => state => {
  const requestsStore = getRequestsStore(state);

  return (
    requestsStore &&
    Object.keys(requestsStore).reduce((previousState, requestName) => {
      const request = requestsStore[requestName];
      const stateOfRequest = requestState(request);

      // if there are any errors return error
      if (previousState === ERROR || stateOfRequest === ERROR) {
        return ERROR;
      }

      // if any request is still loading return loading
      if (previousState === LOADING || stateOfRequest === LOADING) {
        return LOADING;
      }

      // if all requests are loaded return loaded
      if (previousState === LOADED && stateOfRequest === LOADED) {
        return LOADED;
      }

      // if previous state is absent or none of the above then return the current state of the request
      return stateOfRequest;
    }, ABSENT)
  );
};
