import React, { Component } from 'react';
import Trigger from 'rc-trigger';
import FontAwesome from 'react-fontawesome';
import Button from 'components/Button';
import SideMenu from 'components/SideMenu';
import Modal from 'components/Modal';
import CopyForm from 'components/CopyForm';
import AddReturnTripForm from 'components/AddReturnTripForm';
import EditTripDistanceAndTimeForm from 'components/EditTripDistanceAndTimeForm';
import RecalculateTripForm from 'components/RecalculateTripRouteForm';
import './TripMenu.css';
const DEFAULT = 'DEFAULT';
const ADD_RETURN_TRIP = 'ADD_RETURN_TRIP';
const COPY_MENU = 'COPY_MENU';
const EDIT_TRIP_MENU = 'EDIT_TRIP_MENU';
const RECALCULATE_TRIP_ROUTE = 'RECALCULATE_TRIP_ROUTE';
const activeStates = [
  COPY_MENU,
  EDIT_TRIP_MENU,
  ADD_RETURN_TRIP,
  RECALCULATE_TRIP_ROUTE
];
const forms = [
  CopyForm,
  EditTripDistanceAndTimeForm,
  AddReturnTripForm,
  RecalculateTripForm
];
const descriptions = [
  `Copy this trip to a specific date or range of dates.`,
  `Modify distance, start and/or end times. Everything else in your trip will remain unchanged.`,
  `Add a return trip using the same distance and route traveled. The times of the new trip will be changed and it's start and end points will be reversed.`,
  `Recalculate the route of a your trip. The distance of a recalculated trip may be different from the original.`
];
const titles = [
  'Copy',
  'Edit Trip Time and Distance',
  'Add Return Trip',
  'Recalculated Route'
];
const Menu = ({ setState }) => (
  <SideMenu
    items={[
      {
        id: 'copy',
        label: 'Copy',
        icon: 'clone',
        onClick: () => setState(COPY_MENU)
      },
      {
        id: 'edit',
        label: 'Edit Time/Distance',
        icon: 'pencil',
        onClick: () => setState(EDIT_TRIP_MENU)
      },
      {
        id: 'return',
        label: 'Add Return Trip',
        icon: 'car',
        onClick: () => setState(ADD_RETURN_TRIP)
      },
      {
        id: 'recalculate',
        label: 'Recalculate Route',
        icon: 'retweet',
        onClick: () => setState(RECALCULATE_TRIP_ROUTE)
      }
    ]}
  />
);

export default class TripMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { state: DEFAULT };
  }
  render() {
    const { loading, tripId } = this.props;
    const { state } = this.state;
    return [
      <Trigger
        key={1}
        action={['click']}
        popupAlign={{ points: ['cl', 'cr'], offset: [20, 0] }}
        {...(loading || state !== DEFAULT) && { popupVisible: false }}
        popup={
          <Menu state={state} setState={state => this.setState({ state })} />
        }
      >
        <Button disabled={loading} inline>
          <FontAwesome name="gear" spin={loading} fixedWidth />
          {loading && 'Saving...'}
        </Button>
      </Trigger>,
      ...forms.map((Form, index) => (
        <Modal
          className="trip-menu-modal"
          key={index + 2}
          isOpen={state === activeStates[index]}
          onClose={() => this.setState({ state: DEFAULT })}
          description={descriptions[index]}
          title={titles[index]}
        >
          <Form
            tripId={tripId}
            onCancel={() => this.setState({ state: DEFAULT })}
          />
        </Modal>
      ))
    ];
  }
}
