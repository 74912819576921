import React from 'react';
import ImageContainer from 'components/CompanyPages/lib/ImageContainer';
import Money from 'components/Money';
import './StatisticsUserDisplay.css';

export const StatisticsUserDisplay = ({
  name,
  image,
  email,
  managerName,
  teamName,
  percentile,
  averageExpense
}) => (
  <div className="statistics-user-display">
    <h1 className="admin-statistics-title">{name}</h1>
    <ImageContainer
      className="admin-statistics-user-image"
      image={image}
      email={email}
    />
    <div className="admin-statistics-user-data">
      <h1>Email: {email}</h1>
      <h1>Team: {teamName}</h1>
      <h1>Managed by {managerName}</h1>
      <h1>Manager of {teamName}</h1>
      <h1 className="bold-admin-statistics-user-data">
        In top {percentile}% of expensers
      </h1>
      <h1 className="bold-admin-statistics-user-data">
        Average expenses per month:
        <Money>{averageExpense}</Money>
      </h1>
    </div>
  </div>
);

export default StatisticsUserDisplay;
