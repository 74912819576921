import { notify } from './actions';
const getNotification = ({ meta }) =>
  meta && meta.alert ? notify(meta.alert.type, meta.alert.message) : null;

const notificationMiddleware = ({ dispatch }) => next => action => {
  const notification = getNotification(action);
  next(action);
  if (notification) {
    dispatch(notification);
  }
};
export default notificationMiddleware;
