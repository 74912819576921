import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Mousetrap from 'mousetrap';
import Trip from 'components/Trip';
import Scrollbars from 'components/Scrollbars';
import LoadingPage from 'components/LoadingPage';
import ErrorPage from 'components/ErrorPage';
import Pagination from 'components/Pagination';
import CompactTripList from 'components/CompactTripList';

const noop = () => null;

class PaginatedTripList extends Component {
  static propTypes = {
    className: PropTypes.string,
    pages: PropTypes.object,
    total: PropTypes.number, // total # of trips on server
    getPage: PropTypes.func,
    editable: PropTypes.bool, // whether to render Trip or CompactTrip
    nCells: PropTypes.number
  };

  static defaultProps = {
    pages: [],
    total: 0,
    getNextPage: noop,
    editable: false
  };

  constructor(props) {
    super(props);

    this.loadFirstPage = this.loadFirstPage.bind(this);
    this.activateNext = this.activateNext.bind(this);
    this.activatePrevious = this.activatePrevious.bind(this);
    this.scrollbars = null;
  }

  // keyboard shortcuts
  componentWillMount() {
    Mousetrap.bind(['up', 'w', 'k'], () => {
      this.activatePrevious();
      return false;
    });

    Mousetrap.bind(['down', 's', 'j'], () => {
      this.activateNext();
      return false;
    });

    Mousetrap.bind('b', () => {
      const { active, classifyBusiness } = this.props;
      classifyBusiness(active);
      Mousetrap.trigger('down');
    });

    Mousetrap.bind('p', () => {
      const { active, classifyPersonal } = this.props;
      classifyPersonal(active);
      Mousetrap.trigger('down');
    });

    Mousetrap.bind('u', () => {
      const { active, unClassify } = this.props;
      unClassify(active);
    });
  }

  componentWillUnmount() {
    Mousetrap.unbind(['up', 'w', 'k', 'down', 's', 'j', 'b', 'p', 'u']);
  }

  // check if we need  the first page on mount and update
  componentDidMount() {
    this.loadFirstPage();
  }

  loadFirstPage() {
    const { pages, getPage } = this.props;
    if (_.isEmpty(pages)) {
      getPage(1);
    }
  }

  activateNext() {
    // set the next trip as active on classify or keyboard shortcut
    const { currentPage, active, setActive } = this.props;
    const index = currentPage.ids.indexOf(active);
    if (index >= 0 && index < currentPage.ids.length - 1) {
      setActive(currentPage.ids[index + 1]);
    }
  }

  activatePrevious() {
    // set the previous trip as active on keyboard shortcut
    const { currentPage, active, setActive } = this.props;
    const index = currentPage.ids.indexOf(active);
    if (index > 0) {
      setActive(currentPage.ids[index - 1]);
    }
  }

  componentDidUpdate(prevProps) {
    const { currentPage, active, setActive } = this.props;

    this.loadFirstPage();

    if (
      currentPage &&
      currentPage.ids &&
      currentPage.ids.length &&
      currentPage.ids.indexOf(active) === -1
    ) {
      setActive(currentPage.ids[0]);
    }

    if (prevProps.active !== active && currentPage && currentPage.ids) {
      const elIndex = currentPage.ids.indexOf(active);
      if (elIndex >= 0) {
        const elHeight = 147; // height of trip DOM element in pixels
        const elTop = elHeight * elIndex;
        const elBottom = elHeight * (elIndex + 1);
        const scrollTop = this.scrollbars.getScrollTop();
        const clientHeight = this.scrollbars.getClientHeight();

        if (elTop < scrollTop) {
          this.scrollbars.scrollTop(elTop);
        }

        if (elBottom > scrollTop + clientHeight) {
          this.scrollbars.scrollTop(elBottom - clientHeight);
        }
      }
    }
  }
  render() {
    const {
      className,
      total,
      editable,
      currentPage,
      trips,
      nCells
    } = this.props;

    return total === 0 && currentPage && !currentPage.loading ? (
      <div className="trips-page__no-trips">
        <b>No trips found.</b> Try changing the filters above, or take the
        MileCatcher Android/iOS app out for a spin!
      </div>
    ) : (
      // You might ask yourself: why are there inline styles here, instead of
      // CSS? The answer: it's been a long day and I don't want to make a whole
      // stylesheet.
      <div
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        className={className}
      >
        <Scrollbars
          setreference={node => {
            this.scrollbars = node;
          }}
        >
          {currentPage && currentPage.loading && <LoadingPage />}
          {currentPage &&
            !currentPage.loading &&
            currentPage.error && <ErrorPage />}
          {currentPage &&
            !currentPage.loading &&
            !currentPage.error &&
            (editable ? (
              trips.map(({ id }) => (
                <Trip key={id} id={id} onClassify={this.activateNext} />
              ))
            ) : (
              <CompactTripList trips={trips} />
            ))}
        </Scrollbars>
        <Pagination nCells={nCells} />
      </div>
    );
  }
}

export default PaginatedTripList;
