import React, { Component } from 'react';
import _ from 'lodash';
import Mousetrap from 'mousetrap';
import moment from 'moment';
import classNames from 'class-names';
import { difference } from 'lib/utils';
import Textbox from 'components/TextBox';
import Checkbox from 'components/Checkbox';
import Distance from 'components/Distance';
import EstimatedValue from 'components/EstimatedValue';
import VehicleSelector from 'components/VehicleSelector';
import TripMenu from 'components/TripMenu';
import ErrorBoundary from 'components/ErrorBoundary';
import Notes from 'components/Trip/Notes';
import TripLocationField from 'components/Trip/TripLocationField';
import Classify from '../Classify';
import './Trip.css';

export default class Trip extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.focusParking = this.focusParking.bind(this);
    this.debouncedSave = _.debounce(this.save, 1000).bind(this);
    this.state = {
      trip: { ...props.trip }
    };
  }

  componentWillMount() {
    const { isActive } = this.props;
    if (isActive) {
      Mousetrap.bind('shift+enter', this.focusParking);
    }
  }

  componentWillUnmount() {
    const { isActive } = this.props;
    if (isActive) {
      Mousetrap.unbind('shift+enter');
    }
  }

  componentWillReceiveProps(nextProps) {
    const { request, isActive } = this.props;

    // update trip after save
    if (_.get(request, 'loading') && !_.get(nextProps.request, 'loading'))
      this.setState(() => ({ trip: nextProps.trip }));

    // bind 'shift+enter' on active
    if (!isActive && nextProps.isActive) {
      Mousetrap.bind('shift+enter', this.focusParking);
    } else if (isActive && !nextProps.isActive) {
      Mousetrap.unbind('shift+enter');
    }
  }

  focusParking() {
    this.parkingInput.focus();
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.onChange({ [name]: value }, true);
  }

  onChange(params, debounce) {
    this.setState(
      state => ({
        trip: { ...state.trip, ...params }
      }),
      () => {
        if (debounce) {
          this.debouncedSave();
        } else {
          this.save();
        }
      }
    );
  }

  save() {
    const { trip: ownTrip } = this.state;
    const { trip, updateTrip } = this.props;
    const diff = difference(ownTrip, trip);
    if (!_.isEmpty(diff)) {
      updateTrip({ id: trip.id, ...diff });
    }
  }

  render() {
    const {
      request,
      selected,
      selectTrip,
      deselectTrip,
      isActive,
      setActive,
      onClassify,
      startingPoint,
      endPoint,
      startLocation,
      endLocation,
      changeTripStartLocation,
      changeTripEndLocation
    } = this.props;
    const { trip } = this.state;

    return (
      <div
        className={classNames('trip', { trip_highlighted: isActive })}
        onClick={() => setActive(trip.id)}
      >
        <ErrorBoundary>
          <div className="trip__checkbox">
            <Checkbox
              checked={selected}
              onChange={e =>
                e.target.checked ? selectTrip(trip.id) : deselectTrip(trip.id)
              }
            />
          </div>

          <div className="trip__col trip__col_1">
            <div className="trip__date">
              {moment(trip.startTime).format('ddd ll') + ' '}
              <TripMenu loading={request && request.loading} tripId={trip.id} />

              <span className="trip__labels">
                {trip.manuallyCreated && (
                  <span
                    className="trip__label"
                    title="Manually created through the web portal"
                  >
                    Manual
                  </span>
                )}
                {trip.copied && <span className="trip__label">Copied</span>}
                {trip.reported && <span className="trip__label">Reported</span>}
              </span>
            </div>

            <div className="trip__start">
              <span className="trip__time">
                {moment(trip.startTime).format('LT')}
              </span>

              <span className="trip__address" title={trip.departureAddress}>
                <TripLocationField
                  className="trip-location-field"
                  location={startLocation}
                  address={trip.departureAddress}
                  coordinates={startingPoint}
                  changeTripLocation={changeTripStartLocation}
                  tripId={trip.id}
                  applicationDate={trip.createdAt}
                />
              </span>
            </div>

            <div className="trip__end">
              <span className="trip__time">
                {moment(trip.endTime).format('LT')}
              </span>

              <span className="trip__address" title={trip.destinationAddress}>
                <TripLocationField
                  className="trip-location-field"
                  location={endLocation}
                  address={trip.destinationAddress}
                  coordinates={endPoint}
                  changeTripLocation={changeTripEndLocation}
                  tripId={trip.id}
                  applicationDate={trip.createdAt}
                />
              </span>
            </div>

            <div className="trip__classify">
              <Classify
                selected={trip.purposeId}
                onSelect={purposeId => {
                  this.onChange({ purposeId }, false);
                  if (purposeId === trip.purposeId) {
                    this.onChange({ purposeId: null }, false);
                  } else {
                    setTimeout(onClassify, 1); // dodge setActive call from onClick event
                    this.onChange({ purposeId }, false);
                  }
                }}
                disabled={request && request.loading}
              />
            </div>
          </div>

          <div className="trip__col trip__col_3">
            <div className="trip__distance">
              <Distance value={trip.distance} />
            </div>

            <div className="trip__parking">
              <Textbox
                name="parkingExpense"
                value={trip.parkingExpense || ''}
                onChange={this.handleInputChange}
                placeholder="Parking"
                tabIndex={isActive ? 1 : -1}
                inputref={ref => {
                  this.parkingInput = ref;
                }}
              />
            </div>

            <div className="trip__tolls">
              <Textbox
                name="tollExpense"
                value={trip.tollExpense || ''}
                onChange={this.handleInputChange}
                placeholder="Tolls"
                tabIndex={isActive ? 3 : -1}
              />
            </div>
          </div>

          <div className="trip__col trip__col_2">
            <EstimatedValue
              className="trip__price"
              purposeId={trip.purposeId}
              date={trip.startTime}
              type={trip.tripType}
              cost={trip.expense}
              distance={trip.distance}
            />
            <div className="trip__notes">
              <Notes
                onChange={this.handleInputChange}
                value={trip.notes || ''}
                tabIndex={isActive ? 2 : -1}
              />
            </div>

            <div className="trip__vehicle">
              <VehicleSelector
                selectedId={trip.vehicleId}
                onSelect={vehicleId => this.onChange({ vehicleId }, false)}
                disabled={request && request.loading}
              />
            </div>
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}
