import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { postCostCenter } from 'api/endpoints/company/costCenters';
import {
  POST_COST_CENTERS_REQUEST,
  POST_COST_CENTERS_SUCCESS,
  POST_COST_CENTERS_FAILURE
} from 'ducks/costCenters/costCentersActionTypes';

export const addCostCenter = createSimpleAction(
  postCostCenter,
  [
    POST_COST_CENTERS_REQUEST,
    POST_COST_CENTERS_SUCCESS,
    POST_COST_CENTERS_FAILURE
  ],
  'costCenter'
);

export default addCostCenter;
