import { logError } from 'raven';
const TOKEN_KEY = '_mc_portal_token';
const USER_ID_KEY = '_mc_portal_id';
const COMPANY_ID_KEY = '_mc_portal_company_id';
const SPEED_GAUGE_RECORD_KEY = '_mc_portal_speed_gauge_record_id';
const REFERRAL_CODE = '_mc_portal_referral_code';
const ROLE_KEY = '_mc_portal_role';
const KEYS = [TOKEN_KEY, USER_ID_KEY, COMPANY_ID_KEY, ROLE_KEY];

// Try/catch blocks are because any attempt to access localStorage on iOS Mobile
// Safari will throw a 'SecurityError' for some inexplicable reason.
// (See https://sentry.io/share/issue/cbb0fbd60a6c4dfdbfefbf2e97960a3e)

function handleSecurityError(error) {
  if (!(error instanceof window.SecurityError)) {
    logError(error);
  }
}

export const interactWithLocalStorage = action => {
  try {
    return action();
  } catch (error) {
    handleSecurityError(error);
    return null;
  }
};

export const saveToLocal = (key, value) =>
  interactWithLocalStorage(() => localStorage.setItem(key, value));

export const getFromLocal = key =>
  interactWithLocalStorage(() => localStorage.getItem(key));

export const removeFromLocal = key =>
  interactWithLocalStorage(() => localStorage.removeItem(key));

export const setUserId = userId => saveToLocal(USER_ID_KEY, userId);
export const setCompanyId = companyId => saveToLocal(COMPANY_ID_KEY, companyId);
export const setAuthToken = token => saveToLocal(TOKEN_KEY, token);
export const setSpeedGaugeRecordId = token => saveToLocal(SPEED_GAUGE_RECORD_KEY, token);
export const setReferralCode = token => saveToLocal(REFERRAL_CODE, token);
export const setRole = role => saveToLocal(ROLE_KEY, role);

export const getId = key => {
  return interactWithLocalStorage(() => {
    const id = localStorage.getItem(key);
    return id ? parseInt(id, 10) : null;
  });
};

export const getAuthToken = () => getFromLocal(TOKEN_KEY);
export const getUserId = () => getId(USER_ID_KEY);
export const getCompanyId = () => getId(COMPANY_ID_KEY);
export const getRole = () => getId(ROLE_KEY);

export const clearLocalStorage = () =>
  KEYS.forEach(key => removeFromLocal(key));
