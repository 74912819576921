import { connect } from 'react-redux';
import {
  mapStateToMembersList,
  mapDispatchToMembersList
} from './membersListMappings';
import MembersList from './MembersList';
export default connect(
  mapStateToMembersList,
  mapDispatchToMembersList
)(MembersList);
