import React from 'react';
import mileCatcher from './milecatcher.png';
import './PreloginBox.css';

const PreloginBox = ({ children, className, content }) => (
  <div className={`prelogin-box ${className || 'prelogin-box-spacing'}`}>
    <div className="prelogin-box__box-header">
      <img
        className="prelogin-box__box-header__img"
        src={mileCatcher}
        alt="MileCatcher"
      />
    </div>
    <div
      className={`prelogin-box__box-content ${content ||
        'prelogin-box__box-content'}`}
    >
      {children}
    </div>
  </div>
);

export default PreloginBox;
