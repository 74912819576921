import React from 'react';
import Selection from './Selection';
import Checkbox from 'components/Checkbox';

export default function({ multipleSelections, selections, onClick }) {
  const singles = selections.map(selection => (
    <Selection selection={selection} onClick={onClick}>
      {selection.current}
    </Selection>
  ));
  const multiples = multipleSelections.map(selection => {
    return (
      <Selection key={selection.key} selection={selection} onClick={onClick}>
        <Checkbox checked={selection.selected} dark />
        <p>selection.title</p>
      </Selection>
    );
  });
  return singles.concat(multiples);
}
