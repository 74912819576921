import _ from 'lodash';
import * as t from './actionTypes';
import trips from 'ducks/trips';

export const selectTrip = id => ({ type: t.SELECT_TRIP, payload: id });
export const deselectTrip = id => ({ type: t.DESELECT_TRIP, payload: id });

export const selectAll = () => (dispatch, getState) => {
  const state = getState();
  const pageNum = trips.selectors.getCurrentPageNum(state);
  const currentPage = trips.selectors.getPage(state, pageNum);
  return dispatch({
    type: t.SELECT_ALL,
    payload: {
      page: pageNum,
      ids: _.get(currentPage, 'ids', [])
    }
  });
};

export const deselectAll = () => (dispatch, getState) => {
  const state = getState();
  const pageNum = trips.selectors.getCurrentPageNum(state);
  const currentPage = trips.selectors.getPage(state, pageNum);
  return dispatch({
    type: t.DESELECT_ALL,
    payload: {
      page: pageNum,
      ids: _.get(currentPage, 'ids', [])
    }
  });
};
