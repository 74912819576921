import mapOverObject from 'lib/mapOverObject';

export const paginateHandler = (handler, initialState = {}) => (
  state,
  action
) => {
  const name = action && action.name;

  return name
    ? {
        ...state,
        [name]: handler(state[name] || initialState, action)
      }
    : state;
};

export const paginateHandlers = (handlers, initialState) =>
  mapOverObject(handlers, handler => paginateHandler(handler, initialState));

export default paginateHandlers;
