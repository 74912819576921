import capitalizeFirstLetter from 'lib/capitalizeFirstLetter';
import HideButton from 'components/CompanyPages/lib/HideButton/index';
import confirmation from 'components/Confirmation/index';
import ReportDownloadLinks from 'components/ReportDownloadLinks/index';
import Money from 'components/Money/index';
import React, { Component } from 'react';
import ReportStatusActionButtons from 'components/ReportStatusActionButtons';

const HideReportButton = confirmation(HideButton);

export default class CompanyReport extends Component {
  constructor(props) {
    super(props);

    this.hideReport = this.hideReport.bind(this);
  }

  hideReport() {
    const { id, hideCompanyReport, hidden } = this.props;

    return hideCompanyReport && hideCompanyReport(id, { hidden: !hidden });
  }

  render() {
    const {
      name,
      approvalStatus,
      submissionDate,
      dateRange,
      value,
      index,
      hidden,
      classNames,
      ...report
    } = this.props;

    return (
      <tr className="admin-reports-table-data" key={`admin-report-${index}`}>
        <td className={classNames[0]}>{name}</td>
        <td className={classNames[1]}>{submissionDate}</td>
        <td className={classNames[2]}>{dateRange}</td>
        <td className={classNames[3]}>
          {value ? <Money>{value}</Money> : '-'}
        </td>
        <td className={classNames[4]}>
          <ReportStatusActionButtons
            approvalStatus={approvalStatus}
            {...report}
          />
        </td>
        <td className={classNames[5]}>
          <ReportDownloadLinks {...report} />
        </td>
        <td className={classNames[6]}>
          <HideReportButton
            onClick={this.hideReport}
            className="admin-report-delete-button"
            confirm={true}
            hidden={hidden}
            title={`${hidden ? 'Show' : 'Hide'} Report`}
            message={`Are you sure you want to ${
              hidden ? 'show' : 'hide'
            } this report?`}
          />
        </td>
      </tr>
    );
  }
}
