import _ from 'lodash';
import { NAME, PAGE_SIZE } from './constants';
import { createSelector } from 'reselect';
import { createRequestSelectors } from 'redux/requestReducer';
import { requestState } from 'lib/requests';

const getState = state => state[NAME];
export const getNumberOfPages = state =>
  Math.ceil(getTotalCount(state) / PAGE_SIZE);
export const getRequest = state => getState(state).request;
export const { getRequestState } = createRequestSelectors(getRequest);

export const getItems = state => getState(state).items;
export const getById = (state, id) => {
  const items = getItems(state);
  if (items) {
    return items[id];
  }
};
export const getPages = state => getState(state).pages;
export const getPage = (state, pageNum) => getPages(state)[pageNum];
export const getPagination = state => getState(state).pagination;
export const getTotalCount = state => getPagination(state).totalCount;
export const getCurrentPageNum = state => getPagination(state).current;
export const getStarStatus = (state, id) => {
  const report = getById(state, id);
  if (report) {
    return report.starred;
  }
};
export const getSelectedFilters = state => {
  const filterOptions = getFilterOptions(state);
  return Object.keys(filterOptions)
    .map(option => filterOptions[option])
    .filter(({ selected }) => selected)
    .reduce((filters, { option, selected }) => {
      filters[option] = selected;
      return filters;
    }, {});
};
export const getFilterOptions = state => state.reports.filters;
export const getCurrentPage = state => getPage(state, getCurrentPageNum(state));
export const getCurrentPageRequestState = state =>
  requestState(getPagination(state).request);

export const getIdsForAllPages = createSelector(getPages, pages => {
  const pageNumbers = _.keys(pages)
    .map(x => Number(x))
    .sort();
  return _.flatten(pageNumbers.map(pageNumber => pages[pageNumber]));
});
