import { getCompanyAdminReport } from 'api/endpoints/company/reports';
import saveTextToCsvFile from 'lib/saveTextToComputer';
import moment from 'moment';

export const exportCompanyReport = () => {
  const timeStamp = moment().format('MM-DD-YYYY');
  return getCompanyAdminReport().then(
    ({ data }) =>
      data && saveTextToCsvFile(data, `administrative-report-${timeStamp}`)
  );
};

export default exportCompanyReport;
