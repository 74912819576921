import mapOverObject from 'lib/mapOverObject';

export const paginateAction = action => pageName => (...args) => ({
  ...action(...args),
  name: pageName
});

export const paginateActions = actions =>
  mapOverObject(actions, action => paginateAction(action));

export default paginateActions;
