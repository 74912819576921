import React, { Component } from 'react';
import TeamMember from './TeamMember';
import DropDown from 'components/CompanyPages/lib/CompanyDropDown';
import { renderCompanyTextField } from 'components/CompanyPages/lib/CompanyFields/renderCompanyField';
import { required } from 'lib/validators';
import CompanyFormError from 'components/CompanyPages/lib/CompanyFormError';
import AddButton from 'components/CompanyPages/lib/AddButton';
import confirm from 'components/Confirmation';
import ScrollBars from 'components/Scrollbars';
import { reduxForm, Field } from 'redux-form';
import capitalizeFirstLetter from 'lib/capitalizeFirstLetter';
import arrayToString from 'lib/arrayToString';
import ExpenseRateField from 'components/CompanyPages/lib/ExpenseRateField';
import './EditTeamPage.css';
import remoteSubmission from '../../../../../RemoteSubission/remoteSubmission';

const SaveButton = confirm(AddButton);
const form = 'edit-team-page';

export default reduxForm({ form })(
  class EditTeamPage extends Component {
    constructor(props) {
      const { costCenters } = props;
      const costCenterIndex = 0;

      super(props);

      this.state = {
        selectedCostCenter: costCenters[costCenterIndex],
        costCenterIndex,
        userChanges: {}
      };

      this.handleUserChange = this.handleUserChange.bind(this);
      this.selectCostCenter = this.selectCostCenter.bind(this);
      this.saveChanges = this.saveChanges.bind(this);
      this.getChangesMessage = this.getChangesMessage.bind(this);
      this.getChangesArray = this.getChangesArray.bind(this);
      this.getRoleChanges = this.getRoleChanges.bind(this);
      this.getDeleteChanges = this.getDeleteChanges.bind(this);
    }

    getChangesArray() {
      const { usersObject } = this.props;
      const { userChanges } = this.state;

      return Object.keys(userChanges).map(key => {
        const change = userChanges[key];
        const { name, email } = (change && usersObject[change.id]) || {};
        return {
          ...change,
          name: capitalizeFirstLetter(name || email)
        };
      });
    }

    getDeleteChanges() {
      return this.getChangesArray().filter(({ deleted }) => deleted);
    }

    getRoleChanges() {
      return this.getChangesArray().filter(({ deleted }) => !deleted);
    }

    getChangesMessage() {
      const { team } = this.props;
      const removed = this.getDeleteChanges().map(({ name }) => name);
      const removalDescription = `${arrayToString(
        removed,
        true
      )} will be removed`;
      const roleChanges = this.getRoleChanges().map(
        ({ name, role }) => `${name} will have their role changed to ${role}`
      );

      if (removed.length) {
        roleChanges.push(removalDescription);
      }

      return `Are you sure you want to save changes to "${
        team ? team.name : 'team'
      }"? ${arrayToString(roleChanges, true)}.`;
    }

    selectCostCenter({ target }) {
      const { isAdmin } = this.props;
      const { costCenters } = this.props;
      const costCenterIndex = target && target.id;

      isAdmin &&
        this.setState({
          selectedCostCenter: costCenters && costCenters[costCenterIndex],
          costCenterIndex
        });
    }

    handleUserChange(changes) {
      const { isAdmin } = this.props;
      const { userChanges } = this.state;
      const id = changes.id;

      isAdmin &&
        this.setState({
          userChanges: {
            ...userChanges,
            [id]: {
              ...userChanges[id],
              ...changes
            }
          }
        });
    }

    saveChanges({ name, customBusinessRate }) {
      // TODO figure out how to apply cost centers to teams

      const { team, updateTeam, onSubmit, removeUserFromTeam } = this.props;
      const { selectedCostCenter } = this.state;
      const teamId = team.id;
      const members = [];
      const managers = [];

      this.getDeleteChanges().forEach(({ id }) =>
        removeUserFromTeam(teamId, id)
      );

      this.getRoleChanges().forEach(({ id, role }) =>
        (role.toLowerCase() === 'manager' ? managers : members).push(id)
      );

      updateTeam(teamId, {
        name,
        customBusinessRate,
        managers,
        members
      });

      onSubmit && onSubmit();
    }

    render() {
      const {
        members,
        managers,
        team,
        isAdmin,
        costCenters,
        handleSubmit,
        error,
        submitting,
        valid
      } = this.props;

      const { selectedCostCenter } = this.state;

      return (
        <form className="edit-team-page">
          <CompanyFormError>{error}</CompanyFormError>
          <div className="edit-team-inputs">
            {isAdmin && (
              <div className="edit-team-input">
                <h1>Name</h1>
                <Field
                  name="name"
                  className="edit-team-name-input"
                  component={renderCompanyTextField}
                  validate={required}
                />
              </div>
            )}
            <div className="edit-team-input">
              <h1>Expense rate</h1>
              <ExpenseRateField name="customBusinessRate" placeHolder="Rate" />
            </div>
            {/*<div className="edit-team-input">*/}
            {/*<h1>Cost Center</h1>*/}
            {/*<DropDown*/}
            {/*className="edit-team-cost-center-drop-down"*/}
            {/*onChange={this.selectCostCenter}*/}
            {/*value={selectedCostCenter && selectedCostCenter.name}*/}
            {/*>*/}
            {/*{costCenters}*/}
            {/*</DropDown>*/}
            {/*</div>*/}
          </div>
          {[[members, 'Members'], [managers, 'Managers']].map(
            ([elements, title]) => (
              <div className="edit-team-section">
                <h1 className="edit-team-section-title">{title}</h1>
                <ScrollBars
                  renderTrackHorizontal={props => (
                    <div
                      {...props}
                      className="track-horizontal"
                      style={{ display: 'none' }}
                    />
                  )}
                  renderThumbHorizontal={props => (
                    <div
                      {...props}
                      className="thumb-horizontal"
                      style={{ display: 'none' }}
                    />
                  )}
                >
                  <ul className="edit-team-page-members-list">
                    {elements &&
                      elements.map(element => (
                        <TeamMember
                          isAdmin={isAdmin}
                          onChange={this.handleUserChange}
                          team={team}
                          user={element}
                        />
                      ))}
                  </ul>
                </ScrollBars>
              </div>
            )
          )}

          {isAdmin && (
            <SaveButton
              type="button"
              className="edit-team-save-button"
              title="Confirm team changes"
              message={this.getChangesMessage()}
              confirm={true}
              onClick={handleSubmit(this.saveChanges)}
              disabled={submitting || !valid}
            >
              Save
            </SaveButton>
          )}
        </form>
      );
    }
  }
);
