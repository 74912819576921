import { connect } from 'react-redux';
import CostCentersPage from 'components/CompanyPages/Admin/SettingsPage/CostCentersPage/CostCentersPage';
import {
  mapStateToCostCenters,
  mapDispatchToCostCenters
} from './costCentersPageMappings';

export default connect(
  mapStateToCostCenters,
  mapDispatchToCostCenters
)(CostCentersPage);
