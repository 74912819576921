import { connect } from 'react-redux';
import RoleSelector from './RoleSelector';
import {
  mapStateToRoleSelector,
  mapDispatchToRoleSelector
} from './roleSelectorMappings';

export default connect(
  mapStateToRoleSelector,
  mapDispatchToRoleSelector
)(RoleSelector);
