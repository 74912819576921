import { createRequestReducer } from 'redux/requestReducer';
import {
  COMPANY_INVITE_USERS_FAILURE,
  COMPANY_INVITE_USERS_REQUEST,
  COMPANY_INVITE_USERS_SUCCESS
} from '../companyActionTypes';
export const userInviteRequests = createRequestReducer([
  [
    COMPANY_INVITE_USERS_REQUEST,
    COMPANY_INVITE_USERS_SUCCESS,
    COMPANY_INVITE_USERS_FAILURE
  ]
]);
export default userInviteRequests;
