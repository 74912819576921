import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  mapStateToGettingStartedPage,
  mapDispatchToGettingStartedPage
} from './gettingStartedPageMappings';
import GettingStartedPage from './GettingStartedPage';
export default withRouter(
  connect(mapStateToGettingStartedPage, mapDispatchToGettingStartedPage)(
    GettingStartedPage
  )
);
