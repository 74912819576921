import { connect } from 'react-redux';
import {
  mapStateToWorkHoursPage,
  mapDispatchToWorkHoursPage
} from './workHoursPageMappings';
import WorkHoursPage from './WorkHoursPage';
export default connect(
  mapStateToWorkHoursPage,
  mapDispatchToWorkHoursPage
)(WorkHoursPage);
