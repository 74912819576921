import { connect } from 'react-redux';
import trips from 'ducks/trips';
import selection from 'ducks/selection';
import EditMultiplePage from './EditMultiplePage';

const mapStateToProps = state => ({
  ids: selection.selectors.getSelectedIds(state),
  total: selection.selectors.getSelectedValue(state),
  potential: selection.selectors.getSelectedPotentialValue(state)
});

const mapDispatchToProps = dispatch => ({
  updateSelectedTrips: params =>
    dispatch(trips.actions.updateSelectedTrips(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMultiplePage);
