import { numeric } from '../../lib/normalizers';
import { getActive, getCurrentUser } from '../../ducks/users/selectors';
import { getUsersCountry } from '../../ducks/countries/selectors';
export const mapStateToDistanceSelector = (
  state,
  { activeUser, distance, value }
) => {
  const user = (activeUser ? getActive : getCurrentUser)(state);
  return {
    normalize: input => {
      const country = getUsersCountry(state);
      return country && numeric(country.countryCode, input);
    },
    value: distance || value,
    distanceMeasure: user.settings.metric ? 'km' : 'mi'
  };
};
