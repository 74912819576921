import getCompany from 'ducks/company/companySelectors/company/getCompany';
import userCanViewAdminPages from 'ducks/users/usersSelectors/userCanViewAdminPages';
import { getCompanyId, getRole } from 'lib/auth';

export const mapStateToHeader = state => ({
  canViewAdminPages: userCanViewAdminPages(),
  company: getCompany(state),
  companyId: getCompanyId(),

  userRole: getRole()
});

export const mapDispatchToHeader = {};
