import React from 'react';
import ImageContainer from 'components/CompanyPages/lib/ImageContainer';
import capitalizeFirstLetter from 'lib/capitalizeFirstLetter';
import './EditedUserElement.css';

export const EditedUserElement = ({ user, onDelete }) => {
  const { email, status, name, image } = user || {};
  const cancelled = status === 'cancelled';

  return (
    user && (
      <div className="edited-user-element">
        <ImageContainer
          className="invited-user-image"
          src={image}
          email={email}
        />
        <div className="edited-user-info">
          <h1 className="edited-user-name">
            {name ||
              (cancelled ? 'Deactivated' : capitalizeFirstLetter(status))}
          </h1>
          <h1 className="edited-user-email">{email}</h1>
          {!cancelled && (
            <button className="remove-edited-user-button" onClick={onDelete}>
              Deactivate
            </button>
          )}
        </div>
      </div>
    )
  );
};

export default EditedUserElement;
