import { connect } from 'react-redux';
import {
  mapStateToRulesPage,
  mapDispatchToRulesPage
} from './rulesPageMappings';
import RulesPage from './RulesPage';
export default connect(
  mapStateToRulesPage,
  mapDispatchToRulesPage
)(RulesPage);
