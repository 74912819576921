import {
  ADD_LOCATION_REQUEST,
  ADD_LOCATION_FAILURE,
  ADD_LOCATION_SUCCESS
} from '../locationActionTypes';
import {
  locationRequest,
  locationFailure,
  locationSuccess,
  addressNotFound
} from './locationActionCreators';
import selectLocation from 'ducks/locations/locationActions/selectLocation';
import _ from 'lodash';
import createLocation from 'ducks/locations/lib/createLocation';
import { getCurrentUser } from 'ducks/users/selectors';
import { putUser } from 'api/endpoints/user/users';

const action = 'save';

export const addLocationRequest = locationRequest(ADD_LOCATION_REQUEST, action);
export const addLocationSuccess = locationSuccess(ADD_LOCATION_SUCCESS, action);
export const addLocationFailure = locationFailure(ADD_LOCATION_FAILURE, action);
export const formatLocationRequest = (
  id,
  name,
  address,
  coordinates,
  applicationDate
) => ({
  id,
  locations: [createLocation(name, address, coordinates, applicationDate)]
});

export default ({ name, address, coordinates, appliedAt }) => (
  dispatch,
  getState
) => {
  const { id } = getCurrentUser(getState());
  dispatch(addLocationRequest(id));
  return _.isArray(coordinates)
    ? putUser(
        id,
        formatLocationRequest(id, name, address, coordinates, appliedAt)
      )
        .then(({ data }) => {
          const locations = data.user.locations;
          dispatch(
            selectLocation(
              locations.filter(
                location =>
                  location.name === name && location.address === address
              )[0]
            )
          );
          return dispatch(addLocationSuccess(data.user));
        })
        .catch(error => dispatch(addLocationFailure(error, id)))
    : dispatch(addressNotFound());
};
