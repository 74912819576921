import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'class-names';
import FontAwesome from 'react-fontawesome';
import { Scrollbars } from 'react-custom-scrollbars';
import Checkbox from 'components/Checkbox';
import './Dropdown.css';

const propTypes = {
  value: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(PropTypes.array).isRequired,
  onSelect: PropTypes.func.isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
  selected: PropTypes.any,
  closeOnSelect: PropTypes.bool
};

const defaultProps = {
  value: '',
  options: [],
  onSelect: () => null,
  inline: false,
  selected: null,
  closeOnSelect: true
};

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, selected: null };
    this.toggle = this.toggle.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, true);
  }

  handleOutsideClick(event) {
    const { isOpen } = this.state;
    if (isOpen && (!this.node || !this.node.contains(event.target))) {
      this.setState({ isOpen: false });
    }
  }

  toggle() {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  isMultiSelect() {
    const { selected } = this.props;
    return _.isArray(selected);
  }

  isSelected(id) {
    const { selected } = this.props;
    if (_.isArray(selected)) {
      return (
        selected.indexOf(id) > -1 || (id === null && selected.length === 0)
      );
    } else {
      return selected === id;
    }
  }
  onClick(selected) {
    const { closeOnSelect, onSelect } = this.props;
    closeOnSelect && this.setState({ isOpen: false });
    return onSelect(selected);
  }
  renderOptions() {
    const { options } = this.props;
    return options.map(option => {
      const selection = option[0];
      const selected = this.isSelected(selection);
      return (
        <div
          key={selection}
          className={classNames('dropdown__item', {
            dropdown__item_selected: selected
          })}
          onClick={() => this.onClick(selection)}
        >
          {this.isMultiSelect() &&
          selection !== null && // 'All Categories in purposes filter'
            selection !== -2 && (
              <Checkbox
                checked={
                  selected // 'Autoclassified in purposes filter'
                }
                dark
              />
            )}
          {option[1]}
        </div>
      );
    });
  }

  render() {
    const { disabled, value, icon, className, inline, prelogin } = this.props;
    const { isOpen } = this.state;
    const options = this.renderOptions();
    return (
      <div
        className={classNames('dropdown', className, {
          dropdown_disabled: disabled,
          dropdown_open: isOpen,
          dropdown_inline: inline,
          dropdown_prelogin: prelogin
        })}
        key="drop-down-list"
        ref={node => (this.node = node)}
      >
        <button
          type="button"
          className={classNames('dropdown__button', {
            dropdown__button_open: isOpen
          })}
          aria-haspopup="true"
          aria-expanded={isOpen.toString()}
          onClick={this.toggle}
        >
          {icon && (
            <FontAwesome className="dropdown__button__icon" name={icon} />
          )}
          <span className="dropdown__button__inner">{value}</span>
          {inline && (
            <span>
              {' '}
              <FontAwesome name="caret-down" />
            </span>
          )}
        </button>
        <div
          className={classNames('dropdown__menu', {
            dropdown__menu_open: isOpen
          })}
        >
          {prelogin ? <Scrollbars>{options}</Scrollbars> : options}
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;
