import { companyApi } from '../../client';

export const postAutoApprovalRule = autoApprovalRule =>
  companyApi.post('/auto_approval_rules', { autoApprovalRule });

export const getAutoApprovalRules = () =>
  companyApi.get('/auto_approval_rules');

export const getAutoApprovalRule = id =>
  companyApi.get(`/auto_approval_rules/${id}`);

export const putAutoApprovalRule = (id, autoApprovalRule) =>
  companyApi.put(`/auto_approval_rules/${id}`, { autoApprovalRule });

export const deleteAutoApprovalRule = id =>
  companyApi.delete(`/auto_approval_rules/${id}`);
