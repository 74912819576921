import React, { Component } from 'react';
import Modal from 'components/Modal';
import FontAwesome from 'react-fontawesome';
import Button from 'components/Button';
const ENTER_KEY = 13;
const ESC_KEY = 27;
export default class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.checkKeyPress = this.checkKeyPress.bind(this);
  }
  componentDidMount() {
    document.addEventListener('keypress', this.handleKeyPress, true);
  }
  componentWillUnmount() {
    document.removeEventListener('Keypress', this.handleKeyPress, true);
  }
  checkKeyPress(event, key) {
    return event.charCode === key || event.keyCode === key;
  }
  handleKeyPress(event) {
    const { onConfirm, isOpen, onCancel } = this.props;
    if (isOpen) {
      this.checkKeyPress(event, ENTER_KEY) && onConfirm();
      this.checkKeyPress(event, ESC_KEY) && onCancel();
    }
    return false;
  }
  render() {
    const {
      isOpen,
      title,
      onCancel,
      onConfirm,
      message,
      key,
      ...props
    } = this.props;
    return (
      <Modal
        {...props}
        key={key}
        isOpen={isOpen}
        title={title}
        onClose={onCancel}
      >
        <div className="confirm">
          {message && <div className="confirmation-message">{message}</div>}
          <div className="confirmation-buttons">
            <Button className="confirmation-button" onClick={onConfirm}>
              <FontAwesome name="check" /> OK
            </Button>
            <Button
              className="confirmation-button"
              onClick={onCancel}
              color="cancel"
            >
              <FontAwesome name="times" /> Cancel
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
