import { connect } from 'react-redux';
import {
  mapStateToCompanySignUpTerms,
  mapDispatchToCompanySignUpTerms
} from './companySignUpTermsMappings';
import CompanySignUpTerms from './CompanySignUpTerms';
export default connect(
  mapStateToCompanySignUpTerms,
  mapDispatchToCompanySignUpTerms
)(CompanySignUpTerms);
