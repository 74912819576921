import React from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Notification from './Notification';

import './Notifications.css';

const Notifications = ({ notifications, dismiss }) =>
  <div className="notifications">
    <CSSTransitionGroup
      transitionName="notification"
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
    >
      {notifications.map(notification =>
        <Notification
          key={notification.id}
          type={notification.type}
          onClick={() => dismiss(notification.id)}
        >
          {notification.message}
        </Notification>
      )}
    </CSSTransitionGroup>
  </div>;

export default Notifications;
