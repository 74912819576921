import { getUserRole } from './getUserRole';
import { rolesByName } from 'components/CompanyPages/lib/roles';
import { getRole } from 'lib/auth';

export const userIsAdmin = state => {
  const { admin, owner } = rolesByName;
  const userRole = getUserRole(state);
  const roleFromAuth = getRole();
  const role = userRole || roleFromAuth;

  return role && (role.toLowerCase() === admin.id || role.toLowerCase === owner.id);
};

export default userIsAdmin;
