import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import TextBubble from 'components/TextBubble';
import './InformationPopUp.css';

export default class InformationPopUp extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { open } = this.state;

    this.setState({ open: !open });
  }

  render() {
    const { open } = this.state;
    const { message, children, className } = this.props;
    return (
      <div
        className={`info-pop-up ${className ? ' ' + className : ''}`}
        onMouseEnter={this.toggle}
        onMouseLeave={this.toggle}
      >
        <button className="info-pop-up-button">
          <FontAwesome name={'far fa-question-circle'} />
        </button>
        {open && (
          <TextBubble
            className="info-text-bubble"
            message={message || children}
          />
        )}
      </div>
    );
  }
}
