import raven, { logError, getEnvironment } from 'raven';
const middleware = store => next => action => {
  const environment = getEnvironment();
  try {
    // create 'breadcrumb' for use in error reporting
    raven.captureBreadcrumb({
      category: 'redux',
      message: action.type
    });
    next(action);
  } catch (error) {
    if (environment === 'production') {
      // send error to sentry.io
      logError(error, {
        extra: {
          action,
          state: store.getState()
        }
      });
    } else {
      throw error; // don't suppress error in development
    }
  }
};

export default middleware;
