import { getAutoApprovalRules } from 'ducks/rules/rulesSelectors/getAutoApprovalRules';

export const getAutoApprovalRulesArray = state => {
  const autoApprovalRules = getAutoApprovalRules(state);
  return (
    autoApprovalRules &&
    Object.keys(autoApprovalRules).map(id => autoApprovalRules[id])
  );
};

export default getAutoApprovalRulesArray;
