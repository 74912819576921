import React, { Component } from 'react';
import moment from 'moment';
import confirmation from 'components/Confirmation';
import ImageContainer from 'components/CompanyPages/lib/ImageContainer';
import capitalizeFirstLetter from 'lib/capitalizeFirstLetter';
import CheckBox from 'components/CompanyPages/lib/CheckableField';
import Money from 'components/Money';

const Button = props => <button {...props} />;
const ResendInviteButton = confirmation(Button);
const DeactivateUserButton = confirmation(Button);
const dateFormat = 'MMM DD YYYY';

export default class InvitedUserElement extends Component {
  constructor(props) {
    super(props);

    this.selectUser = this.selectUser.bind(this);
    this.onResend = this.onResend.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onResend() {
    const { resendInvitation, id } = this.props;

    resendInvitation && resendInvitation(id);
  }

  isActive() {
    return this.props.class === 'user';
  }

  onCancel() {
    const { cancelInvitation, deactivateUser, id } = this.props;
    const cancelUserElement = this.isActive()
      ? deactivateUser
      : cancelInvitation;

    cancelUserElement && cancelUserElement(id);
  }

  selectUser() {
    const { onChange, id } = this.props;

    onChange && onChange(id);
  }

  render() {
    const {
      id,
      email,
      name,
      employeeId,
      unitOfMeasure,
      customBusinessRate,
      image,
      status,
      role,
      team,
      cancelled,
      isChecked,
      updatedAt,
      isAdmin
    } = this.props;

    const isActive = this.isActive();

    return (
      <div className="invited-user-element">
        <label className="invited-user-check-box first-column users-page-column">
          <CheckBox
            id={id}
            type="checkbox"
            checked={isChecked}
            onChange={this.selectUser}
          />
          <span>
            <ImageContainer
              className="invited-user-image"
              email={email}
              src={image}
            />
          </span>
        </label>
        <div className="invited-user-info second-column users-page-column">
          <h1 className={employeeId ? 'visible' : 'hidden'}>
            id# {employeeId}
          </h1>
          <h1>{name || email}</h1>
          <h2>
            {capitalizeFirstLetter(
              cancelled ? 'Deactivated' : isActive ? 'Active' : 'Invited'
            )}{' '}
            {!cancelled ? (role === 'admin' ? 'administrator' : role) : ''}{' '}
            {isActive ? 'since' : 'on'} {moment(updatedAt).format(dateFormat)}
          </h2>
          {isAdmin && [
            <DeactivateUserButton
              confirm={true}
              onClick={this.onCancel}
              className={`cancel-invited-user${cancelled ? ' hidden' : ''}`}
              title={`Deactivate member`}
              message={`Are you sure you want to deactivate ${email}?`}
            >
              Deactivate
            </DeactivateUserButton>,
            (!isActive &&
              !cancelled && [
                <ResendInviteButton
                  confirm={true}
                  onClick={this.onResend}
                  className="resend-invitation"
                  title={`Resend invitation`}
                  message={`Are you sure you want resend an invitation email to ${email}?`}
                >
                  Resend Invitation
                </ResendInviteButton>
              ])
          ]}
        </div>
        <div className="third-column users-page-column invited-user-team">
          <h1>{team && team.name}</h1>
        </div>
        <div className="fourth-column users-page-column invited-user-manager">
          <h1>
            <Money>{customBusinessRate}</Money> / {unitOfMeasure}
          </h1>
        </div>
      </div>
    );
  }
}
