import * as autoApprovalRules from './autoApprovalRules';
import * as autoSubmissionRules from './autoSubmissionRules';
import * as company from './company';
import * as costCenters from './costCenters';
import * as invitations from './invitations';
import * as kpiReports from './kpiReports';
import * as locations from './locations';
import * as purposes from './purposes';
import * as reports from './reports';
import * as stripe from '../stripe';
import * as teams from './teams';
import * as trips from './trips';
import * as users from './users';
import * as vehicles from './vehicles';

export default {
  ...autoApprovalRules,
  ...autoSubmissionRules,
  ...company,
  ...costCenters,
  ...invitations,
  ...kpiReports,
  ...locations,
  ...purposes,
  ...reports,
  ...stripe,
  ...teams,
  ...trips,
  ...users,
  ...vehicles
};
