import React from 'react';
import classNames from 'class-names';
import ErrorBoundary from 'components/ErrorBoundary';

import './Panel.css';

const Panel = ({ style, children, title, sidebar, filter, ...props }) => {
  let titleClass = 'panel__title';
  if (filter) {
    titleClass += ' center__title__with__filter';
  }
  return (
    <div
      style={style}
      className={classNames('panel', { panel_sidebar: sidebar })}
      {...props}
    >
      <div className="panel__header">
        {title && <div className={titleClass}>{title}</div>}
        {filter && (
          <div className="panel__filter">
            {filter}
            <div className="filter__divider" />
          </div>
        )}
      </div>
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
};

export default Panel;
