import ColoredList from 'components/CompanyPages/lib/ColoredList';
import React from 'react';
import FontAwesome from 'react-fontawesome';
import Image from 'components/CompanyPages/lib/ImageContainer';
import ScrollBars from 'components/Scrollbars';
import './MembersList.css';

export default ({ members, onDrag, isAdmin }) => (
  <div className="members-list-component">
    <div className="members-header">
      <h1 className="members-amount">{`${
        members ? members.length : 0
      } Members`}</h1>
      {isAdmin && (
        <div className="members-description">
          <div className="members-description-icon">
            <FontAwesome name="arrows" />
          </div>
          <div className="members-description-text">
            <h1>Drag & Drop on Team</h1>
          </div>
        </div>
      )}
    </div>
    {members && (
      <div className="members-list">
        <ScrollBars>
          <ColoredList uniqueKey={'members-list'}>
            {members.map(({ id, image, email }, index) => (
              <div
                id={id}
                key={`member-${index}`}
                draggable={isAdmin}
                onDragStart={onDrag}
                className={`members-list-element${isAdmin ? 'click-able' : ''}`}
              >
                <Image
                  className="members-list-element-image"
                  image={image}
                  email={email}
                />
                <h2 className="members-list-element-name">{email}</h2>
              </div>
            ))}
          </ColoredList>
        </ScrollBars>
      </div>
    )}
  </div>
);
