import { SET_PAGE_NUMBER } from '../paginationActionTypes';
import createReducer from 'redux/createReducer';

export const pageNumber = createReducer(1, {
  [SET_PAGE_NUMBER]: (state, { payload, pageName }) =>
    !isNaN(payload)
      ? {
          ...state,
          [pageName]: payload
        }
      : state
});

export default pageNumber;
