import React, { Component } from 'react';
import InvitedUserElement from './InvitedUserElement';
import Paginator from 'components/Paginator';
import 'components/CompanyPages/Admin/SettingsPage/UsersPage/UsersPage.css';
import './InvitedUserList.css';

export default ({
  users,
  total,
  pageNumber,
  loadMembers,
  pageSize,
  isSelected,
  onChange
}) => (
  <Paginator
    getPage={loadMembers}
    numberOfElements={total}
    pageNumber={pageNumber}
    pageSize={pageSize}
  >
    {users
      ? users.map((user, index) => (
          <InvitedUserElement
            index={index}
            key={`invited-user-${index}`}
            isChecked={isSelected(user.id)}
            onChange={onChange}
            {...user}
          />
        ))
      : []}
  </Paginator>
);
