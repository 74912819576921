const matchSortLocations = (locations, update) =>
  locations.map(({ id }) => {
    return update.find(location => location.id === id);
  });
export const matchSortUserLocations = (user, locations) => ({
  ...user,
  locations: locations
    ? matchSortLocations(locations, user.locations)
    : user.locations
});
export default matchSortLocations;
