import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import HeaderDropdown from './HeaderDropdown';
import trips from 'ducks/trips';
import users from 'ducks/users';
import { getScopes } from 'lib/timeScopes';
import { getUsersCountryCode } from '../../ducks/countries/selectors';

const mapStateToProps = state => {
  const earliestTrip = _.get(users.selectors.getActive(state), 'earliestTrip');
  return {
    getTimeScopes: () => getScopes(earliestTrip, getUsersCountryCode(state))
  };
};

const mapDispatchToProps = dispatch => ({
  setFilters: filters => dispatch(trips.actions.setFilters(filters))
});

const ReportDropdown = ({ className, history, setFilters, getTimeScopes }) => (
  <HeaderDropdown
    className={className}
    value="Report"
    options={_.entries(getTimeScopes())}
    onSelect={timeScope => {
      setFilters({
        timeScope,
        category: [],
        vehicle: null,
        timeScopeMode: true
      });
      history.push('/reports/new');
    }}
  />
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportDropdown));
