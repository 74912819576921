import React from 'react';
import { NavLink } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import './GetStartedLink.css';
export default () => (
  <NavLink className="get-started-link" to="/getting_started">
    <FontAwesome name="list" className="icon" />
    <p className="title">Get Started</p>
  </NavLink>
);
