import { connect } from 'react-redux';
import { invalidate } from 'ducks/session/sessionActions';
import { send, clear } from 'ducks/notifications/actions';
import { showInvalidSubscriptionMessage } from '../../ducks/notifications/actions';
import { redirectToSubscribe } from '../../ducks/session/sessionActions';
import Paywall from './Paywall';
export default connect(null, {
  invalidate,
  send,
  clear,
  showInvalidSubscriptionMessage: () => () =>
    showInvalidSubscriptionMessage(redirectToSubscribe)
})(Paywall);
