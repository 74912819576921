import { getTeams as loadTeams } from 'ducks/teams/teamsActions/getTeams';
import { getCompanyMembers as loadUsers } from 'ducks/company/companyActions/users/getCompanyMembers';
import { getCompanyInvites as loadInvitations } from 'ducks/company/companyActions/invitations/getCompanyInvites';
import combineFilters from 'components/CompanyPages/lib/Filters/lib/combineFilters';
import userRoleFilter from 'components/CompanyPages/lib/Filters/lib/userRoleFilter';
import userStatusFilter from 'components/CompanyPages/lib/Filters/lib/userStatusFilter';
import getTeamFilters from 'components/CompanyPages/lib/Filters/lib/getTeamFilters';
import getManagerFilters from 'components/CompanyPages/lib/Filters/lib/getManagerFilters';
import userIsAdmin from 'ducks/users/usersSelectors/userIsAdmin';
import setUserStatusFilter from 'ducks/company/companyActions/setUserStatusFilter';
import getCompanyMembersAndInvites from 'ducks/company/companySelectors/companyMembers/getCompanyMembersAndInvites';
import getCompanyUsersPageRequests from 'ducks/company/companySelectors/companyRequests/getCompanyUsersPageRequests';
import { getCompanyMembersPage } from 'ducks/company/companyActions/users/getCompanyMembersPage';
import { setCompanyMembersPageSize } from 'ducks/company/companyActions/users/setCompanyMembersPageSize';

export const mapStateToUsersPage = state => ({
  pageName: 'companyUsers',
  requestsState: getCompanyUsersPageRequests(state),
  isAdmin: userIsAdmin(state),
  users: getCompanyMembersAndInvites(state),
  filters: combineFilters({
    role: userRoleFilter,
    status: userStatusFilter,
    teamId: getTeamFilters(state),
    managerId: getManagerFilters(state)
  })
});

export const mapDispatchToUsersPage = {
  loadTeams,
  loadUsers,
  loadInvitations,
  setUserStatusFilter,
  getCompanyMembersPage,
  setCompanyMembersPageSize
};
