import React from 'react';
import FontAwesome from 'react-fontawesome';
import './NavIcon.css';

const fontAwesomeMapping = {
  'whats-new': 'bell',
  help: 'question-circle',
  teams: 'users-cog'
};
const NavIcon = ({ icon, active, title, ...props }) => (
  <div
    className={`nav-icon nav-icon_${icon}${active ? ' nav-icon_active' : ''}`}
    {...props}
  >
    {fontAwesomeMapping[icon] ? (
      <FontAwesome name={fontAwesomeMapping[icon]} />
    ) : (
      <span />
    )}
    <div className="nav-icon__tooltip">{title}</div>
  </div>
);
export default NavIcon;
