import {
  DELETE_LOCATION_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS
} from 'ducks/locations/locationActionTypes';
import locationModifier from 'ducks/locations/locationActions/lib/locationModifier';

export default locationModifier(
  'delete',
  [DELETE_LOCATION_SUCCESS, DELETE_LOCATION_REQUEST, DELETE_LOCATION_FAILURE],
  'destroy'
);
