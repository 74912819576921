export const addFailureMessage = (action, message) => (...args) => dispatch =>
  dispatch(action(...args)).catch(() =>
    dispatch({
      type: 'FAILURE_NOTIFICATION',
      meta: {
        alert: {
          type: 'failure',
          message
        }
      }
    })
  );

export default addFailureMessage;
