import { connect } from 'react-redux';
import MyLocationsHeader from './MyLocationsHeader';
import {
  mapDispatchToMyLocationsHeader,
  mapStateToMyLocationsHeader
} from './MyLocationsHeaderMappings';

export default connect(
  mapStateToMyLocationsHeader,
  mapDispatchToMyLocationsHeader
)(MyLocationsHeader);
