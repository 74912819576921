import React, { Component } from 'react';
import classNames from 'class-names';
import Trigger from 'rc-trigger';
import ReactDOM from 'react-dom';
import Panel from './Panel';
import './TimePicker.css';

class TimePicker extends Component {
  render() {
    const {
      parent,
      minuteInterval,
      format,
      className,
      value,
      onChange,
      ...props
    } = this.props;
    return (
      <Trigger
        action={['click']}
        popup={
          <Panel
            minuteInterval={minuteInterval}
            className="time-picker-popup"
            value={value}
            onSelect={onChange}
          />
        }
        popupAlign={{ points: ['tl', 'bl'] }}
        destroyPopupOnHide
        getPopupContainer={
          parent ? () => ReactDOM.findDOMNode(parent) : undefined
        }
      >
        <input
          type="text"
          // note that 'time-input' class adds margins
          className={classNames('textbox time-input', className)}
          value={value.format(format || 'LT')}
          readOnly
          {...props}
        />
      </Trigger>
    );
  }
}

export default TimePicker;
