import { UPDATE_PAGE } from 'ducks/pagination/paginationActionTypes';
import { getPageNumber } from 'ducks/pagination/paginationSelectors/getPageNumber';

export const updatePage = pageName => element => (dispatch, getState) => {
  const state = getState && getState();
  const pageNumber = getPageNumber(pageName)(state);

  return dispatch({
    type: UPDATE_PAGE,
    payload: { pageNumber, element },
    pageName
  });
};

export default updatePage;
