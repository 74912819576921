import React from 'react';
import { connect } from 'react-redux';
import session from 'ducks/session';
import NavIcon from './NavIcon';

const SignOutButton = ({ dispatch }) => (
  <a href="/" role="button" onClick={() => dispatch(session.actions.signOut())}>
    <NavIcon icon="sign-out" title="Sign out" />
  </a>
);

export default connect()(SignOutButton);
