export const SET_FILTERS = 'tripsStore/SET_FILTERS';

export const GET_PAGE_REQUEST = 'tripsStore/GET_PAGE_REQUEST';
export const GET_PAGE_SUCCESS = 'tripsStore/GET_PAGE_SUCCESS';
export const GET_PAGE_FAILURE = 'tripsStore/GET_PAGE_FAILURE';

export const SET_PAGE = 'tripsStore/SET_PAGE';

export const INVALIDATE = 'tripsStore/INVALIDATE';

export const UPDATE_REQUEST = 'tripsStore/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'tripsStore/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'tripsStore/UPDATE_FAILURE';

export const UPDATE_MULTIPLE_REQUEST = 'tripsStore/UPDATE_MULTIPLE_REQUEST';
export const UPDATE_MULTIPLE_SUCCESS = 'tripsStore/UPDATE_MULTIPLE_SUCCESS';
export const UPDATE_MULTIPLE_FAILURE = 'tripsStore/UPDATE_MULTIPLE_FAILURE';

export const DELETE_REQUEST = 'tripsStore/DELETE_REQUEST';
export const DELETE_SUCCESS = 'tripsStore/DELETE_SUCCESS';
export const DELETE_FAILURE = 'tripsStore/DELETE_FAILURE';

export const MERGE_REQUEST = 'tripsStore/MERGE_REQUEST';
export const MERGE_SUCCESS = 'tripsStore/MERGE_SUCCESS';
export const MERGE_FAILURE = 'tripsStore/MERGE_FAILURE';

export const ADD_REQUEST = 'tripsStore/ADD_REQUEST';
export const ADD_SUCCESS = 'tripsStore/ADD_SUCCESS';
export const ADD_FAILURE = 'tripsStore/ADD_FAILURE';

export const RECALCULATE_TRIP_REQUEST = 'tripStore/RECALCULATE_TRIP_REQUEST';
export const RECALCULATE_TRIP_SUCCESS = 'tripStore/RECALCULATE_TRIP_SUCCESS';
export const RECALCULATE_TRIP_FAILURE = 'tripStore/RECALCULATE_TRIP_FAILURE';

export const STATISTICS_REQUEST = 'tripsStore/STATISTICS_REQUEST';
export const STATISTICS_SUCCESS = 'tripsStore/STATISTICS_SUCCESS';
export const STATISTICS_FAILURE = 'tripsStore/STATISTICS_FAILURE';

export const SET_ACTIVE = 'tripsStore/SET_ACTIVE';

export const OPEN_DISTANCE_FILTER_MODAL =
  'tripStore/OPEN_DISTANCE_FILTER_MODAL';
export const CLOSE_DISTANCE_FILTER_MODAL =
  'tripStore/CLOSE_DISTANCE_FILTER_MODAL';
