import getState from './getLocationState';
import { requestState } from 'lib/requests';
import { ADD_LOCATION_REQUEST_ID } from 'ducks/locations/constants';
export const getLocationRequests = state => {
  const locations = getState(state);
  return locations && locations.requests;
};
export const getRequest = state => {
  const requests = getLocationRequests(state);
  return requests && requests[ADD_LOCATION_REQUEST_ID];
};
export const compareLocationRequestState = comparison => state => {
  const request = getRequest(state);
  return request && requestState(request) === comparison;
};
