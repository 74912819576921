import { SELECT_COMPANY_LOCATION } from 'ducks/company/companyActionTypes';

export const selectLocation = location => ({
  type: SELECT_COMPANY_LOCATION,
  payload: location && location.id
});

export const selectCompanyLocation = location => dispatch =>
  dispatch(selectLocation(location));

export default selectCompanyLocation;
