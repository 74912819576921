import {
  COMPANY_INVITE_USERS_FAILURE,
  COMPANY_INVITE_USERS_REQUEST,
  COMPANY_INVITE_USERS_SUCCESS
} from '../../companyActionTypes';
import { MEMBER } from 'lib/userRoles';
import { postCompanyInvites } from 'api/endpoints/company/invitations';
import stringInvitesToArray from 'lib/commaSeparatedStringToArray';
import validateInviteEmails from '../../lib/validateInviteEmails';
import { INVALID_EMAIL_MESSAGE } from 'lib/validators';

export const inviteUsersRequest = payload => ({
  type: COMPANY_INVITE_USERS_REQUEST,
  payload
});

export const inviteUsersSuccess = payload => ({
  type: COMPANY_INVITE_USERS_SUCCESS,
  payload,
  meta: { alert: { type: 'success', message: 'User successfully invited' } }
});

export const inviteUsersFailure = error => ({
  type: COMPANY_INVITE_USERS_FAILURE,
  payload: error,
  error,
  meta: {
    alert: {
      type: 'error',
      message:
        (error && error.errors && error.errors._error) ||
        'Failed to invite user'
    }
  }
});

export const formatInvitation = (users, role, teamId) => {
  return {
    users,
    role,
    teamId
  };
};

export const inviteUsers = (users, role = MEMBER, teamId) => dispatch => {
  const invitations = stringInvitesToArray(users) || [];
  const invitation = formatInvitation(invitations, role, teamId);
  if (validateInviteEmails(invitations)) {
    dispatch(inviteUsersRequest(invitation));
    return postCompanyInvites(invitation)
      .then(({ data }) => dispatch(inviteUsersSuccess(data.invitationTokens)))
      .catch(error => dispatch(inviteUsersFailure(error)));
  }
  return dispatch(inviteUsersFailure(INVALID_EMAIL_MESSAGE));
};

export default inviteUsers;
