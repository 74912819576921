const transforms = {
  userSignIn: [
    {
      regex: /legacy user detected/i,
      message: 'Please upgrade your phone app to v2 before logging in.'
    }
  ]
};

function transformError(errorMsg, context) {
  if (!(errorMsg && errorMsg.length)) return errorMsg;

  const localTransforms = transforms[context] || [];

  // return the "message" attached to the first matching regex, or the original
  // "errorMsg" if none match.
  return localTransforms.reduce(
    (current, { regex, message }) => (regex.test(errorMsg) ? message : current),
    errorMsg
  );
}

export default transformError;
