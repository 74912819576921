const checkForMobile = new RegExp(
  'Android|webOS|iPhone|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile',
  'i'
);
export const getUserAgent = () => navigator.userAgent;
export const checkForIpad = agent => agent.includes('iPad');
export const checkForMobileDevices = agent =>
  checkForMobile.test(agent) && !checkForIpad(agent);
export const isMobileDevice = () => checkForMobileDevices(getUserAgent());
export const isIpad = () => checkForIpad(getUserAgent());
