import { companyApi } from '../../client';
import client from '../../client';

export const postCompanyInvites = invitations =>
  companyApi.post(`/invitations`, invitations);

export const postCompanyBulkInvitations = invitations =>
  companyApi.post(`/invitations/bulk`, invitations);

export const deleteCompanyInvitation = id =>
  companyApi.delete(`/invitations/${id}`);

export const putCompanyInvitation = (id, invitationToken) =>
  companyApi.put(`/invitations/${id}`, { invitationToken });

export const postInviteUserToCompany = invitation =>
  companyApi.post(`/users/invite`, invitation);

export const confirmUserInvitation = (
  uuid,
  name,
  password,
  passwordConfirmation
) =>
  client.post(`/company/complete_invitation`, {
    name,
    password,
    passwordConfirmation,
    uuid
  });

export const resendInvitation = id =>
  companyApi.post(`/invitations/${id}/resend`);

export const getInvites = (params = {}) =>
  companyApi.get(`/invitations`, { params });
