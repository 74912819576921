import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import CompanyDropDown from 'components/CompanyPages/lib/CompanyDropDown';
import ConfirmationButton from 'components/Confirmation/Button';
import Money from 'components/Money';
import './Purpose.css';

export default class Purpose extends Component {
  constructor(props) {
    const { purpose, teams } = props;
    const team =
      purpose && teams && teams.find(({ id }) => id === purpose.teamId);
    const teamName = team && team.name;

    super(props);

    this.state = {
      costCenter: 'Cost center (optional)',
      team: teamName || 'All teams',
      teamIndex: null,
      costCenterIndex: null
    };

    this.setTeam = this.setTeam.bind(this);
    this.setCostCenter = this.setCostCenter.bind(this);
    this.hidePurpose = this.hidePurpose.bind(this);
    this.deletePurpose = this.deletePurpose.bind(this);
    this.updatePurpose = this.updatePurpose.bind(this);
  }

  setTeam({ target }) {
    const { teams, updateCompanyPurpose, purpose } = this.props;
    const teamIndex = target.id;
    const team = teams && teams[teamIndex];
    const teamId = team && team.id;

    // TODO implement this when associations between teams and purposes are supported
    // teamId && updateCompanyPurpose({...purpose, teamId});
    this.setState({
      team: team && team.name,
      teamIndex
    });
  }

  setCostCenter({ target }) {
    const { costCenters } = this.props;
    const costCenterIndex = target.id;
    const costCenter = costCenters && costCenters[costCenterIndex];

    this.updatePurpose({ costCenter: costCenter.id });
    this.setState({
      costCenter: costCenter && costCenter.name,
      costCenterIndex
    });
  }

  hidePurpose() {
    const { hideCompanyPurpose, purpose } = this.props;

    purpose &&
      hideCompanyPurpose &&
      hideCompanyPurpose(purpose.id, purpose && !purpose.hidden);
  }

  deletePurpose() {
    const { deleteCompanyPurpose, purpose } = this.props;

    purpose && deleteCompanyPurpose && deleteCompanyPurpose(purpose.id);
  }

  updatePurpose() {
    const { purpose, modifyPurpose } = this.props;

    modifyPurpose && modifyPurpose(purpose);
  }

  render() {
    const { purpose, rate, unitOfMeasure, teams, costCenters } = this.props;
    const { name, id, hidden } = purpose || {};
    const { costCenter, team } = this.state;

    return (
      <div id={id} className="purpose">
        <div className="purpose-section">
          <button
            className="modify-purpose-button purpose-option"
            onClick={this.updatePurpose}
          >
            <FontAwesome name="bars" />
          </button>
          <h1 className="purpose-name">{name}</h1>
          <ConfirmationButton
            confirm={true}
            title="Delete purpose"
            message={`Are you sure you want to delete the ${name} purpose?`}
            className="delete-purpose-button purpose-option"
            onClick={this.deletePurpose}
          >
            <FontAwesome name="trash" />
          </ConfirmationButton>
          <ConfirmationButton
            confirm={true}
            title={`${hidden ? 'Show' : 'Hide'} purpose`}
            message={`Are you sure you want to ${
              hidden ? 'show' : 'hide'
            } the ${name} purpose?`}
            className="hide-purpose-button purpose-option"
            onClick={this.hidePurpose}
          >
            <FontAwesome name="eye-slash" />
          </ConfirmationButton>
          <h1 className="purpose-rate">
            <Money>{rate}</Money> / {unitOfMeasure}
          </h1>
        </div>
        <div className="purpose-applications">
          <h1 className="purpose-application-title">Applicable for</h1>
          <div className="purpose-drop-downs">
            <CompanyDropDown
              className="purpose-team-drop-down purpose-drop-down"
              value={team}
              onChange={this.setTeam}
            >
              {teams}
            </CompanyDropDown>
            <CompanyDropDown
              className="purpose-cost-center-drop-down purpose-drop-down"
              value={costCenter}
              onChange={this.setCostCenter}
            >
              {costCenters}
            </CompanyDropDown>
          </div>
        </div>
      </div>
    );
  }
}
