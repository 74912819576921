import React from 'react';
import PreLoginBox, {
  PreloginBoxTitle,
  PreloginBoxLink
} from 'components/PreLogin/PreLoginBox';
import SignIn from './SignInToSubscription';
export default () => (
  <div className="sign-in-page">
    <PreLoginBox>
      <PreloginBoxTitle>
        Please Log In to Modify Your Subscription
      </PreloginBoxTitle>
      <SignIn />
      <PreloginBoxLink to="/forgot_password">
        Forgot your password?
      </PreloginBoxLink>
    </PreLoginBox>
  </div>
);
