import {
  mapDispatchToEditTripDistanceAndTimeForm,
  mapStateToTripEditDistanceAndTimeForm
} from './EditTripDistanceAndTimeContainer';
import AddReturnTripForm from './EditTripDistanceAndTimeForm';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
const Form = reduxForm({ form: 'edit-trip-form' })(AddReturnTripForm);
export default connect(
  mapStateToTripEditDistanceAndTimeForm,
  mapDispatchToEditTripDistanceAndTimeForm
)(Form);
