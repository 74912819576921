import React, { Component } from 'react';
import CompanyDropDown from 'components/CompanyPages/lib/CompanyDropDown';
import AddButton from 'components/CompanyPages/lib/AddButton';
import './AddCostCenterPage.css';

export default class AddCostCenterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purpose: 'All Purposes',
      teams: '',
      name: '',
      number: '',
      purposeIndex: null
    };
    this.addCostCenter = this.addCostCenter.bind(this);
    this.updateName = this.updateName.bind(this);
    this.updateNumber = this.updateNumber.bind(this);
    this.updatePurpose = this.updatePurpose.bind(this);
    this.updateTeams = this.updateTeams.bind(this);
  }
  addCostCenter() {
    const { onSubmit, addCostCenter, purposes } = this.props;
    const { teams, name, number, purposeIndex } = this.state;
    const purpose = purposes && !isNaN(purposeIndex) && purposes[purposeIndex];
    addCostCenter &&
      addCostCenter({
        name,
        number
      });
    onSubmit && onSubmit();
  }
  updateNumber({ target }) {
    this.setState({ number: target.value });
  }
  updateTeams({ target }) {
    this.setState({ teams: target.value });
  }
  updateName({ target }) {
    this.setState({ name: target.value });
  }
  updatePurpose({ target }) {
    const { purposes } = this.props;
    const purposeIndex = target.id;
    const purpose = purposes && purposes[purposeIndex];
    this.setState({
      purpose: purpose && purpose.name,
      purposeIndex
    });
  }
  render() {
    const { purpose, number, name, teams } = this.state;
    const { purposes } = this.props;
    return (
      <div className="add-cost-center-page">
        {[
          ['Cost center name', name, this.updateName],
          ['Cost center number', number, this.updateNumber],
          ['Applicable for team(s)', teams, this.updateTeams]
        ].map(([title, value, updateMethod]) => (
          <div className="add-cost-center-input-section">
            <h1 className="add-cost-center-input-title">{title}</h1>
            <input
              className="add-cost-center-input"
              value={value}
              onChange={updateMethod}
            />
          </div>
        ))}
        <div className="add-cost-center-input-section">
          <h1 className="add-cost-center-input-title">
            Applicable for purpose
          </h1>
          <CompanyDropDown
            className="add-cost-center-drop-down"
            value={purpose}
            onChange={this.updatePurpose}
          >
            {purposes}
          </CompanyDropDown>
        </div>
        <AddButton
          className="add-cost-center-button"
          onClick={this.addCostCenter}
        >
          Add
        </AddButton>
      </div>
    );
  }
}
