import moment from 'moment';
import { getCompanyPurposes } from 'ducks/company/companySelectors/getCompanyPurposes';
import { updateCostCenter } from 'ducks/costCenters/costCentersActions/updateCostCenter';

export const mapStateToCostCenterElement = (state, { createdAt }) => ({
  purposes: getCompanyPurposes(state),
  formattedDate: moment(createdAt).format('MMMM DD, Y')
});

export const mapDispatchToCostCenterElement = {
  updateCostCenter
};
