const nameSpace = `users`;

export const GET_REQUEST = `${nameSpace}/GET_REQUEST`;
export const GET_SUCCESS = `${nameSpace}/GET_SUCCESS`;
export const GET_FAILURE = `${nameSpace}/GET_FAILURE`;

export const UPDATE_REQUEST = `${nameSpace}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${nameSpace}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${nameSpace}/UPDATE_FAILURE`;

export const SET_ACTIVE = `${nameSpace}/SET_ACTIVE`;

export const GET_USERS_REQUEST = `${nameSpace}/GET_USERS_REQUEST`;
export const GET_USERS_SUCCESS = `${nameSpace}/GET_USERS_SUCCESS`;
export const GET_USERS_FAILURE = `${nameSpace}GET_USERS_FAILURE`;
