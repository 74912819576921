import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SignInOrUpPage from './SignInOrUp';
import SignUpPage from './SignUp/SignUpPage';
import ForgotPasswordPage from './ForgotPassword/ForgotPasswordPage';
import ResetPasswordPage from './ResetPassword/ResetPasswordPage';
import SignInToSubscription from './SignInToSubscribe';
import DeleteAccountPage from 'components/DeleteAccountPage';
import CompanySignUp from './CompanySignUp';
import ConfirmInvitation from 'components/InvitationConfirmation/ConfirmInvitation';
import InvitationConfirmed from 'components/InvitationConfirmation/InvitationConfirmed';
import InvalidUuid from 'components/InvitationConfirmation/InvalidUuid';
import ConfirmOrConfirmed from 'components/InvitationConfirmation/ConfirmOrConfirmed';

const Prelogin = ({ defaultPage }) => (
  <Switch>
    <Route path="/delete" component={DeleteAccountPage} />
    <Route path="/company/sign_up" component={CompanySignUp} />
    <Route path="/invitation/confirm" component={ConfirmInvitation} />
    <Route path="/invitation/confirmed" component={InvitationConfirmed} />
    <Route path="/invitation/invalid" component={InvalidUuid} />
    <Route path="/confirm/invitation" component={ConfirmOrConfirmed} />
    <Route path="/subscription" component={SignInToSubscription} />
    <Route path="/sign_in" component={SignInOrUpPage} />
    <Route path="/sign_up/:r" component={SignUpPage} />
    <Route path="/sign_up" component={SignUpPage} />
    <Route path="/forgot_password" component={ForgotPasswordPage} />
    <Route path="/reset_password/:token" component={ResetPasswordPage} />
    <Redirect to={defaultPage || '/sign_in'} />
  </Switch>
);

export default Prelogin;
