import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { putCompanyLocations } from 'api/endpoints/company/locations';
import {
  PUT_COMPANY_LOCATIONS_REQUEST,
  PUT_COMPANY_LOCATIONS_SUCCESS,
  PUT_COMPANY_LOCATIONS_FAILURE
} from 'ducks/company/companyActionTypes';

export const updateCompanyLocation = createSimpleAction(
  putCompanyLocations,
  [
    PUT_COMPANY_LOCATIONS_REQUEST,
    PUT_COMPANY_LOCATIONS_SUCCESS,
    PUT_COMPANY_LOCATIONS_FAILURE
  ],
  'location'
);

export default updateCompanyLocation;
