import MultiSelectDropDown from 'components/CompanyPages/lib/MultiSelectDropDown';
import React, { Component } from 'react';
import './TeamSelector.css';

export default class InviteUsersPage extends Component {
  constructor(props) {
    const { selected: selectedTeams } = props;
    const selected = (selectedTeams || []).reduce(
      (selected, teamId) => ({
        ...selected,
        [teamId]: true
      }),
      {}
    );

    super(props);

    this.state = { selected };
    this.selectTeam = this.selectTeam.bind(this);
    this.setSelected = this.setSelected.bind(this);
  }

  setSelected(selected) {
    this.setState({ selected });
  }

  selectTeam(teamIndexes) {
    const { teams, onChange } = this.props;
    const selectedTeamIds = teamIndexes.map(index => teams[index].id);

    this.setState({ teamIndexes });

    onChange && onChange(selectedTeamIds);
  }

  render() {
    const { teams, className, title } = this.props;
    const { selected } = this.state;
    const selectedTeams = Object.keys(selected);
    const amountSelected = selectedTeams.length;
    const selectedIndex = selectedTeams[0];
    const firstTeam = teams[selectedIndex];
    const firstName = firstTeam && firstTeam.name;

    return (
      <div className={className}>
        {title && <h1 className="team-selector-title">{title}</h1>}
        <MultiSelectDropDown
          title={amountSelected === 1 ? firstName : 'Select Teams'}
          className="team-selector-drop-down"
          getSelected={this.setSelected}
          onChange={this.selectTeam}
        >
          {teams &&
            teams.map(({ name }, index) => (
              <div
                key={`team-selection-${index}`}
                id={index}
                className="team-selector-element"
              >
                <input
                  id={index}
                  className="team-selection-check-box"
                  type="checkbox"
                  checked={selected[index]}
                />
                <h1 id={index} className="team-selection-name">
                  {name}
                </h1>
              </div>
            ))}
        </MultiSelectDropDown>
      </div>
    );
  }
}
