import { connect } from 'react-redux';
import EditUsersPage from './EditUsersPage';
import {
  mapStateToEditUsersPage,
  mapDispatchToEditUsersPage
} from './editUsersPageMappings';

export default connect(
  mapStateToEditUsersPage,
  mapDispatchToEditUsersPage
)(EditUsersPage);
