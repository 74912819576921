import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Distance from 'components/Distance';
import TimePicker from 'components/TimePicker';
import EstimatedValue from 'components/EstimatedValue';
import Classify from '../../Classify';
import AddressInput from './AddressInput';

import './AddTripForm.css';
import { locationSearch } from 'lib/services/googlePlaces';

const renderDatePickerField = ({ input: { value, onChange } }) => (
  <DatePicker
    maxDate={moment()}
    className="textbox date-input"
    selected={moment(value)}
    onChange={value => onChange(value.format())}
  />
);

const renderTimePickerField = ({ input: { value, onChange } }) => (
  <TimePicker
    value={moment(value)}
    onChange={value => onChange(value.format())}
  />
);

const renderAddressField = ({
  input: { value, onChange },
  meta: _,
  onCoords,
  ...props
}) => (
  <AddressInput
    value={value}
    onChange={onChange}
    onSelect={input => {
      locationSearch(input, props.location).then(coordinates =>
        onCoords(coordinates)
      );
      onChange(input);
    }}
    {...props}
  />
);

const renderPurposeField = ({ input: { value, onChange } }) => (
  <Classify
    selected={value}
    onSelect={id => (id === value ? onChange(null) : onChange(id))}
  />
);

const formSelect = formValueSelector('add-trip');

const TripInfo = connect(state => formSelect(state, 'distance', 'purposeId'))(
  ({ distance, purposeId }) => (
    <div className="add-trip-form__row">
      {distance && (
        <div className="add-trip-form__distance">
          <Distance value={distance} />
        </div>
      )}
      {distance && (
        <EstimatedValue
          className="add-trip-form__price"
          distance={distance}
          purposeId={purposeId}
          {...!purposeId && { type: 'Unclassified' }}
        />
      )}
    </div>
  )
);

const AddTripForm = ({ setPoint, mapFocus }) => (
  <div className="add-trip-form">
    <div className="add-trip-form__date-column">
      <Field name="date" component={renderDatePickerField} />
    </div>
    <div className="add-trip-form__address-column">
      <div className="add-trip-form__row">
        <span className="direction-point">A</span>
        <Field
          component={renderTimePickerField}
          className="time-input"
          name="startTime"
        />
        <Field
          component={renderAddressField}
          name="departureAddress"
          placeholder="Departure address"
          onCoords={coords => setPoint(coords, true, false)}
          location={mapFocus}
        />
      </div>
      <div className="add-trip-form__row">
        <span className="direction-point">B</span>
        <Field
          component={renderTimePickerField}
          className="time-input"
          name="endTime"
        />
        <Field
          component={renderAddressField}
          name="destinationAddress"
          placeholder="Destination address"
          onCoords={coords => setPoint(coords, false, false)}
          location={mapFocus}
        />
      </div>
    </div>
    <div className="add-trip-form__info-column">
      <TripInfo />
      <div className="add-trip-form__row">
        <Field component={renderPurposeField} name="purposeId" />
      </div>
    </div>
  </div>
);

export default AddTripForm;
