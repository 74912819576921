import React, { Component } from 'react';
import DropDown from 'components/CompanyPages/lib/DropDownSelector';
import 'components/CompanyPages/lib/TeamSelector/TeamSelector.css';

export default class SingleTeamSelector extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedIndex: 0 };
    this.selectTeam = this.selectTeam.bind(this);
  }

  selectTeam({ target }) {
    const selectedIndex = target && target.id;
    const { onChange, teams } = this.props;
    const team = teams[selectedIndex];

    this.setState({ selectedIndex });

    onChange && selectedIndex && onChange(team && team.id);
  }

  render() {
    const { teams, className, title } = this.props;
    const { selectedIndex } = this.state;
    const selected = teams[selectedIndex];
    const selectedId = selected.id;
    const selectedName = selected.name;

    return (
      <div className={className}>
        <h1 className="team-selector-title">{title}</h1>
        <DropDown
          title={selectedName && selectedId ? selectedName : title}
          className="team-selector-drop-down"
        >
          {teams &&
            teams
              .map(
                ({ name }, index) =>
                  name !== selectedName && (
                    <div
                      key={`team-selection-${index}`}
                      id={index}
                      className="team-selector-element"
                      onClick={this.selectTeam}
                    >
                      <h1 id={index} className="team-selection-name">
                        {name}
                      </h1>
                    </div>
                  )
              )
              .filter(e => e)}
        </DropDown>
      </div>
    );
  }
}
