import React from 'react';

import './Spinner.css';

const Spinner = () =>
  <div className="spinner">
    <div className="spinner__cube spinner__cube_1" />
    <div className="spinner__cube spinner__cube_2" />
    <div className="spinner__cube spinner__cube_3" />
    <div className="spinner__cube spinner__cube_4" />
    <div className="spinner__cube spinner__cube_5" />
    <div className="spinner__cube spinner__cube_6" />
    <div className="spinner__cube spinner__cube_7" />
    <div className="spinner__cube spinner__cube_8" />
    <div className="spinner__cube spinner__cube_9" />
  </div>;

export default Spinner;
