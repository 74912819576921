import { companyApi } from '../../client';

export const getCompanyTrips = params => companyApi.get('/trips', { params });

export const deleteCompanyTrips = id => companyApi.delete(`/trips/${id}`);

export const putCompanyTrip = (id, data) =>
  companyApi.put(`/trips/${id}`, data);

export const postCompanyTrips = ({ trip }) =>
  companyApi.post('/trips', { trip });

// Statistics
export const getCompanyStatistics = params =>
  companyApi.get('/statistics', { params });
