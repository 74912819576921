import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getRequestById,
  getActive,
  getCurrentUser,
  getActiveId
} from 'ducks/users/selectors';
import { routeToSubscription } from 'ducks/session/sessionActions';
import Portal from './Portal';
import { getCompanyId } from 'ducks/company/companySelectors/company/getCompanyId';
const mapStateToProps = state => ({
  request: getRequestById(state, getActiveId(state)),
  activeUser: getActive(state) || getCompanyId() !== null,
  currentUser: getCurrentUser(state)
});
const mapDispatchToProps = {
  routeToSubscription
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Portal)
);
