import { connect } from 'react-redux';
import PurposesRatesTable from './PurposesRatesTable';
import countries from 'ducks/countries';
import _ from 'lodash';
import moment from 'moment/moment';
import {getMetricMeasure} from "../../ducks/users/selectors";

const mapStateToProps = state => {
  const country = countries.selectors.getUsersCountry(state);
  const request = countries.selectors.getRequest(state);
  const periods = country && country.taxPeriods;
  const taxPeriods =
    _.isArray(periods) &&
    periods.length &&
    _.take(periods.sort((a, b) => moment(a) - moment(b)), 3);
  const rates =
    taxPeriods &&
    taxPeriods[0].rates.map(({ name, mileageRanges }) => {
      const { length } = mileageRanges;
      return mileageRanges.map(({ start, end }, mileageRangeIndex) => ({
        current: length > 1 ? { name, start, end } : name,
        previous: taxPeriods.map(({ rates }) => {
          const rate = rates.find(rate => rate.name === name);
          const mileageRanges = rate && rate.mileageRanges;
          return mileageRanges && mileageRanges[mileageRangeIndex]
            ? mileageRanges[mileageRangeIndex].rate
            : '';
        })
      }));
    });
  const metric = getMetricMeasure(state);
  return {
    request,
    ...(country && { rates, name: country.fullName, taxPeriods, metric })
  };
};

export default connect(mapStateToProps)(PurposesRatesTable);
