import { connect } from 'react-redux';
import {
  mapStateToPurposesPage,
  mapDispatchToPurposesPage
} from './purposesPageMappings';
import PurposesPage from './PurposesPage';

export default connect(
  mapStateToPurposesPage,
  mapDispatchToPurposesPage
)(PurposesPage);
