import * as email from './email';
import * as locations from './locations';
import * as news from './news';
import * as password from './password';
import * as purposes from './purposes';
import * as reports from './reports';
import * as trips from './trips';
import * as users from './users';
import * as vehicles from './vehicles';
import * as workHours from './workHours';

export default {
  ...email,
  ...locations,
  ...news,
  ...purposes,
  ...password,
  ...reports,
  ...trips,
  ...users,
  ...vehicles,
  ...workHours
};
