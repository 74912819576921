import addSuccessAndFailureMessages from 'ducks/lib/addSuccessAndFailureMessages';
import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { putCompanyPurposes } from 'api/endpoints/company/purposes';
import {
  PUT_COMPANY_PURPOSE_REQUEST,
  PUT_COMPANY_PURPOSE_SUCCESS,
  PUT_COMPANY_PURPOSE_FAILURE
} from 'ducks/company/companyActionTypes';

export const updateCompanyPurpose = purpose => dispatch =>
  dispatch(
    addSuccessAndFailureMessages(
      createSimpleAction(
        putCompanyPurposes,
        [
          PUT_COMPANY_PURPOSE_REQUEST,
          PUT_COMPANY_PURPOSE_SUCCESS,
          PUT_COMPANY_PURPOSE_FAILURE
        ],
        'purpose'
      ),
      `Purpose ${purpose && purpose.name ? ` ${purpose.name}` : ''}updated`,
      `Unable to update purpose${
        purpose && purpose.name ? `: ${purpose.name}` : ''
      }`
    )(purpose)
  );

export default updateCompanyPurpose;
