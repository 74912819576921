import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TripToolbar from './TripToolbar';
import selection from 'ducks/selection';
import trips from 'ducks/trips';

const mapStateToProps = state => ({
  selectedCount: selection.selectors.countSelected(state),
  allSelected: _.get(trips.selectors.getCurrentPage(state), 'selectAll', false),
  totalTrips: trips.selectors.getTotalItems(state)
});

const mapDispatchToProps = dispatch => ({
  selectAll: () => dispatch(selection.actions.selectAll()),
  deselectAll: () => dispatch(selection.actions.deselectAll()),
  deleteSelected: bindActionCreators(trips.actions.deleteSelected, dispatch),
  mergeSelected: bindActionCreators(trips.actions.mergeSelected, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TripToolbar);
