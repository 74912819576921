import { connect } from 'react-redux';
import purposes from 'ducks/purposes';
import PurposesMenu from './PurposesMenu';

const mapStateToProps = (state, ownProps) => ({
  purposes: ownProps.includeDefault
    ? purposes.selectors.getActive(state)
    : purposes.selectors.getActiveWithoutDefault(state)
});

export default connect(mapStateToProps)(PurposesMenu);
