import { connect } from 'react-redux';
import reports from 'ducks/reports/index';
import Report from './Report';

const { selectors, actions } = reports;

export default connect(
  (state, { id }) => ({ report: selectors.getById(state, id) }),
  (dispatch, { id }) => ({
    getReport: () => dispatch(actions.getReport(id)),
    deleteReport: () => dispatch(actions.deleteReport(id)),
    starReport: () => dispatch(actions.starReport(id))
  })
)(Report);
