import { createSimpleAction } from 'ducks/lib/simpleActionCreator';
import { putCompanyReports } from 'api/endpoints/company/reports';
import {
  PUT_COMPANY_REPORTS_REQUEST,
  PUT_COMPANY_REPORTS_SUCCESS,
  PUT_COMPANY_REPORTS_FAILURE
} from 'ducks/company/companyActionTypes';

export const updateCompanyReports = (reportIds, params) => dispatch => {
  const { approvalStatus } = params;
  const amount = reportIds ? reportIds.length : 0;

  return dispatch(
    createSimpleAction(
      putCompanyReports,
      [
        PUT_COMPANY_REPORTS_REQUEST,
        PUT_COMPANY_REPORTS_SUCCESS,
        PUT_COMPANY_REPORTS_FAILURE
      ],
      'reports'
    )(reportIds, params)
  ).then(() =>
    dispatch({
      type: 'UPDATE_SUCCESS_ALERT',
      meta: {
        alert: {
          type: 'success',
          message: `${
            approvalStatus ? 'Submitted report' : 'Unsubmitted report'
          }${amount > 1 ? 's' : ''}`
        }
      }
    })
  );
};

export default updateCompanyReports;
