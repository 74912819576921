import { rolesByName } from 'components/CompanyPages/lib/roles';
import { getRole } from 'lib/auth';

export const userIsMember = () => {
  const { member } = rolesByName;
  const role = getRole();
  return role && role.toLowerCase() === member.id;
};

export default userIsMember;
