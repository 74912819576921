import { connect } from 'react-redux';
import { getCurrentUser } from 'ducks/users/selectors';
import deleteUser from 'ducks/users/userActions/deleteUser';
import SubscriptionInfo from './SubscriptionInfo';

const mapStateToProps = state => {
  const user = getCurrentUser(state);
  return {
    subscription: user && user.subscription
  };
};

const mapDispatchToProps = { deleteUser };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionInfo);
