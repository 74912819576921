import React, { Component } from 'react';
import classNames from 'class-names';
import FontAwesome from 'react-fontawesome';
import Trigger from 'rc-trigger';
import PurposesMenu from 'components/PurposesMenu';

import './Classify.css';

const BUSINESS = 'BUSINESS';
const PERSONAL = 'PERSONAL';
const OTHER = 'OTHER';

// purposes that aren't explicity "Personal" but should be counted as such
const PERSONAL_PURPOSES = ['Medical', 'Moving', 'Charity', 'Commute'];

export default class Classify extends Component {
  render() {
    const {
      selected,
      disabled,
      onSelect,
      business,
      personal,
      other
    } = this.props;

    const active = selected
      ? selected === (business || {}).id
        ? BUSINESS
        : selected === (personal || {}).id
          ? PERSONAL
          : OTHER
      : null;

    const otherPurpose = other.find(p => p.id === selected);
    const otherText = otherPurpose ? otherPurpose.name : 'Other';
    const isOtherPersonal = otherPurpose
      ? otherPurpose.type === 'business' &&
        PERSONAL_PURPOSES.indexOf(otherPurpose.name) > -1
      : false;

    return (
      <div className={classNames('classify', { classify_disabled: disabled })}>
        <button
          type="button"
          onClick={() => onSelect(business.id)}
          className={classNames('classify__btn', {
            classify__btn_selected: active === BUSINESS
          })}
          disabled={!business}
        >
          <span className="purpose-indicator purpose-indicator_business" />{' '}
          Business
        </button>

        <button
          type="button"
          onClick={() => onSelect(personal.id)}
          className={classNames('classify__btn', {
            classify__btn_selected: active === PERSONAL
          })}
          disabled={!personal}
        >
          <span className="purpose-indicator purpose-indicator_personal" />{' '}
          Personal
        </button>

        <Trigger
          action={['click']}
          popup={<PurposesMenu value={selected} onChange={onSelect} />}
          popupAlign={{ points: ['cl', 'cr'], offset: [20, 0] }}
          // close menu on classify (or other update to the trip)
          {...disabled && { popupVisible: false }}
        >
          <button
            type="button"
            onClick={this.toggle}
            className={classNames('classify__btn', 'classify__btn_other', {
              classify__btn_selected: active === OTHER
            })}
          >
            <span
              className={classNames('purpose-indicator', {
                'purpose-indicator_personal': isOtherPersonal
              })}
            />{' '}
            {otherText}
            <FontAwesome className="classify__btn__icon" name="angle-right" />
          </button>
        </Trigger>
      </div>
    );
  }
}
