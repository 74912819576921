import React from 'react';
import CompactTripGroup from './CompactTripGroup';
import groupTripsByDate from './lib/groupTripsByDate';
import sortDates from './lib/sortDates';
export default ({ trips }) => {
  const tripsGroupedByDate = groupTripsByDate(trips);
  const sortedDates = sortDates(Object.keys(tripsGroupedByDate));
  return sortedDates.map(date => (
    <CompactTripGroup trips={tripsGroupedByDate[date]} date={date} />
  ));
};
