import { allSelected } from './allSelected';
import { getSelected } from './getSelected';
import { isSelected } from './isSelected';
import { paginateSelectors } from 'ducks/lib/Pagination/paginateSelectors';

export default paginateSelectors({
  allSelected,
  getSelected,
  isSelected
});
