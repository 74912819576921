import React, { Component } from 'react';
export default class LocationHint extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    const { onClick, location } = this.props;
    onClick && onClick(location);
  }
  render() {
    const { location, className } = this.props;
    return (
      <button
        className={className}
        key={`location-hint-${location.id}`}
        onClick={this.onClick}
      >
        {location.name}
      </button>
    );
  }
}
