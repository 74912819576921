import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './ViewNavigator.css';

export default class ViewNavigator extends Component {
  render() {
    const { links, title: selected } = this.props;

    return (
      <div className="view-navigator">
        {links.map(({ path, title }, index) => (
          <NavLink className="link" title={title} key={path} to={path}>
            <div
              key={`nav-link-button=${index + 1}`}
              className={`${
                selected === title ? 'selected ' : ''
              }navigation-link-box`}
              title={title}
            >
              {title}
            </div>
          </NavLink>
        ))}
      </div>
    );
  }
}
