import React from "react";
import PropTypes from "prop-types";
import HeaderDropdown from "components/Header/HeaderDropdown";

const propTypes = {
  activeUser: PropTypes.object,
  users: PropTypes.array,
  switchUser: PropTypes.func.isRequired
};

const UserSwitcher = ({ activeUser, users, switchUser }) =>
  activeUser && users.length ? (
    <HeaderDropdown
      className="switch-user-drop-down"
      options={users
        .sort(user => user.email.split("@"))
        .reverse()
        .map(user => [user.id, user.email])}
      value={activeUser.email}
      onSelect={id => switchUser(id)}
    />
  ) : null;

UserSwitcher.propTypes = propTypes;
export default UserSwitcher;
