import React, { Component, Children } from 'react';
import Mousetrap from 'mousetrap';
import moment from 'moment';
import 'components/HighlightSelected/HighlightSelected.css';

export default class SplitIntoDatesAndHighlight extends Component {
  constructor(props) {
    super(props);

    this.mapOverDates = this.mapOverDates.bind(this);
    this.splitChildrenByDates = this.splitChildrenByDates.bind(this);
    this.activateNext = this.activateNext.bind(this);
    this.activatePrevious = this.activatePrevious.bind(this);
    this.select = this.select.bind(this);

    this.elements = [];
    this.state = { selected: 0 };
  }

  getElementHeight() {
    const { selected } = this.state;
    const { getElementHeight } = this.props;
    const element = this.elements[selected];
    const height = (element && element.clientHeight) || 0;

    getElementHeight && getElementHeight(height);

    return height;
  }

  select(selected) {
    const { onChange } = this.props;

    this.setState({ selected });
    this.getElementHeight();

    onChange && onChange(selected);
  }

  // keyboard shortcuts
  componentWillMount() {
    Mousetrap.bind(['up', 'w', 'k'], () => {
      this.activatePrevious();

      return false;
    });

    Mousetrap.bind(['down', 's', 'j'], () => {
      this.activateNext();

      return false;
    });
  }

  componentWillUnmount() {
    Mousetrap.unbind(['up', 'w', 'k', 'down', 's', 'j']);
  }

  activateNext() {
    const { selected } = this.state;
    const { children } = this.props;
    const lastElement = children && children.length - 1;
    const nextElement = selected + 1;
    const firstElement = 0;

    if (selected >= firstElement) {
      this.select(selected < lastElement ? nextElement : firstElement);
    }
  }

  activatePrevious() {
    const { selected } = this.state;
    const previousElement = selected - 1;
    const firstElement = 0;

    if (selected > firstElement) {
      this.select(previousElement);
    }
  }

  mapOverDates(elementsByDate, formatDateList) {
    return Object.keys(elementsByDate).map(date =>
      formatDateList(elementsByDate[date], date)
    );
  }

  splitChildrenByDates(children, elements) {
    const elementsByDate = {};

    Children.forEach(children, (childElement, index) => {
      const element = elements[index];
      const date = moment(element.startTime).format('ddd DD MMM, YYYY');

      if (elementsByDate[date]) {
        elementsByDate[date].push(childElement);
      } else {
        elementsByDate[date] = [childElement];
      }
    });

    return elementsByDate;
  }

  render() {
    const { selected } = this.state;
    const { children, elements, format } = this.props;
    const className = 'highlight-able-element';

    let index = 0;

    return this.mapOverDates(
      this.splitChildrenByDates(children, elements),
      (elements, date) =>
        format &&
        format(
          elements.map(element => {
            const currentIndex = index;
            const formattedElement = (
              <div
                key={`${className}-${currentIndex}`}
                ref={element => element && this.elements.push(element)}
                className={`${className}${
                  currentIndex === selected ? ' highlighted-element' : ''
                }`}
                onClick={() => this.select(currentIndex)}
              >
                {element}
              </div>
            );

            index += 1;

            return formattedElement;
          }),
          date
        )
    );
  }
}
