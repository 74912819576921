import React, { Component } from 'react';
import PageTitleHeader from 'components/CompanyPages/lib/PageTitleHeader';
import GetImageButton from 'components/CompanyPages/lib/GetInputButton';
import ScrollBars from 'components/Scrollbars';
import AddButton from 'components/CompanyPages/lib/AddButton';
import moment from 'moment';
import Money from 'components/Money';
import RenderPage from 'lib/RenderPage';
import { differenceInDays } from 'components/CompanyPages/lib/dateDifference/differenceInDays';
import RateInput from 'components/RateInput';
import CompanyAccountsCardInfo from './CompanyAccountsCardInfo';
import confirmation from 'components/Confirmation';
import { Elements, StripeProvider } from 'react-stripe-elements';
import './CompanyAccountPage.css';

const ConfirmButton = confirmation(AddButton);

export default class CompanyAccountPage extends Component {
  constructor(props) {
    const { company, customBusinessRate } = props;

    super(props);

    this.state = {
      numberOfSeatsSelected: 0,
      companyName: company ? company.name : 'Hey Joe',
      logo: null,
      costOfAdditionalSeats: 0,
      customBusinessRate: customBusinessRate || 0
    };

    this.handleSeatInput = this.handleSeatInput.bind(this);
    this.chooseCompanyLogo = this.chooseCompanyLogo.bind(this);
    this.updateCompanyName = this.updateCompanyName.bind(this);
    this.setCustomBusinessRate = this.setCustomBusinessRate.bind(this);
  }

  componentWillMount() {
    const { loadCompany } = this.props;

    loadCompany && loadCompany();
  }

  updateCompanyName({ target }) {
    this.setState({ companyName: target.value });
  }

  setCustomBusinessRate(customBusinessRate) {
    this.setState({ customBusinessRate });
  }

  chooseCompanyLogo({ image }) {
    this.setState({ logo: image });
  }

  handleSeatInput({ target }) {
    const { costPerSeat } = this.props;
    const seats = Number(target.value);

    this.setState({
      numberOfSeatsSelected: seats,
      costOfAdditionalSeats: seats * costPerSeat
    });
  }

  render() {
    const {
      seats,
      expirationDate,
      renewalCost,
      employeeCount,
      activeEmployeeCount,
      openSeatCount
    } = this.props;

    const {
      numberOfSeatsSelected,
      costOfAdditionalSeats,
      customBusinessRate,
      companyName,
      logo
    } = this.state;

    return (
      <ScrollBars>
        <div className="company-account-page">
          <RenderPage>
            <PageTitleHeader>Business Information</PageTitleHeader>
            <div className="company-account-information-section">
              <div className="company-account-name">
                <h1 className="company-account-name-title">Company name</h1>
                <input
                  type="text"
                  value={companyName}
                  onChange={this.updateCompanyName}
                  className="company-account-name-input"
                />
              </div>
              <div className="company-account-name">
                <h1 className="company-account-name-title">Expense rate</h1>
                <RateInput
                  type="text"
                  defaultValue={customBusinessRate}
                  onChange={this.setCustomBusinessRate}
                  className="company-account-rate-input"
                />
              </div>
              <div className="company-account-description">
                <h1 className="company-account-description-text">
                  Upload your company logo to customize your email invites,
                  reports and more. Requirements: PNG or JPG file, max size
                  100mb, recommended dimensions 100x100px.
                </h1>
              </div>
              <div className="company-account-image-section">
                <div className="company-account-image-display">
                  <img
                    src={logo}
                    className="company-account-image"
                    alt={'100 x 100 px'}
                  />
                </div>
                <GetImageButton
                  className="company-account-image-button-position"
                  buttonStyling="company-account-image-button"
                  onChange={this.chooseCompanyLogo}
                >
                  Choose
                </GetImageButton>
              </div>
            </div>
            <PageTitleHeader>Billing</PageTitleHeader>
            <div className="company-account-seats-section">
              <div className="company-account-purchase">
                <h1 className="company-account-seats-title">
                  Buy additional seats
                </h1>
                <h1 className="company-account-purchase-description">
                  Your subscription expires in{' '}
                  {differenceInDays(moment(), expirationDate)} days after which
                  trips will no longer be logged.
                </h1>
                <h1 className="company-account-purchase-description">
                  You currently have {employeeCount} employees using
                  MileCatcher.
                  {activeEmployeeCount} of them have been assigned seats. you
                  have {openSeatCount} seats remaining.
                </h1>
                <div className="company-account-purchase-input-container">
                  <h1>Enter number of seats:</h1>
                  <input
                    type="number"
                    defaultValue={openSeatCount}
                    className="company-account-purchase-input"
                    onChange={this.handleSeatInput}
                  />
                </div>
                <h1 className="company-account-cost-display">
                  Pro rated cost plus tax:{' '}
                  <Money>{costOfAdditionalSeats}</Money>
                </h1>
                <ConfirmButton
                  classname="company-account-purchase-button company-account-billing-button"
                  message={`Are you sure you want to purchase ${numberOfSeatsSelected} additional seats?`}
                >
                  Buy
                </ConfirmButton>
              </div>
              <div className="company-account-renewal">
                <h1 className="company-account-seats-title">
                  Renew subscription
                </h1>
                <h1 className="company-account-renewal-description">
                  You currently have {seats} seats expiring on {expirationDate}.{' '}
                  Annual renewal cost is <Money>{renewalCost}</Money> plus tax.
                </h1>
                <h1 className="company-account-renewal-description">
                  Renewal period will start on {expirationDate}. Prepay now.
                </h1>
                <ConfirmButton
                  className="company-account-renewal-button company-account-billing-button"
                  message="Are you sure you would like to renew your subscription?"
                >
                  Renew
                </ConfirmButton>
              </div>
            </div>
            <PageTitleHeader>Payment information</PageTitleHeader>
            <StripeProvider apiKey="pk_test_TYooMQauvdEDq54NiTphI7jx">
              <Elements>
                <CompanyAccountsCardInfo {...this.props} />
              </Elements>
            </StripeProvider>
            <PageTitleHeader>Invoices</PageTitleHeader>
            <div className="company-invoice-section">
              <h1 className="no-invoice-message">No invoices</h1>
            </div>
          </RenderPage>
        </div>
      </ScrollBars>
    );
  }
}
