import { connect } from 'react-redux';
import AddReportPage from './AddReportPage';
import {
  mapStateToAddReportPage,
  mapDispatchAddToReportPage
} from './addReportPageMappings';

export default connect(
  mapStateToAddReportPage,
  mapDispatchAddToReportPage
)(AddReportPage);
