import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import Rate from 'components/Rate';
import DeleteButton from 'components/DeleteButton';

const Handle = SortableHandle(({ purpose, metric }) =>
  <div className="purpose-card__content">
    <div className="purpose-card__name">{purpose.name}</div>
    <div className="purpose-card__rate">
      <Rate value={purpose.rate.mileageRanges[0].rate} metric={metric} />
    </div>
  </div>
);

const PurposeCard = ({ purpose, destroy, metric }) =>
  <div className="purpose-card">
    <Handle purpose={purpose} metric={metric} />

    <DeleteButton
      className="purpose-card__delete"
      onClick={() => destroy(purpose.id)}
    />
  </div>;

export default PurposeCard;
