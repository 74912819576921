import { connect } from 'react-redux';
import InvitationConfirmed from './InvitationConfirmed';
import {
  mapStateToInvitationConfirmed,
  mapDispatchToInvitationConfirmed
} from './invitationConfirmedMappings';

export default connect(
  mapStateToInvitationConfirmed,
  mapDispatchToInvitationConfirmed
)(InvitationConfirmed);
