import { getCompanyCostCenters as loadCostCenters } from 'ducks/costCenters/costCentersActions/getCompanyCostCenters';
import { getCompanyPurposes as loadPurposes } from 'ducks/company/companyActions/purposes/getCompanyPurposes';
import { getTeams as loadTeams } from 'ducks/teams/teamsActions/getTeams';
import { getTeamsArray } from 'ducks/teams/teamsSelectors/getTeamsArray';
import { getCompanyPurposes } from 'ducks/company/companySelectors/getCompanyPurposes';
import { removeCostCenter } from 'ducks/costCenters/costCentersActions/removeCostCenter';
import getCostCenters from 'ducks/costCenters/costCentersSelectors/getCostCenters';

export const mapStateToCostCenters = state => ({
  costCenters: getCostCenters(state),
  purposes: [
    'All Purposes',
    ...getCompanyPurposes(state).map(({ name }) => name)
  ],
  teams: ['All Teams', ...getTeamsArray(state).map(({ name }) => name)]
});

export const mapDispatchToCostCenters = {
  removeCostCenter,
  loadCostCenters,
  loadPurposes,
  loadTeams
};
