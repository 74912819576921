export const addSuccessMessage = (action, message) => (...args) => dispatch =>
  dispatch(action(...args)).then(() =>
    dispatch({
      type: 'SUCCESS_NOTIFICATION',
      meta: {
        alert: {
          type: 'success',
          message
        }
      }
    })
  );

export default addSuccessMessage;
